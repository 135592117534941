import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import AuthUser from "components/Sb/AuthUser";

// toast
import { toast } from 'react-toastify'

//table component
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";


// model
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

import stylesModel from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
const useStylesModel = makeStyles(stylesModel);



import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";

//input 
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
const useStyles = makeStyles(styles);

// Radio style
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);

import FileCopy from "@material-ui/icons/FileCopy";


export default function ConsultantProposalView(props) {
    const classesModel = useStylesModel();
    const classes = useStyles();
    const classesRadio = useStylesRadio()

    //view variables : 
    const [amountView, setViewAmount] = React.useState("")
    const [gstView, setViewGst] = React.useState("")
    const [panView, setViewPan] = React.useState("")
    //const [doc, setDoc] = React.useState("")
    const [proposalsrc, setProposalSrc] = React.useState("")

    //edit variables :
    const [isgst, setIsgst] = React.useState("")
    const [proposalDoc, setproposalDoc] = React.useState(null)
    const [amount, setAmount] = React.useState("")
    const [gst, setGst] = React.useState("")
    const [pan, setPan] = React.useState("")



    //modal variables :
    const [proposalModal, setProposalModal] = React.useState(false)
    const [classicModal, setClassicModal] = React.useState(false);



    const [fileName, setfileName] = React.useState("")

    const { ProjectId } = props
    const { httpAxios, http, getLoading } = AuthUser()

    // Error
    const [fileErrorMsg, setFileErrorMsg] = React.useState("")
    const [formError, setFormError] = React.useState({})
    let formErr = {}


    // Reset Values 
    const resolve = () => {
        setFormError({})
        setAmount("")
        setIsgst("")
        setGst("")
        setPan("")
    }

    const getProposal = () => {
        const data = { 'pid': ProjectId }
        http.post("/customer/getProposalCon", data)
            .then((response) => {
                if (response.status === 200) {
                    let proposal = response.data

                    //set view method

                    setViewAmount(proposal.ammount)
                    setViewGst(proposal.gst)
                    setViewPan(proposal.pan)
                    //setDoc(proposal.proposal_doc)
                    setProposalSrc(proposal.doc_src)

                    //set edit method
                    setIsgst(proposal.is_gst)
                    setAmount(proposal.ammount)
                    setGst(proposal.gst)
                    setPan(proposal.pan)


                }
            }).catch((err) => {
                console.log(err)
            })
    }

   
   


    React.useEffect(() => {

        getProposal()
        
    }, [classicModal])

    // checking Form Error function
    const handleFormError = () => {

        if (amount.length === 0) {
            formErr.amount = "Error in Amount"
        }

        if (isgst === "") {
            formErr.isgst = "Error in isgst"
        }

        if (isgst == 1) {
            if (gst.trim().length === 0) {
                formErr.gst = "Error in Gst"
            }
        }

        if (isgst == 0) {
            if (pan.trim().length === 0) {
                formErr.pan = "Error in pan"
            }
        }

        //upload file validation
        let element = document.getElementById("custom-file-upload");

        if (!proposalDoc) {
            formErr.proposalDoc = "Error in proposalDoc"
            element.classList.add("custom-error");
            setFileErrorMsg("Please! Select your file");
        }
        else {
            //file size validation
            // const MIN_FILE_SIZE = 1024 // 1MB
            const MAX_FILE_SIZE = 5120 // 5MB

            const fileSizeKiloBytes = proposalDoc.size / 1024

            // if (fileSizeKiloBytes < MIN_FILE_SIZE) {
            //     formErr.proposalDoc = "Error in proposalDoc"
            //     element.classList.add("custom-error");
            //     setFileErrorMsg("File size is less than minimum limit");
            // }

            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                formErr.proposalDoc = "Error in proposalDoc"
                element.classList.add("custom-error");
                setFileErrorMsg("File size is greater than maximum limit");
            }

        }
    }

    const handleFormSubmit = (event) => {
        event.preventDefault()
        handleFormError()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)

            if (formErr.isgst) {
                let ele = document.getElementById("gstis");
                ele.classList.add("custom-error");
            }
            formErr = {}
        } else {
            const data = { amount, isgst, gst, pan, proposalDoc, ProjectId }
            console.log(data)

            httpAxios.post("/customer/updateProposalDoc", data)
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data
                        console.log(data)
                        toast.success('Proposal has been Updated Successfully.', {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 1500,
                            onClose: () => getProposal()
                        })

                        
                        //gst reset for successfull edit
                        var elem = document.getElementById("gstis");
                        if (elem.classList.contains("custom-success")) {
                            elem.classList.remove("custom-success");
                        }
                       
                        //proposal_doc reset for successfull edit
                        var element = document.getElementById("custom-file-upload");
                        if (element.classList.contains("custom-success")) {
                           element.classList.remove("custom-success");
                        }
                        setfileName("");
                        //close the edit modal
                        setClassicModal(false)
                        
                        resolve()


                    }
                }).catch((err) => {
                    console.log(err)
                })


        }
    }

    const isGstValidate = () => {

        var elem = document.getElementById("gstis");
        if (elem.classList.contains("custom-error")) {
            elem.classList.remove("custom-error");
            elem.classList.add("custom-success");
        }
        else {
            elem.classList.add("custom-success");
        }

    }

    const fileHandle = (e) => {
        var file = e.target.files;
        setFileErrorMsg("");

        if (file.length > 0) {
            setfileName(file[0].name);
        }

        var element = document.getElementById("custom-file-upload");
        if (element.classList.contains("custom-error")) {
            element.classList.remove("custom-error");
            element.classList.add("custom-success");
        }
        else {
            element.classList.add("custom-success");
        }

    }


    return (
        <>

            <div className={classes.tableResponsive}>
                <Table className="table">
                    <TableBody>
                        <TableRow className={"tableBackground"} >
                            <TableCell>Amount Without GST</TableCell>
                            <TableCell >{amountView}</TableCell>
                        </TableRow>
                        {
                            (gstView != "" && gstView != null) &&
                            <TableRow  >
                                <TableCell>GST</TableCell>
                                <TableCell>{(gstView != null) ? gstView : "NA"}</TableCell>
                            </TableRow>
                        }
                        {(panView != "" && panView != null) &&
                            <TableRow className={"tableBackground"}>
                                <TableCell >PAN</TableCell>
                                <TableCell >{panView}</TableCell>
                            </TableRow>
                        }

                        <TableRow className={"tableBackground"}>
                            <TableCell>Proposal Doc.</TableCell>
                            <TableCell>
                                <Button type="button" round color="rose" onClick={() => setProposalModal(true)}>View</Button></TableCell>
                        </TableRow>


                    </TableBody>
                </Table>
            </div>

            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={4}>
                    <Button style={{ width: '100%', marginTop: "2vh" }} type="submit" round color="success" onClick={() => setClassicModal(true)}>Edit</Button>
                </GridItem>
            </GridContainer > 

            {/* view prposal_doc Model */}
            <GridItem xs={12} sm={12} md={12}>
                <Dialog
                    classes={{
                        root: classesModel.center + " " + classesModel.modalRoot,
                        paper: classesModel.modalBg,
                    }}
                    open={proposalModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setProposalModal(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classesModel.modalHeader}
                    >
                        <Button
                            justIcon
                            className={classesModel.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="transparent"
                            onClick={() => setProposalModal(false)}
                        >
                            <Close className={classesModel.modalClose} />
                        </Button>
                        <h4 className={classesModel.modalTitle}>Proposal Doc</h4>
                    </DialogTitle>

                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classesModel.modalBody}
                    >

                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <iframe className="doc_iframe" src={proposalsrc} width="100%" height="800" style={{ border: "none" }}
                                allowFullScreen>
                            </iframe>
                        </GridItem>


                    </DialogContent>
                </Dialog>
            </GridItem>


            {/* edit proposal Model */}
            <GridItem xs={12} sm={12} md={12}>
                <Dialog
                    classes={{
                        root: classesModel.center + " " + classesModel.modalRoot,
                        paper: classesModel.modalBg,
                    }}
                    open={classicModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setClassicModal(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classesModel.modalHeader}
                    >
                        <Button
                            justIcon
                            className={classesModel.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="transparent"
                            onClick={() => setClassicModal(false)}
                        >
                            <Close className={classesModel.modalClose} />
                        </Button>
                        <h4 className={classesModel.modalTitle}>Edit Project Proposal</h4>
                    </DialogTitle>
                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classesModel.modalBody}
                    >

                        <form onSubmit={handleFormSubmit}>
                            <GridContainer justify="center">
                                {/* Amount without GST */}
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomInput
                                        success={amount.length > 0 ? true : false}
                                        error={Object.keys(formError).length > 0 && formError.amount ? true : false}
                                        labelText={
                                            <span>
                                                Amount without GST <small>(required)</small>
                                            </span>
                                        }
                                        id="amount"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: 'amount',
                                            type: 'number',
                                            value: amount,
                                            onChange: (event) => {
                                                setAmount(event.target.value);
                                                //delete formError.amount
                                            },

                                            endAdornment: (
                                                <InputAdornment position="end" className={classes.inputAdornment}>
                                                    {/* <AttachMoney className={classes.inputAdornmentIcon} /> */}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />


                                    <GridContainer justify="flex-start" alignItems="baseline">
                                        <GridItem xs={4} sm={4} md={4} lg={4}>
                                            <label style={{ fontSize: "18px" }} id="gstis">GST Applicable?</label>
                                        </GridItem>
                                        <GridItem xs={4} sm={4} md={4} lg={4} >
                                            <div
                                                className={
                                                    classesRadio.checkboxAndRadio +
                                                    " " +
                                                    classesRadio.checkboxAndRadioHorizontal
                                                }
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={isgst === 1}
                                                            onChange={() => {
                                                                setIsgst(1)
                                                                isGstValidate()
                                                            }}
                                                            name="is_gst"
                                                            aria-label="Yes"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classesRadio.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classesRadio.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classesRadio.radio,
                                                                root: classesRadio.radioRoot,
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classesRadio.label,
                                                        root: classesRadio.labelRoot,
                                                    }}
                                                    label="Yes"

                                                />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={4} sm={4} md={4} lg={4} >
                                            <div
                                                className={
                                                    classesRadio.checkboxAndRadio +
                                                    " " +
                                                    classesRadio.checkboxAndRadioHorizontal
                                                }
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={isgst === 0}
                                                            onChange={() => {
                                                                setIsgst(0)
                                                                isGstValidate()
                                                            }}
                                                            name="is_gst"
                                                            aria-label="No"
                                                            icon={
                                                                <FiberManualRecord
                                                                    className={classesRadio.radioUnchecked}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <FiberManualRecord
                                                                    className={classesRadio.radioChecked}
                                                                />
                                                            }
                                                            classes={{
                                                                checked: classesRadio.radio,
                                                                root: classesRadio.radioRoot,
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classesRadio.label,
                                                        root: classesRadio.labelRoot,
                                                    }}
                                                    label="No"

                                                />
                                            </div>
                                        </GridItem>
                                    </GridContainer>

                                    {(isgst == 1 &&
                                        <CustomInput
                                            success={gst.length > 0 ? true : false}
                                            error={Object.keys(formError).length > 0 && formError.gst ? true : false}
                                            labelText={
                                                <span>
                                                    GST Number <small>(required)</small>
                                                </span>
                                            }
                                            id="gst"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                name: 'gst',
                                                value: gst,
                                                onChange: (event) => {
                                                    setGst(event.target.value);
                                                    delete formError.gst
                                                },


                                                endAdornment: (
                                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                                        {/* <AttachMoney className={classes.inputAdornmentIcon} /> */}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}


                                    {(isgst == 0 &&
                                        <CustomInput
                                            success={pan.length > 0 ? true : false}
                                            error={Object.keys(formError).length > 0 && formError.pan ? true : false}
                                            labelText={
                                                <span>
                                                    PAN Number <small>(required)</small>
                                                </span>
                                            }
                                            id="pan"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                name: 'pan',
                                                value: pan,
                                                onChange: (event) => {
                                                    setPan(event.target.value);
                                                    delete formError.pan
                                                },

                                                endAdornment: (
                                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                                        {/* <AttachMoney className={classes.inputAdornmentIcon} /> */}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                    <div className='custom-div'>
                                        <label className="custom-file-upload" id="custom-file-upload">
                                            <input type="file" onChange={(e) => { setproposalDoc(e.target.files[0]); fileHandle(e); }} name="proposal_doc" accept=".pdf" />
                                            <span className='attach'>Upload your quotation (Max File Size : 5mb)</span> <FileCopy className={classes.inputAdornmentIcon} />
                                            <div className="file-preview">{fileName}</div>

                                        </label>

                                        <span className='custom-error' style={{ fontWeight: "500" }}>{(fileErrorMsg != "") ? fileErrorMsg : ""}</span>

                                    </div>





                                    <GridContainer justify="center">

                                        <GridItem xs={12} sm={4} md={4}>
                                            <Button style={{ width: '100%' }} type="submit" round color="info">{!getLoading() ? "Submit" : "Please Wait..."}</Button>
                                        </GridItem>

                                    </GridContainer>




                                </GridItem>
                            </GridContainer>
                        </form>


                    </DialogContent>
                </Dialog>
            </GridItem>


        </>
    )
}

ConsultantProposalView.propTypes = {
    ProjectId: PropTypes.string,
    getProvider: PropTypes.func
}

