import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// import AuthUser from "components/Sb/AuthUser";
// import { toast } from "react-toastify";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
// import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
// import Email from "@material-ui/icons/Email";
// import Money from "@material-ui/icons/Money";
// import Business from "@material-ui/icons/Business";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import NoteAdd from "@material-ui/icons/NoteAdd";
// import PanTool from "@material-ui/icons/PanTool";
// import LocationCity from "@material-ui/icons/LocationCity";
// import LocationOn from "@material-ui/icons/LocationOn";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
// import { OutlinedInput } from "@material-ui/core";
// import { useParams } from "react-router-dom";

import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
const useStylesTable = makeStyles(stylesTable);

const useStyles = makeStyles(styles);

const PaymentBreakdownPDForm = (props) => {
  const classesTable = useStylesTable();
  const { setpaymentBreakdownModal } = props;
  const classes = useStyles();
  //   const params = useParams();
  //   const history = useHistory();

  //   getUser
  // const { http } = AuthUser();
  // paymentBreakdownModal

  const recdData = [
    {
      date: "2023-10-19",
      value: "59,000.00",
    },
    {
      date: "2023-10-19",
      value: "59,000.00",
    },
  ];

  return (
    <>
      <GridContainer>
        {/* Industry Recd. */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div className={classesTable.tableResponsive}>
              <h6 style={{textAlign:"left"}}>Industry Recd.</h6>
              <table className="freshLeadTable commonTable">
                <thead>
                  <tr>
                    <th>Sr no. </th>
                    <th>Date</th>
                    <th>Industry Recd.</th>
                  </tr>
                </thead>
                <tbody>
                  {recdData.map((eleData, ed) => {
                    return (
                      <tr key={ed} className={classesTable.tableRowHover}>
                        <td>{ed + 1}</td>
                        <td>{eleData.date}</td>
                        <td style={{ width: "200px" }}>{eleData.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </GridItem>
        {/* Industry Payments(Invoice) */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div className={classesTable.tableResponsive}>
              <h6 style={{textAlign:"left"}}>Industry Payments(Invoice)</h6>
              <table className="freshLeadTable commonTable">
                <thead>
                  <tr>
                    <th>Sr no. </th>
                    <th>Date</th>
                    <th>Industry Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {recdData.map((eleData, ed) => {
                    return (
                      <tr key={ed} className={classesTable.tableRowHover}>
                        <td>{ed + 1}</td>
                        <td>{eleData.date}</td>
                        <td style={{ width: "200px" }}>{eleData.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </GridItem>
        {/* Experts(Paid) */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <div className={classesTable.tableResponsive}>
              <h6 style={{textAlign:"left"}}>Experts(Paid)</h6>
              <table className="freshLeadTable commonTable">
                <thead>
                  <tr>
                    <th>Sr no. </th>
                    <th>Date</th>
                    <th>Amount Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {recdData.map((eleData, ed) => {
                    return (
                      <tr key={ed} className={classesTable.tableRowHover}>
                        <td>{ed + 1}</td>
                        <td>{eleData.date}</td>
                        <td style={{ width: "200px" }}>{eleData.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </GridItem>

        <GridItem xs={4} sm={6} md={6} lg={6}>
          <div style={{ textAlign: "right" }}>
            <Button
              round
              color="danger"
              className={classes.registerButton}
              onClick={() => setpaymentBreakdownModal(false)}
            >
              cancle
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
};

PaymentBreakdownPDForm.propTypes = {
  setpaymentBreakdownModal: PropTypes.func,
  // paymentBreakdownModal: PropTypes.bool,
};

export default PaymentBreakdownPDForm;
