import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { useHistory } from "react-router-dom";
const useStylesTable = makeStyles(stylesTable);

const QualifiedLeadsDB = (props) => {
  const {qualified_customer} = props
  const classesTable = useStylesTable();
  const history = useHistory()
  const [data, setdata] = React.useState([]);

  React.useEffect(() => {
    setdata(qualified_customer);
  }, [qualified_customer]);
  return (
    <>
      <Card>
        <h4>Qualified Leads (Last 30 days)</h4>
        <CardBody>
          <div className={classesTable.tableResponsive}>
            <Table className="commonTable">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Customer Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Requirement</TableCell>
                  <TableCell>Registered Date</TableCell>
                  <TableCell>Grade</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Last Contacted</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((eleData, ed) => {
                  return (
                    <TableRow key={ed} className={classesTable.tableRowHover}>
                      <TableCell>{eleData.customer_id}</TableCell>
                      <TableCell>{eleData.customer_type}</TableCell>
                      <TableCell>{eleData.firstname}</TableCell>
                      <TableCell>{eleData.requirement}</TableCell>
                      <TableCell>{eleData.date_added}</TableCell>
                      <TableCell>{eleData.grade}</TableCell>
                      <TableCell>{eleData.score}</TableCell>
                      <TableCell>{eleData.lastContacted}</TableCell>
                      <TableCell>
                        <Button color="rose" round onClick={()=>{
                            history.push(`/admin/customer/${eleData.customer_id}`)
                          }}>
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
QualifiedLeadsDB.propTypes = {
  qualified_customer: PropTypes.array,
};
export default QualifiedLeadsDB;
