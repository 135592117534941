import React from "react";

 import AuthUser from "components/Sb/AuthUser";
// import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

// @material-ui/icons
// import Code from "@material-ui/icons/Code";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import LocalOffer from "@material-ui/icons/LocalOffer";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

// importing css
import "assets/css/common.css"
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
// const useStyles = makeStyles(styles);


const Step4RegCon = (props) => {
    const {handleSubOne} = props
    //const classes = useStyles()
    const {http} = AuthUser()

    const getConnect = ()=>{

        http.get("/customer/OnboardingPaymentNotifySP")
                .then((response) => {
                   
                    if (response.status === 200) {
                        handleSubOne()
                    }
                })
                .catch((err) => {
                    console.log(err)
                   
                })
    }

    React.useEffect(()=>{
        handleSubOne()
    },[])

    return (
        <>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10} lg={10}>
                    <Card>
                        <CardHeader color="warning" icon>
                            <CardIcon color="warning">
                                <VerifiedUser />
                            </CardIcon>
                            <h3 className="card-title">
                                SolutionBuggy is a curated platform for manufacturing & industrial projects
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <div className="platform-steps">
                                        <div className="platform-head">
                                            <LocalOffer />
                                            <h4>No Commission</h4>
                                        </div>
                                        <div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry&rsquo;s standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a
                                                type specimen book. It has survived not only five centuries                                    </p>
                                        </div>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <div className="platform-steps">
                                        <div className="platform-head">
                                            <LocalOffer />
                                            <h4>No Commission</h4>
                                        </div>
                                        <div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry&rsquo;s standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a
                                                type specimen book. It has survived not only five centuries
                                            </p>
                                        </div>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <div className="platform-steps">
                                        <div className="platform-head">
                                            <LocalOffer />
                                            <h4>No Commission</h4>
                                        </div>
                                        <div>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry&rsquo;s standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a
                                                type specimen book. It has survived not only five centuries                                    </p>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <h5 style={{ textAlign: "center" }}>
                                Over 80k+ Micro to Large scale industries have joined the SolutionBuggy to
                                advance their business & grow globally.
                            </h5>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={10} lg={10}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <VerifiedUser />
                            </CardIcon>
                            <h3 className="card-title">
                                Joining SolutionBuggy is simple and fast
                            </h3>
                        </CardHeader>
                        <CardBody style={{ textAlign: "center" }}>
                            <div style={{ textAlign: "left" }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                        <div className="joining-container">
                                            <LocalOffer style={{ color: "#089CF3" }} />
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry&rsquo;s
                                            </p>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                        <div className="joining-container">
                                            <LocalOffer style={{ color: "#089CF3" }} />
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry&rsquo;s
                                            </p>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                        <div className="joining-container">
                                            <LocalOffer style={{ color: "#089CF3" }} />
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry&rsquo;s
                                            </p>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                            <Button onClick={() => {getConnect()}} color="info" round >Conccet</Button>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>

    );
}


Step4RegCon.propTypes = {
    handleSubOne: PropTypes.func
}

export default Step4RegCon
