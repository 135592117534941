import React from "react";
// props
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";
import { useParams } from "react-router-dom";
// toast
import { toast } from "react-toastify";

// Style
import "assets/css/common.css";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";

// Input
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Style
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);

const GradeCustomerQ2 = (props) => {
  const { handlesLeadQualificaton } = props;
  const classes = useStyles();
  const { http, getUser } = AuthUser();
  const params = useParams();

  // Model
  const [grade, setgrade] = React.useState("");

  // FormError
  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // Form Error
  const handleFormError = () => {
    if (grade.trim().length === 0) {
      formErr.grade = "Please Select Grade";
    }
  };

  //reset values
  const resolve = () => {
    setFormError({});
    setgrade("");
  };

  // Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const data = {
        grade,
        id: parseInt(params.id),
        user_id: getUser().user_id,
      };
      handleLogForm(data);
    }
  };

  const handleLogForm = (formData) => {
    http
      .post("/admin/grade", formData)
      .then((response) => {
        if(response.status==200){
          toast.success(`Your grade Updated Successfull!`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
          handlesLeadQualificaton();
          resolve();
        }
      })
      .catch((err) => {
        toast.success(`Error in grade ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  const gradeMarks = [
    {
      name: "NR2-Job Enquiry",
      valueGrade: "NR2-Job Enquiry",
    },
    {
      name: "NR4-Not Interested",
      valueGrade: "NR4-Not Interested",
    },
    {
      name: "NR5-Others(Not Relevant)",
      valueGrade: "NR5-Others(Not Relevant)",
    },
    {
      name: "W-Wrong Number/Incorrect Number",
      valueGrade: "W-Wrong number",
    },
  ];

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <GridContainer>
          {/* grade */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className="input-margin">
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="grade" className={classes.selectLabel}>
                  <span id="gradeLable">Select your grade*</span>
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={grade}
                  onChange={(event) => {
                    setgrade(event.target.value);
                    delete formError.grade;
                  }}
                  inputProps={{
                    name: "grade",
                    id: "grade",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose grade
                  </MenuItem>

                  {gradeMarks.map((gradeMark, m) => {
                    return (
                      <MenuItem
                        key={m}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={gradeMark.valueGrade}
                      >
                        {gradeMark.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {Object.keys(formError).length > 0 && formError.grade && (
                <p style={{ color: "#f44336", textAlign: "left" }}>
                  {formError.grade}
                </p>
              )}
            </div>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className="input-margin">
              <Button round color="rose" type="submit">
                Update
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

GradeCustomerQ2.propTypes = {
  handlesLeadQualificaton: PropTypes.func,
};

export default GradeCustomerQ2;
