import React from "react";
import PropTypes from "prop-types";

import ReactTable from "components/ReactTable/ReactTable.js";
// @material-ui/icons

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";

// @material-ui/core components

const ActiveUsersDB = (props) => {
  const { userList1 } = props;
  // const classesTable = useStylesTable();
  const history = useHistory()
  const [data, setdata] = React.useState([]);

  React.useEffect(() => {
    const result = userList1.map((ele) => {
      ele.action = (
        <Button color="rose" round onClick={()=>{
          history.push(`/admin/customer/${ele.customer_id}`)
        }}>
          View
        </Button>
      );
      return ele
    });
    setdata(result);
  }, [userList1]);
  return (
    <>
      <Card>
        <h4>Active Users (Total : {userList1&&userList1.length})</h4>
        <CardBody>
          <ReactTable
            columns={[
              {
                Header: "ID",
                accessor: "customer_id",
              },
              {
                Header: "Customer",
                accessor: "customer_type",
              },
              {
                Header: "Name",
                accessor: "firstname",
              },
              {
                Header: "Phone",
                accessor: "phone",
              },
              {
                Header: "Email",
                accessor: "email",
              },
              {
                Header: "IP address",
                accessor: "ip",
              },
              {
                Header: "Status",
                accessor: "status",
              },
              {
                Header: "Action",
                accessor: "action",
              },
            ]}
            data={data}
          />
        </CardBody>
      </Card>
    </>
  );
};
ActiveUsersDB.propTypes = {
  userList1: PropTypes.array,
};
export default ActiveUsersDB;
