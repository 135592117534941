import React from 'react'
import AuthUser from 'components/Sb/AuthUser'
import { toast } from 'react-toastify'

import PropTypes from 'prop-types'

// CSS importing
import "assets/css/LoginPage.css"

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'

// @material-ui/icons
import Email from '@material-ui/icons/Email';

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js'
// import axios from 'axios'
const useStyles = makeStyles(styles)

function LoginWithOTP(props) {
    const classes = useStyles()
    const textErrorStyles = {
        color: '#fb483a',
        fontWeight: 600,
    }

    const { setOtpSend ,email, setEmail} = props


    const {  http,
         setotpEmail, getLoading } = AuthUser()

    const [formErr, setFormErr] = React.useState({})
    const error = {}

    const successMessage = (email) => {
        setotpEmail(email)
    }

    const errorMessage = (msg) => {
        toast.warn(msg, { position: toast.POSITION.TOP_CENTER,autoClose:1500 })
    }

    const handleError = () => {
        var Emailregex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
        if ((!email.match(Emailregex)) || email.trim().length == 0) {
            error.email = 'Email is Invalid!'
        }
    }



    const handleSubmit = (e) => {

        e.preventDefault()
        handleError()
        if (Object.keys(error).length > 0) {
            setFormErr(error)
        } else {
            const data = { email }
            http.post('/loginWithOtp', data)
            .then((res) => {  
                console.log(res)  
                if (res.status == 200) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose:3000
                    })
                    const reponseData = res.data
                    http.post('/otp_sms', reponseData)
                    .then((resSMS)=>{console.log("success of otp sms",resSMS)})
                    .catch((err)=>{console.log("Error in otp sms",err)})
                    successMessage(email)
                    setOtpSend(true)
                    console.log(res) 
                    // setEmail("")
                }else{
                    errorMessage(res.data.message)
                }
            }).catch((err) => {
                if (err.response && err.response.status) {
                    // Handle HTTP error responses
                    errorMessage(`Server returned an error with status ${err.response.status}`);
                } else {
                    // Handle other errors (e.g., network issues)
                    errorMessage(`Server Down Try Later! ${err}`);
                }
            });
        }
    }

    return (
        <>
            <p>Enter your registered E-Mail Address below to get OTP.</p>
            <form onSubmit={handleSubmit} >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            success={email.length > 0 ? true : false}
                            error={Object.keys(formErr).length > 0 && formErr.email ? true : false}
                            labelText={
                                <span>
                                    Enter your Registered Email<small>*</small>
                                </span>
                            }
                            id="email"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Email className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                ),
                                name: 'email',
                                value: email,
                                onChange: (event) => {
                                    setEmail(event.target.value);
                                    setFormErr({})
                                },
                                autoComplete: 'off',
                            }}
                        />
                        <span style={textErrorStyles}>
                            {formErr.email ? formErr.email : ''}
                        </span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className='login-btn-div'>
                            <Button round color="primary" type="submit">
                                {!getLoading() ? "Send OTP" : "Please Wait..."}

                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </form>
        </>
    )
}
LoginWithOTP.propTypes = {
    setOtpSend: PropTypes.func,
    email: PropTypes.string,
    setEmail: PropTypes.func,
}

export default LoginWithOTP