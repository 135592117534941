import React from "react";

// import AuthUser from "components/Sb/AuthUser";
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import AuthUser from 'components/Sb/AuthUser';

// @material-ui/icons
import Code from "@material-ui/icons/Code";
import VerifiedUser from "@material-ui/icons/VerifiedUser";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

// importing css
import "assets/css/common.css"
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
const useStyles = makeStyles(styles);

// import constBG from "assets/img/ind-slick/consult-bg.png"



const OTPVerify = (props) => {
    const { handleToggleOne } = props
    const classes = useStyles()
     const { http} = AuthUser()

    const [otp, setOtp] = React.useState("")

    const [formError, setFormError] = React.useState({})
    let formErr = {}

     //getting otp by connecting on api

     React.useState(() => {

        http.get("/customer/getOnboardingstep")
        .then((res) => {
            if(res.status==200){
                const step = res.data
                
                if(step < 2){
                
                    http.get("/customer/sentOtp")
                        .then((response) => {
                            console.log(response.data)
                        }) .catch((err) => {
                            console.log(err)
                        })
                }
        }

        }).catch((err) => {
            console.log(err)
        })
    },[])
    
    const handleSubmit = (e) => {
        e.preventDefault()
        handleFormError()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)
            toast.error('Your OTP is Mismatch!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose:1500
                //onClose: () => history.push('customer/user-page'),
            })
        } else {
            const data = {otp}

            http.post('/customer/otpVerification', data).then((res) => {
                if(res.status===200){
                    toast.success("OTP has been verified successfully", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose:1500
                    })

                    setFormError({})
                    handleToggleOne()
                    setOtp("")
                }
                if(res.status===210){
                    toast.error("OTP has been Mistach", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose:1500
                    })
                }

              }).catch((err) => {
                console.log(err)
            });

        }
    }
    
    const handleFormError = () => {
        const verifyNumber = (value) => {
            var numberRex = new RegExp("^[0-9]+$");
            if (numberRex.test(value)) {
                return true;
            }
            return false;
        };
        if (otp.trim().length === 0 || !verifyNumber(otp)) {
            formErr.otp = "Error"
        }
    }

    return (
        <>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10} lg={10}>
                    <Card>
                        <CardHeader color="warning" icon>
                            <CardIcon color="warning">
                                <VerifiedUser />
                            </CardIcon>
                            <h4 className="card-title">
                                OTP verification
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit}>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={8} lg={8}>
                                        <h5 style={{ textAlign: "center" }}>
                                            Verify your details to proceed further & get 
                                            connected with the genuine and relevant projects as per your expertise
                                        </h5>
                                    </GridItem>
                                    {/* otp */}
                                    <GridItem xs={12} sm={12} md={5} lg={5}>
                                        <div className="input-margin">
                                            <CustomInput
                                                success={otp.length > 0 ? true : false}
                                                error={Object.keys(formError).length > 0 && formError.otp ? true : false}
                                                labelText={
                                                    <span>
                                                        OTP Verfication <small>(required)</small>
                                                    </span>
                                                }
                                                id="otp"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    name: 'otp',
                                                    value: otp,
                                                    type: "number",
                                                    onChange: (event) => {
                                                        setOtp(event.target.value);
                                                        setFormError({})
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment position="end" className={classes.inputAdornment}>
                                                            <Code className={classes.inputAdornmentIcon} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </GridItem>
                                    {/* Submit btn */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ textAlign: "center" }}>
                                            <Button
                                                color="info"
                                                className={classes.registerButton}
                                                type="submit"
                                                round
                                            >
                                                Verify OTP
                                            </Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>

    );
}


OTPVerify.propTypes = {
    handleToggleOne: PropTypes.func
}

export default OTPVerify
