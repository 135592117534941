import React from "react";

import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

// @material-ui/icons
// import Description from "@material-ui/icons/Description";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";

// Radio
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);
// importing css
import "assets/css/common.css";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { OutlinedInput } from "@material-ui/core";
const useStyles = makeStyles(styles);

const ExistingIndustryQuery = (props) => {
  const { industry_type, handleMainToggle } = props;
  const classes = useStyles();
  const classesRadio = useStylesRadio();
  const { http } = AuthUser();

  const [indutryPlan, setIndutryPlan] = React.useState(industry_type);
  const [industries, setindustries] = React.useState("");
  const [sub_cat, setsub_cat] = React.useState("");
  const [req, setreq] = React.useState("");
  const [start_within, setstart_within] = React.useState("");
  const [state, setstate] = React.useState("");
  const [desc, setdesc] = React.useState("");
  const [budget, setbudget] = React.useState("");
  const [land, setland] = React.useState("");

  const [stateList, setstateList] = React.useState([]);
  const [industriesList, setindustriesList] = React.useState([]);
  const [sub_catList, setsub_catList] = React.useState([]);

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // changes
  React.useEffect(() => {
    setIndutryPlan(industry_type);
  }, []);
  React.useEffect(() => {
    setindustries("");
    setsub_cat("");
    setreq("");
    setstart_within("");
    setstate("");
    setdesc("");
    setbudget("");
    setland("");
  }, [indutryPlan]);

  // getting state List
  React.useEffect(() => {
    http
      .get("/stateList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setstateList(data);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  // getting industry lists
  React.useEffect(() => {
    http
      .get("/industryList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setindustriesList(data);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  const handleSelectCategory = (id) => {
    http
      .post("/customer/categoryDepDropdown", { industries: id })
      .then((response) => {
        if (response.status == 200) {
          const data = response.data;
          setsub_catList(data);
        } else {
          //warning msg
          toast.warn("Oops Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  let industryLable = document.getElementById("industryLable");
  let stateLable = document.getElementById("stateLable");
  let sub_catLable = document.getElementById("sub_catLable");
  let reqLable = document.getElementById("reqLable");
  let start_withinLable = document.getElementById("start_withinLable");
  // let budgetLable = document.getElementById("budgetLable");

  const handleErrorMsg = (lable) => {
    lable.classList.remove("errorMsg");
    lable.classList.add("successMsg");
  };
  // handle Form error function
  const handleFormError = () => {
    if (industries.length === 0) {
      formErr.industries = "Industry need to select";
      industryLable.classList.add("errorMsg");
    }

    if ((industries === 9 || industries === 18) && sub_cat.length === 0) {
      formErr.sub_cat = "sub_cat need to select";
      sub_catLable.classList.add("errorMsg");
    }

    if (state.length === 0) {
      formErr.state = "State need to select";
      stateLable.classList.add("errorMsg");
    }

    if (req.length === 0) {
      formErr.req = "State need to select";
      reqLable.classList.add("errorMsg");
    }

    if (start_within.length === 0) {
      formErr.start_within = "State need to select";
      start_withinLable.classList.add("errorMsg");
    }

    if (req === "Diversification") {
      if (budget.length === 0) {
        formErr.budget = "Budget need to select";
      }
    }

    if (desc.trim().length === 0) {
      formErr.desc = "Describe is required";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      setFormError({});
      let assist = "";
      const data = {
        industries,
        sub_cat,
        req,
        start_within,
        state,
        desc,
        industry_type,
        budget,
        land,
        assist,
      };
      http
        .post("/customer/reqSub", data)
        .then((res) => {
          if (res.status === 200) {
            setindustries("");
            setsub_cat("");
            setreq("");
            setstart_within("");
            setstate("");
            setdesc("");
            setland("");
            setbudget("");

            toast.success(
              `Your ${industry_type} Profile Updated Successfull!`,
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                //onClose: () => history.push('customer/user-page'),
              }
            );
            handleMainToggle();

            //mail api calling

            http
              .get("/customer/sentPrequalificationNotification")
              .then((res) => {
                if (res.status == 200) {
                  toast.success(`Notification is sent`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1500,
                  });
                }
              })
              .catch((err) => {
                toast.error(`Oops Something went wrong! ${err.message}`, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 1500,
                });
              });
          } else {
            //warning msg
            toast.warn("Oops Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1500,
            });
          }
        })
        .catch((err) => {
          toast.error(`Oops Something went wrong! ${err.message}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer justify="center">
        {/* Industry */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <span id="industryLable" style={{ fontWeight: "bold" }}>
              Select your Industry<small>(required)</small>
            </span>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={industries}
                onChange={(event) => {
                  setindustries(event.target.value);
                  handleSelectCategory(event.target.value);
                  handleErrorMsg(industryLable);
                }}
                inputProps={{
                  name: "industries",
                  id: "industries",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose industry
                </MenuItem>
                {industriesList.map((industry) => {
                  return (
                    <MenuItem
                      key={industry.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={industry.id}
                    >
                      {industry.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </GridItem>
        {/* Sub Category */}
        {(industries === 9 || industries === 18) && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className="input-margin">
              <span id="sub_catLable" style={{ fontWeight: "bold" }}>
                Choose Sub-Sector <small>(required)</small>
              </span>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={sub_cat}
                  onChange={(event) => {
                    setsub_cat(event.target.value);
                    handleErrorMsg(sub_catLable);
                  }}
                  inputProps={{
                    name: "sub_cat",
                    id: "sub_cat",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Sub-Sector
                  </MenuItem>
                  {sub_catList.map((industry) => {
                    return (
                      <MenuItem
                        key={industry.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={industry.id}
                      >
                        {industry.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </GridItem>
        )}

        {/* State */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <span id="stateLable" style={{ fontWeight: "bold" }}>
              Location of your current industry <small>(required)</small>
            </span>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={state}
                onChange={(event) => {
                  setstate(event.target.value);
                  handleErrorMsg(stateLable);
                }}
                inputProps={{
                  name: "state",
                  id: "state_ex",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose State
                </MenuItem>

                {stateList.map((states) => {
                  return (
                    <MenuItem
                      key={states.state_id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={states.state_id}
                    >
                      {states.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </GridItem>
        {/* Select your project requirement */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <span id="reqLable" style={{ fontWeight: "bold" }}>
              Select your project requirement <small>(required)</small>
            </span>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={req}
                onChange={(event) => {
                  setreq(event.target.value);
                  handleErrorMsg(reqLable);
                }}
                inputProps={{
                  name: "req",
                  id: "req",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Consultant
                </MenuItem>

                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Business Development"
                >
                  Business Development
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Process Improvement"
                >
                  Process Improvement
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="R&D"
                >
                  R&D
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="PPC-Production Planning & Control"
                >
                  PPC-Production Planning & Control
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Contract Manufacturing"
                >
                  Contract Manufacturing
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Certifications"
                >
                  Certifications
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Subsidy Schemes & Funding"
                >
                  Subsidy Schemes & Funding
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Industrial Automation"
                >
                  Industrial Automation
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Diversification"
                >
                  Diversification
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="others"
                >
                  others
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </GridItem>

        {/* budget */}
        {req === "Diversification" && (
          <>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <div className="input-margin">
                <span id="budgetLable" style={{ fontWeight: "bold" }}>
                  What is the minimum amount you are planning to invest?{" "}
                  <small>(required)</small>
                </span>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={budget}
                    onChange={(event) => {
                      setbudget(event.target.value);
                      delete formError.budget;
                      document
                        .getElementById("budgetLable")
                        .classList.add("successMsg");
                    }}
                    inputProps={{
                      name: "budget",
                      id: "budget",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose budget
                    </MenuItem>

                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="Less than 10Lac"
                    >
                      Less than 10Lac
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="10Lac - 30Lac"
                    >
                      10Lac - 30Lac
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="30Lac - 60Lac"
                    >
                      30Lac - 60Lac
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="60Lac - 1Cr"
                    >
                      60Lac - 1Cr
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="1Cr - 1.5Cr"
                    >
                      1Cr - 1.5Cr
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="1.5Cr - 2Cr"
                    >
                      1.5Cr - 2Cr
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value="More than 2Cr"
                    >
                      More than 2Cr
                    </MenuItem>
                  </Select>
                </FormControl>
                {Object.keys(formError).length > 0 && formError.budget && (
                  <p style={{ color: "red" }}>{formError.budget}</p>
                )}
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <p style={{ marginTop: "20px", marginBottom: "0px" }}>
                    Do you own any land to start a project?
                  </p>
                </GridItem>
                <GridItem xs={6} sm={4}>
                  <div
                    className={
                      classesRadio.checkboxAndRadio +
                      " " +
                      classesRadio.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={land === "Yes"}
                          onChange={() => {
                            setland("Yes");
                          }}
                          name="radio button enabled"
                          aria-label="Yes"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radio,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      label="Yes"
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} sm={4}>
                  <div
                    className={
                      classesRadio.checkboxAndRadio +
                      " " +
                      classesRadio.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={land === "No"}
                          onChange={() => {
                            setland("No");
                          }}
                          name="radio button enabled"
                          aria-label="No"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radio,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      label="No"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </>
        )}
        {/* start_within */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <span id="start_withinLable" style={{ fontWeight: "bold" }}>
              How soon are you planning to start your project?{" "}
              <small>(required)</small>
            </span>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="start_within"
                className={classes.selectLabel}
              ></InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={start_within}
                onChange={(event) => {
                  setstart_within(event.target.value);
                  handleErrorMsg(start_withinLable);
                }}
                inputProps={{
                  name: "start_within",
                  id: "start_within",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose planning
                </MenuItem>

                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Less Than 1 month"
                >
                  Less Than 1 month
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="1 to 3 months"
                >
                  1 to 3 months
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="3 to 6 months"
                >
                  3 to 6 months
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="More than 6 months"
                >
                  More than 6 months
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </GridItem>
        {/* Describe */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel
              htmlFor="desc"
              style={{ marginLeft: "12px", marginBottom: "-12px" }}
            >
              Describe your requirement in brief<small>(required)</small>
            </InputLabel>
            <OutlinedInput
              multiline
              rows={10}
              name="desc"
              value={desc}
              onChange={(event) => {
                setdesc(event.target.value);
                delete formError.desc;
              }}
              id="desc"
              color="primary"
              label="Project Requirement"
            />
          </FormControl>
          {Object.keys(formError).length > 0 && formError.desc && (
            <p style={{ color: "red" }}>{formError.desc}</p>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              color="info"
              className={classes.registerButton}
              type="submit"
              round
            >
              save
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

ExistingIndustryQuery.propTypes = {
  industry_type: PropTypes.string,
  handleMainToggle: PropTypes.func,
};

export default ExistingIndustryQuery;
