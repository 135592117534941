import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";

import { toast } from "react-toastify";
//import {useHistory} from 'react-router-dom'

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
// import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
// import Email from "@material-ui/icons/Email";
// import Smartphone from "@material-ui/icons/Smartphone";
// import Business from "@material-ui/icons/Business";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import NoteAdd from "@material-ui/icons/NoteAdd";
// import Laptop from "@material-ui/icons/Laptop";
import LocationCity from "@material-ui/icons/LocationCity";
// import LocationOn from "@material-ui/icons/LocationOn";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { OutlinedInput } from "@material-ui/core";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const NewEntrepreneur2 = (props) => {
  const { handleResetIndPop, industries } = props;
  const classes = useStyles();
  const params = useParams();
  //   const history = useHistory();
  const { http,getUser } = AuthUser();

  const [industriesAll, setindustriesAll] = React.useState([]);
  const [existing_ind, setExisting_ind] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [contact_type, setContact_type] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [land, setLand] = React.useState("");
  const [product_i, setProduct_i] = React.useState("");
  const [industry, setIndustry] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [requirement, setRequirement] = React.useState("");
  const [timeline, setTimeline] = React.useState("");
  const [cat_sel,setCat_sel] = React.useState("")

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // getting industry lists
  React.useEffect(() => {
    http
      .get("/industryList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setindustriesAll(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // handle Form error function
  const handleFormError = () => {
    // 1 industry
    if(industries == "2-New Entrepreneur with Business Background"){
        if (existing_ind.length === 0) {
            formErr.existing_ind = "Industry need to select";
          }
    }
    // 2 category
    if (category.trim().length === 0) {
      formErr.category = "category need to select";
    }
    // 3 contact_type
    if (contact_type.trim().length === 0) {
      formErr.contact_type = "contact type need to select";
    }
    // 4 budget
    if (budget.trim().length === 0) {
      formErr.budget = "budget need to select";
    }
    // 5 land
    if (land.trim().length === 0) {
      formErr.land = "land need to select";
    }
    // 6 product_i
    if (product_i.trim().length === 0) {
      formErr.product_i = "Product Identified need to select";
    }
    // 7 industry
    if (industry.length === 0) {
      formErr.industry = "Industry need to select";
    }
    // 8 location
    if (location.trim().length === 0) {
      formErr.location = "Location is required";
    }
    // 9 requirement
    if (requirement.trim().length === 0) {
      formErr.requirement = "Requirement is required";
    }
    // 10 timeline
    if (timeline.trim().length === 0) {
      formErr.timeline = "timeline need to select";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        category,
        contact_type,
        budget,
        land,
        product_i,
        industry,
        location,
        requirement,
        timeline,
        cus_id: parseInt(params.id),
        ind_type:industries,
        existing_ind:existing_ind.length>0?existing_ind:null ,
        cat_sel,
        user_id: getUser().user_id,
      };
      console.log(formData);
      handleExistingForm(formData)
    }
  };

  const handleExistingForm = (formData) => {
    http
      .post("/admin/lead_questionire_1", formData)
      .then((reposne) => {
        console.log(reposne);
        resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resolve = ()=>{
    handleResetIndPop();
    setFormError({});
    toast.success("Successfully updated", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
    })
    setExisting_ind("")
    setCategory("")
    setContact_type("")
    setBudget("")
    setLand("")
    setProduct_i("")
    setIndustry("")
    setLocation("")
    setRequirement("")
    setTimeline("")
    setCat_sel("")
  }

  const existingIndustries = [
    "Construction","Retail","Manufacturing",
    "Education","Agriculture","Information Technology",
    "Petroleum","Transportation","Real Estate","Mining","Financial Services","Logistics",
    "Media","Ecommerce","Healthcare","Food","Insurance","Automobile","Telecom","Aerospace",
    "Electronics","Trading","Government Sector","Others"
  ]

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* 1 industy */}
          {industries == "2-New Entrepreneur with Business Background" && (
            <GridItem xs={12} sm={12} md={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="existing_ind"
                  className={classes.selectLabel}
                >
                  <span id="industry_lable">
                    Select Existing Industry*
                  </span>
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={existing_ind}
                  onChange={(event) => {
                    setExisting_ind(event.target.value);
                    delete formError.existing_ind;
                  }}
                  inputProps={{
                    name: "existing_ind",
                    id: "existing_ind",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose industry
                  </MenuItem> Construction
                  {existingIndustries.map((industry,i) => {
                    return (
                      <MenuItem
                        key={i}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={industry}
                      >
                        {industry}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {Object.keys(formError).length > 0 && formError.existing_ind && (
                <p style={{ color: "#f44336", textAlign: "left" }}>
                  {formError.existing_ind}
                </p>
              )}
            </GridItem>
          )}

          {/* 2 category */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="category">Project Requirement Category</InputLabel>
              <Select
                native
                value={category}
                onChange={(event) => {
                  setCategory(event.target.value);
                  setCat_sel(event.target.options[event.target.selectedIndex].parentNode.label)
                  delete formError.category;
                }}
                defaultValue=""
                id="category"
                label="Project Requirement Category"
              >
                <option aria-label="None" value="" />
                <optgroup label="Category 1">
                  <option value={"Product Development"}>Product Development</option>
                  <option value={"Product design/R&D"}>Product design/R&D</option>
                  <option value={"Product Formulation/ Shelf life"}>Product Formulation/ Shelf life</option>
                  <option value={"Process Improvement"}>Process Improvement</option>
                  <option value={"Automation"}>Automation</option>
                  <option value={"Diversification Expand/New Industry Setup"}>Diversification Expand/New Industry Setup</option>
                  <option value={"Merger, Aquistion, Joint Venture, Technology transfer"}>Merger, Aquistion, Joint Venture, Technology transfer</option>
                </optgroup>
                <optgroup label="Category 2">
                  <option value={"Product Packaging"}>Product Packaging</option>
                  <option value={"Certification, licenses"}>Certification, licenses</option>
                  <option value={"Organisational Development"}>Organisational Development</option>
                  <option value={"Cost Reduction"}>Cost Reduction</option>
                  <option value={"Lean, Kaizen, Six sigma"}>Lean, Kaizen, Six sigma</option>
                  <option value={"TPM,TQM, PPC"}>TPM,TQM, PPC</option>
                  <option value={"Funding, loans"}>Funding, loans</option>
                  <option value={"Contract Manufacturing"}>Contract Manufacturing</option>
                  <option value={"Machining , tooling, Fabrication"}>Machining , tooling, Fabrication</option>
                  <option value={"Product Identification"}>Product Identification</option>
                  <option value={"Reverse Engineering"}>Reverse Engineering</option>
                  <option value={"Energy management"}>Energy management</option>
                  <option value={"Industry 4.0, Robotics"}>Industry 4.0, Robotics</option>
                </optgroup>
                <optgroup label="Category 3">
                  <option value={"Sales & Marketing"}>Sales & Marketing</option>
                  <option value={"Branding"}>Branding</option>
                  <option value={"Business Developmant"}>Business Developmant</option>
                  <option value={"Supply chain management"}>Supply chain management</option>
                  <option value={"Vendor development"}>Vendor development</option>
                  <option value={"Training"}>Training</option>
                  <option value={"CAD, CAM"}>CAD, CAM</option>
                  <option value={"Strategy Consulting"}>Strategy Consulting</option>
                  <option value={"3D printing"}>3D printing</option>
                  <option value={"Others"}>Others</option>
                </optgroup>
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.category && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.category}
              </p>
            )}
          </GridItem>
          {/* 3 contact_type */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="contact_type"
                className={classes.selectLabel}
              >
                <span id="contact_type_lable">
                  Contact Person Level*
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={contact_type}
                onChange={(event) => {
                  setContact_type(event.target.value);
                  delete formError.contact_type;
                }}
                inputProps={{
                  name: "contact_type",
                  id: "contact_type",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose contact type
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"Junior Level"}
                >
                  Junior Level
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"Decision Maker"}
                >
                  Decision Maker
                </MenuItem>
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.contact_type && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.contact_type}
              </p>
            )}
          </GridItem>
          {/* 4 budget */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="budget" className={classes.selectLabel}>
                <span id="budget_lable">Self Investment Amount*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={budget}
                onChange={(event) => {
                  setBudget(event.target.value);
                  delete formError.budget;
                }}
                inputProps={{
                  name: "budget",
                  id: "budget",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Investment Amount
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"Less than 20Lac"}
                >
                  Less than 20Lac
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"20Lac - 50Lac"}
                >
                  20Lac - 50Lac
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"50Lac - 1Cr"}
                >
                  50Lac - 1Cr
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"1Cr - 2Cr"}
                >
                  1Cr - 2Cr
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"2Cr - 5Cr"}
                >
                  2Cr - 5Cr
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"5Cr - 15Cr"}
                >
                  5Cr - 15Cr
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"More than 15Cr"}
                >
                  More than 15Cr
                </MenuItem>
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.budget && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.budget}
              </p>
            )}
          </GridItem>
          {/* 5 land */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="land" className={classes.selectLabel}>
                <span id="land_lable">Having Own Land?*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={land}
                onChange={(event) => {
                  setLand(event.target.value);
                  delete formError.land;
                }}
                inputProps={{
                  name: "land",
                  id: "land",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"Yes"}
                >
                  Yes
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"No"}
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.land && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.land}
              </p>
            )}
          </GridItem>
          {/* 6 product_i */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="product_i" className={classes.selectLabel}>
                <span id="product_i_lable">Any Product Identified?*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={product_i}
                onChange={(event) => {
                  setProduct_i(event.target.value);
                  delete formError.product_i;
                }}
                inputProps={{
                  name: "product_i",
                  id: "product_i",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"Yes"}
                >
                  Yes
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"No"}
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.product_i && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.product_i}
              </p>
            )}
          </GridItem>
          {/* 7 industy */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="industry" className={classes.selectLabel}>
                <span id="industry_lable">
                  Select industry vertical*
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={industry}
                onChange={(event) => {
                  setIndustry(event.target.value);
                  delete formError.industry;
                }}
                inputProps={{
                  name: "industry",
                  id: "industry",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose industry
                </MenuItem>
                {industriesAll.map((industry) => {
                  return (
                    <MenuItem
                      key={industry.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={industry.id}
                    >
                      {industry.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.industry && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.industry}
              </p>
            )}
          </GridItem>
          {/* 8 location */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={location.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.location
                  ? true
                  : false
              }
              labelText={
                <span>
                  Project Location*
                </span>
              }
              id="location"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "location",
                value: location,
                onChange: (event) => {
                  setLocation(event.target.value);
                  delete formError.location;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <LocationCity className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* 9 requirement */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="requirement">
              &#173;Complete Customer Requirement&#173;
              </InputLabel>
              <OutlinedInput
                 multiline
                 rows={10}
                 name="requirement"
                 value={requirement}
                 onChange={(event) => {
                   setRequirement(event.target.value);
                   delete formError.requirement;
                 }}
                id="requirement"
                color="success"
                label="Project Requirement"
              />
            </FormControl>
            {Object.keys(formError).length > 0 && formError.requirement && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.requirement}
              </p>
            )}
          </GridItem>
          {/* 10 timeline */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="timeline" className={classes.selectLabel}>
                <span id="timeline_lable">
                  Project Timeline*
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={timeline}
                onChange={(event) => {
                  setTimeline(event.target.value);
                  delete formError.timeline;
                }}
                inputProps={{
                  name: "timeline",
                  id: "timeline",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Timeline
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"Immediate"}
                >
                  Immediate
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"1 month – 3 months"}
                >
                  1 month – 3 months
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"3 months – 6 months"}
                >
                  3 months – 6 months
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"6 months"}
                >
                  6 months
                </MenuItem>
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.timeline && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.timeline}
              </p>
            )}
          </GridItem>
          {/* btn */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                save
              </Button>
            </div>
          </GridItem>
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => handleResetIndPop()}
              >
                cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

NewEntrepreneur2.propTypes = {
  industries: PropTypes.string,
  handleResetIndPop: PropTypes.func,
};

export default NewEntrepreneur2;
