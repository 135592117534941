import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { toast } from "react-toastify";
import AuthUser from "components/Sb/AuthUser";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";

// core components
import Badge from "components/Badge/Badge.js";

import styles from "assets/jss/material-dashboard-pro-react/components/timelineStyle.js";
import EditCreateTask from "../EditContactForms/EditCreateTask";

const useStyles = makeStyles(styles);

export default function Timeline(props) {
  const classes = useStyles();
  const { http } = AuthUser();
  const { stories, simple } = props;
  const timelineClass =
    classes.timeline +
    " " +
    cx({
      [classes.timelineSimple]: simple,
    });

  return (
    <ul className={timelineClass}>
      {stories.map((prop, key) => {
        const panelClasses =
          classes.timelinePanel +
          " " +
          cx({
            [classes.timelinePanelInverted]: prop.inverted || simple,
            [classes.timelineSimplePanel]: simple,
          });
        const timelineBadgeClasses =
          classes.timelineBadge +
          " " +
          classes[prop.badgeColor] +
          " " +
          cx({
            [classes.timelineSimpleBadge]: simple,
          });

        const handleLogDeleteForm = (formData) => {
          // console.log(formData);
          http
            .post("/admin/delete_log", formData)
            .then((reposne) => {
              // console.log(reposne);
              if (
                Object.keys(reposne).length > 0 &&
                Object.prototype.hasOwnProperty.call(reposne.data, "code")
              ) {
                toast.success(`Your Log Acivity Successfull Deleted!`, {
                  position: toast.POSITION.TOP_CENTER,
                  //   onClose: () => resolve(),
                  autoClose: 1500,
                });
                prop.handlesLeadQualificaton();
              }
            })
            .catch((err) => {
              // console.log(err);
              toast.error(`Error! - ${err.message}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
              });
            });
        };

        return (
          <li className={classes.item} key={key}>
            {prop.badgeIcon ? (
              <div className={timelineBadgeClasses}>
                <prop.badgeIcon className={classes.badgeIcon} />
              </div>
            ) : null}
            <div
              className={panelClasses}
              style={{
                background: `${
                  prop.type == "Grade" ||
                  prop.type == "Sales Qualification Details"
                    ? prop.type == "Grade"
                      ? "#E0FFE1"
                      : "#dbffc3"
                    : "#ffffff"
                }`,
              }}
            >
              {prop.title ? (
                <div className={classes.timelineHeading}>
                  <Badge color={prop.titleColor}>{prop.logged}</Badge>
                </div>
              ) : null}
              {/* <div className={classes.timelineBody}>{prop.logged}</div> */}

              <div>
                <p style={{ color: "#00acc1" }}>Logged By : {prop.logged}</p>
                <h4 style={{ color: prop.titleColor }}>{prop.type}</h4>
                <h5>{prop.date}</h5>
                <hr />
                {prop.type == "Call" && <h5>{prop.call_outCome}</h5>}
                <h5>{prop.comments}</h5>
                <hr />
                {prop.assignedEdit && (
                  <>
                    {(prop.type == "Create Task" ||
                      prop.type == "Note" ||
                      prop.type == "Call") && (
                      <div>
                        <EditCreateTask
                          handlesLeadQualificaton={prop.handlesLeadQualificaton}
                          comment={prop.comments}
                          id={prop.id}
                          date={prop.date}
                          type={prop.type}
                        />
                        {prop.type == "Create Task" && (
                          <Button
                            color="reddit"
                            simple
                            onClick={() => {
                              handleLogDeleteForm({ id: prop.id });
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>

              {prop.footerTitle ? (
                <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
              ) : null}
              {prop.footer ? <hr className={classes.footerLine} /> : null}
              {prop.footer ? (
                <div className={classes.timelineFooter}>{prop.footer}</div>
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

Timeline.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool,
};
