import React from 'react'
//import {useHistory} from "react-router-dom";
//import { Redirect } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
//import axios from 'axios'
 import AuthUser from 'components/Sb/AuthUser'
// import { toast } from 'react-toastify'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import Icon from '@material-ui/core/Icon'

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
//import Email from '@material-ui/icons/Email'
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
//import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
//import CardFooter from 'components/Card/CardFooter.js'
import { toast } from 'react-toastify'

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js'

const useStyles = makeStyles(styles)

export default function AdminLogin() {
  //const history = useHistory();
  //const navigate = useNavigate();
  const {http, setToken} = AuthUser()
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  const [sucesssCard,setsucessCard] = React.useState(false)
  const [failuremsg,setfailuremsg] = React.useState("")

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('')
    }, 700)
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id)
    }
  })

  React.useEffect(()=>{
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const token = searchParams.get('atoken');
  const role = searchParams.get('role');
  const user = searchParams.get('user');  

  // console.log(token)
  // console.log(role)

  validateToken(token,role,user);

  },[])

  const successMessage = (user,role, access_token) => {
    toast.success('Otp Verification Successfull!', {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => setToken(user,role, access_token),
        autoClose: 1500,
    })
}

  const validateToken = (token,role,user)=>{
    const formData = {'token':token,'role':role,'user':user}
    http.post('/adminTokenValidation',formData)
    .then((response)=>{
      if(response.status==200){
        let access_token = response.data.access_token
        console.log(response.data)
        if (typeof access_token !== 'undefined')
                        successMessage(response.data.user,response.data.role, response.data.access_token)
        setsucessCard(true)
      }
      else{
        setsucessCard(false)
        setfailuremsg("something went wrong!")
      }
      
    })
    .catch((err)=>{
      console.log(err.response.data.message)
      setsucessCard(false)
      setfailuremsg(err.response.data.message)
    })
  }

  const classes = useStyles()
  
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
         {sucesssCard ? 
            (<Card login className={classes[cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="success"
            >
              <h4
                className={classes.cardTitle}
                style={{ fontSize: '24px', fontWeight: '600' }}
              >
                Login Successfull
              </h4>
             
            </CardHeader>
            <CardBody className={classes.justifyContentCenter}>
            <Button round color="success" type="submit">
                Go to Dashboard
              </Button>
             
            </CardBody>
           
          </Card> )
         
         :    
         (<Card login className={classes[cardAnimaton]}>
         <CardHeader
           className={`${classes.cardHeader} ${classes.textCenter}`}
           color="danger"
         >
           <h4
             className={classes.cardTitle}
             style={{ fontSize: '24px', fontWeight: '600' }}
           >
             Login Failed
           </h4>
          
         </CardHeader>
         <CardBody>
           <div style={{textAlign:"center"}}><h5><b>Oops!!!{failuremsg}</b></h5></div>
        </CardBody>
        
       </Card> )
       }

         
         
        </GridItem>
      </GridContainer>
    </div>
  )
}
