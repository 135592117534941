import React from "react";
import { useParams } from "react-router-dom";
import AuthUser from "components/Sb/AuthUser";
// toast
import { toast } from "react-toastify";

// Import Css
import "assets/css/common.css";
import "assets/css/viewProject.css";
import "assets/css/proposalCon.css";

// Icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import ArrowBack from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachMoney from "@material-ui/icons/AttachMoney";
//import

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

// Sweetalert
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
const useStylesAlert = makeStyles(Alert);

// model
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

import stylesModel from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
const useStylesModel = makeStyles(stylesModel);

// compentents
import PaymentRequest from "./PaymentRequest";
import ConsultantProposal from "./ConsultantProposal";
import ConsultantProposalView from "./ConsultantProposalView";
import IndustryProfile from "./IndustryProfile";
import Loader from "views/Loader/Loader";

export default function ConsultanViewProjectTable() {
  const classes = useStyles();
  const classesModel = useStylesModel();

  // Sweetalert
  const classesAlert = useStylesAlert();
  const [alert, setAlert] = React.useState(null);

  const { http, getLoading } = AuthUser();
  const { id } = useParams();

  const [projectID, setProjectID] = React.useState("");
  const [offer, setOffer] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [type, setType] = React.useState("");
  const [date, setDate] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [industries, setIndustries] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [project, setProject] = React.useState({});
  const [industryCid, setindustryCid] = React.useState("");
  const [provider, setProvider] = React.useState({});
  const [industry, setIndustry] = React.useState({});
  const [plan, setPlan] = React.useState({});
  const [payments, setPayments] = React.useState({});
  const [manager, setManager] = React.useState("");
  const [message, setMessage] = React.useState("");

  // Model
  const [classicModal, setClassicModal] = React.useState(false);
  const [applyModal, setApplyModal] = React.useState(false);
  const [indModal, setIndModal] = React.useState(false);

  // Sweet alert
  const sweetalrtCalling = (handleApiCalling, msg) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => handleApiCalling()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
        cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };

  const getPayment = () => {
    const data = { pid: id };
    http
      .post("/customer/getPaymentRequest", data)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          if (Object.keys(data).length > 0) {
            setPayments(data);
            //Paymentlist(data)
          }
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  const getProvider = () => {
    const data = { problem_id: id };
    http
      .post("/customer/getProviderDetails", data)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setProvider(data);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  React.useEffect(() => {
    const data = { cid: industryCid };
    http
      .post("/customer/getIndustryDetails", data)
      .then((response) => {
        if (response.status === 200) {
          const res = response.data;
          setIndustry(res);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, [industryCid]);

  React.useEffect(() => {
    const data = { pid: id };

    http
      .post("/customer/getProjectManager", data)
      .then((response) => {
        if (response.status === 200) {
          const res = response.data;
          console.log(res);
          setManager(res);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  React.useEffect(() => {
    getProvider();
    getPayment();
  }, []);

  const hideAlert = () => {
    setAlert(null);
  };

  // End of sweetalert

  React.useEffect(() => {
    const formData = new FormData();
    formData.append("p_id", id);

    http
      .post("/customer/ViewProject", formData)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setProjectID(data.id);
          setProject(data);
          setindustryCid(data.customer_id);

          if (data.action == 0) {
            setStatus("Not Live");
          } else if (data.action == 1) {
            setStatus("Live");
          } else if (data.action == 2 || data.action == 4 || data.action == 5) {
            setStatus("Completed");
          } else if (data.action == 3) {
            setStatus("On hold");
          }
          if (data.execution == 0) {
            setType("Not defined");
          } else if (data.execution == 1) {
            setType("Normal");
          } else {
            setType("Execution");
          }
          setDate(data.date_added.slice(0, 10));
          setLocation(data.location);
          setDescription(data.describe);
          setIndustries(data.industry);
          setSubCategory(data.cat);
          setBudget(data.budget);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  React.useEffect(() => {
    http
      .get("/customer/ViewPlan")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setPlan(data);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  React.useEffect(() => {
    const data = { problem_id: id };
    http
      .post("/customer/consultantProjectStatus", data)
      .then((response) => {
        if (response.status === 200) {
          const msg = response.data;
          console.log(msg);
          if (msg != "") {
            setMessage(msg);
            // toast.info(msg, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 1500,
            // });
          }
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, [provider]);

  const goBack = () => {
    window.history.back();
  };

  // API for Apply project
  const handleApplyProject = () => {
    hideAlert();
    setApplyModal(true);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const data = { problem_id: id, offer: offer };
    http
      .post("/customer/ApplyProject", data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setApplyModal(false);
          toast.success("You have successfully applied to this project.", {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => getProvider(),
            autoClose: 1500,
          });
          sentApplyMail(id);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  const sentApplyMail = (pid) => {
    const data = { pid: pid };
    http
      .post("/customer/ApplyMailSent", data)
      .then((response) => {
        if (response.data) {
          toast.success("Successfull Showed Interest To The Project!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  const hadleShowInterest = () => {
    http
      .post("/customer/ShowInterest", { problem_id: id })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.success) {
            // console.log(data)
            hideAlert();
            toast.success("Successfull Showed Interest To The Project!", {
              position: toast.POSITION.TOP_CENTER,
              onClose: () => getProvider(),
              autoClose: 1500,
            });
          } else {
            toast.warn("Oops Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1500,
            });
          }
        } else {
          //warning msg
          toast.warn("Oops Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  const handleNotRelevant = () => {
    http
      .post("/customer/NotRelevant", { problem_id: id })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.success) {
            // console.log(data)
            hideAlert();
            toast.success("Successfull Project is Move to Not Relevant!", {
              position: toast.POSITION.TOP_CENTER,
              onClose: () => getProvider(),
              autoClose: 1500,
            });
          } else {
            toast.warn("Oops Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1500,
            });
          }
        } else {
          //warning msg
          toast.warn("Oops Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Button
          onClick={goBack}
          className="go-back"
          color="success"
          round
          style={{ fontWeight: 600 }}
        >
          <ArrowBack /> Back
        </Button>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <DescriptionIcon />
              </CardIcon>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  marginTop: "12px",
                }}
              >
                <h4 className={classes.cardIconTitle} style={{ color: "#000" }}>
                  Project ID : {projectID}
                </h4>
              </div>
            </CardHeader>
            <CardBody>
              {Object.keys(project).length > 0 ? (
                <div>
                  <h4 style={{ color: "#9c27b0", margin: "12px 0px" }}>
                    {message}
                  </h4>
                  <div className={classes.tableResponsive}>
                    <Table className="table">
                      <TableBody>
                        <TableRow className={"tableBackground"}>
                          <TableCell>Status</TableCell>
                          <TableCell>
                            <Button
                              color={
                                (project.action == 0 && "rose") ||
                                (project.action == 1 && "primary") ||
                                (project.action == 2 && "success") ||
                                (project.action == 4 && "success") ||
                                (project.action == 5 && "success") ||
                                (project.action == 3 && "warning")
                              }
                              round
                            >
                              {status}
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>{type}</TableCell>
                        </TableRow>
                        <TableRow className={"tableBackground"}>
                          <TableCell>Date</TableCell>
                          <TableCell>{date}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Location</TableCell>
                          <TableCell>{location}</TableCell>
                        </TableRow>
                        <TableRow className={"tableBackground"}>
                          <TableCell>Description</TableCell>
                          <TableCell>{description}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Industries</TableCell>
                          <TableCell>{industries}</TableCell>
                        </TableRow>
                        <TableRow className={"tableBackground"}>
                          <TableCell>Sub Category </TableCell>
                          <TableCell>{subCategory}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Budget</TableCell>
                          <TableCell>{budget}</TableCell>
                        </TableRow>
                        {provider.action > 0 && project.execution == 2 && (
                          <TableRow>
                            <TableCell>Project Manager</TableCell>
                            <TableCell>
                              {manager === "" ? "NA" : manager}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
              {Object.keys(project).length > 0 && (
                <div>
                  {Object.keys(provider).length < 1 || provider.action == 0 ? (
                    <div className="btn-div">
                      {alert}
                      <GridContainer justify="center">
                        {Object.keys(plan).length > 0 ? (
                          // (project.action == 1 || project.action == 5) && (
                          <GridItem xs={12} sm={4} md={4}>
                            <Button
                              color="info"
                              round
                              className="btn-action-consultan"
                              onClick={() => {
                                sweetalrtCalling(
                                  handleApplyProject,
                                  "Do you want to apply to this project"
                                );
                              }}
                            >
                              Apply
                            </Button>
                          </GridItem>
                        ) : (
                          //)
                          // (project.action != 5) && (
                          <GridItem xs={12} sm={4} md={4}>
                            <Button
                              color="info"
                              round
                              className="btn-action-consultan"
                              onClick={() => {
                                sweetalrtCalling(
                                  hadleShowInterest,
                                  "Do you want to show the interest on this project"
                                );
                                // hadleShowInterest()
                              }}
                            >
                              Show Interest
                            </Button>
                          </GridItem>
                          // )
                        )}
                        <GridItem xs={12} sm={4} md={4}>
                          <Button
                            color="rose"
                            round
                            className="btn-action-consultan"
                            onClick={() => {
                              sweetalrtCalling(
                                handleNotRelevant,
                                "Do you think this project is not relevant"
                              );
                            }}
                          >
                            Not Relevant
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ) : (
                    <div className="btn-div">
                      {alert}
                      {provider.action == 5 && Object.keys(plan).length > 0 ? (
                        <GridContainer justify="center">
                          {project.action == 1 ? (
                            <GridItem xs={12} sm={4} md={4}>
                              <Button
                                color="info"
                                round
                                className="btn-action-consultan"
                                onClick={() => {
                                  sweetalrtCalling(
                                    handleApplyProject,
                                    "Do you want to apply to this project"
                                  );
                                }}
                              >
                                Apply
                              </Button>
                            </GridItem>
                          ) : (
                            ""
                          )}
                        </GridContainer>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
        {project.execution == 2 && Object.keys(plan).length > 0 && (
          <GridItem xs={12} sm={12} md={6}>
            {provider.shortlist == 1 && provider.exe_doc == 0 && (
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <PermIdentity />
                  </CardIcon>
                  <h4 className="proposal-heading">Submit Your Proposal</h4>
                </CardHeader>
                <CardBody>
                  <ConsultantProposal
                    ProjectId={id}
                    getProvider={getProvider}
                  />
                </CardBody>
              </Card>
            )}
            {provider.shortlist == 1 && provider.exe_doc == 1 && (
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <PermIdentity />
                  </CardIcon>
                  <h4 className="proposal-heading">Details of Your Proposal</h4>
                </CardHeader>
                <CardBody>
                  <ConsultantProposalView
                    ProjectId={id}
                    getProvider={getProvider}
                  />
                </CardBody>
              </Card>
            )}
            {project.action == 4 && provider.action == 2 && (
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <AttachMoney />
                  </CardIcon>
                  <h4 className="proposal-heading">Add Payment Request</h4>
                  <div style={{ textAlign: "right", marginBottom: "2vh" }}>
                    <Button
                      round
                      color="rose"
                      onClick={() => setClassicModal(true)}
                    >
                      {" "}
                      Add Payment Request{" "}
                    </Button>
                  </div>
                </CardHeader>
                {Object.keys(payments).length > 0 && (
                  <CardBody>
                    <div className={classes.tableResponsive}>
                      <Table className="table">
                        <TableBody>
                          <TableRow>
                            <TableCell className={"tableBackground"}>
                              Amount
                            </TableCell>
                            <TableCell className={"tableBackground"}>
                              Doc. Type
                            </TableCell>
                            <TableCell className={"tableBackground"}>
                              Date Added
                            </TableCell>
                            <TableCell className={"tableBackground"}>
                              Status
                            </TableCell>
                          </TableRow>
                          {payments.map((payment, i) => (
                            <TableRow key={i + 1}>
                              <TableCell>{payment.amount}</TableCell>
                              <TableCell>{payment.doc_type}</TableCell>
                              <TableCell>{payment.date_added}</TableCell>
                              <TableCell style={{ color: "#00acc1" }}>
                                {(payment.status == 0 &&
                                  "Payment Request raised") ||
                                  (payment.status == 1 &&
                                    "Approved by project manager") ||
                                  (payment.status == 2 &&
                                    "TDS submitted by accounts") ||
                                  (payment.status == 3 &&
                                    "Disapproved by project manager") ||
                                  (payment.status == 4 && "Payment Completed")}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </CardBody>
                )}
              </Card>
            )}
          </GridItem>
        )}

        {project.execution < 2 && Object.keys(plan).length > 0 && (
          <GridItem xs={12} sm={12} md={6}>
            {(project.action == 1 || provider.action == 2) && (
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <PermIdentity />
                  </CardIcon>
                  <h4 className="proposal-heading">Industry Details</h4>
                </CardHeader>
                <CardBody>
                  <div className={classes.tableResponsive}>
                    <Table className="table">
                      <TableBody>
                        <TableRow className={"tableBackground"}>
                          <TableCell>Name</TableCell>
                          <TableCell>
                            {industry.firstname && industry.firstname}{" "}
                            {industry.lastname && industry.lastname}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Phone</TableCell>
                          <TableCell>
                            {industry.phone ? industry.phone : ""}
                          </TableCell>
                        </TableRow>

                        <TableRow className={"tableBackground"}>
                          <TableCell>Email</TableCell>
                          <TableCell>
                            {industry.email ? industry.email : ""}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <Button
                        style={{ width: "100%", marginTop: "2vh" }}
                        type="submit"
                        round
                        color="rose"
                        onClick={() => setIndModal(true)}
                      >
                        View
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            )}
          </GridItem>
        )}

        {/*view industry details Model */}
        <GridItem xs={12} sm={12} md={12}>
          <Dialog
            classes={{
              root: classesModel.center + " " + classesModel.modalRoot,
              paper: classesModel.modalBg,
            }}
            open={indModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIndModal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classesModel.modalHeader}
            >
              <Button
                justIcon
                className={classesModel.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setIndModal(false)}
              >
                <Close className={classesModel.modalClose} />
              </Button>
              {/* <div style={{ textAlign: "center" }}><h4 className='proposal-heading'>Industry Profile</h4></div> */}
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classesModel.modalBody}
            >
              {/* <PaymentRequest setClassicModal={setClassicModal} ProjectId={id} getPayment={getPayment} /> */}
              <IndustryProfile
                setIndModal={setIndModal}
                ProjectId={id}
                industry={industry}
              />
            </DialogContent>
          </Dialog>
        </GridItem>

        {/* Model */}
        <GridItem xs={12} sm={12} md={12}>
          <Dialog
            classes={{
              root: classesModel.center + " " + classesModel.modalRoot,
              paper: classesModel.modalBg,
            }}
            open={classicModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setClassicModal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classesModel.modalHeader}
            >
              <Button
                justIcon
                className={classesModel.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setClassicModal(false)}
              >
                <Close className={classesModel.modalClose} />
              </Button>
              <div style={{ textAlign: "center" }}>
                <h4 className="proposal-heading">Payment Request</h4>
              </div>
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classesModel.modalBody}
            >
              <PaymentRequest
                setClassicModal={setClassicModal}
                ProjectId={id}
                getPayment={getPayment}
              />
            </DialogContent>
          </Dialog>
        </GridItem>

        {/* Apply Model */}
        <GridItem xs={12} sm={12} md={12}>
          <Dialog
            classes={{
              root: classesModel.center + " " + classesModel.modalRoot,
              paper: classesModel.modalBg,
            }}
            open={applyModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setApplyModal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogContent
              id="classic-modal-slide-description"
              className={classesModel.modalBody}
            >
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <form onSubmit={handleFormSubmit}>
                  <label>
                    <b>
                      Brief Description <small>(required)</small>{" "}
                      <DescriptionIcon />{" "}
                    </b>
                  </label>
                  <br />
                  <br />
                  <textarea
                    name="offer"
                    id="offer"
                    value={offer}
                    onChange={(event) => setOffer(event.target.value)}
                    rows="8"
                    cols="35"
                    style={{ width: "100%" }}
                  ></textarea>

                  <div>
                    <Button type="submit" round color="primary">
                      {!getLoading() ? "Submit" : "Please Wait..."}
                    </Button>
                    <Button
                      round
                      color="rose"
                      onClick={() => {
                        setApplyModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </form>
              </GridItem>
            </DialogContent>
          </Dialog>
        </GridItem>
      </GridContainer>
    </>
  );
}
