import React from "react";

import AuthUser from "components/Sb/AuthUser";
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
// import axios from "axios";

// @material-ui/icons
// import Smartphone from "@material-ui/icons/Smartphone";
import Domain from "@material-ui/icons/Domain";
import Group from "@material-ui/icons/Group";
import Business from "@material-ui/icons/Business";
import Link from "@material-ui/icons/Link";
import Note from "@material-ui/icons/Note";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//style
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Button from "components/CustomButtons/Button.js";

// Redux 
// import { useDispatch, useSelector } from "react-redux"
// import { startUserList } from "redux/actions/userAction";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

const useStyles = makeStyles(styles);

const IndustryStep2 = (props) => {
  const { handleIsToggleFormEdit } = props
  // const dispatch = useDispatch()

  const classes = useStyles();
  const { http} = AuthUser()

  // React.useEffect(() => {
  //   dispatch(startUserList())
  // }, [])
  // const user = useSelector((state) => {
  //   return state.user
  // })
  // console.log(user)

  const [companyname, setcompanyname] = React.useState("")
  const [industries, setindustries] = React.useState("")
  const [mycurrentposition, setmycurrentposition] = React.useState("")
  const [tinno, settinno] = React.useState("")
  const [city, setCity] = React.useState("")
  const [state, setstate] = React.useState("")
  const [companywebsite, setcompanywebsite] = React.useState("")
  const [companydesc, setcompanydesc] = React.useState("")
  const [stateAll, setStateAll] = React.useState([])
  const [industriesAll, setindustriesAll] = React.useState([])
  const [formError, setFormError] = React.useState({})
  let formErr = {}

  // getting profile api
  React.useEffect(() => {
    http.get('/profile')
      .then((response) => {
        const data = response.data
        console.log(data, 78)
        if (response.status === 200) {
          // const stateName = stateAll.find((ele) => {
          //   return ele.name.includes(data.state)
          // })
          setcompanyname(data.companyname ? data.companyname : "")
          setmycurrentposition(data.mycurrentposition ? data.mycurrentposition : "")
          settinno(data.tinno ? data.tinno : "")
          setcompanywebsite("NA")
          setCity(data.city ? data.city : "")
          setcompanydesc(data.brief_bio ? data.brief_bio : "")
          setindustries(data.industries ? data.industries : "")
          setstate(data.state ? data.state : "")
        }
      })
      .catch((err) => {
        console.log(err)
        
      })
  }, [stateAll, industriesAll])

  // State list api
  React.useEffect(() => {
    http.get("/stateList")
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          setStateAll(data)
        }
      })
      .catch((err) => {
        console.log(err)
          
      })
  }, [])

  // industry List api
  React.useEffect(() => {
    http.get("/industryList")
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          setindustriesAll(data)
        }
      })
      .catch((err) => {
        console.log(err)
         
      })
  }, [])

  // Form Error handling
  const handleFormError = () => {
    let idIndusty = document.getElementById("ind")
    let idState = document.getElementById("stateRequried")
    if (companyname.trim().length === 0) {
      formErr.companyname = "Company Name is required"
    }
    if (industries.length === 0) {
      // formErr.industries = "Industry type is required"
      idIndusty.classList.add("errorMsg")
    } else {
      if (idIndusty.classList.contains("errorMsg")) {
        idIndusty.classList.remove("errorMsg")
        idIndusty.classList.add("successMsg")
      }
    }

    if (city.trim().length === 0) {
      formErr.city = "City is required"
    }

    if (state.length === 0) {
      // formErr.state = "State Name is required"
      idState.classList.add("errorMsg")
    } else {
      if (idState.classList.contains("errorMsg")) {
        idState.classList.remove("errorMsg")
        idState.classList.add("successMsg")
      }
    }

    if (companydesc.trim().length === 0 && companydesc.trim().length < 10) {
      formErr.companydesc = "Brief is required"
    }
  }

  //  Form  Submiting 
  const handleSubmit = (event) => {
    event.preventDefault()
    handleFormError()
    console.log(formErr)
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr)
    } else {
      const data = {
        companyname, industries, mycurrentposition, tinno, city, state, companydesc
      }
      console.log(data)
      setFormError({})
      handleEditCompany(data)

    }
  }

  // Api calling for  Edit Existing company
  const handleEditCompany = (data) => {
    http.post("/customer/editExistingCompany", data)
      .then((response) => {
        if(response.status==200){
          const dataRes = response.data
          console.log(dataRes)
          toast.success('Your Profile has been updated successfully', {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => history.push('/customer/user-page'),
            autoClose:1500
          })

        }
        else{
           //warning msg
           toast.warn('Oops Something went wrong!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose:1500
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomInput
            success={companyname.length > 0 ? true : false}
            error={Object.keys(formError).length > 0 && formError.companyname ? true : false}
            labelText={
              <span>
                Company name <small>(required)</small>
              </span>
            }
            id="companyname"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: companyname,
              name: "companyname",
              onChange: (event) => {
                setcompanyname(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Domain className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
          // success={Object.keys(formError).length>0 && formError.industry?false:true}
          // error={Object.keys(formError).length>0 && formError.industry?true:false}
          >
            <InputLabel
              htmlFor="industries"
              className={classes.selectLabel}
            >
              <span id="ind">Choose industry <small>(required)</small></span>
            </InputLabel>
            <Select
              // multiple
              value={industries}
              onChange={(event) => { setindustries(event.target.value) }}
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              inputProps={{
                name: "industries",
                id: "industries",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose industry
              </MenuItem>
              {
                industriesAll.map((industry) => {
                  return <MenuItem
                    key={industry.id}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelectedMultiple,
                    }}
                    value={industry.id}
                  >
                    {industry.name}
                  </MenuItem>
                })
              }

            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomInput
            labelText={
              <span>
                Position
              </span>
            }
            id="mycurrentposition"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "mycurrentposition",
              value: mycurrentposition,
              onChange: (event) => {
                setmycurrentposition(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Group className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomInput
            labelText={
              <span>
                GST No
              </span>
            }
            id="tinno"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "tinno",
              value: tinno,
              onChange: (event) => {
                settinno(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Business className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomInput
            success={city.length > 0 ? true : false}
            error={Object.keys(formError).length > 0 && formError.city ? true : false}
            labelText={
              <span >
                City <small>(required)</small>
              </span>
            }
            id="city"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: city,
              name: "city",
              onChange: (event) => {
                setCity(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Domain className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="state"
              className={classes.selectLabel}
            >
              <span id="stateRequried" > Choose State <small>(required)</small></span>
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={state}
              inputProps={{
                name: "state",
                id: "state",
                onChange: (event) => {
                  setstate(event.target.value);
                }
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose State
              </MenuItem>
              {
                stateAll.map((states) => {
                  return <MenuItem
                    key={states.state_id}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={states.state_id}
                  >
                    {states.name}
                  </MenuItem>
                })
              }
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            labelText={
              <span>
                Website URL
              </span>
            }
            id="companywebsite"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: companywebsite,
              name: "companywebsite",
              onChange: (event) => {
                setcompanywebsite(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Link className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            success={companydesc.length > 0 ? true : false}
            error={Object.keys(formError).length > 0 && formError.companydesc ? true : false}
            labelText={
              <span>
                Brief description <small>(required)</small>
              </span>
            }
            id="companydesc"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "companydesc",
              value: companydesc,
              onChange: (event) => {
                setcompanydesc(event.target.value);
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Note className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className="edit-btn-industry">
            <Button type="button" round color="info" onClick={handleIsToggleFormEdit}>Previous</Button>
            <Button type="submit" round color="success">Submit</Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
}

IndustryStep2.propTypes = {
  handleIsToggleFormEdit: PropTypes.func
}

export default IndustryStep2;