import React from "react"


import ReactTable from "components/ReactTable/ReactTable.js";

// Core Compoenents
import Button from "components/CustomButtons/Button.js";

const ProfileUpdated = () => {
    const [data, setdata] = React.useState([])

    React.useEffect(() => {
        setdata([
            {
                ID: "58237",
                customerType: "SP",
                name: "Suriya",
                phone: "7870889999",
                email: "zicsllp@gmail.com",
                iPaddress: "157.35.21.111",
                status: "Active",
                lastLogin: "2023-02-02 01:14:22",
                action: (
                    <Button color="rose" round>View</Button>
                )
            },
            {
                ID: "58237",
                customerType: "SP",
                name: "Suriya",
                phone: "7870889999",
                email: "zicsllp@gmail.com",
                iPaddress: "157.35.21.111",
                status: "Active",
                lastLogin: "2023-02-02 01:14:22",
                action: (
                    <Button color="rose" round>View</Button>
                )
            },
        ])
    }, [])
    return (
        <>
            <ReactTable
                columns={[
                    {
                        Header: "ID",
                        accessor: "ID",
                    },
                    {
                        Header: "Customer",
                        accessor: "customerType",
                    },
                    {
                        Header: "Name",
                        accessor: "name",
                    },
                    {
                        Header: "Phone",
                        accessor: "phone",
                    },
                    {
                        Header: "Email",
                        accessor: "email",
                    },
                    {
                        Header: "IP address",
                        accessor: "iPaddress",
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                    },
                    {
                        Header: "Last Login",
                        accessor: "lastLogin",
                    },
                    {
                        Header: "Action",
                        accessor: "action",
                    },
                ]}
                data={data}
            />
        </>
    )
}

export default ProfileUpdated