import React from "react"
import AuthUser from 'components/Sb/AuthUser';
// import costume style
import "assets/css/register.css"

// @material-ui/icons
import DoneAll from "@material-ui/icons/CheckCircle";
import VerifiedUser from "@material-ui/icons/People";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import OTPVerify from "./RegisterProcessor/OTPVerify";
import Step2RegCon from "./RegisterProcessor/Step2RegCon";
import Step3RegCon from "./RegisterProcessor/Step3RegCon";
import Step4RegCon from "./RegisterProcessor/Step4RegCon";
import BuyMemberShipConsultan from "views/BuyMemberShip/Consultan/BuyMemberShipConsultan";

const ConsultantRegister = () => {
    const { http} = AuthUser();
    const [mainToggle, setMainToggle] = React.useState(false)
    const [toggleOne, setToggleOne] = React.useState(false)
    const [toggleTwo, setToggleTwo] = React.useState(false)
    const [subOne, setSubOne] = React.useState(false)

    React.useState(()=>{

        http.get("/customer/getOnboardingstep")
        .then((response) => {
       
        if (response.status === 200) {
            const step = response.data
            // console.log(response)

            if(step==2){
                setToggleOne(true)
                setMainToggle(false)
                setToggleTwo(false)
                setSubOne(false)
            }
            if(step==3){
                setToggleOne(true)
                setMainToggle(true)
                setToggleTwo(false)
                setSubOne(false)
            }
            if(step==4){
                setToggleOne(true)
                setMainToggle(true)
                setToggleTwo(true)
                setSubOne(false)
            }
            if(step==5){
                setToggleOne(true)
                setMainToggle(true)
                setToggleTwo(true)
                setSubOne(true)
            }

        }
        })
        .catch((err) => {
            console.log(err)
        })
    },[])

    

    const handleMainToggle = () => {
            setMainToggle(true)
    }

    const handleToggleOne = () => {
            setToggleOne(true)
    }

    const handleToggleTwo = () => {
            setToggleTwo(true)
    }

    const handleSubOne = () => {
            setSubOne(true)
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <CardHeader color="warning" icon>
                            <CardIcon color="warning">
                                <VerifiedUser />
                            </CardIcon>
                            <h4 style={{ color: "black", fontSize: "18px", fontWeight: "500", marginTop: "18px" }}>
                                KYC Verification
                            </h4>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={11} lg={11}>
                                    {
                                        !mainToggle ? (
                                            <div className="editTile postProjectToggler consultanEditTile">
                                                <h5 className={toggleOne ? "" : "activeTitle"}>
                                                    Verification
                                                    {
                                                        toggleOne && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5 className={toggleOne ? "activeTitle" : ""}>
                                                    Preferences
                                                    {
                                                        mainToggle && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5>Experience</h5>
                                                <h5>Explore Projects</h5>
                                                <h5>Complete Onboarding</h5>
                                            </div>
                                        ) : (
                                            <div className="editTile postProjectToggler consultanEditTile">
                                                <h5 >Verification
                                                    {
                                                        toggleOne && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5 >
                                                    Preferences
                                                    {
                                                        mainToggle && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5 className={toggleTwo ? "" : "activeTitle"}>
                                                    Experience
                                                    {
                                                        toggleTwo && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5 className={toggleTwo && !subOne ? "activeTitle" : ""}>
                                                    Explore Projects
                                                    {
                                                        toggleTwo && subOne && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5 className={toggleTwo && subOne ? "activeTitle" : ""}>Complete Onboarding</h5>
                                            </div>
                                        )
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                            {
                                !mainToggle ? (
                                    <>
                                        {
                                            !toggleOne ? (
                                                <>
                                                    <OTPVerify handleToggleOne={handleToggleOne} />
                                                </>
                                            ) : (
                                                <>
                                                    <Step2RegCon handleMainToggle={handleMainToggle} />
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            !toggleTwo ? (
                                                <>
                                                    <Step3RegCon handleToggleTwo={handleToggleTwo} />
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        !subOne ? (
                                                            <>
                                                                <Step4RegCon handleSubOne={handleSubOne} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <BuyMemberShipConsultan />
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default ConsultantRegister