import React, { useEffect, useState } from 'react'

// CSS importing
import "assets/css/LoginPage.css"

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// import InputAdornment from '@material-ui/core/InputAdornment'

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js'
import LoginWithOTP from './LoginWithOTP'
import LoginOTPSubmit from './LoginOTPSubmit'
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

const useStyles = makeStyles(styles)

export default function LoginPage() {
  const [otpSend, setOtpSend] = React.useState(false)
  const [email, setEmail] = React.useState("")

  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('')
    }, 700)
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id)
    }
  })


  const classes = useStyles()

  const [browser, setBrowser] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('Chrome') > -1) {
      setBrowser(false);
    } else if (userAgent.indexOf('Safari') > -1) {
      setBrowser(true);
    } else if (userAgent.indexOf('Firefox') > -1) {
      setBrowser(false);
    } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
      setBrowser(false);
    } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
      setBrowser(false);
    } else {
      setBrowser(true);
    }
  }, []);

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card login className={classes[cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="primary"
            >
              <h4
                className={classes.cardTitle}
                style={{ fontSize: '24px', fontWeight: '600' }}
              >
                Login with OTP
              </h4>
            </CardHeader>
            <CardBody>
              {
                otpSend ? (
                  <>
                    <LoginOTPSubmit />
                  </>
                ) : (
                  <>
                    <LoginWithOTP setOtpSend={setOtpSend} email={email} setEmail={setEmail} />
                  </>
                )
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          {browser && <SnackbarContent
            message={
              `Note: This page is currently experiencing compatibility issues with Safari. Don't worry, it should work smoothly on all other modern web browsers.`
            }
            close
            color="danger"
          />}
        </GridItem>
      </GridContainer>
    </div>
  )
}
