import React, { useEffect } from "react";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
// Model
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import stylesModel from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
const useStylesModel = makeStyles(stylesModel);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// @material-ui/icons
import Business from "@material-ui/icons/Business";
import Close from "@material-ui/icons/Close";
import Person from "@material-ui/icons/Person";
import WhatsApp from "@material-ui/icons/WhatsApp";

// Style
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

// Import Style CSS
import "assets/css/ContactCompany.css";

// Core Component
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";

// Compentents
import ContactContainer from "./ContactForms/ContactContainer";
import TimeLineCompany from "./TimeLineCompanyStatus.js/TimeLineCompany";
import LanguageCustomer from "./Grade/LanguageCustomer";
import ViewEditCompany from "./ContactForms/ViewEditCompany";
import TableStyle from "components/Table/TableStyle";
import LeadQualification0 from "./LeadQualificationZero/LeadQualification0";
import CallbackNoResponse from "./LeadQualificationZero/CallbackNoResponse";
import MarkNotRelevant from "./LeadQualificationZero/MarkNotRelevant";
import ChatBotContact from "./chatbot/ChatBotContact";
import Loader from "views/Loader/Loader";

// import GradeCustomerSP from "./Grade/GradeCustomerSP";
// import GradeCustomerSS from "./Grade/GradeCustomerSS";

import GradeCustomerQ1 from "./Grade/GradeCustomerQ1";
import GradeCustomerQ0 from "./Grade/GradeCustomerQ0";
import GradeCustomerQ2 from "./Grade/GradeCustomerQ2";

const useStyles = makeStyles(styles);

const ContactView = () => {
  const { http, getUser } = AuthUser();
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const classesModel = useStylesModel();
  const [user, setUser] = React.useState({});
  const [assignedEdit, setAssignEdit] = React.useState(false);
  // Model
  const [classicModal, setClassicModal] = React.useState(false);
  const [callbackModal, setCallbackModal] = React.useState(false);
  const [markNotRelevantModal, setMarkNotRelevantModal] = React.useState(false);
  const [leadQualification0Modal, setLeadQualification0Modal] = React.useState(
    false
  );
  const [openChatModal, setOpenChatModal] = React.useState(false);
  // company score
  const [companyScore, setCompanyScore] = React.useState("");
  const [companyData, setCompanyData] = React.useState({});
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [gradeValue, setGradeValue] = React.useState("");

  // Source / Search Query
  const [priority, setPriority] = React.useState("");
  const [source, setSource] = React.useState("");
  const [query, setQuery] = React.useState("");

  const [lead_qualificaton, setlead_qualificaton] = React.useState(false);
  const handlesLeadQualificaton = () => {
    setlead_qualificaton(!lead_qualificaton);
  };

  React.useState(() => {
    if (Object.keys(getUser()).length > 0) {
      setUser(getUser());
    }
  }, [companyData]);

  React.useEffect(() => {
    if (
      companyData.assignedto == 1 ||
      companyData.assignedto == 2 ||
      companyData.assignedto == user.user_id
    ) {
      setAssignEdit(true);
    }
  }, [companyData, user]);

  React,
    useEffect(() => {
      http
        .post("/admin/company", { id: params.id })
        .then((response) => {
          const data = response.data;
          if (Object.keys(data).length > 0) {
            setCompanyData(data);
            setCompanyScore(data.lead_score ? data.lead_score : "NA");
            setName(
              data.company1 && data.company1.name ? data.company1.name : "NA"
            );
            setEmail(
              data.company1 && data.company1.email ? data.company1.email : "NA"
            );
            setPhone(
              data.company1 && data.company1.mobile
                ? data.company1.mobile
                : "NA"
            );
            setPosition(
              data.customer_type
                ? (data.customer_type == "SS" && "Industry") ||
                    (data.customer_type == "SP" && "Consultant")
                : "NA"
            );
            setGradeValue(
              Object.keys(data.company1).length > 0 && data.company1.grade
                ? data.company1.grade
                : "NA"
            );
            setPriority(data.sales_priority ? data.sales_priority : "NA");
            setSource(
              Object.keys(data.source).length > 0 && data.source.sourcemedium
                ? data.source.sourcemedium
                : "NA"
            );
            setQuery(
              Object.keys(data.source).length > 0 && data.source.search_query
                ? data.source.search_query
                : "NA"
            );
          }
        })
        .catch((err) => {
          toast.error(
            `Error in company details ${JSON.stringify(err.message)}`,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1500,
            }
          );
        });
    }, [lead_qualificaton]);

  const SearchQueryData = [
    {
      heading: "Priority:",
      para: `${priority}`,
    },
    {
      heading: "Source:",
      para: `${source}`,
    },
    {
      heading: "Search Query:",
      para: `${query}`,
    },
  ];

  // console.log(companyData)
  // history.push(`/customer/project-details/${id}`) company1.customer_id

  return (
    <>
      {companyData && Object.keys(companyData).length > 0 ? (
        <>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <h4>
                Lead Score :{" "}
                <span style={{ fontSize: "22px", color: "#e91e63" }}>
                  {companyScore}
                </span>
                /10
              </h4>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <h4 style={{ textAlign: "end" }}>
                Grade :{" "}
                <span style={{ fontSize: "22px", color: "#e91e63" }}>
                  {gradeValue}
                </span>
              </h4>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {/* Company details */}
            <GridItem xs={12} sm={12} md={6} lg={3}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <Business />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Company Details</h4>
                </CardHeader>
                <CardBody>
                  <div
                    style={{
                      margin: "2px",
                      borderRadius: "12px",
                      padding: "8px",
                      background: `${
                        companyData.customer_type == "SS"
                          ? "#FFCB7E"
                          : "#B5E6EC"
                      }`,
                    }}
                  >
                    <div className={``}>
                      <div style={{ margin: "12px 0px" }}>
                        {companyData.company1 &&
                          companyData.company1.customer_id && (
                            <>
                              <div style={{ marginBottom: "4px" }}>
                                <Button
                                  color="danger"
                                  round
                                  onClick={() => {
                                    history.push(
                                      `/admin/customer/${companyData.company1.customer_id}`
                                    );
                                  }}
                                >
                                  <Person /> View
                                </Button>
                              </div>
                            </>
                          )}
                        <div style={{ margin: "4px 0x" }}>
                          <a
                            href={`https://wa.me/91${phone}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button color="success" round>
                              <WhatsApp /> Whatsapp
                            </Button>
                          </a>
                        </div>
                      </div>
                      <h5>{name}</h5>
                      <p>{email}</p>
                      <p>{phone}</p>
                      <p>{position}</p>
                    </div>
                  </div>
                  {assignedEdit && companyData && companyData.company1 && (
                    <div style={{ margin: "12px 0px" }}>
                      <Button
                        color="rose"
                        round
                        onClick={() => setClassicModal(true)}
                      >
                        View/Edit
                      </Button>
                    </div>
                  )}
                  <Dialog
                    classes={{
                      root: classesModel.center + " " + classesModel.modalRoot,
                      paper: classesModel.modal,
                    }}
                    open={classicModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setClassicModal(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classesModel.modalHeader}
                    >
                      <Button
                        justIcon
                        className={classesModel.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModal(false)}
                      >
                        <Close className={classesModel.modalClose} />
                      </Button>
                      <h4 className={classesModel.modalTitle}>
                        View/Edit Details
                      </h4>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classesModel.modalBody}
                    >
                      <ViewEditCompany
                        setClassicModal={setClassicModal}
                        companyData={companyData}
                        handlesLeadQualificaton={handlesLeadQualificaton}
                      />
                    </DialogContent>
                  </Dialog>
                </CardBody>
              </Card>
              {/*  Sales Qualification data */}
              {Object.keys(companyData).length > 0 &&
                companyData.qualified == 1 && (
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <Business />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        Sales Qualification data
                      </h4>
                    </CardHeader>
                    <CardBody>
                      {companyData.qualified == 1 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: companyData.qualified_log,
                          }}
                        ></div>
                      )}
                    </CardBody>
                  </Card>
                )}
              {/* Source / Search Query  */}
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <Business />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    Source / Search Query
                  </h4>
                </CardHeader>
                <CardBody>
                  <TableStyle data={SearchQueryData} />
                </CardBody>
              </Card>
            </GridItem>
            {/* log form */}
            <GridItem xs={12} sm={12} md={6} lg={6}>
              {assignedEdit && Object.keys(companyData).length > 0 && (
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <Business />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      {companyData.qualified == 0 &&
                      companyData.customer_type == "SS"
                        ? "Lead Qualification"
                        : "Company Details"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {companyData.qualified == 0 &&
                    companyData.customer_type == "SS" ? (
                      <>
                        <LeadQualification0
                          handlesLeadQualificaton={handlesLeadQualificaton}
                          leadQualification0Modal={leadQualification0Modal}
                          setLeadQualification0Modal={
                            setLeadQualification0Modal
                          }
                          setCallbackModal={setCallbackModal}
                          setMarkNotRelevantModal={setMarkNotRelevantModal}
                        />
                        <CallbackNoResponse
                          callbackModal={callbackModal}
                          setCallbackModal={setCallbackModal}
                          handlesLeadQualificaton={handlesLeadQualificaton}
                        />
                        <MarkNotRelevant
                          markNotRelevantModal={markNotRelevantModal}
                          setMarkNotRelevantModal={setMarkNotRelevantModal}
                          handlesLeadQualificaton={handlesLeadQualificaton}
                        />
                      </>
                    ) : (
                      <>
                        <ContactContainer
                          handlesLeadQualificaton={handlesLeadQualificaton}
                          companyData={companyData}
                        />
                      </>
                    )}
                  </CardBody>
                </Card>
              )}
              {/* TimeLineCompany */}
              {Object.keys(companyData).length > 0 &&
                companyData.log.length > 0 && (
                  <TimeLineCompany
                    logs={companyData.log}
                    assignedEdit={assignedEdit}
                    handlesLeadQualificaton={handlesLeadQualificaton}
                  />
                )}
            </GridItem>
            {/* Grade form */}
            {assignedEdit && (
              <GridItem xs={12} sm={12} md={6} lg={3}>
                {/* Grade form SS*/}
                {Object.keys(companyData).length > 0 && (
                  <>
                    {/* {companyData.customer_type == "SS" &&
                      (companyData.qualified == 0 ||
                        companyData.qualified == 1 ||
                        companyData.qualified == 2) && (
                        <Card>
                          <CardHeader color="info" icon>
                            <CardIcon color="info">
                              <Business />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Grade</h4>
                          </CardHeader>
                          <CardBody>
                            <GradeCustomerSS
                              handlesLeadQualificaton={handlesLeadQualificaton}
                            />
                          </CardBody>
                        </Card>
                      )} */}

                    {/*  Grade form SP ,  , qualified = 0 */}
                    {companyData.qualified == 0 && (
                      <Card>
                        <CardHeader color="info" icon>
                          <CardIcon color="info">
                            <Business />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Grade</h4>
                        </CardHeader>
                        <CardBody>
                          <GradeCustomerQ0
                            handlesLeadQualificaton={handlesLeadQualificaton}
                          />
                        </CardBody>
                      </Card>
                    )}
                    {/* Grade form SP , qualified = 1*/}
                    {companyData.qualified == 1 && (
                      <Card>
                        <CardHeader color="info" icon>
                          <CardIcon color="info">
                            <Business />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Grade</h4>
                        </CardHeader>
                        <CardBody>
                          <GradeCustomerQ1
                            handlesLeadQualificaton={handlesLeadQualificaton}
                          />
                        </CardBody>
                      </Card>
                    )}
                    {/*  Grade form SP ,  , qualified = 2 */}
                    {companyData.qualified == 2 && (
                      <Card>
                        <CardHeader color="info" icon>
                          <CardIcon color="info">
                            <Business />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>Grade</h4>
                        </CardHeader>
                        <CardBody>
                          <GradeCustomerQ2
                            handlesLeadQualificaton={handlesLeadQualificaton}
                          />
                        </CardBody>
                      </Card>
                    )}
                  </>
                )}
                {/* Language form */}
                {assignedEdit && (
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <Business />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>Company Details</h4>
                    </CardHeader>
                    <CardBody>
                      <LanguageCustomer
                        handlesLeadQualificaton={handlesLeadQualificaton}
                      />
                    </CardBody>
                  </Card>
                )}
              </GridItem>
            )}
          </GridContainer>
          {(phone && phone.length > 0) && (
            <>
              <div style={{ position: "fixed", bottom: "2%", right: "2%" }}>
                <div
                  style={{
                    backgroundColor: "#25D366",
                    padding: "8px 8px 4px 8px",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setOpenChatModal(true);
                  }}
                >
                  <div>
                    <WhatsApp
                      style={{
                        color: "#ffffff",
                        width: "35px",
                        height: "35px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <ChatBotContact
                openChatModal={openChatModal}
                setOpenChatModal={setOpenChatModal}
                phone={phone}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
};

export default ContactView;
