import React from "react";
import PropTypes from "prop-types";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// start model
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import stylesModel from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
const useStylesModel = makeStyles(stylesModel);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
// end model

// components
import LeadNoteCompany from "./LeadNoteCompany";
import LeadQualificationPopup from "./LeadQualificationPopup";

const LeadQualification0 = (props) => {
  const [industries, setindustries] = React.useState("");
  const {setMarkNotRelevantModal, handlesLeadQualificaton ,leadQualification0Modal,setLeadQualification0Modal,setCallbackModal} = props;

  // Model
  const classesModel = useStylesModel();
  

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Button
            color="success"
            round
            onClick={() => setLeadQualification0Modal(true)}
          >
            Lead Qualification
          </Button>
          <Dialog
            classes={{
              root: classesModel.center + " " + classesModel.modalRoot,
              paper: classesModel.modal,
            }}
            open={leadQualification0Modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setLeadQualification0Modal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classesModel.modalHeader}
            >
              <Button
                justIcon
                className={classesModel.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => {
                  setLeadQualification0Modal(false);
                  setindustries("");
                }}
              >
                <Close className={classesModel.modalClose} />
              </Button>
              <h4 className={classesModel.modalTitle}>Lead Qualification</h4>
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classesModel.modalBody}
            >
              <LeadQualificationPopup
                handlesLeadQualificaton={handlesLeadQualificaton}
                industries={industries}
                setindustries={setindustries}
                setClassicModal={setLeadQualification0Modal}
                setCallbackModal={setCallbackModal}
                setMarkNotRelevantModal={setMarkNotRelevantModal}
              />
            </DialogContent>
          </Dialog>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <p style={{ marginTop: "20px" }}>Note</p>
          <LeadNoteCompany handlesLeadQualificaton={handlesLeadQualificaton} />
        </GridItem>
      </GridContainer>
    </>
  );
};
LeadQualification0.propTypes = {
  handlesLeadQualificaton: PropTypes.func,
  leadQualification0Modal:PropTypes.bool,
  setLeadQualification0Modal: PropTypes.func,
  setCallbackModal: PropTypes.func,
  setMarkNotRelevantModal: PropTypes.func,
};
export default LeadQualification0;
