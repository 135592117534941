import React from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import AuthUser from "components/Sb/AuthUser";
import { useParams } from "react-router-dom";
// Style
import "assets/css/common.css";

// @material-ui/icons
import Face from "@material-ui/icons/Money";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// Input
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";

// Import Style
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);

const CreatePaymentLink = ({ handlesLeadQualificaton, companyData }) => {
  const classes = useStyles();
  const { http, getUser } = AuthUser();
  const params = useParams();

  const [cid, setcid] = React.useState("");
  const [pid, setpid] = React.useState("");
  const [amount, setamount] = React.useState("");
  const [discount, setdiscount] = React.useState("");
  const [atotal, setatotal] = React.useState("");
  const [gtotal, setgtotal] = React.useState("");

  // FormError
  const [formError, setFormError] = React.useState({});
  let formErr = {};

  React.useEffect(() => {
    if (Object.keys(companyData).length > 0 && companyData.company1 && companyData.company1.customer_id) {
      setcid(companyData.company1.customer_id);
    }
  }, [pid]);

  React.useEffect(() => {
    if (amount.toString().length > 0) {
      setatotal(parseInt(amount));
      setgtotal(Math.round(1.18 * parseInt(amount)));
    }
  }, [amount]);

  const handleDiscountAmount = (valueDiscount) => {
    if (amount.toString().length > 0) {
      if (valueDiscount.toString().length > 0) {
        const discoutAmount = parseInt(amount) - parseInt(valueDiscount);
        setatotal(parseInt(discoutAmount));
        setgtotal(Math.round(1.18 * parseInt(discoutAmount)));
      }
    }
  };

  const handleFormError = () => {
    if (pid.toString().length === 0) {
      formErr.pid = "Error";
    }
    if (cid.toString().length === 0) {
      formErr.cid = "Error";
    }
    if (amount.toString().length == 0) {
      formErr.amount = "Error";
    }
  };

  // Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const data = {
        cid,
        pid: parseInt(pid),
        discount: parseInt(discount),
        user_id: getUser().user_id,
        id: parseInt(params.id),
      };
      console.log(data);
      handleLogForm(data);
    }
  };

  const resolve = () => {
    setFormError({});
    setcid("");
    setpid("");
    setamount("");
    setdiscount("");
    setatotal("");
    setgtotal("");
  };

  const handleLogForm = (formData) => {
    http
      .post("/admin/payment_link", formData)
      .then((response) => {
        // console.log(reposne);
        if (response.data) {
          toast.success(`Payment link updated Successfull!`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          handlesLeadQualificaton();
          resolve();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`Error in log ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };
  //
  const handlePlanId = (Planid) => {
    http
      .post("/getPlan", { id: Planid })
      .then((response) => {
        // console.log(reposne);
        const data = response.data;
        if (Object.keys(data).length > 0) {
          if (data.plancost) {
            setamount(data.plancost);
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`Error in log ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <GridContainer>
        {/* Cid */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            success={cid.length > 0 ? true : false}
            error={
              Object.keys(formError).length > 0 && formError.cid ? true : false
            }
            labelText={
              <span>
                Customer ID <small>(required)</small>
              </span>
            }
            id="cid"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "cid",
              value: cid,
              disabled: true,
              onChange: (event) => {
                setcid(event.target.value);
                delete formError.cid;
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        {/* pid */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="pid" className={classes.selectLabel}>
              <small id="pidLable">
                Select Plan<small>(required)</small>
              </small>
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={pid}
              onChange={(event) => {
                setpid(event.target.value);
                setdiscount("");
                handlePlanId(parseInt(event.target.value));
                delete formError.pid;
              }}
              inputProps={{
                name: "pid",
                id: "pid",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Plan
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="36"
              >
                Industry-Starter
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="37"
              >
                Industry-Accelerator
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="38"
              >
                Industry-Enterprise
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="33"
              >
                MSME Acceleration
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="25"
              >
                Consultant-Basic
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="26"
              >
                Consultant-Premium
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value="27"
              >
                Consultant-Assist
              </MenuItem>
            </Select>
          </FormControl>
          {Object.keys(formError).length > 0 && formError.pid && (
            <p style={{ color: "#f44336", textAlign: "left" }}>
              {formError.pid}
            </p>
          )}
        </GridItem>
        {/* Amount */}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CustomInput
            success={amount.length > 0 ? true : false}
            error={
              Object.keys(formError).length > 0 && formError.amount
                ? true
                : false
            }
            labelText={<span>Plan Amount*</span>}
            id="amount"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "amount",
              value: amount,
              disabled: true,
            }}
          />
        </GridItem>
        {/* Discount */}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CustomInput
            labelText={<span>Discount</span>}
            id="discount"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "discount",
              value: discount,
              type: "number",
              onChange: (event) => {
                setdiscount(event.target.value);
                delete formError.discount;
                handleDiscountAmount(event.target.value);
              },
            }}
          />
        </GridItem>
        {/* Total amount after discount */}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CustomInput
            labelText={<span>Total amount after discount</span>}
            id="atotal"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "atotal",
              value: atotal,
              disabled: true,
            }}
          />
        </GridItem>
        {/* Total amount with GST: */}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CustomInput
            labelText={<span>Total amount with GST</span>}
            id="gtotal"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "gtotal",
              value: gtotal,
              disabled: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <Button round color="rose" type="submit">
              Create Link
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};
CreatePaymentLink.propTypes = {
  handlesLeadQualificaton: PropTypes.func,
  companyData: PropTypes.object,
};
export default CreatePaymentLink;
