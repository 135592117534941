import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/core/styles"; 

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import PermIdentity from "@material-ui/icons/PermIdentity";
import Business from "@material-ui/icons/Business";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
const useStyles = makeStyles(styles);

export default function IndustryProfile(props) {
    const classes = useStyles();
    const {setIndModal,industry} = props
   
  return (
    <>
      <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <PermIdentity />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Personal Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Fisrt Name: </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.firstname ? industry.firstname : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Last Name: </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.lastname ? industry.lastname : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Email : </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.email ? industry.email : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Phone : </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.phone ? industry.phone : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Date of Birth : </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.dob ? industry.dob : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>City : </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.city ? industry.city : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <Business />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Company Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Company Name: </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.companyname ? industry.companyname : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Current Position: </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.mycurrentposition ? industry.mycurrentposition : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Industry : </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.industries ? industry.industries : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        
                                       
                                       
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Linkedin : </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.linkedin ? industry.linkedin : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Brief Bio : </h5>
                                                <p className={classes.lableValue}>
                                                    {industry.brief_bio ? industry.brief_bio : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <Clearfix />
 <GridContainer justify="center">

                                        <GridItem xs={12} sm={4} md={4}>
                                            <Button  type="submit" round color="rose" onClick={()=>{setIndModal(false)}}>   Close</Button>
                                        </GridItem>

                                    </GridContainer>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
    </>
  )

  
}

IndustryProfile.propTypes = {
    setIndModal: PropTypes.func,
    ProjectId: PropTypes.string,
    industry: PropTypes.object
}

