import React from "react";
import AuthUser from "components/Sb/AuthUser";
// import { toast } from "react-toastify";
// import PropTypes from "prop-types";
// import Loader from "views/Loader/Loader";
// import { useHistory } from "react-router-dom";

// @material-ui/core components

// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.js";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CreateOfflineLeads from "./OfflineLead/CreateOfflineLeads";
import CreateContactPopupCL from "./CreateContact/CreateContactPopupCL";

const ActionCompanyList = () => {
  const { getUser } = AuthUser();
  //   const history = useHistory();

  const [createOfflineLeadsModel, setCreateOfflineLeadsModel] = React.useState(
    false
  );

  const [createContactModel, setCreateContactModel] = React.useState(
    false
  );

  return (
    <div>
      <GridContainer>
        {getUser().user_type === "A" && (
          <GridItem xs={12} sm={12} md={6} lg={3}>
            <Button
              round
              color="danger"
              onClick={() => setCreateOfflineLeadsModel(true)}
            >
              Create Offline Lead
            </Button>
            <CreateOfflineLeads
              createOfflineLeadsModel={createOfflineLeadsModel}
              setCreateOfflineLeadsModel={setCreateOfflineLeadsModel}
            />
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={6} lg={3}>
            <Button
              round
              color="info"
              onClick={() => setCreateContactModel(true)}
            >
              Create Contact
            </Button>
            <CreateContactPopupCL setCreateContactModel={setCreateContactModel} createContactModel={createContactModel}/>
          </GridItem>
      </GridContainer>
    </div>
  );
};

export default ActionCompanyList;
