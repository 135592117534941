import React from "react";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
// import ReactTable from "components/ReactTable/ReactTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { useHistory } from "react-router-dom";
import Loader from "views/Loader/Loader";
import ActionCompanyList from "./ActionCompanyList";
const useStylesTable = makeStyles(stylesTable);

const companyListAdminSales = () => {
  const { http, getUser } = AuthUser();
  const classesTable = useStylesTable();
  const history = useHistory();

  const [companyList, setCompanyList] = React.useState([]);

  const [prioritySearched, setprioritySearched] = React.useState("");
  const [gradeSearched, setgradeSearched] = React.useState("");
  const [CIDSearch, setCIDSearch] = React.useState("");
  const [nameSearched, setnameSearched] = React.useState("");
  const [emailseached, setemailSearched] = React.useState("");
  const [mobileseached, setmobileSearched] = React.useState("");
  const [customer_typeseached, setcustomer_typeseached] = React.useState("");
  const [
    previously_assignedseached,
    setpreviously_assignedSearched,
  ] = React.useState("");

  const [last_contactedseached, setlast_contactedSearched] = React.useState("");
  const [plan_takenseached, setplan_takenSearched] = React.useState("");
  const [list_nameseached, setlist_nameSearched] = React.useState("");
  const [sourcemediumseached, setsourcemediumSearched] = React.useState("");
  const [create_dateseached, setcreate_dateSearched] = React.useState("");

  const [currentPage, setCurrentPage] = React.useState(1);

  const [filterData, setFilterData] = React.useState([]);
  const dataPerPage = 10;

  React.useEffect(() => {
    http
      .post("/admin/companylist", { id: getUser().user_id })
      .then((response) => {
        const data = response.data;
        if (response) {
          setCompanyList(data.reverse());
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong! ${JSON.stringify(err.message)}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  React.useEffect(() => {
    setFilterData(companyList);
  }, [companyList]);

  const handleprioritySearched = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.priority) {
        return ele.priority
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handlegradeSearched = (value) => {
    const result = companyList.filter((ele) => {
      if (ele.grade) {
        return ele.grade
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };
  const handleCIDSearch = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.customer_id) {
        return ele.customer_id
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handlenameSearched = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.name) {
        return ele.name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handleemailSearched = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.email) {
        return ele.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handlemobileSearched = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.mobile) {
        return ele.mobile
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handlCtypeSearched = (value) => {
    const result = companyList.filter((ele) => {
      if (ele.customer_type) {
        return ele.customer_type
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handlepreviously_assignedseached = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.previously_assigned) {
        return ele.previously_assigned
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handlelast_contactedseached = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.last_contacted) {
        return ele.last_contacted
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };
  const handleplan_takenseached = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.plan_taken) {
        return ele.plan_taken
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handlelist_nameseached = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.list_name) {
        return ele.list_name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handlesourcemediumseached = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.sourcemedium) {
        return ele.sourcemedium
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };
  const handlecreate_dateseached = (e) => {
    const result = companyList.filter((ele) => {
      if (ele.create_date) {
        return ele.create_date
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalData = filterData.length;
    const maxPages = Math.ceil(totalData / dataPerPage);

    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Calculate the index range for the expenses to display on the current page
  const startIndex = (currentPage - 1) * dataPerPage;
  const endIndex = startIndex + dataPerPage;

  // Filter and map expenses based on the current page
  const dataToDisplay = filterData.slice(startIndex, endIndex);

  const totalDatas = filterData.length;
  const totalPages = Math.ceil(totalDatas / dataPerPage);

  //   console.log()

  return (
    <div>
      {companyList && companyList.length > 0 ? (
        <>
          <ActionCompanyList />
          <Card>
            <h4 style={{ marginLeft: "18px", marginTop: "18px" }}>
              Company list (Total : {companyList.length})
            </h4>
            <h6
              style={{
                marginLeft: "18px",
                marginTop: "18px",
                textTransform: "none",
              }}
            >
              Sort Company list (Total : {filterData.length})
            </h6>
            <CardBody>
              <div className="pageinationSearch">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <p>
                      Page {currentPage} of {totalPages}
                    </p>
                  </div>
                  <div>
                    <Button round onClick={handlePreviousPage}>
                      Pervious
                    </Button>
                    <Button round onClick={handleNextPage}>
                      Next
                    </Button>
                  </div>
                </div>
              </div>
              <div className={classesTable.tableResponsive}>
                <Table className="commonTable compantListTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>Action</TableCell>
                      <TableCell>Priority</TableCell>
                      <TableCell>Grade</TableCell>
                      <TableCell>CID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Mobile</TableCell>
                      <TableCell>Customer Type</TableCell>
                      <TableCell>Previously Assigned</TableCell>
                      <TableCell>Last Contacted</TableCell>
                      <TableCell>Plan Taken</TableCell>
                      <TableCell>List Name</TableCell>
                      <TableCell>Source</TableCell>
                      <TableCell>Date Added</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by priority"
                            value={prioritySearched}
                            onChange={(e) => {
                              e.preventDefault();
                              setprioritySearched(e.target.value);
                              handleprioritySearched(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <select
                            name="customer_type"
                            className="searchInput searchSelectionInput"
                            value={gradeSearched}
                            onChange={(e) => {
                              e.preventDefault();
                              setgradeSearched(e.target.value);
                              handlegradeSearched(e.target.value);
                            }}
                          >
                            <option value="">Search by grade</option>
                            <option value="*">Null</option>
                            <optgroup label="New">
                              <option value="B-Sales Qualified">
                                B-Sales Qualified
                              </option>
                              <option value="A-Potential(Membership Discussion Completed)">
                                A-Potential(Membership Discussion Completed)
                              </option>
                              <option value="P-Plan Taken">P-Plan Taken</option>
                              <option value="PNI-Potential Project but Not Interested to Onboard">
                                PNI-Potential Project but Not Interested to
                                Onboard
                              </option>
                              <option value="NR1-No Response">
                                NR1-No Response
                              </option>
                              <option value="NR2-Job Enquiry">
                                NR2-Job Enquiry
                              </option>
                              <option value="NR3-Low Budget">
                                NR3-Low Budget
                              </option>
                              <option value="NR4-Not Interested">
                                NR4-Not Interested
                              </option>
                              <option value="NR5-Others(Not Relevant)">
                                NR5-Others(Not Relevant)
                              </option>
                              <option value="NR6-Not Interested / Project Dropped / Not Responding">
                                NR6-Not Interested / Project Dropped / Not
                                Responding
                              </option>
                              <option value="FR-Future Prospects">
                                FR-Future Prospects (&gt; 3 months)
                              </option>
                              <option value="W-Wrong number">
                                W-Wrong Number/Incorrect Number
                              </option>
                              <option value="D-Duplicate profile">
                                D-Duplicate Profile
                              </option>
                              <option value="CB-Callback">CB-Callback</option>
                            </optgroup>
                            <optgroup label="Old">
                              <option value="C-Explanation &amp; Mailing done">
                                C-Explanation &amp; Mailing done
                              </option>
                              <option value="B-Marketing Qualified Lead">
                                B-Marketing Qualified Lead
                              </option>
                              <option value="A-Potential Client">
                                A-Potential Client
                              </option>
                              <option value="Potential Customer">
                                A-Potential Customer
                              </option>
                              <option value="Qualified Lead">
                                B-Qualified Lead
                              </option>
                              <option value="C-Opportunity">
                                C-Opportunity
                              </option>
                              <option value="P1-Prequalified">
                                P1-Prequalified
                              </option>
                              <option value="R-Renewals">R-Renewals</option>
                              <option value="PS-Project Selling">
                                PS-Project Selling
                              </option>
                              <option value="C-Registered">C-Registered</option>
                              <option value="D-Marketing Qualified Lead">
                                D-Marketing Qualified Lead
                              </option>
                              <option value="D-Pipeline">D-Pipeline</option>
                              <option value="E-Explanation &amp; Mailing done">
                                E-Explanation &amp; Mailing done
                              </option>
                              <option value="Future Prospects">
                                FR-Future Prospects
                              </option>
                              <option value="F-Cold Lead">F-Cold Lead</option>
                              <option value="none">none</option>
                              <option value="NR-Not Relevant">
                                NR-Not Relevant
                              </option>
                              <option value="NI-Not Interested">
                                NI-Not Interested
                              </option>
                              <option value="LB-Low Budget">
                                LB-Low Budget
                              </option>
                              <option value="P-Plan Taken">P-Plan Taken</option>
                            </optgroup>
                          </select>
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by CID"
                            value={CIDSearch}
                            onChange={(e) => {
                              e.preventDefault();
                              setCIDSearch(e.target.value);
                              handleCIDSearch(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by name"
                            value={nameSearched}
                            onChange={(e) => {
                              e.preventDefault();
                              setnameSearched(e.target.value);
                              handlenameSearched(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by Email"
                            value={emailseached}
                            onChange={(e) => {
                              e.preventDefault();
                              setemailSearched(e.target.value);
                              handleemailSearched(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by Mobile"
                            value={mobileseached}
                            onChange={(e) => {
                              e.preventDefault();
                              setmobileSearched(e.target.value);
                              handlemobileSearched(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <select
                            name="customer_type"
                            className="searchInput"
                            value={customer_typeseached}
                            onChange={(e) => {
                              e.preventDefault();
                              setcustomer_typeseached(e.target.value);
                              handlCtypeSearched(e.target.value);
                            }}
                          >
                            <option value="">select customer type</option>
                            <option value="SS">SS</option>
                            <option value="SP">SP</option>
                          </select>
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by Previously Assigned"
                            value={previously_assignedseached}
                            onChange={(e) => {
                              e.preventDefault();
                              setpreviously_assignedSearched(e.target.value);
                              handlepreviously_assignedseached(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by last contacted"
                            value={last_contactedseached}
                            onChange={(e) => {
                              e.preventDefault();
                              setlast_contactedSearched(e.target.value);
                              handlelast_contactedseached(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by plan"
                            value={plan_takenseached}
                            onChange={(e) => {
                              e.preventDefault();
                              setplan_takenSearched(e.target.value);
                              handleplan_takenseached(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by list name"
                            value={list_nameseached}
                            onChange={(e) => {
                              e.preventDefault();
                              setlist_nameSearched(e.target.value);
                              handlelist_nameseached(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by sourcemedium"
                            value={sourcemediumseached}
                            onChange={(e) => {
                              e.preventDefault();
                              setsourcemediumSearched(e.target.value);
                              handlesourcemediumseached(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by create date"
                            value={create_dateseached}
                            onChange={(e) => {
                              e.preventDefault();
                              setcreate_dateSearched(e.target.value);
                              handlecreate_dateseached(e);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataToDisplay.map((company, c) => {
                      return (
                        <TableRow
                          key={c}
                          className={classesTable.tableRowHover}
                        >
                          <TableCell>
                            <Button
                              color="rose"
                              round
                              onClick={() => {
                                history.push(`/admin/company/${company.id}`);
                              }}
                            >
                              View
                            </Button>
                          </TableCell>
                          <TableCell>
                            {/* {company.priority} */}
                            {company.priority &&
                              company.priority.toLowerCase() == "high" && (
                                <div style={{ textAlign: "center" }}>
                                  <Button color="success">
                                    {company.priority}
                                  </Button>
                                </div>
                              )}
                            {company.priority &&
                              company.priority.toLowerCase() == "medium" && (
                                <div style={{ textAlign: "center" }}>
                                  <Button color="warning">
                                    {company.priority}
                                  </Button>
                                </div>
                              )}
                          </TableCell>
                          <TableCell>{company.grade}</TableCell>
                          <TableCell>{company.customer_id}</TableCell>
                          <TableCell>{company.name}</TableCell>
                          <TableCell>{company.email}</TableCell>
                          <TableCell>{company.mobile}</TableCell>

                          <TableCell>{company.customer_type}</TableCell>
                          <TableCell>{company.previously_assigned}</TableCell>

                          <TableCell>{company.last_contacted}</TableCell>
                          <TableCell>{company.plan_taken}</TableCell>

                          <TableCell>{company.list_name}</TableCell>
                          <TableCell>{company.sourcemedium}</TableCell>
                          <TableCell>{company.create_date}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <div className="pageinationSearch">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <p>
                      Page {currentPage} of {totalPages}
                    </p>
                  </div>
                  <div>
                    <Button round onClick={handlePreviousPage}>
                      Pervious
                    </Button>
                    <Button round onClick={handleNextPage}>
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </div>
  );
};

export default companyListAdminSales;
