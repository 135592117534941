import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// import { toast } from "react-toastify";
// import AuthUser from "components/Sb/AuthUser";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Button from "components/CustomButtons/Button.js";

// core components
// import Badge from "components/Badge/Badge.js";
import styles from "assets/jss/material-dashboard-pro-react/components/timelineStyle.js";

const useStyles = makeStyles(styles);

export default function ListTimeLinePersonalSS(props) {
  const classes = useStyles();
  // const { http } = AuthUser();
  const { stories, simple } = props;
  const timelineClass =
    classes.timeline +
    " " +
    cx({
      [classes.timelineSimple]: simple,
    });

  return (
    <ul className={timelineClass}>
      {stories.map((prop, key) => {
        const panelClasses =
          classes.timelinePanel +
          " " +
          cx({
            [classes.timelinePanelInverted]: prop.inverted || simple,
            [classes.timelineSimplePanel]: simple,
          });
        const timelineBadgeClasses =
          classes.timelineBadge +
          " " +
          classes[prop.badgeColor] +
          " " +
          cx({
            [classes.timelineSimpleBadge]: simple,
          });

        return (
          <li className={classes.item} key={key}>
            {prop.badgeIcon ? (
              <div className={timelineBadgeClasses}>
                <prop.badgeIcon className={classes.badgeIcon} />
              </div>
            ) : null}
            <div className={panelClasses}>
              {/* <div className={classes.timelineHeading}>
                  <Badge color={prop.titleColor}>{prop.logged}</Badge>
              </div> */}
              {/* <div className={classes.timelineBody}>{prop.logged}</div> */}

              <div>
                <h4 style={{ color: prop.titleColor }}>{prop.logged}</h4>
              </div>
              {prop.logged == "Personal" && (
                <>
                  <p>First Name: <span style={{fontWeight:"bold"}}>{prop.data.firstname && prop.data.firstname}</span></p>
                  <p>Last Name: <span style={{fontWeight:"bold"}}>{prop.data.lastname}</span></p>
                  <p>Email: <span style={{fontWeight:"bold"}}>{prop.data.email}</span></p>
                  <p>Contact Number: <span style={{fontWeight:"bold"}}>{prop.data.phone}</span></p>
                  <p>Customer type: <span style={{fontWeight:"bold"}}>{prop.data.type}</span></p>
                </>
              )}
              {prop.logged == "Company" && (
                <>
                  <p>Current Company: <span style={{fontWeight:"bold"}}>{prop.data.companyname}</span></p>
                  <p>Position: <span style={{fontWeight:"bold"}}>{prop.data.mycurrentposition}</span></p>
                  <hr style={{margin:"10px 0px"}}/>
                  <div>
                    <p style={{display:"inline-block"}}>industries:</p>
                    {(prop.data.industries && prop.data.industries.length>0) ?
                      prop.data.industries.map((eleInd, ei) => {
                        return <span key={ei} style={{fontWeight:"bold"}}>{eleInd.name} | </span>
                      }) : <span style={{fontWeight:"bold"}}>NA</span>
                    }
                  </div>
                  <hr style={{margin:"10px 0px"}}/>
                  <div>
                    <span style={{display:"inline-block"}}>category:</span>
                    {(prop.data.category && prop.data.category.length>0) ?
                      prop.data.category.map((eleCat, ec) => {
                        return <span key={ec} style={{fontWeight:"bold"}}>{eleCat.name} | </span>
                      }) : <span style={{fontWeight:"bold"}}>NA</span>
                    }
                  </div>
                </>
              )}
              {prop.logged == "Career" && (
                <>
                  <p>Work Experience: <span style={{fontWeight:"bold"}}>{prop.data.mytotalnoexp}</span></p>
                  <p>brief_bio:<span style={{fontWeight:"bold"}}>{prop.data.brief_bio}</span></p>
                  <p>myqualificaton:<span style={{fontWeight:"bold"}}>{prop.data.myqualificaton}</span></p>
                  <p>linkedin:<span style={{fontWeight:"bold",wordBreak:"break-word"}}>{prop.data.linkedin}</span></p>
                </>
              )}
              {prop.logged == "Other" && (
                <>
                  <p>address:<span style={{fontWeight:"bold"}}>{prop.data.address}</span></p>
                  <p>city:<span style={{fontWeight:"bold"}}>{prop.data.city}</span></p>
                  <p>state:<span style={{fontWeight:"bold"}}>{prop.data.state}</span></p>
                </>
              )}

              {prop.footerTitle ? (
                <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
              ) : null}
              {prop.footer ? <hr className={classes.footerLine} /> : null}
              {prop.footer ? (
                <div className={classes.timelineFooter}>{prop.footer}</div>
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

ListTimeLinePersonalSS.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool,
};
