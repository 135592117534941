import React from "react";

import AuthUser from "components/Sb/AuthUser";
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import defaultImage from "assets/img/default-avatar.png";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);

const IndustryStep1 = (props) => {
  const { handleIsToggleFormEdit } = props
  const classes = useStyles();
  const { http} = AuthUser()
  const [firstname, setfirstname] = React.useState("");
  const [lastname, setlastname] = React.useState("");
  const [email, setemail] = React.useState("");
  const [phone, setPhone] = React.useState("")
  const [companylogo, Setcompanylogo] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);
  const [formError, setFormError] = React.useState({})
  let formErr = {}

  React.useEffect(() => {
    http.get("/profile")
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          setfirstname(data.firstname ? data.firstname : "")
          setlastname(data.lastname ? data.lastname : "")
          setemail(data.email ? data.email : "")
          setPhone(data.phone ? data.phone : "")
          setImagePreviewUrl(data.companylogo ? 'https://api.solutionbuggy.com/customerProfile/' + data.companylogo : defaultImage)
        }
      })
      .catch((err) => {
        console.log(err)
         
      })
  }, [])

  // verify email , function that returns true if value is email, false otherwise
  // const verifyEmail = (value) => {
  //   var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (emailRex.test(value)) {
  //     return true;
  //   }
  //   return false;
  // };

  // function varifying the input 
  const handleError = () => {
    if (firstname.trim().length === 0) {
      formErr.firstname = "firstname is required"
    }
    if (lastname.trim().length === 0) {
      formErr.lastname = "lastname is required"
    }
    if (phone.trim().length === 0) {
      formErr.phone = "phone is required"
    }
  }

  // Image uploading function
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    if (newFile) {
      reader.readAsDataURL(newFile);
    }
  };
  const handleImg = (e) => {
    Setcompanylogo(e.target.files[0])
  }

  // Form Submitting function
  const handleSubmit = (event) => {
    event.preventDefault()
    handleError()
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr)
    } else {
      const formData = {
        firstname, lastname, email, phone, companylogo
      }
      console.log(formData)
      setFormError({})
      handleEditPersonal(formData)
      handleIsToggleFormEdit()
    }
  }

  // Api call function
  const handleEditPersonal = (data) => {
    http.post("/customer/editExistingPersonal", data)
      .then((response) => {
        if(response.status==200){
          const dataRes = response.data
          console.log(dataRes)
          // handleIsToggleFormEdit()
          // toast.success('Your Profile Updated Successfull!', {
          //   position: toast.POSITION.TOP_CENTER,
          //   //onClose: () => history.push('customer/user-page'),
          // })
        }
        else{
          //warning msg
          toast.error('Oops something went wrong!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose:1500
            // onClose: () => history.push('/customer/user-page'),
          })
        }
      })
      .catch((err) => {
        console.log(err)
          
      })
  }


  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="picture-container">
            <div className="picture">
              <img src={imagePreviewUrl} className="picture-src" alt="..." />
              <input type="file" onChange={(e) => { handleImageChange(e), handleImg(e) }} name="companylogo" />
            </div>
            <h6 className="description">Choose Picture</h6>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomInput
            success={firstname.length > 0 ? true : false}
            error={Object.keys(formError).length > 0 && formError.firstname ? true : false}
            labelText={
              <span>
                First Name <small>(required)</small>
              </span>
            }
            id="firstname"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "firstname",
              value: firstname,
              onChange: (event) => {
                setfirstname(event.target.value)
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />

        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomInput
            success={lastname.length > 0 ? true : false}
            error={Object.keys(formError).length > 0 && formError.lastname ? true : false}
            labelText={
              <span>
                Last Name <small>(required)</small>
              </span>
            }
            id="lastname"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "lastname",
              value: lastname,
              onChange: (event) => {
                setlastname(event.target.value)
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomInput
            success={email.length > 0 ? true : false}
            labelText={
              <span>
                Email
              </span>
            }
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "email",
              value: email,
              disabled: true,
              onChange: (event) => {
                setemail(event.target.value)
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomInput
            success={phone.length > 0 ? true : false}
            error={Object.keys(formError).length > 0 && formError.phone ? true : false}
            labelText={
              <span>
                Phone <small>(required)</small>
              </span>
            }
            id="phone"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: "phone",
              value: phone,
              type: "text",
              onChange: (event) => {
                setPhone(event.target.value)
              },
              endAdornment: (
                <InputAdornment position="end" className={classes.inputAdornment}>
                  <Smartphone className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="edit-btn-industry">
            <Button type="submit" round color="success">Next</Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
}
IndustryStep1.propTypes = {
  handleIsToggleFormEdit: PropTypes.func
}
export default IndustryStep1;
