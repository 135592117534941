import React from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import AuthUser from "components/Sb/AuthUser";
import { useParams } from "react-router-dom";

// Style
import "assets/css/common.css";

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
// Input
import Button from "components/CustomButtons/Button.js";

// Radio Import Style
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStylesRadio = makeStyles(stylesRadio);
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
const CreateTask = (props) => {
  const { handlesLeadQualificaton } = props;
  const classesRadio = useStylesRadio();
  const { http, getUser } = AuthUser();
  const params = useParams();

  const [meet, setMeet] = React.useState(1);
  const [date, setdate] = React.useState("");
  const [comments, setcomments] = React.useState("");

  React.useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");

    setdate(`${year}-${month}-${day}T${hours}:${minutes}`);
  }, [meet, comments]);

  // FormError
  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // Form Error
  const handleFormError = () => {
    if (comments.trim().length === 0) {
      formErr.comments = "Error";
    }
  };

  //reset values
  const resolve = () => {
    setFormError({});
    setcomments("");
  };

  // Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const data = {
        date,
        type: meet,
        comments,
        cus_id: parseInt(params.id),
        call_outcome: 0,
        activity: 3,
        user_id: getUser().user_id,
      };
      handleLogForm(data);
      // console.log(data);
    }
  };

  const handleLogForm = (formData) => {
    http
      .post("/admin/log", formData)
      .then((response) => {
        // console.log(reposne);
        if (response.data) {
          toast.success(`Your Log Acivity Updated Successfull!`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          handlesLeadQualificaton();
          resolve();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`Error in log ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <GridContainer alignItems="baseline">
        <GridItem xs={6} sm={6}>
          <div
            className={
              classesRadio.checkboxAndRadio +
              " " +
              classesRadio.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={meet === 1}
                  onChange={() => {
                    setMeet(1);
                  }}
                  name="r3"
                  aria-label="Call"
                  icon={
                    <FiberManualRecord
                      className={classesRadio.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classesRadio.radioChecked} />
                  }
                  classes={{
                    checked: classesRadio.radio,
                    root: classesRadio.radioRoot,
                  }}
                />
              }
              classes={{
                label: classesRadio.label,
                root: classesRadio.labelRoot,
              }}
              label="Call"
            />
          </div>
        </GridItem>
        <GridItem xs={6} sm={6}>
          <div
            className={
              classesRadio.checkboxAndRadio +
              " " +
              classesRadio.checkboxAndRadioHorizontal
            }
          >
            <FormControlLabel
              control={
                <Radio
                  checked={meet === 3}
                  onChange={() => {
                    setMeet(3);
                  }}
                  name="r3"
                  aria-label="Meet"
                  icon={
                    <FiberManualRecord
                      className={classesRadio.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classesRadio.radioChecked} />
                  }
                  classes={{
                    checked: classesRadio.radio,
                    root: classesRadio.radioRoot,
                  }}
                />
              }
              classes={{
                label: classesRadio.label,
                root: classesRadio.labelRoot,
              }}
              label="Meet"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <label htmlFor="date">Date and Time:</label>
            <input
              type="datetime-local"
              id="date"
              value={date}
              name="date"
              onChange={(event) => {
                setdate(event.target.value);
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="comments" style={{ marginLeft: "12px" }}>
              Comments
            </InputLabel>
            <OutlinedInput
              multiline
              rows={10}
              name="comments"
              value={comments}
              onChange={(event) => {
                setcomments(event.target.value);
                delete formError.comments;
              }}
              id="requirement"
              color="primary"
              label="Project Requirement"
            />
          </FormControl>
          {Object.keys(formError).length > 0 && formError.comments && (
            <p style={{ color: "#f44336", textAlign: "left" }}>
              {formError.comments}
            </p>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <Button round color="rose" type="submit">
              Submit
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

CreateTask.propTypes = {
  handlesLeadQualificaton: PropTypes.func,
};

export default CreateTask;
