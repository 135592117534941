import React from "react";
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
//import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
// import Close from "@material-ui/icons/Close";


// // core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js"; 

// import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};



const useStyles = makeStyles(styles);

function BrowseProject(props) {
  const {projects,plan} = props 
    const history = useHistory()
    const [data, setData] = React.useState([]);

    const reDirectToViewProject = (id) => {
        history.push(`/customer/project-details/${id}`)
    }

    // const planCheck = ()=>{

    // }

    React.useEffect(()=>{
            const final = projects.map((project)=>{
              let action = (project.action == 0 && !plan) && (<><span style={{color:"#fb483a",fontWeight:"bold"}}>Not Live</span></>) || (project.action == 0 && plan) && (<><span style={{color:"#00acc1",fontWeight:"bold"}}>Under Review</span></>) || (project.action == 1) && (<><span style={{color:"#9c27b0",fontWeight:"bold"}}>Live</span></>) || (project.action == 2 || project.action == 4 || project.action == 5) && (<><span style={{color:"#4caf50",fontWeight:"bold"}}>Completed</span></>) || (project.action == 3) && (<><span style={{color:"#ffa726",fontWeight:"bold"}}>On hold</span></>)
                return {
                    id: project.id,
                    projectId: project.id,
                    // title: project.title,
                    industry: project.industry,
                    category: project.cat,
                    // location: project.location,
                    status: action,
                    actions: (
                        <div className="actions-right">
                        <Button type="submit" round color="rose" style={{ fontWeight: 600 }}
                            onClick={() => {
                            reDirectToViewProject(project.id)
                            }} >View</Button>
                        </div>
                    )
                }
            })
            setData(final)
    },[projects])

  const classes = useStyles();

  return (
    <>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}><b>Project List</b></h4>
          </CardHeader>
          <CardBody>
            {
                projects.length>0?(
                    <>
                    <ReactTable
                        columns={[

                            {
                            Header: "Project ID",
                            accessor: "projectId",
                            },
                            {
                            Header: "Industry",
                            accessor: "industry",
                            },
                            {
                            Header: "Category",
                            accessor: "category",
                            },
                            {
                            Header: "Status",
                            accessor: "status",
                            },
                            {
                            Header: "Actions",
                            accessor: "actions",
                            },

                        ]}
                        data={data}
                    />
                    </>
                ):(
                    <>
                        <p>No Data</p>
                    </>
                )
            }
          </CardBody>
        </Card>
    </>
  );
}
BrowseProject.propTypes = {
    projects: PropTypes.array,
    plan:PropTypes.bool
}
export default BrowseProject