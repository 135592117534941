import React from "react";

// Authuser
import AuthUser from "components/Sb/AuthUser";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/viewProfile.css"

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Business from "@material-ui/icons/Business";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import defaultImage from "assets/img/default-avatar.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function IndustryProfile() {
    const classes = useStyles();
    const {http} = AuthUser()
    const [companyname, setCompanyName] = React.useState("")
    const [firstname, setFirstName] = React.useState("")
    const [lastname, setlastname] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [city, setcity] = React.useState("")
    const [customer_id, setcustomer_id] = React.useState("")
    const [mycurrentposition, setmycurrentposition] = React.useState("")
    const [industries, setindustries] = React.useState("")
    const [industriesList, setindustriesList] = React.useState([])
    const [tinno, settinno] = React.useState("")
    const [address, setaddress] = React.useState("")
    const [brief_bio, setbrief_bio] = React.useState("")
    const [companylogo, setcompanylogo] = React.useState("")

    React.useEffect(() => {
        http.get("/industryList")
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    setindustriesList(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    React.useEffect(() => {
        http.get("/profile")
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    setCompanyName(data.companyname ? data.companyname : "NA")
                    setFirstName(data.firstname ? data.firstname : "NA")
                    setlastname(data.lastname ? data.lastname : "NA")
                    setEmail(data.email ? data.email : "NA")
                    setPhone(data.phone ? data.phone : "NA")
                    setcity(data.city ? data.city : "NA")
                    setcustomer_id(data.customer_id ? data.customer_id : "NA")
                    setmycurrentposition(data.mycurrentposition ? data.mycurrentposition : "NA")
                    setindustries(data.industries ? data.industries : "")
                    settinno(data.tinno ? data.tinno : "NA")
                    setaddress(data.address ? data.address : "NA")
                    setbrief_bio(data.brief_bio ? data.brief_bio : "NA")
                    setcompanylogo(data.companylogo ? 'https://api.solutionbuggy.com/customerProfile/' + data.companylogo : defaultImage)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const resultIndustries = industriesList.filter((industry) => {
        return industries == industry.id
    })

    return (
        <div>
            <GridContainer justify="center">
                
                <GridItem xs={12} sm={12} md={8}>
                    <Card profile>
                        <CardAvatar profile>
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img src={companylogo} alt="companylogo" />
                            </a>
                        </CardAvatar>
                        <CardBody>
                            <div>
                                <h3 className={classes.cardCategory}>customer id: {customer_id}</h3>
                            </div>
                            <div style={{textAlign:"center"}} >
                                <h5 className={classes.cardTitle} style={{marginBottom:"8px",fontSize:"16px"}} ><Face />Fristname :  {firstname}</h5>
                                <h5 className={classes.cardTitle} style={{marginBottom:"8px",fontSize:"16px"}}><Email />Email :  {email}</h5>
                                <h5 className={classes.cardTitle} style={{marginBottom:"8px",fontSize:"16px"}}><Smartphone />Phone : {phone}</h5>
                            </div>
                            {/* <p className={classes.description}>Don{"'"}t be scared </p> */}
                            <div>
                                <Link to="/customer/edit-profile" >
                                    <Button color="info" round>Edit</Button>
                                </Link>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <PermIdentity />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Personal Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Fisrt Name: </h5>
                                                <p className={classes.lableValue}>{firstname}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Last Name: </h5>
                                                <p className={classes.lableValue}>{lastname}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Email : </h5>
                                                <p className={classes.lableValue}>{email}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Phone : </h5>
                                                <p className={classes.lableValue}>{phone}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>City : </h5>
                                                <p className={classes.lableValue}>{city}</p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    {/* <Button color="rose" className={classes.updateProfileButton}>
                                        Update Profile
                                    </Button> */}
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <Business />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Company Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Company Name: </h5>
                                                <p className={classes.lableValue}>{companyname}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Current Position: </h5>
                                                <p className={classes.lableValue}>{mycurrentposition}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Industry : </h5>
                                                {
                                                    resultIndustries.map((industry) => {
                                                        return <span key={industry.id} className={classes.tags}>{industry.name}</span>
                                                    })
                                                }
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>GST : </h5>
                                                <p className={classes.lableValue}>{tinno}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Address : </h5>
                                                <p className={classes.lableValue}>{address}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Brief Description : </h5>
                                                <p className={classes.lableValue}>{brief_bio}</p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>

            </GridContainer>
        </div>
    );
}
