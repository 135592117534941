import React from "react"
import AuthUser from "components/Sb/AuthUser";
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import { Link, VerifiedUser, LocationCity, Phone } from "@material-ui/icons";

// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// Radio style
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);

// Core components
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

const Step3RegCon = () => {
    //const { handleToggleTwo } = props
    const classes = useStyles();
    const { http,getUser } = AuthUser()
    const classesRadio = useStylesRadio()

    // Form 1 Experinces
    const [employmentStatus, setemploymentStatus] = React.useState("")
    const [empStatus, setempStatus] = React.useState("")
    const [industries, setindustries] = React.useState([])
    const [skills, setSkills] = React.useState([])
    const [countries, setcountries] = React.useState("")

    const [industriesList, setindustriesList] = React.useState([])
    const [sub_catList, setsub_catList] = React.useState([])

    // Form 2 personal info
    const [linkedIn, setLinkedIn] = React.useState("")
    const [phone, setPhone] = React.useState(getUser().phone)
    const [city, setCity] = React.useState("")
    const [languages, setlanguages] = React.useState([])

    const [formError, setFormError] = React.useState({})
    let formErr = {}

    // getting industry lists
    React.useEffect(() => {
        http.get("/industryList")
            .then((response) => {
                const data = response.data
                if (response.status === 200) {
                    setindustriesList(data)
                }
            })
            .catch((err) => {
                console.log(err)

            })
    }, [])

    // // getting skills lists
    React.useEffect(() => {
        http.get("/skillList")
            .then((response) => {
                if (response.status == 200) {
                    const data = response.data
                    setsub_catList(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    // const verifyNumber = (value) => {
    //     var numberRex = new RegExp("^[0-9]+$");
    //     if (numberRex.test(value)) {
    //         return true;
    //     }
    //     return false;
    // };

    const handleFormError = () => {
        let employmentStatusLable = document.getElementById("employmentStatusLable")
        let industryLable = document.getElementById("industryLable")
        let skillsLable = document.getElementById("skillsLable")
        let countriesLable = document.getElementById("countriesLable")
        let languagesLable = document.getElementById("languagesLable")

        // employmentStatus
        if (employmentStatus.length === 0) {
            formErr.employmentStatus = "Error in employment Status"
            employmentStatusLable.classList.add("errorMsg")
        } else {
            if (employmentStatusLable.classList.contains("errorMsg")) {
                employmentStatusLable.classList.remove("errorMsg")
                employmentStatusLable.classList.add("successMsg")
            }
        }
        // empStatus
        if (employmentStatus === "Freelancer/Self employment") {
            if (empStatus.trim().length === 0) {
                formErr.empStatus("Error")
            }
        }
        // industries
        if (industries.length === 0) {
            formErr.industries = "Error in industries"
            industryLable.classList.add("errorMsg")
        } else {
            if (industryLable.classList.contains("errorMsg")) {
                industryLable.classList.remove("errorMsg")
                industryLable.classList.add("successMsg")
            }
        }
        // skills
        if (skills.length === 0) {
            formErr.industries = "Error in skills"
            skillsLable.classList.add("errorMsg")
        } else {
            if (skillsLable.classList.contains("errorMsg")) {
                skillsLable.classList.remove("errorMsg")
                skillsLable.classList.add("successMsg")
            }
        }
        // countries
        if (countries.length === 0) {
            formErr.countries = "Error in skills"
            countriesLable.classList.add("errorMsg")
        } else {
            if (countriesLable.classList.contains("errorMsg")) {
                countriesLable.classList.remove("errorMsg")
                countriesLable.classList.add("successMsg")
            }
        }
        // languagesLable
        if (languages.length === 0) {
            formErr.languages = "Error in employment Status"
            languagesLable.classList.add("errorMsg")
        } else {
            if (languagesLable.classList.contains("errorMsg")) {
                languagesLable.classList.remove("errorMsg")
                languagesLable.classList.add("successMsg")
            }
        }
        // phone
        if (phone.length < 10) {
            formErr.phone = "Error"
        }
        // city
        if (city.trim().length === 0) {
            formErr.city = "Error"
        }
    }

    //reset values 
    const resolve = () => {
        setFormError({})
        setemploymentStatus("")
        setindustries([])
        setSkills([])
        setempStatus("")
        setLinkedIn("")
        setPhone(getUser().phone)
        setCity("")
        setlanguages([])

        //temporary change
        // handleToggleTwo()
        setcountries("")
        window.location.reload() 
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        handleFormError()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)
            console.log(formError)
        } else {
            const data = {employmentStatus, empStatus, industries, skills, countries, languages, phone, city, linkedIn}

            //api connectivity

            http.post('/customer/updateOnboardingExperience', data).then((res) => {
                if (res.status == 200) {
                    console.log(res)
                    toast.success("Your Experience has been updated", {
                        position: toast.POSITION.TOP_CENTER,
                        onClose: () => resolve(),
                        autoClose: 1500,
                    })
            }
                
            }).catch((err) => {
                console.log(err)

            });

        }
    }


    

    return (
        <>
            <form onSubmit={handleFormSubmit} id="ex_form">
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10} lg={10}>
                        <Card>
                            <CardHeader color="warning" icon>
                                <CardIcon color="warning">
                                    <VerifiedUser />
                                </CardIcon>
                                <h4 className="card-title">
                                    Professional Experience – Employment status
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    {/* 1) What is your current employment status */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="employmentStatus"
                                                className={classes.selectLabel}
                                            >
                                                <span id="employmentStatusLable">What is your current employment status <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}

                                                value={employmentStatus}
                                                onChange={(event) => {
                                                    setemploymentStatus(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "employmentStatus",
                                                    id: "employmentStatus",
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose What is your current employment status
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Freelancer/Self employment"
                                                >
                                                    Freelancer/Self employment
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Not employed"
                                                >
                                                    Not employed
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Employed"
                                                >
                                                    Employed
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        {
                                            employmentStatus === "Freelancer/Self employment" && (
                                                <>
                                                    <GridContainer>
                                                        <GridItem xs={6} sm={6} >
                                                            <div
                                                                className={
                                                                    classesRadio.checkboxAndRadio +
                                                                    " " +
                                                                    classesRadio.checkboxAndRadioHorizontal
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Radio
                                                                            checked={empStatus === "Individual Consultant"}
                                                                            onChange={() => {
                                                                                setempStatus("Individual Consultant")
                                                                            }}
                                                                            name="radio button enabled"
                                                                            aria-label="Individual Consultant"
                                                                            icon={
                                                                                <FiberManualRecord
                                                                                    className={classesRadio.radioUnchecked}
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <FiberManualRecord
                                                                                    className={classesRadio.radioChecked}
                                                                                />
                                                                            }
                                                                            classes={{
                                                                                checked: classesRadio.radio,
                                                                                root: classesRadio.radioRoot,
                                                                            }}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classesRadio.label,
                                                                        root: classesRadio.labelRoot,
                                                                    }}
                                                                    label="Individual Consultant"
                                                                />
                                                            </div>
                                                        </GridItem>
                                                        <GridItem xs={6} sm={6}>
                                                            <div
                                                                className={
                                                                    classesRadio.checkboxAndRadio +
                                                                    " " +
                                                                    classesRadio.checkboxAndRadioHorizontal
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Radio
                                                                            checked={empStatus === "Consultant Firm"}
                                                                            onChange={() => {
                                                                                setempStatus("Consultant Firm")
                                                                            }}
                                                                            name="radio button enabled"
                                                                            aria-label="Consultant Firm"
                                                                            icon={
                                                                                <FiberManualRecord
                                                                                    className={classesRadio.radioUnchecked}
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <FiberManualRecord
                                                                                    className={classesRadio.radioChecked}
                                                                                />
                                                                            }
                                                                            classes={{
                                                                                checked: classesRadio.radio,
                                                                                root: classesRadio.radioRoot,
                                                                            }}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classesRadio.label,
                                                                        root: classesRadio.labelRoot,
                                                                    }}
                                                                    label="Consultant Firm"
                                                                />
                                                            </div>
                                                        </GridItem>
                                                    </GridContainer>
                                                </>
                                            )
                                        }
                                    </GridItem>
                                    {/* 2) Select the industry you have expertise in (The Industry & Interest that you have selected here will help SolutionBuggy algorithm to match you with relevant client & opportunities */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="industries"
                                                className={classes.selectLabel}
                                            >
                                                <span id="industryLable">Select the industry you have expertise in <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                multiple
                                                value={industries}
                                                onChange={(event) => {
                                                    setindustries(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "industries",
                                                    id: "industries",
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose industry
                                                </MenuItem>
                                                {
                                                    industriesList.map((industry) => {
                                                        return <MenuItem
                                                            key={industry.id}
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected,
                                                            }}
                                                            value={industry.id}
                                                        >
                                                            {industry.name}
                                                        </MenuItem>
                                                    })
                                                }

                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    {/* Skills you have expertise in */}
                                    {/* Sub Category */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div className="input-margin">
                                            <FormControl
                                                fullWidth
                                                className={classes.selectFormControl}
                                            >
                                                <InputLabel
                                                    htmlFor="skills"
                                                    className={classes.selectLabel}
                                                >
                                                    <span id="skillsLable">Skills you have expertise in <small>(required)</small></span>
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        className: classes.selectMenu,
                                                    }}
                                                    classes={{
                                                        select: classes.select,
                                                    }}
                                                    multiple
                                                    value={skills}
                                                    onChange={(event) => {
                                                        setSkills(event.target.value)
                                                    }}
                                                    inputProps={{
                                                        name: "skills",
                                                        id: "skills",

                                                    }}
                                                >
                                                    <MenuItem
                                                        disabled
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                        }}
                                                    >
                                                        Choose skills
                                                    </MenuItem>
                                                    {
                                                        sub_catList.map((industry) => {
                                                            return <MenuItem
                                                                key={industry.id}
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected,
                                                                }}
                                                                value={industry.id}
                                                            >
                                                                {industry.name}
                                                            </MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </GridItem>
                                    {/* Select the countries you have worked in */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="countries"
                                                className={classes.selectLabel}
                                            >
                                                <span id="countriesLable">Select the countries you have worked in <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}

                                                value={countries}
                                                onChange={(event) => {
                                                    setcountries(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "countries",
                                                    id: "countries",
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Select the countries you have worked in
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="India"
                                                >
                                                    India
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10} lg={10}>
                        <Card>
                            <CardHeader color="warning" icon>
                                <CardIcon color="warning">
                                    <VerifiedUser />
                                </CardIcon>
                                <h4 className="card-title">
                                    Personal Info
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    {/* linkedIn */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <CustomInput
                                            success={linkedIn.length > 0 ? true : false}
                                            // error={Object.keys(formError).length > 0 && formError.desc ? true : false}
                                            labelText={
                                                <span>
                                                    LinkedIn profile link
                                                </span>
                                            }
                                            id="linkedIn"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                name: 'linkedIn',
                                                value: linkedIn,
                                                onChange: (event) => {
                                                    setLinkedIn(event.target.value);
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                                        <Link className={classes.inputAdornmentIcon} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <p>If you do not have a link &sbquo; mention &quot;Not Available&ldquo; </p>
                                    </GridItem>
                                    {/* Phone */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <CustomInput
                                            
                                            labelText={
                                                <span>
                                                    Phone Number / Whatsapp Number <small>(required)</small>
                                                </span>
                                            }
                                            id="phone"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                name: 'phone',
                                                value: phone,
                                                type: "text",
                                                disabled :true,
                                                endAdornment: (
                                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                                        <Phone className={classes.inputAdornmentIcon} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </GridItem>
                                    {/* City */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <CustomInput
                                            success={city.length > 0 ? true : false}
                                            error={Object.keys(formError).length > 0 && formError.city ? true : false}
                                            labelText={
                                                <span>
                                                    Current city <small>(required)</small>
                                                </span>
                                            }
                                            id="city"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                name: 'city',
                                                value: city,
                                                onChange: (event) => {
                                                    setCity(event.target.value);
                                                    delete formError.city
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                                        <LocationCity className={classes.inputAdornmentIcon} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </GridItem>
                                    {/*languages */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="languages"
                                                className={classes.selectLabel}
                                            >
                                                <span id="languagesLable">Languages you are comfortable in <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                multiple
                                                value={languages}
                                                onChange={(event) => {
                                                    setlanguages(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "languages",
                                                    id: "languages",
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose your languages
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="English"
                                                >
                                                    English
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Kannada"
                                                >
                                                    Kannada
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Tamil"
                                                >
                                                    Tamil
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Malayalam"
                                                >
                                                    Malayalam
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Telugu"
                                                >
                                                    Telugu
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Hindi"
                                                >
                                                    Hindi
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    {/* Submit  */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <Button type="submit" color="info" round>Submit</Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </form>
        </>
    )
}
Step3RegCon.propTypes = {
    handleToggleTwo: PropTypes.func
}
export default Step3RegCon