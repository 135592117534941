import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { useHistory } from "react-router-dom";
const useStylesTable = makeStyles(stylesTable);

const PendingTasksDB = (props) => {
  const { pendingTask } = props;
  const classesTable = useStylesTable();
  const history = useHistory()
  const [currentPage, setCurrentPage] = React.useState(1);
  const [seached, setSearched] = React.useState("");
  const [filterData, setFilterData] = React.useState([]);
  const dataPerPage = 10;

  React.useEffect(() => {
    if (pendingTask) {
      const result = pendingTask.filter((ele) => {
        return ele.id.toString().includes(seached.toString());
      });
      setFilterData(result);
    }
  }, [seached, pendingTask]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalData = filterData.length;
    const maxPages = Math.ceil(totalData / dataPerPage);

    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Calculate the index range for the expenses to display on the current page
  const startIndex = (currentPage - 1) * dataPerPage;
  const endIndex = startIndex + dataPerPage;

  // Filter and map expenses based on the current page
  const dataToDisplay = filterData.slice(startIndex, endIndex);

  const totalDatas = filterData.length;
  const totalPages = Math.ceil(totalDatas / dataPerPage);

  return (
    <>
      <Card>
        <h4>Old Pending Task (Total : 71)</h4>
        <CardBody>
          <div className="pageinationSearch">
            <form>
              <input
                type="number"
                placeholder="Search by ID"
                value={seached}
                onChange={(e) => {
                  setSearched(e.target.value);
                }}
                className="searchInput"
              />
            </form>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "10px" }}>
                <p>
                  Page {currentPage} of {totalPages}
                </p>
              </div>
              <div>
                <Button round onClick={handlePreviousPage}>
                  Pervious
                </Button>
                <Button round onClick={handleNextPage}>
                  Next
                </Button>
              </div>
            </div>
          </div>
          <div className={classesTable.tableResponsive}>
            <Table className="commonTable">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Task Comments</TableCell>
                  <TableCell>Pending from</TableCell>
                  <TableCell>Grading</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataToDisplay.map((eleData, ed) => {
                  return (
                    <TableRow key={ed} className={classesTable.tableRowHover}>
                      <TableCell>{eleData.id}</TableCell>
                      <TableCell>{eleData.name}</TableCell>
                      <TableCell>{eleData.sales_priority}</TableCell>
                      <TableCell>{eleData.comments}</TableCell>
                      <TableCell>{eleData.date}</TableCell>
                      <TableCell>{eleData.grade}</TableCell>
                      <TableCell>
                        <Button color="rose" round onClick={()=>{
                            history.push(`/admin/company/${eleData.id}`)
                          }}>
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
PendingTasksDB.propTypes = {
  pendingTask: PropTypes.array,
};
export default PendingTasksDB;
