import React from "react";
import axios from "axios";
import "assets/css/chat.css";
import PropTypes from "prop-types";
// @material-ui/icons
// import Send from "@material-ui/icons/Send";
import MessageChats from "./MessageChats";

// start model
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import stylesModel from "assets/jss/material-dashboard-pro-react/views/chatNotificationStyle.js";
import { toast } from "react-toastify";
const useStylesModel = makeStyles(stylesModel);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
// end model

const ChatBotContact = ({ openChatModal, setOpenChatModal,phone }) => {
  const [messages, setMesssages] = React.useState([]);
  const [writeMessage, setWriteMessage] = React.useState("");
  const [messageSend, setMesssageSend] = React.useState(false);
  const [formError, setFormError] = React.useState({});
  const [blockInput, setBlockInput] = React.useState(false);
  // Model
  const classesModel = useStylesModel();

  const handlechangesMessageSend = () => {
    setMesssageSend(!messageSend);
  };


  React.useEffect(() => {
    axios
      .get(`https://live-server-6804.wati.io/api/v1/getMessages/91${phone.toString().slice(-10)}`, {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NWViNzBkNy04YmQzLTQzYWYtOWY5ZS00ZjlhNDNlMGUwYTIiLCJ1bmlxdWVfbmFtZSI6ImFkbWluQHNvbHV0aW9uYnVnZ3kuY29tIiwibmFtZWlkIjoiYWRtaW5Ac29sdXRpb25idWdneS5jb20iLCJlbWFpbCI6ImFkbWluQHNvbHV0aW9uYnVnZ3kuY29tIiwiYXV0aF90aW1lIjoiMDcvMjUvMjAyMyAwNTo1Nzo1MyIsImRiX25hbWUiOiI2ODA0IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.P3tcvuLUkPSZjEiRAuFQq-roRPcxh31cVNswQ1H4knY",
        },
      })
      .then((response) => {
        // console.log(response);
        setMesssages(response.data.messages.items.reverse());
      })
      .catch((error) => {
        toast.error(`Server Down Try Later! ${error.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  }, [messageSend,phone]);

  const handleFormError = () => {
    if (writeMessage.trim().length == 0) {
      formError.writeMessage = "Plesae tell us are you interested in";
    }
  };

  const resolve = () => {
    setFormError({});
    setWriteMessage("");
    handlechangesMessageSend();
  };

  const handleFormsubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (!Object.keys(formError).length > 0) {
      handleSendMessage(writeMessage);
    }
  };

  const handleSendMessage = (messageText) => {
    axios
      .post(
        `https://live-server-6804.wati.io/api/v1/sendSessionMessage/919738111380?messageText=${messageText}`,
        { messageText },
        {
          headers: {
            accept: "*/*",
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NWViNzBkNy04YmQzLTQzYWYtOWY5ZS00ZjlhNDNlMGUwYTIiLCJ1bmlxdWVfbmFtZSI6ImFkbWluQHNvbHV0aW9uYnVnZ3kuY29tIiwibmFtZWlkIjoiYWRtaW5Ac29sdXRpb25idWdneS5jb20iLCJlbWFpbCI6ImFkbWluQHNvbHV0aW9uYnVnZ3kuY29tIiwiYXV0aF90aW1lIjoiMDcvMjUvMjAyMyAwNTo1Nzo1MyIsImRiX25hbWUiOiI2ODA0IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiQURNSU5JU1RSQVRPUiIsImV4cCI6MjUzNDAyMzAwODAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.P3tcvuLUkPSZjEiRAuFQq-roRPcxh31cVNswQ1H4knY",
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          resolve();
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error(`Server Down Try Later! ${error.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  // console.log(messages);

  const handleblockInput = ()=>{
    setBlockInput(true)
  }

  return (
    <>
      <Dialog
        classes={{
          root: classesModel.center + " " + classesModel.modalRoot,
          paper: classesModel.modal,
        }}
        open={openChatModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenChatModal(false)}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classesModel.modalHeader}
        >
          <Button
            justIcon
            className={classesModel.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setOpenChatModal(false)}
          >
            <Close className={classesModel.modalClose} />
          </Button>
          <h4 className={classesModel.modalTitle}>SB Business WhatsApp</h4>
        </DialogTitle>
        <DialogContent
          id="notice-modal-slide-description"
          className={classesModel.modalBody}
        >
          <div className="model-chat-bg">
            <div className="message-body">
              {messages.map((eleMessage, m) => {
                return (
                  <MessageChats
                      key={m}
                      message={eleMessage}
                      setBlockInput={setBlockInput}
                      handleblockInput={handleblockInput}
                    />
                );
              })}
            </div>
            {!blockInput && (
              <div className="write-form-body">
                <form onSubmit={handleFormsubmit} className="write-form">
                  <div className="write-input-btn">
                    <input
                      type="text"
                      className="text-msg"
                      placeholder="Type here and Enter to send"
                      value={writeMessage}
                      onChange={(e) => {
                        setWriteMessage(e.target.value);
                        delete formError.writeMessage;
                      }}
                    />
                    {/* <button type="submit" className="send-btn">
                      Send
                      <Send
                        style={{
                          color: "#ffffff",
                          width: "24px",
                          height: "24px",
                          marginLeft:"4px"
                        }}
                      />
                    </button> */}
                  </div>
                </form>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
      {/*  */}
      {/* <Dialog
        classes={{
          root: classesModel.center + " " + classesModel.modalRoot,
          paper: classesModel.modal,
        }}
        open={openChatModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenChatModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesModel.modalHeader}
        >
          <Button
            justIcon
            className={classesModel.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setOpenChatModal(false);
            }}
          >
            <Close className={classesModel.modalClose} />
          </Button>
          <h4 className={classesModel.modalTitle}>Chat bot</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classesModel.modalBody}
        >
          <div className="model-chat-bg">
            <div className="message-body">
              {messages.map((eleMessage, m) => {
                return <MessageChats message={eleMessage} key={m} />;
              })}
              <div className="write-form-body">
                <form onSubmit={handleFormsubmit} className="write-form">
                  <div className="write-input-btn">
                    <input
                      type="text"
                      className="text-msg"
                      value={writeMessage}
                      onChange={(e) => {
                        setWriteMessage(e.target.value);
                        delete formError.writeMessage;
                      }}
                    />
                    <button type="submit" className="send-btn">
                      <Send
                        style={{
                          color: "#ffffff",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog> */}
    </>
  );
};

ChatBotContact.propTypes = {
  openChatModal: PropTypes.bool,
  setOpenChatModal: PropTypes.func,
  phone:PropTypes.any,
};

export default ChatBotContact;
