import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
// import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
// import Email from "@material-ui/icons/Email";
// import Money from "@material-ui/icons/Money";
// import Business from "@material-ui/icons/Business";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import NoteAdd from "@material-ui/icons/NoteAdd";
// import PanTool from "@material-ui/icons/PanTool";
// import LocationCity from "@material-ui/icons/LocationCity";
// import LocationOn from "@material-ui/icons/LocationOn";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { OutlinedInput } from "@material-ui/core";
// import { useParams } from "react-router-dom";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const useStyles = makeStyles(styles);

const ChangeStageFormPD = (props) => {
  const { setchangeStageModal, changeStageModal } = props;
  const classes = useStyles();
  //   const params = useParams();
  //   const history = useHistory();

  //   getUser
  // const { http } = AuthUser();

  const [problem_stage, setproblem_stage] = React.useState("");
  const [completion_date, setcompletion_date] = React.useState(new Date());
  const [comments, setcomments] = React.useState("");

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  React.useEffect(() => {
    if (!changeStageModal) {
      setproblem_stage("");
      setcompletion_date(new Date());
      setcomments("");
      setFormError({});
    }
  }, [changeStageModal]);

  // handle Form error function
  const handleFormError = () => {
    // problem_stage
    if (problem_stage.toString().trim().length === 0) {
      formErr.problem_stage = "Problem stage is required";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        problem_stage,
        completion_date: `${completion_date._d}`,
        comments,
      };
      console.log(formData);
      resolve();
      //   handleFormAPI(formData);
    }
  };

  //   const handleFormAPI = (formData) => {
  //     http
  //       .post("/admin/lead_questionire", formData)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           resolve();
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(`Can't update - try later ${err.message}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 1500,
  //         });
  //       });
  //   };

  const resolve = () => {
    toast.success("Successfully updated", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    setchangeStageModal(false);
    setproblem_stage("");
    setcompletion_date(new Date());
    setcomments("");
    setFormError({});
  };

  const problemStageLists = [
    "Project confirmation for Execution",
    "Searching & identifying Experts",
    "Receiving of proposal from Expert",
    "Evaluation & finalization of Expert",
    "Submission of SB Proposal",
    "Project Awarded & Advance",
  ];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* problem_stage */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="problem_stage"
                className={classes.selectLabel}
              >
                <span id="problem_stage">Select Problem stage*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={problem_stage}
                onChange={(event) => {
                  setproblem_stage(event.target.value);
                  delete formError.problem_stage;
                }}
                inputProps={{
                  name: "problem_stage",
                  id: "problem_stage",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose industry
                </MenuItem>
                {problemStageLists.map((problem, p) => {
                  return (
                    <MenuItem
                      key={p}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={p + 1}
                    >
                      {problem}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.problem_stage && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.problem_stage}
              </p>
            )}
          </GridItem>
          {/* completion_date  */}
          <GridItem xs={12} sm={12} md={12}>
            <p style={{ textAlign: "left" }}>Completion Date</p>
            <Datetime
              dateFormat="DD-MM-YYYY"
              timeFormat={false}
              value={completion_date}
              onChange={(date) => setcompletion_date(date)}
            />
          </GridItem>
          {/* comments */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="comments" style={{ marginLeft: "12px" }}>
                Comments
              </InputLabel>
              <OutlinedInput
                multiline
                rows={10}
                name="comments"
                value={comments}
                onChange={(event) => {
                  setcomments(event.target.value);
                  //   delete formError.comments;
                }}
                id="comments"
                color="primary"
                label="comments"
              />
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.comments && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.comments}
              </p>
            )} */}
          </GridItem>
          {/* save */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                save
              </Button>
            </div>
          </GridItem>
          {/* cancle */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => setchangeStageModal(false)}
              >
                cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

ChangeStageFormPD.propTypes = {
  changeStageModal: PropTypes.bool,
  setchangeStageModal: PropTypes.func,
};

export default ChangeStageFormPD;
