import React from "react"


// import AuthUser from "components/Sb/AuthUser";
// import axios from "axios";
// import { toast } from 'react-toastify'
// import {useHistory} from 'react-router-dom'

//  @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons;
import Business from "@material-ui/icons/Business";
// Style
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);


const OfferReceived = () => {
    const classes = useStyles();
    return (
        <>
            <Card>
                <CardHeader color="info" icon>
                    <CardIcon color="info">
                        <Business />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Offer Received</h4>
                </CardHeader>
                <CardBody>
                    <div className="offer-container">
                        <GridContainer >
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                                <h3>Dr. Anubha Khale</h3>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                                <Button color="success" round>Applied</Button>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div>
                                    <h6>Date Applied: 28 January 2023, 8:26 pm</h6>
                                    <h6><b>Plan Name :</b> Assist (Valid Upto: 2023-04-01)</h6>
                                    <h6><b>Description :</b> We are very much interested and looking forward to take up this project. Please revert back as soon as possible. Kindly contact ARQ Solution LLP +91 9869346909 director@arqsolution.in</h6>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div className="submit-cancle-btn" >
                                    <Button round color="rose">Shortlist</Button>
                                    <Button round style={{ marginLeft: "16px" }}>Not Shortlist</Button>
                                </div>

                            </GridItem>
                        </GridContainer>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default OfferReceived