import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import React from "react"
import IndusrtyViewProjectTable from "./ProjectTables/IndusrtyViewProjectTable"

const IndustryViewProject = ()=>{
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <IndusrtyViewProjectTable/>
            </GridItem>
        </GridContainer>
    )
}

export default IndustryViewProject