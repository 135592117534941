import React from "react";

// import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

// Style
import "assets/css/common.css"

// @material-ui/icons
import Face from "@material-ui/icons/Message";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";

// Input 
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);

const GenerateMeetingLink = () => {
    const classes = useStyles();

    const [meeting_date, setmeeting_date] = React.useState("")
    const [comments, setcomments] = React.useState("")

    // FormError 
    const [formError, setFormError] = React.useState({})
    let formErr = {}

    // Form Error 
    const handleFormError = () => {
        if (comments.trim().length === 0) {
            formErr.comments = "Error"
        }
    }

    //reset values
    const resolve = () => {
        setFormError({})
        setmeeting_date("")
        setcomments("")
    }

    // Form Submit 
    const handleFormSubmit = (e) => {
        e.preventDefault()
        handleFormError()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)
        } else {
            const data = {
                meeting_date, comments
            }
            toast.success(`Your create task Updated Successfull!`, {
                position: toast.POSITION.TOP_CENTER,
                onClose: () => resolve(),
                autoClose:1500
            })
            console.log(data)
        }
    }


    return (
        <form onSubmit={handleFormSubmit}>
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <CustomInput
                                // labelText={
                                //     <span>
                                //         Date <small>(required)</small>
                                //     </span>
                                // }
                                id="meeting_date"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    name: 'meeting_date',
                                    value: meeting_date,
                                    type: "meeting_date",
                                    placeholder: "Date (required)",
                                    onChange: (event) => {
                                        setmeeting_date(event.target.value);
                                    },
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <CustomInput
                                success={comments.length > 0 ? true : false}
                                error={Object.keys(formError).length > 0 && formError.comments ? true : false}
                                labelText={
                                    <span>
                                        comments <small>(required)</small>
                                    </span>
                                }
                                id="comments"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    name: 'comments',
                                    value: comments,
                                    onChange: (event) => {
                                        setcomments(event.target.value);
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.inputAdornment}>
                                            <Face className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <div className="input-margin">
                                <Button round color="rose" type="submit" >Create Meeting</Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </form>
    );
}

export default GenerateMeetingLink 