import React from 'react'
import PropTypes from 'prop-types'
import "../../assets/css/TableStyle.css"

const TableStyle = (props) => {
    const {data} = props
    return (
        <>
            <table className='tableMain'>
                <tbody>
                    {
                        data.map((eleData, ed) => {
                            return <tr className={"tableRow"} key={ed}>
                                <th className='tableHeading'>{eleData.heading}</th>
                                <td className='tablePara'>{eleData.para}</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </>
    )
}
TableStyle.propTypes = {
    data : PropTypes.array,
}
export default TableStyle
