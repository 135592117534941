import React from "react";
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";
// import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

// Core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// icons
import Business from "@material-ui/icons/Business";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";
import PermIdentity from "@material-ui/icons/PermIdentity";
// import Web from "@material-ui/icons/Public";
import Money from "@material-ui/icons/Money";
import LocationOn from "@material-ui/icons/LocationOn";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { toast } from "react-toastify";
import { OutlinedInput } from "@material-ui/core";
const useStyles = makeStyles(styles);

const CreateContactFormCL = (props) => {
  const { setCreateContactModel, createContactModel } = props;
  const { http,getUser } = AuthUser();
  const classes = useStyles();
  //   const params = useParams();

  // Varibles
  const [company, setcompany] = React.useState("");
  const [name, setName] = React.useState("");
  const [designation, setdesignation] = React.useState("");

  const [address1, setaddress1] = React.useState("");
  // const [address2, setaddress2] = React.useState("");
  // const [address3, setaddress3] = React.useState("");
  const [city, setcity] = React.useState("");
  // const [tel1, settel1] = React.useState("");
  // const [tel2, settel2] = React.useState("");
  const [industries, setindustries] = React.useState("");
  const [category, setcategory] = React.useState("");
  const [mobile, setmobile] = React.useState("");
  const [email, setemail] = React.useState("");
  // const [website, setwebsite] = React.useState("");
  const [turnover, setturnover] = React.useState("");
  //   const [customerID, setCustomerID] = React.useState("");
  //   const [createdDate, setcreatedDate] = React.useState("");
  const [skills, setskills] = React.useState("");
  const [keyword, setkeyword] = React.useState("");

  //   const [assigned, setAssigned] = React.useState("");

  const [industriesList, setindustriesList] = React.useState([]);
  const [categoryList, setcategoryList] = React.useState([]);

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  const keywordValues = [
    "Google ads",
    "Google ads Call",
    "WhatsApp",
    "Call enquiry",
    "Tawk chat",
    "Email Enquiry",
    "SMS enquiry",
    "LinkedIn Arjun group",
    "LinkedIn Vikas group",
    "LinkedIn Guru group",
    "Induvidual LinkedIn",
    "Facebook leads",
    "Normal Referral",
    "Project Referral",
    "Webinar",
    "Indiamart",
    "Blog",
    "Others",
  ];

  React.useEffect(() => {
    if (!createContactModel) {
      setFormError({});
      setcompany("");
      setName("");
      setaddress1("");
      setindustries("");
      setcategory("");
      setcity("");
      setmobile("");
      setemail("");
      setturnover("");
      setskills("");
      setkeyword("");
    }
  }, [createContactModel]);

  // getting industry lists
  React.useEffect(() => {
    http.get("/industryList").then((response) => {
      const data = response.data;
      if (response.status === 200) {
        setindustriesList(data);
      }
    });
  }, []);

  // getting industry lists
  React.useEffect(() => {
    http.get("/categoryList").then((response) => {
      const data = response.data;
      if (response.status === 200) {
        setcategoryList(data);
      }
    });
  }, []);

  // First form Error checking and toggle
  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  // Error
  const handleFormError = () => {
    // if (company.trim().length === 0) {
    //   formErr.company = "Company name required";
    // }
    if (name.trim().length === 0) {
      formErr.name = "Name required";
    }
    // if (designation.trim().length === 0) {
    //   formErr.designation = "Designation required";
    // }

    // if (address1.trim().length === 0) {
    //   formErr.address1 = "Address is required";
    // }

    if (industries.length === 0) {
      formErr.industries = "Industry need to select";
    }

    if (category.length === 0) {
      formErr.category = "Category need to select";
    }

    if (mobile.length > 0) {
      formErr.mobile = "mobile number is required";
    } else if (!verifyNumber(mobile)) {
      formErr.mobile = "mobile number is required";
    }

    if (email.length > 0) {
      formErr.email = "Email address is required";
    } else if (!verifyEmail(mobile)) {
      formErr.email = "Email address is required";
    }

  };

  const resolve = () => {
    setCreateContactModel(false);
    setFormError({});
    setcompany("");
    setName("");
    setaddress1("");
    setindustries("");
    setcategory("");
    setcity("");
    setmobile("");
    setemail("");
    setturnover("");
    setskills("");
    setkeyword("");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const data = {
        company,
        name,
        designation,
        address1,
        city,
        mobile,
        email,
        turnover,
        skills,
        keyword,
        created_by:getUser().user_id,
        assigned_to:getUser().user_id,
      };
      handleLogForm(data);
    }
  };

  const handleLogForm = (formData) => {
    http
      .post("/admin/add_new_lead", formData)
      .then((response) => {
        // console.log(reposne);
        if (response.data && response.data.code == 200) {
          toast.success(`Successfull! company details updated`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          resolve();
        } else {
          toast.error(`Something went wrong try again`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`Error in updating company ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  // console.log(getUser())
  
  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <GridContainer>
          {/* Comapny */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={company && company.length > 0 ? true : false}
              labelText={<span>Company</span>}
              id="company"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: company,
                name: "company",
                onChange: (event) => {
                  setcompany(event.target.value);
                  // delete formError.company;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {/* {Object.keys(formError).length > 0 && formError.company && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.company}
              </p>
            )} */}
          </GridItem>
          {/* Assigned To */}
          {/* <GridItem xs={12} sm={12} md={12} lg={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h5>Assigned To : </h5>
              <h4>{assigned}</h4>
            </div>
          </GridItem> */}
          {/* Customer ID */}
          {/* <GridItem xs={12} sm={12} md={12} lg={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h5>Customer ID : </h5>
              <h4>{customerID}</h4>
            </div>
          </GridItem> */}
          {/* Created Date*/}
          {/* <GridItem xs={12} sm={12} md={12} lg={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h5>Created Date : </h5>
              <h4>{createdDate}</h4>
            </div>
          </GridItem> */}
          {/* Name */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={name && name.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.name
                  ? true
                  : false
              }
              labelText={
                <span>
                  Name <span>*</span>
                </span>
              }
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: name,
                name: "name",
                onChange: (event) => {
                  setName(event.target.value);
                  delete formError.name;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <PermIdentity className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.name && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.name}
              </p>
            )}
          </GridItem>
          {/* Designation */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={designation && designation.length > 0 ? true : false}
              labelText={<span>Designation</span>}
              id="designation"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: designation,
                name: "designation",
                onChange: (event) => {
                  setdesignation(event.target.value);
                  // delete formError.designation;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {/* {Object.keys(formError).length > 0 && formError.designation && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.designation}
              </p>
            )} */}
          </GridItem>
          {/* address1 */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={address1 && address1.length > 0 ? true : false}
              // error={(Object.keys(formError).length > 0 && formError.name) ? true : false}
              labelText={<span>Address 1</span>}
              id="address1"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: address1,
                name: "address1",
                onChange: (event) => {
                  setaddress1(event.target.value);
                  // delete formError.address1;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <LocationOn className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {/* {Object.keys(formError).length > 0 && formError.address1 && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.address1}
              </p>
            )} */}
          </GridItem>
          {/* address2 */}
          {/* <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={(address2&&address2.length>0) ? true : false}
              // error={(Object.keys(formError).length > 0 && formError.name) ? true : false}
              labelText={<span>Address 2</span>}
              id="address2"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: address2,
                name: "address2",
                onChange: (event) => {
                  setaddress2(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <LocationOn className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem> */}
          {/* address3 */}
          {/* <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={(address3&&address3.length>0) ? true : false}
              // error={(Object.keys(formError).length > 0 && formError.name) ? true : false}
              labelText={<span>Address 3</span>}
              id="address3"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: address3,
                name: "address3",
                onChange: (event) => {
                  setaddress3(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <LocationOn className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem> */}
          {/* city */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={city && city.length > 0 ? true : false}
              // error={(Object.keys(formError).length > 0 && formError.name) ? true : false}
              labelText={<span>City</span>}
              id="city"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: city,
                name: "city",
                onChange: (event) => {
                  setcity(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <LocationOn className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* tel1 */}
          {/* <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={(tel1&&tel1.length>0) ? true : false}
              labelText={<span>Telephone 1</span>}
              id="tel1"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: tel1,
                name: "tel1",
                type: "number",
                onChange: (event) => {
                  settel1(event.target.value);
                  delete formError.tel;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Smartphone className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem> */}
          {/* tel2 */}
          {/* <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={(tel2&&tel2.length>0) ? true : false}
              labelText={<span>Telephone 2</span>}
              id="tel2"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: tel2,
                name: "tel2",
                type: "number",
                onChange: (event) => {
                  settel2(event.target.value);
                  delete formError.tel;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Smartphone className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem> */}
          {/* industry list */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="industries" className={classes.selectLabel}>
                <span id="industryLable">Choose industry*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={industries}
                onChange={(value) => {
                  setindustries(value.target.value);
                  delete formError.industries;
                }}
                inputProps={{
                  name: "industries",
                  id: "industries",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose industry
                </MenuItem>
                {industriesList.map((industry) => {
                  return (
                    <MenuItem
                      key={industry.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={industry.id}
                    >
                      {industry.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.industries && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.industries}
              </p>
            )}
          </GridItem>
          {/* Category list */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="category" className={classes.selectLabel}>
                <span id="categoryLable">Choose Skills*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={category}
                onChange={(value) => {
                  setcategory(value.target.value);
                  delete formError.category;
                }}
                inputProps={{
                  name: "category",
                  id: "category",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Skills
                </MenuItem>
                {categoryList.map((category) => {
                  return (
                    <MenuItem
                      key={category.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={category.id}
                    >
                      {category.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.category && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.category}
              </p>
            )}
          </GridItem>
          {/* mobile */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={mobile && mobile.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.mobile
                  ? true
                  : false
              }
              labelText={<span>Mobile*</span>}
              id="mobile"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: mobile,
                name: "mobile",
                type: "number",
                onChange: (event) => {
                  setmobile(event.target.value);
                  delete formError.mobile;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Smartphone className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.mobile && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.mobile}
              </p>
            )}
          </GridItem>
          {/* email */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={email && email.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.email
                  ? true
                  : false
              }
              labelText={<span>Email*</span>}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: email,
                name: "email",
                onChange: (event) => {
                  setemail(event.target.value);
                  delete formError.email;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.email && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.email}
              </p>
            )}
          </GridItem>
          {/* website */}
          {/* <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={(website&&website.length>0) ? true : false}
              // error={(Object.keys(formError).length > 0 && formError.firstname) ? true : false}
              labelText={<span>Website</span>}
              id="website"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: website,
                name: "website",
                onChange: (event) => {
                  setwebsite(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Web className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem> */}
          {/* turnover */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={turnover && turnover.length > 0 ? true : false}
              // error={(Object.keys(formError).length > 0 && formError.firstname) ? true : false}
              labelText={<span>Turnover</span>}
              id="turnover"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: turnover,
                name: "turnover",
                onChange: (event) => {
                  setturnover(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Money className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* Skills/Comments*/}
          {/* comments */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="skills" style={{ marginLeft: "12px" }}>
                Comments
              </InputLabel>
              <OutlinedInput
                multiline
                rows={10}
                name="skills"
                value={skills}
                onChange={(event) => {
                  setskills(event.target.value);
                  delete formError.comments;
                }}
                id="skills"
                color="primary"
                label="Skills/Comments"
              />
            </FormControl>
            {Object.keys(formError).length > 0 && formError.skills && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.skills}
              </p>
            )}
          </GridItem>
          {/* Source */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="keyword" className={classes.selectLabel}>
                <span id="keyword">
                  Select source
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={keyword}
                onChange={(event) => {
                  setkeyword(event.target.value);
                  delete formError.keyword;
                }}
                inputProps={{
                  name: "keyword",
                  id: "keyword",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose source
                </MenuItem>
                {keywordValues.map((keywordValue, k) => {
                  return (
                    <MenuItem
                      key={k}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={keywordValue}
                    >
                      {keywordValue}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          {/* Submit */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className="edit-btn-industry">
              <Button round color="rose" type="submit">
                Submit
              </Button>
              <Button
                round
                onClick={() => {
                  setCreateContactModel(false);
                }}
              >
                Cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};
CreateContactFormCL.propTypes = {
  setCreateContactModel: PropTypes.func,
  createContactModel: PropTypes.bool,
};
export default CreateContactFormCL;
