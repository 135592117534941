import React from "react";
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
//import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
// import Close from "@material-ui/icons/Close";

// // core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";

// import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
};



const useStyles = makeStyles(styles);

function RecentActivities(props) {
    const {activities,plan} = props 
    const history = useHistory()
    const [data, setData] = React.useState([]);

    const reDirectToViewProject = (id) => {
        history.push(`/customer/project-details/${id}`)
    }

    // const planCheck = ()=>{

    // }

    React.useEffect(() => {
        const final = activities.map((activity) => {
            let action = (activity.action == 0 && !plan) && (<><span style={{color:"#fb483a",fontWeight:"bold"}}>Not Live</span></>) || (activity.action == 0 && plan) && (<><span style={{color:"#00acc1",fontWeight:"bold"}}>Under Review</span></>) || (activity.action == 1) && (<><span style={{color:"#9c27b0",fontWeight:"bold"}}>Live</span></>) || (activity.action == 2 || activity.action == 4 || activity.action == 5) && (<><span style={{color:"#4caf50",fontWeight:"bold"}}>Completed</span></>) || (activity.action == 3) && (<><span style={{color:"#ffa726",fontWeight:"bold"}}>On hold</span></>)
           let execution =(activity.execution == 0) && (<><span style={{fontWeight:"bold",color:"#ccc"}}>Not Defined</span></>) || (activity.execution == 1) && (<><span style={{fontWeight:"bold",color:"#E91e63"}}>Normal</span></>) || (activity.execution == 2) && (<><span style={{fontWeight:"bold",color:"#8e24aa"}}>Execution</span></>)
            return {
                id: activity.projectId,
                projectId: (<><span style={{fontWeight:"bold"}}>{activity.projectId}</span></>) ,
                //title: activity.title,
               // location: activity.location,
                // date_added:activity.date_added,
                type:execution,
                status: action,
                actions: (
                    <div className="actions-right">
                        <Button type="submit" round color="rose" style={{ fontWeight: 600 }}
                            onClick={() => {
                                reDirectToViewProject(activity.projectId)
                            }} >View</Button>
                    </div>
                )
            }
        })
        setData(final)
    }, [activities])

    const classes = useStyles();

    return (
        <>
            <Card>
                <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                        <Assignment />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}><b>Project Status</b></h4>
                </CardHeader>
                <CardBody>
                    {
                        activities.length > 0 ? (
                            <>
                                <ReactTable
                                    columns={[

                                        {
                                            Header: (<><span style={{fontWeight:"bold"}}>Project ID</span></>),
                                            accessor: "projectId",
                                        },
                                        // {
                                        //     Header: "Title",
                                        //     accessor: "title",
                                        // },

                                         {
                                            Header:  (<><span style={{fontWeight:"bold"}}>Project Type</span></>),
                                            accessor: "type",
                                        },
                                        // {
                                        //     Header: "Location", 
                                        //     accessor: "location",
                                        // },
                                      //   {
                                      //     Header: "Posted on", 
                                      //     accessor: "date_added",
                                      // },
                                      {
                                        Header: (<><span style={{fontWeight:"bold"}}>Status</span></>), 
                                        accessor: "status",
                                    },
                                        
                                        

                                        {
                                            Header: (<><span style={{fontWeight:"bold"}}>Actions</span></>),
                                            accessor: "actions",
                                        },

                                    ]}
                                    data={data} 
                                />
                            </>
                        ) : (
                            <>
                                <p>No Data</p>
                            </>
                        )
                    }
                </CardBody>
            </Card>
        </>
    );
}
RecentActivities.propTypes = {
    activities: PropTypes.array,
    plan:PropTypes.bool
}
export default RecentActivities