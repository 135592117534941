import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { InputAdornment } from "@material-ui/core";

import "react-datetime/css/react-datetime.css";
import { Business } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(styles);

const ProjectProposalFormPD = (props) => {
  const { setprojectProposalModal, projectProposalModal } = props;
  const classes = useStyles();
  //   const params = useParams();
  //   const history = useHistory();

  //   getUser
  // const { http } = AuthUser();

  const [sb_proposal, setsb_proposal] = React.useState("");
  const [indusryFile, setindusryFile] = React.useState("");

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  React.useEffect(() => {
    if (!projectProposalModal) {
      setsb_proposal("");
      setindusryFile("");
      setFormError({});
    }
  }, [projectProposalModal]);

  // handle Form error function
  const handleFormError = () => {
    // problem_stage
    if (sb_proposal.toString().trim().length === 0) {
      formErr.sb_proposal = "Proposal is required";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        sb_proposal,
        indusryFile,
      };
      console.log(formData);
      resolve();
      //   handleFormAPI(formData);
    }
  };

  //   const handleFormAPI = (formData) => {
  //     http
  //       .post("/admin/lead_questionire", formData)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           resolve();
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(`Can't update - try later ${err.message}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 1500,
  //         });
  //       });
  //   };

  const resolve = () => {
    toast.success("Successfully updated", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    setprojectProposalModal(false);
    setsb_proposal("");
    setindusryFile("");
    setFormError({});
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* sb_proposal */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={sb_proposal.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.sb_proposal
                  ? true
                  : false
              }
              labelText={<span>SB Proposal ₹*</span>}
              id="sb_proposal"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "sb_proposal",
                type:"number",
                value: sb_proposal,
                onChange: (event) => {
                  setsb_proposal(event.target.value);
                  delete formError.sb_proposal;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.sb_proposal && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.sb_proposal}
              </p>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <input
              type="file"
              name="indusry-file"
              // value={indusryFile}
              onChange={(event) => {
                setindusryFile(event.target.files[0]);
              }}
              style={{ display: "block" }}
            />
          </GridItem>
          {/* save */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                save
              </Button>
            </div>
          </GridItem>
          {/* cancle */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => setprojectProposalModal(false)}
              >
                cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

ProjectProposalFormPD.propTypes = {
  projectProposalModal: PropTypes.bool,
  setprojectProposalModal: PropTypes.func,
};

export default ProjectProposalFormPD;
