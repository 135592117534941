const initialValue = {}

const userReducer = (state=initialValue,action)=>{
    switch(action.type){
        case "USER_LIST":{
            return {...action.payload}
        }
        default : {
            return state
        }
    }
}

export default userReducer