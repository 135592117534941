import React from "react";

// import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

// Style
import "assets/css/common.css"


// core components
// import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";

import Button from "components/CustomButtons/Button.js";

// import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// const useStyles = makeStyles(styles);

const FilesProjectDetails = () => {

    const [files, setfiles] = React.useState({})

    const [formError, setFormError] = React.useState("")

    const formErr = {}

    // Form Error 
    const handleFormError = () => {
        if (Object.keys(files).length === 0) {
            formErr.comments = "Error"
        }
    }

    //reset values
    const resolve = () => {
        setFormError({})
        setfiles({})
    }

    // Form Submit 
    const handleFormSubmit = (e) => {
        e.preventDefault()
        handleFormError()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)
            toast.success(`Your create task Updated Successfull! ${formError}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose:1500
                //onClose: () => history.push('customer/user-page'),
            })
        } else {
            const data = {
                files
            }
           toast.success(`Your create task Updated Successfull!`, {
                position: toast.POSITION.TOP_CENTER,
                onClose: () =>  resolve(),
                autoClose:1500
            })
            console.log(data)
        }
    }
    const handleImageChange = (e) => {
        e.preventDefault();
        // let reader = new FileReader();
        let newFile = e.target.files[0];
        console.log(newFile)
        setfiles(newFile)

        // reader.onloadend = () => {
        //   setfiles(reader.result);
        // };
        // if (newFile) {
        //   reader.readAsDataURL(newFile);
        // }
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <input type="file" onChange={(e) => { handleImageChange(e) }} name="files" />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <div className="input-margin">
                                <Button round color="rose" type="submit" >Create Meeting</Button>
                            </div>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </form>
    );
}

export default FilesProjectDetails 