import React from "react";

// Authuser
import AuthUser from "components/Sb/AuthUser";
import "assets/css/viewProfile.css"
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Business from "@material-ui/icons/Business";
// import Email from "@material-ui/icons/Email";
// import Smartphone from "@material-ui/icons/Smartphone";
// import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";


const useStyles = makeStyles(styles);

function ModleViewProfile(props) {
    const classes = useStyles();
    const {http} = AuthUser()
    const { consultant } = props

    const [industries, setindustries] = React.useState([])
    const [industriesList, setindustriesList] = React.useState([])
    const [category, setcategory] = React.useState([])
    const [categoryList, setcategoryList] = React.useState([])

    React.useEffect(() => {
        http.get("/industryList")
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    setindustriesList(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    React.useEffect(() => {
        http.get("/categoryList")
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    setcategoryList(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    React.useEffect(() => {
        http.get("/customer/customerIndustries")
            .then((response) => {
                if (response.status === 200) {
                    const datasIndustries = response.data
                    let result = []
                    datasIndustries.forEach((ele) => {
                        result.push(ele.industries_id)
                    })
                    setindustries(result ? result : "0")
                }
            })
            .catch((err) => {
                console.log(err)
            })

        http.get("/customer/customerSkill")
            .then((response) => {
                if (response.status === 200) {
                    const dataSkills = response.data
                    let result = []
                    dataSkills.forEach((ele) => {
                        result.push(ele.skill_id)
                    })
                    setcategory(result ? result : "0")
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const resultIndustries = industriesList.filter((industry) => {
        return industries.includes(industry.id)
    })
    const resultCategory = categoryList.filter((categorie) => {
        return category.includes(categorie.id)
    })

    return (
        <div>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <PermIdentity />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Personal Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Fisrt Name: </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.firstname ? consultant.firstname : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Last Name: </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.lastname ? consultant.lastname : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Email : </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.email ? consultant.email : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Phone : </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.phone ? consultant.phone : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Date of Birth : </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.dob ? consultant.dob : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>City : </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.city ? consultant.city : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <Business />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Company Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Company Name: </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.companyname ? consultant.companyname : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Current Position: </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.mycurrentposition ? consultant.mycurrentposition : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Industry : </h5>
                                                {
                                                    resultIndustries.map((industry) => {
                                                        return <span key={industry.id} className={classes.tags}>{industry.name}</span>
                                                    })
                                                }
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Skills : </h5>
                                                {
                                                    resultCategory.map((category) => {
                                                        return <span key={category.id} className={classes.tags}>{category.name}</span>
                                                    })
                                                }
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Experience : </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.mytotalnoexp ? consultant.mytotalnoexp : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Acheivement : </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.archievements ? consultant.archievements : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Certification : </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.certification ? consultant.certification : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Linkedin : </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.linkedin ? consultant.linkedin : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Brief Bio : </h5>
                                                <p className={classes.lableValue}>
                                                    {consultant.brief_bio ? consultant.brief_bio : "NA"}
                                                </p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}

ModleViewProfile.propTypes = {
    consultant: PropTypes.object
}
export default ModleViewProfile