import React from "react";
import PropTypes from "prop-types";

import NavPills from "components/NavPills/NavPills.js";
import CreateTask from "./CreateTask";
import LogActivity from "./LogActivity";
import NoteCompany from "./NoteCompany";
import CreatePaymentLink from "./CreatePaymentLink";

const ContactContainer = (props) => {
  const {handlesLeadQualificaton,companyData}= props;
  return (
    <>
      <NavPills
        color="info"
        tabs={[
          {
            tabButton: "Log activity",
            tabContent: <LogActivity handlesLeadQualificaton={handlesLeadQualificaton} />,
          },
          {
            tabButton: "Note",
            tabContent: <NoteCompany handlesLeadQualificaton={handlesLeadQualificaton}/>,
          },
          {
            tabButton: "Create Task",
            tabContent: <CreateTask handlesLeadQualificaton={handlesLeadQualificaton}/>,
          },
          {
            tabButton: "Create Payment Link",
            tabContent: <CreatePaymentLink handlesLeadQualificaton={handlesLeadQualificaton} companyData={companyData}/>,
          },
        ]}
      />
    </>
  );
};

ContactContainer.propTypes = {
  handlesLeadQualificaton: PropTypes.func,
  companyData: PropTypes.object,
};

export default ContactContainer;
