import React from "react";
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";

// Importing css
import "assets/css/common.css";
import "assets/css/IndustryRequriement.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CardMembership from "@material-ui/icons/CardMembership";
import Payment from "@material-ui/icons/Payment";
import RateReview from "@material-ui/icons/Star";

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.js";
// import CardAvatar from "components/Card/CardAvatar.js"; // profile

// slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import Css
import "../../../../assets/css/IndustryRequriement.css";
import defaultImage from "assets/img/default-avatar.png";
// import b1 from "assets/img/ind-slick/b1.png";
// import b2 from "assets/img/ind-slick/b2.png";
// import b3 from "assets/img/ind-slick/b3.png";
// import b4 from "assets/img/ind-slick/b4.png";

// industry images
import aerospaceandDefence from "assets/img/industryRegisterStep2/aerospaceandDefence.png";
import automotive from "assets/img/industryRegisterStep2/automotive.png";
// import beverage from "assets/img/industryRegisterStep2/beverage.png";
import chemical from "assets/img/industryRegisterStep2/chemical.png";
import electrical from "assets/img/industryRegisterStep2/electrical.png";
import electronic from "assets/img/industryRegisterStep2/electronic.png";
import energy from "assets/img/industryRegisterStep2/energy.png";
import fmcg from "assets/img/industryRegisterStep2/fmcg.png";
import food from "assets/img/industryRegisterStep2/food.png";
import machanical from "assets/img/industryRegisterStep2/machanical.png";
import machineTool from "assets/img/industryRegisterStep2/machineTool.png";
import miningMetal from "assets/img/industryRegisterStep2/miningMetal.png";
import packaging from "assets/img/industryRegisterStep2/packaging.png";
import paperandPrinting from "assets/img/industryRegisterStep2/paperandPrinting.png";
import pharma from "assets/img/industryRegisterStep2/pharma.png";
import rubberPlastic from "assets/img/industryRegisterStep2/rubberPlastic.png";
import textile from "assets/img/industryRegisterStep2/textile.png";

// import cosmetics from "assets/img/industryRegisterStep2/cosmetics.png";
import jewellery from "assets/img/industryRegisterStep2/jewellery.png";
import medicalLaboratory from "assets/img/industryRegisterStep2/medicalLaboratory.png";
import oilGas from "assets/img/industryRegisterStep2/oilGas.png";
import railways from "assets/img/industryRegisterStep2/railways.png";
import roboticsAutomation from "assets/img/industryRegisterStep2/roboticsAutomation.png";
import wood from "assets/img/industryRegisterStep2/wood.png";

// icons
import contractmanufacturingIcon from "assets/img/industryRegisterStep2/icons/contractmanufacturing.png";
import fundingIcon from "assets/img/industryRegisterStep2/icons/funding.png";
import licenseIcon from "assets/img/industryRegisterStep2/icons/license.png";
import machineryIcon from "assets/img/industryRegisterStep2/icons/machinery.png";
import marketresearchIcon from "assets/img/industryRegisterStep2/icons/marketresearch.png";
import packagingICon from "assets/img/industryRegisterStep2/icons/packaging.png";
import plantsetupIcon from "assets/img/industryRegisterStep2/icons/plantsetup.png";
import productDevelopmentIcon from "assets/img/industryRegisterStep2/icons/productDevelopment.png";
import technologyadoptionIcon from "assets/img/industryRegisterStep2/icons/technologyadoption.png";
import completedTask from "assets/img/industryRegisterStep2/icons/completedTask.png";
import Loader from "views/Loader/Loader";
import { toast } from "react-toastify";

const useStyles = makeStyles(styles);

const ExploreConsultant = ({ handleToggleTwo }) => {
  const classes = useStyles();

  const { http } = AuthUser();
  const [noOfconsultant, setnoOfconsultant] = React.useState("");
  //   const [noOfexpert, setnoOfexpert] = React.useState("");
  const [projectType, setProjectType] = React.useState("");
  const [clientLogo, setclientLogo] = React.useState("");
  const [resultProject, setresultProject] = React.useState([]);

  const [rating, setrating] = React.useState([]);

  React.useEffect(() => {
    http
      .get("/customer/getInfo")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setnoOfconsultant(data.threeDigit);
          //   setnoOfexpert(data.twoDigit);
          setProjectType(data.industry);
          setclientLogo(defaultImage);
          handleRating(4);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  React.useEffect(() => {
    const resultProjects = projectData.filter((eleProject) => {
      if (
        eleProject.filterName.toLowerCase().includes(projectType.toLowerCase())
      ) {
        return eleProject;
      }
    });
    setresultProject(resultProjects);
  }, [projectType]);

  const getConnect = () => {
    http
      .get("/customer/OnboardingPaymentNotifySS")
      .then((response) => {
        if (response.status === 200) {
          handleToggleTwo();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRating = (rate) => {
    let arr = [];
    for (let i = 1; i <= rate; i++) {
      arr.push(i);
    }
    return setrating(arr);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    touchMove: true,
    swipe: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  const settingsReview = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  //   const handleExpertRating = (star) => {
  //     let arr = [];
  //     for (let i = 0; i < star; i++) {
  //       arr.push(<RateReview style={{ color: "#FAB410" }} />);
  //     }
  //     return arr;
  //   };

  const reviewDatas = [
    {
      img: clientLogo,
      rating: "4.5",
      name: "Srinivasan Krishnan",
      review:
        "I am very happy to associate with SolutionBuggy. I have found relevant projects for consulting from SB. Looking forward to work on more projects. Solution Buggy is  also a one of a  kind transparent platform wherein you are able to interact with clients directly",
    },
    {
      img: clientLogo,
      rating: "4.5",
      name: "Bhagyashri Mhatre",
      review:
        "I am writing this review as one of the registered consultant with Solution Buggy. This is my second year with SB. Initially we faced challenges for getting the projects but now we are really getting lots of inquiries for projects. No every project gets converted but still we are happy as Staff of SB is really taking efforts to close down the leads from PAN India, They are very helpful, cooperative and really working hard for win win situation for both consultants and clients.Thank you SB",
    },
    {
      img: clientLogo,
      rating: "4.1",
      name: "Dr. Anubha Khale",
      review:
        "Its a wonderful platform to connect the expert with those needing such a professional help. I could get very interesting projects through SolutionBuggy. The team is also very cooperative and supports in case of technical issues.I am sure, more and more number of professionals will come together on this platform for exchange of business solutions. I wish all the very best to the team for achieving huge success ",
    },
    {
      img: clientLogo,
      rating: "4.5",
      name: "Tanmay Sharma",
      review: `Solution Buggy is a trustworthy platform where really genuine people about business meet. I got 2 projects within a span of 2 months.
          In the era where gig economy is the talk of the town, I think Solution buggy is the platform to be at for Industry experts.
          Highly Recommended.`,
    },
  ];

  //  Aerospace , Defence  , Automotive , Chemical , Electrical , Electronics , Energy , FMCG ,
  //  Food & Beverages , Machine tools ,Mechanical,
  //  Mining & Metals , Packaging , Paper & Printing ,
  //  Pharmaceutical,Plastics,Rubber,Textile,Jewellery & Cosmetics ,
  //  Medical & Laboratory , Railways,Wood ,Robotics & Automation

  const projectData = [
    {
      name: "Food Industry",
      filterName: "Food & Beverages",
      para:
        "Few of our recent Food projects completed for New & Existing Industry",
      projects: "600+ Completed",
      img: food,
      allProjects: [
        {
          name: "Market Research",
          completed: "10+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "12+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "21+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "18+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "15+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "10+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "14+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "9+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "14+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Textile Industry",
      filterName: "Textile",
      para:
        "Few of our recent Textile projects completed for New & Existing Industry",
      projects: "660+ Completed",
      img: textile,
      allProjects: [
        {
          name: "Market Research",
          completed: "11+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "15+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "19+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "8+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "20+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "16+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "13+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "12+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "18+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Aerospace and Defence Industry",
      filterName: "Aerospace Defence",
      para:
        "Few of our recent Aerospace and Defence projects completed for New & Existing Industry",
      projects: "300+ Completed",
      img: aerospaceandDefence,
      allProjects: [
        {
          name: "Market Research",
          completed: "11+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "12+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "20+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "13+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "8+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "15+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "14+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "10+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "16+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Automotive Industry",
      filterName: "Automotive",
      para:
        "Few of our recent Automotive projects completed for New & Existing Industry",
      projects: "1800+ Completed",
      img: automotive,
      allProjects: [
        {
          name: "Market Research",
          completed: "15+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "10+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "14+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "10+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "19+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "17+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "12+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "11+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "8+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Chemical Industry",
      filterName: "Chemical",
      para:
        "Few of our recent Chemical projects completed for New & Existing Industry",
      projects: "1350+ Completed",
      img: chemical,
      allProjects: [
        {
          name: "Market Research",
          completed: "17+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "12+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "16+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "8+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "16+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "19+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "17+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "10+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "12+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "FMCG Industry",
      filterName: "FMCG",
      para:
        "Few of our recent FMCG projects completed for New & Existing Industry",
      projects: "400+ Completed",
      img: fmcg,
      allProjects: [
        {
          name: "Market Research",
          completed: "14+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "16+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "13+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "11+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "13+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "10+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "12+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "9+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "13+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Mechanical Industry",
      filterName: "Mechanical",
      para:
        "Few of our recent Machanical projects completed for New & Existing Industry",
      projects: "250+ Completed",
      img: machanical,
      allProjects: [
        {
          name: "Market Research",
          completed: "15+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "13+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "10+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "14+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "20+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "09+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "15+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "12+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "10+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Pharma Industry",
      filterName: "Pharmaceutical",
      para:
        "Few of our recent Pharma projects completed for New & Existing Industry",
      projects: "270+ Completed",
      img: pharma,
      allProjects: [
        {
          name: "Market Research",
          completed: "18+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "14+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "09+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "19+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "17+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "11+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "07+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "14+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "08+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Machine Tool Industry",
      filterName: "Machine tools",
      para:
        "Few of our recent Machine Tool projects completed for New & Existing Industry",
      projects: "220+ Completed",
      img: machineTool,
      allProjects: [
        {
          name: "Market Research",
          completed: "10+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "08+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "13+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "17+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "11+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "16+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "21+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "19+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "17+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Electronics Tool Industry",
      filterName: "Electronics",
      para:
        "Few of our recent Electronic Tool projects completed for New & Existing Industry",
      projects: "500+ Completed",
      img: electronic,
      allProjects: [
        {
          name: "Market Research",
          completed: "10+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "08+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "13+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "17+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "11+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "16+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "21+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "19+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "17+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Electrical Industry",
      filterName: "Electrical",
      para:
        "Few of our recent Electrical projects completed for New & Existing Industry",
      projects: "1200+ Completed",
      img: electrical,
      allProjects: [
        {
          name: "Market Research",
          completed: "14+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "16+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "13+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "11+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "13+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "10+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "12+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "9+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "13+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Rubber and Plastic Industry",
      filterName: "Plastics Rubber",
      para:
        "Few of our recent Rubber and Plastic projects completed for New & Existing Industry",
      projects: "510+ Completed",
      img: rubberPlastic,
      allProjects: [
        {
          name: "Market Research",
          completed: "11+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "15+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "19+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "8+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "20+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "16+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "13+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "12+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "18+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Energy Industry",
      filterName: "Energy",
      para:
        "Few of our recent Energy projects completed for New & Existing Industry",
      projects: "600+ Completed",
      img: energy,
      allProjects: [
        {
          name: "Market Research",
          completed: "15+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "13+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "10+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "14+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "20+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "09+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "15+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "12+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "10+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Mining & Metals Industry",
      filterName: "Mining & Metals",
      para:
        "Few of our recent Mining & Metal projects completed for New & Existing Industry",
      projects: "300+ Completed",
      img: miningMetal,
      allProjects: [
        {
          name: "Market Research",
          completed: "11+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "15+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "19+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "8+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "20+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "16+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "13+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "12+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "18+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Paper and Printing Industry",
      filterName: "Paper & Printing",
      para:
        "Few of our recent Paper and Printing projects completed for New & Existing Industry",
      projects: "400+ Completed",
      img: paperandPrinting,
      allProjects: [
        {
          name: "Market Research",
          completed: "11+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "12+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "20+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "13+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "8+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "15+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "14+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "10+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "16+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Packaging Industry",
      filterName: "Packaging",
      para:
        "Few of our recent Packaging projects completed for New & Existing Industry",
      projects: "700+ Completed",
      img: packaging,
      allProjects: [
        {
          name: "Market Research",
          completed: "15+ Completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "10+ Completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "14+ Completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "10+ Completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "19+ Completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "17+ Completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "12+ Completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "11+ Completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "8+ Completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Jewellery & Cosmetics",
      filterName: "Jewellery & Cosmetics",
      para:
        "Few of our recent Jewellery & Cosmetics projects completed for New & Existing Industry",
      projects: "100+ completed",
      img: jewellery,
      allProjects: [
        {
          name: "Market Research",
          completed: "5+ completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "3+ completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "2+ completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "4+ completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "2+ completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "3+ completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "1+ completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "3+ completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "2+ completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Medical & Laboratory",
      filterName: "Medical & Laboratory",
      para:
        "Few of our recent Medical & Laboratory projects completed for New & Existing Industry",
      projects: "270+ Completed",
      img: medicalLaboratory,
      allProjects: [
        {
          name: "Market Research",
          completed: "11+ completed",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "19+ completed",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "12+ completed",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "18+ completed",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "14+ completed",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "17+ completed",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "11+ completed",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "21+ completed",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "11+ completed",
          img: contractmanufacturingIcon,
        },
      ],
    },
    // Oil & Gas,Railways,Wood,Robotics & Automation
    {
      name: "Oil & Gas",
      filterName: "Oil & Gas",
      para: "Services we Offer",
      projects: "",
      img: oilGas,
      allProjects: [
        {
          name: "Market Research",
          completed: "",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Railways",
      filterName: "Railways",
      para: "Services We Offer",
      projects: "",
      img: railways,
      allProjects: [
        {
          name: "Market Research",
          completed: "",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Wood",
      filterName: "Wood",
      para: "Services We Offer",
      projects: "",
      img: wood,
      allProjects: [
        {
          name: "Market Research",
          completed: "",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "",
          img: contractmanufacturingIcon,
        },
      ],
    },
    {
      name: "Robotics & Automation",
      filterName: "Robotics & Automation",
      para: "Services We Offer",
      projects: "",
      img: roboticsAutomation,
      allProjects: [
        {
          name: "Market Research",
          completed: "",
          img: marketresearchIcon,
        },
        {
          name: "Product Development",
          completed: "",
          img: productDevelopmentIcon,
        },
        {
          name: "Plant Setup",
          completed: "",
          img: plantsetupIcon,
        },
        {
          name: "Funding",
          completed: "",
          img: fundingIcon,
        },
        {
          name: "Machinery Installation",
          completed: "",
          img: machineryIcon,
        },
        {
          name: "Technology Adoption",
          completed: "",
          img: technologyadoptionIcon,
        },
        {
          name: "License & Certifications",
          completed: "",
          img: licenseIcon,
        },
        {
          name: "Packaging & Distribution",
          completed: "",
          img: packagingICon,
        },
        {
          name: "Contract manufacturing",
          completed: "",
          img: contractmanufacturingIcon,
        },
      ],
    },
  ];

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={11} lg={11}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <CardMembership />
            </CardIcon>
            <h4
              className={classes.cardIconTitle}
              style={{
                color: "#3c4858",
                marginTop: "14px",
                fontWeight: "500",
                fontSize: "24px",
              }}
            >
              {noOfconsultant} Best Expert Available Now For {projectType}
            </h4>
          </CardHeader>
          <CardBody>
            {projectType ? (
              <div className="consultant-project-main">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    {projectType.trim().length > 0 &&
                      resultProject.map((project, pd) => {
                        return (
                          <div key={pd}>
                            <GridContainer justify="center" alignItems="center">
                              <GridItem xs={12} sm={12} md={3} lg={3}>
                                <div
                                  className="project-card"
                                  style={{
                                    backgroundImage: `url('${project.img}')`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                  }}
                                >
                                  <div className="project-card-title">
                                    <h4>{project.name}</h4>
                                  </div>
                                  <div className="pc-para-completed">
                                    <div className="project-card-para">
                                      <p>{project.para}</p>
                                    </div>
                                    {project.projects.length > 0 && (
                                      <div className="project-card-comleted">
                                        <img
                                          src={completedTask}
                                          alt="completedTask"
                                          width={"18px"}
                                          height={"18px"}
                                        />
                                        <h6>{project.projects}</h6>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={9} lg={9}>
                                <Slider {...settings}>
                                  {project.allProjects.map((eleAll, ea) => {
                                    return (
                                      <div key={ea}>
                                        <div className="market-cards">
                                          <div className="market-cards-title">
                                            <h5>{eleAll.name}</h5>
                                          </div>
                                          <div className="market-cards-img">
                                            <img
                                              src={eleAll.img}
                                              alt={eleAll.name}
                                              width={"80x"}
                                              height={"80px"}
                                            />
                                          </div>
                                          {eleAll.completed.length > 0 && (
                                            <div className="market-cards-report">
                                              <p>
                                                Our recent {eleAll.name}{" "}
                                                Projects Completed
                                              </p>
                                            </div>
                                          )}
                                          {eleAll.completed.length > 0 && (
                                            <div className="market-cards-complete">
                                              <h6>{eleAll.completed}</h6>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </Slider>
                              </GridItem>
                            </GridContainer>
                          </div>
                        );
                      })}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div style={{ textAlign: "center", marginTop: "12px" }}>
                      {projectType.trim().length > 0 && (
                        <Button color="info" round onClick={getConnect}>
                          Connect with Project Term
                        </Button>
                      )}
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            ) : (
              <Loader />
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={11} lg={11}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <Payment />
            </CardIcon>
            <h4
              className={classes.cardIconTitle}
              style={{ color: "#3c4858", marginTop: "14px", fontWeight: "500" }}
            >
              Our Success Stories
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10} lg={10}>
                <Slider {...settingsReview}>
                  {reviewDatas.map((reviewData, rd) => {
                    return (
                      <div key={rd}>
                        <div className="client-review-conatiner">
                          <div style={{ textAlign: "center" }}>
                            <div className="img-client-div client-review-div">
                              <a
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  src={clientLogo}
                                  alt="..."
                                  width="100%"
                                  height="auto"
                                />
                              </a>
                            </div>
                            <div>
                              <h5 className="client-review-name">
                                {reviewData.name}
                              </h5>
                              <div className="client-rating-container">
                                {rating.map((ele) => {
                                  return (
                                    <div key={ele}>
                                      <RateReview
                                        style={{ color: "#FAB410" }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="client-review-story">
                              {reviewData.review}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

ExploreConsultant.propTypes = {
  handleToggleTwo: PropTypes.func,
};

export default ExploreConsultant;
