import React from "react";
import AuthUser from "components/Sb/AuthUser";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// Style
import "assets/css/common.css";

// @material-ui/icons
// import Grade from "@material-ui/icons/Grade";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";

// Input
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";

// Style
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);

const LanguageCustomer = ({ handlesLeadQualificaton }) => {
  const classes = useStyles();
  const { http } = AuthUser();
  const params = useParams();

  const [language, setlanguage] = React.useState("");

  // FormError
  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // Form Error
  const handleFormError = () => {
    if (language.length === 0) {
      formErr.language = "language is required";
    }
  };

  //reset values
  const resolve = () => {
    setFormError({});
    setlanguage("");
  };

  // Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const data = {
        language,
        id: parseInt(params.id),
      };
      handleLogForm(data);
    }
  };

  const handleLogForm = (formData) => {
    http
      .post("/admin/language_automation", formData)
      .then((response) => {
        // console.log(reposne);
        if (response.data && response.data.code == 200) {
          toast.success(`Updated Language`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          handlesLeadQualificaton();
          resolve();
        } else {
          toast.error(`Something went wrong try again`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`Error in updating company ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  const languageValue = ["Malayalam", "Telugu", "Tamil", "Kannada", "Hindi"];

  return (
    <form onSubmit={handleFormSubmit}>
      <GridContainer>
        {/* language */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="language" className={classes.selectLabel}>
                <span id="languageLable">Select language*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={language}
                onChange={(event) => {
                  setlanguage(event.target.value);
                  delete formError.language;
                }}
                inputProps={{
                  name: "language",
                  id: "language",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose language
                </MenuItem>
                {languageValue.map((ele, i) => {
                  return (
                    <MenuItem
                      key={i}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={ele}
                    >
                      {ele}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.language && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.language}
              </p>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <Button round color="rose" type="submit">
              Update
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};
LanguageCustomer.propTypes = {
  handlesLeadQualificaton: PropTypes.func,
};
export default LanguageCustomer;
