import React from "react";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

// @material-ui/icons
// import Description from "@material-ui/icons/Description";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";

// Radio
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);

// importing css
import "assets/css/common.css";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { InputLabel, OutlinedInput } from "@material-ui/core";

const useStyles = makeStyles(styles);

const NewIndustryQuery = (props) => {
  const { industry_type, handleMainToggle } = props;
  const classes = useStyles();
  const classesRadio = useStylesRadio();
  const { http } = AuthUser();

  const [indutryPlan, setIndutryPlan] = React.useState(industry_type);
  const [industries, setindustries] = React.useState("");
  const [sub_cat, setsub_cat] = React.useState("");
  const [req, setreq] = React.useState("");
  const [budget, setbudget] = React.useState("");
  const [land, setland] = React.useState("");
  const [start_within, setstart_within] = React.useState("");
  const [state, setstate] = React.useState("");
  const [desc, setdesc] = React.useState("");
  const [assist, setAssist] = React.useState("");

  const [stateList, setstateList] = React.useState([]);
  const [industriesList, setindustriesList] = React.useState([]);
  const [sub_catList, setsub_catList] = React.useState([]);

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  const budgetList = [
    "50Lac - 1Cr",
    "1Cr - 10Cr",
    "10Cr - 50Cr",
    "50Cr - 100Cr",
    "More than 100Cr",
  ];

  // changes
  React.useEffect(() => {
    setIndutryPlan(industry_type);
  }, []);
  React.useEffect(() => {
    setindustries("");
    setsub_cat("");
    setreq("");
    setland("");
    setstart_within("");
    setstate("");
    setdesc("");
    setAssist("");
    setbudget("");
  }, [indutryPlan]);

  // getting state List
  React.useEffect(() => {
    http
      .get("/stateList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setstateList(data);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  // getting industry lists
  React.useEffect(() => {
    http
      .get("/industryList")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setindustriesList(data);
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  const handleSelectCategory = (id) => {
    http
      .post("/customer/categoryDepDropdown", { industries: id })
      .then((response) => {
        if (response.status == 200) {
          const data = response.data;
          setsub_catList(data);
        } else {
          //warning msg
          toast.warn("Oops Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        toast.error(`Oops Something went wrong! ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  let industryLable = document.getElementById("industryLable");
  let stateLable = document.getElementById("stateLable");
  let sub_catLable = document.getElementById("sub_catLable");
  let reqLable = document.getElementById("reqLable");
  // let assistLable = document.getElementById("assistLable");
  let start_withinLable = document.getElementById("start_withinLable");
  // let budgetLable = document.getElementById("budgetLable");
  // let landLable = document.getElementById("landLable");

  const handleErrorMsg = (lable) => {
    lable.classList.remove("errorMsg");
    lable.classList.add("successMsg");
  };

  // handle Form error function
  const handleFormError = () => {
    if (industries.length === 0) {
      formErr.industries = "Industry need to select";
      industryLable.classList.add("errorMsg");
    }

    if ((industries === 9 || industries === 18) && sub_cat.length === 0) {
      formErr.sub_cat = "sub_cat need to select";
      sub_catLable.classList.add("errorMsg");
    }

    if (state.length === 0) {
      formErr.state = "State need to select";
      stateLable.classList.add("errorMsg");
    }

    if (req.length === 0) {
      formErr.req = "State need to select";
      reqLable.classList.add("errorMsg");
    }

    if (start_within.length === 0) {
      formErr.start_within = "State need to select";
      start_withinLable.classList.add("errorMsg");
    }

    if (req === "New Plant Setup") {
      if (budget.length === 0) {
        formErr.budget = "State need to select";
      }

      if (assist.length === 0) {
        formErr.assist = "Govt schemes or grants is required";
      }
    }

    if (desc.trim().length === 0) {
      formErr.desc = "Describe is required";
    }
  };

  const resolve = () => {
    setFormError({});
    setindustries("");
    setsub_cat("");
    setreq("");
    setstart_within("");
    setstate("");
    setdesc("");
    setland("");
    setbudget("");
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const data = {
        industries,
        sub_cat,
        req,
        start_within,
        state,
        desc,
        industry_type,
        budget,
        land,
        assist,
      };

      http
        .post("/customer/reqSub", data)
        .then((res) => {
          if (res.status === 200) {
            resolve();
            toast.success(
              `Your ${industry_type} Profile Updated Successfull!`,
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                //onClose: () => history.push('customer/user-page'),
              }
            );
            handleMainToggle();

            //mail api calling

            http
              .get("/customer/sentPrequalificationNotification")
              .then((res) => {
                if (res.status == 200) {
                  toast.success(`Notification is sent`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1500,
                  });
                }
              })
              .catch((err) => {
                toast.error(`Oops Something went wrong! ${err.message}`, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 1500,
                });
              });
          } else {
            //warning msg
            toast.warn("Oops Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1500,
            });
          }
        })
        .catch((err) => {
          toast.error(`Oops Something went wrong! ${err.message}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        {/* Industry */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <span id="industryLable" style={{ fontWeight: "bold" }}>
              Please mention the Industry that you are planning to start{" "}
              <small>(required)</small>
            </span>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={industries}
                onChange={(event) => {
                  setindustries(event.target.value);
                  handleSelectCategory(event.target.value);
                  handleErrorMsg(industryLable);
                }}
                label="Choose industry"
                inputProps={{
                  name: "industries",
                  id: "industries",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose industry
                </MenuItem>
                {industriesList.map((industry) => {
                  return (
                    <MenuItem
                      key={industry.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={industry.id}
                    >
                      {industry.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </GridItem>

        {/* Sub Category */}
        {(industries == 9 || industries === 18) && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className="input-margin">
              <span id="sub_catLable" style={{ fontWeight: "bold" }}>
                Choose Sub Category <small>(required)</small>
              </span>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={sub_cat}
                  onChange={(event) => {
                    setsub_cat(event.target.value);
                    handleErrorMsg(sub_catLable);
                  }}
                  inputProps={{
                    name: "sub_cat",
                    id: "sub_cat",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Sub Category
                  </MenuItem>
                  {sub_catList.map((industry) => {
                    return (
                      <MenuItem
                        key={industry.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={industry.id}
                      >
                        {industry.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </GridItem>
        )}

        {/* State */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <span id="stateLable" style={{ fontWeight: "bold" }}>
              Mention your location <small>(required)</small>
            </span>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={state}
                onChange={(event) => {
                  setstate(event.target.value);
                  handleErrorMsg(stateLable);
                }}
                inputProps={{
                  name: "state",
                  id: "state_ex",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose State
                </MenuItem>

                {stateList.map((states) => {
                  return (
                    <MenuItem
                      key={states.state_id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={states.state_id}
                    >
                      {states.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </GridItem>

        {/* Require Consultant for? */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <span id="reqLable" style={{ fontWeight: "bold" }}>
              Select your project requirement <small>(required)</small>
            </span>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={req}
                onChange={(event) => {
                  setreq(event.target.value);
                  handleErrorMsg(reqLable);
                }}
                inputProps={{
                  name: "req",
                  id: "req",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Select your project requirement
                </MenuItem>

                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="New Plant Setup"
                >
                  New Plant Setup
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Product Development/Identification/Formulation"
                >
                  Product Development/Identification/Formulation
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Market Research Report"
                >
                  Market Research Report
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Detailed Project Report"
                >
                  Detailed Project Report
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Others"
                >
                  Others
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </GridItem>

        {/*  Govt. schemes & land */}
        {req == "New Plant Setup" && (
          <>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <div className="input-margin">
                <span id="budgetLable" style={{ fontWeight: "bold" }}>
                  What is the minimum amount you are planning to invest?
                  <small>(required)</small>
                </span>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={budget}
                    onChange={(event) => {
                      setbudget(event.target.value);
                      delete formError.budget;
                      document
                        .getElementById("budgetLable")
                        .classList.add("successMsg");
                    }}
                    inputProps={{
                      name: "budget",
                      id: "budget",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose budget
                    </MenuItem>

                    {budgetList.map((budgetName, b) => {
                      return (
                        <MenuItem
                          key={b}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={budgetName}
                        >
                          {budgetName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {Object.keys(formError).length > 0 && formError.budget && (
                  <p style={{ color: "red" }}>{formError.budget}</p>
                )}
              </div>
            </GridItem>
            {/* Do you need assistance to avail Govt. schemes or grants for your project? */}
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <p id="assistLable" style={{ fontWeight: "bold" }}>
                    Do you need assistance to avail Govt. schemes or grants for
                    your project?
                  </p>
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <div
                    className={
                      classesRadio.checkboxAndRadio +
                      " " +
                      classesRadio.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={assist === "Yes"}
                          onChange={() => {
                            setAssist("Yes");
                            delete formError.assist;
                            document
                              .getElementById("assistLable")
                              .classList.add("successMsg");
                          }}
                          value={assist}
                          name="assist"
                          aria-label="Yes"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radio,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      label="Yes"
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <div
                    className={
                      classesRadio.checkboxAndRadio +
                      " " +
                      classesRadio.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={assist === "No"}
                          onChange={() => {
                            setAssist("No");
                            delete formError.assist;
                            document
                              .getElementById("assistLable")
                              .classList.add("successMsg");
                          }}
                          value={assist}
                          name="assist"
                          aria-label="No"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radio,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      label="No"
                    />
                  </div>
                </GridItem>
                {Object.keys(formError).length > 0 && formError.assist && (
                  <p style={{ color: "red" }}>{formError.assist}</p>
                )}
              </GridContainer>
            </GridItem>
            {/* land */}
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <p
                    id="landLable"
                    style={{
                      marginTop: "20px",
                      marginBottom: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    Do you own a land to start a project?
                  </p>
                </GridItem>
                <GridItem xs={6} sm={4}>
                  <div
                    className={
                      classesRadio.checkboxAndRadio +
                      " " +
                      classesRadio.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={land === "Yes"}
                          onChange={() => {
                            setland("Yes");
                          }}
                          value={land}
                          name="land"
                          aria-label="Yes"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radio,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      label="Yes"
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} sm={4}>
                  <div
                    className={
                      classesRadio.checkboxAndRadio +
                      " " +
                      classesRadio.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={land === "No"}
                          onChange={() => {
                            setland("No");
                          }}
                          name="land"
                          value={land}
                          aria-label="No"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radio,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      label="No"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </>
        )}

        {/* start_within */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <span id="start_withinLable" style={{ fontWeight: "bold" }}>
              How soon are you planning to start your project?
              <small>(required)</small>
            </span>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={start_within}
                onChange={(event) => {
                  setstart_within(event.target.value);
                  handleErrorMsg(start_withinLable);
                }}
                inputProps={{
                  name: "start_within",
                  id: "start_within",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose How soon are you planning to start your project?
                </MenuItem>

                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="Less Than 1 month"
                >
                  Less Than 1 month
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="1 to 3 months"
                >
                  1 to 3 months
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="3 to 6 months"
                >
                  3 to 6 months
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="More than 6 months"
                >
                  More than 6 months
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </GridItem>

        {/* Describe */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel
              htmlFor="desc"
              style={{ marginLeft: "12px", marginBottom: "-12px" }}
            >
              Describe your requirement in brief<small>(required)</small>
            </InputLabel>
            <OutlinedInput
              multiline
              rows={10}
              name="desc"
              value={desc}
              onChange={(event) => {
                setdesc(event.target.value);
                delete formError.desc;
              }}
              id="desc"
              color="primary"
              label="Project Requirement"
            />
          </FormControl>
          {Object.keys(formError).length > 0 && formError.desc && (
            <p style={{ color: "red" }}>{formError.desc}</p>
          )}
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              color="info"
              className={classes.registerButton}
              type="submit"
              round
            >
              save
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

NewIndustryQuery.propTypes = {
  industry_type: PropTypes.string,
  handleMainToggle: PropTypes.func,
};

export default NewIndustryQuery;
