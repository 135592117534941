import React from "react";

import PropTypes from "prop-types";
import { toast } from "react-toastify";
import AuthUser from "components/Sb/AuthUser";
// import { useParams } from "react-router-dom";

// Style
import "assets/css/common.css";

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";

// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
// Radio Import Style
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);

// start model
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import stylesModel from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
const useStylesModel = makeStyles(stylesModel);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
// end model

const EditCreateTask = (props) => {
  const { handlesLeadQualificaton, comment, date: datelate, id, type } = props;
  const classesRadio = useStylesRadio();
  // Model
  const classesModel = useStylesModel();
  const [createTaskModal, setCreateTaskModal] = React.useState(false);
  //   const classes = useStyles();
  const { http } = AuthUser();

  const currentDate = new Date();
  function dateCurrent() {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const [meet, setMeet] = React.useState(1);
  const [date, setdate] = React.useState(datelate ? datelate : dateCurrent());
  const [comments, setcomments] = React.useState(
    comment ? comment.props.dangerouslySetInnerHTML.__html : ""
  );

  // FormError
  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // Form Error
  const handleFormError = () => {
    if (comments.trim().length === 0) {
      formErr.comments = "Error";
    }
  };

  //reset values
  const resolve = () => {
    setFormError({});
  };

  // Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const data = {
        date,
        comments,
        id,
      };
      handleLogForm(data);
      // console.log(data);
    }
  };

  const handleLogForm = (formData) => {
    // console.log(formData);
    http
      .post("/admin/update_log", formData)
      .then((reposne) => {
        // console.log(reposne);
        if (
          Object.keys(reposne).length > 0 &&
          Object.prototype.hasOwnProperty.call(reposne.data, "code")
        ) {
          toast.success(`Your Log Acivity Updated Successfull!`, {
            position: toast.POSITION.TOP_CENTER,
            //   onClose: () => resolve(),
            autoClose: 1500,
          });
          handlesLeadQualificaton();
          setCreateTaskModal(false);
          resolve();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`Error! - ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <Button
        round
        onClick={() => {
          setCreateTaskModal(true);
        }}
      >
        Edit
      </Button>
      <Dialog
        classes={{
          root: classesModel.center + " " + classesModel.modalRoot,
          paper: classesModel.modal,
        }}
        open={createTaskModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setCreateTaskModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesModel.modalHeader}
        >
          <Button
            justIcon
            className={classesModel.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setCreateTaskModal(false);
            }}
          >
            <Close className={classesModel.modalClose} />
          </Button>
          <h4 className={classesModel.modalTitle}>{type}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classesModel.modalBody}
        >
          <form onSubmit={handleFormSubmit}>
            {type == "Create Task" && (
              <GridContainer alignItems="baseline">
                <GridItem xs={6} sm={6}>
                  <div
                    className={
                      classesRadio.checkboxAndRadio +
                      " " +
                      classesRadio.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={meet === 1}
                          onChange={() => {
                            setMeet(1);
                          }}
                          name="r3"
                          aria-label="Call"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radio,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      label="Call"
                    />
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6}>
                  <div
                    className={
                      classesRadio.checkboxAndRadio +
                      " " +
                      classesRadio.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={meet === 3}
                          onChange={() => {
                            setMeet(3);
                          }}
                          name="r3"
                          aria-label="Meet"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radio,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      label="Meet"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              {type == "Create Task" && (
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <FormControl fullWidth>
                    <label htmlFor="date">Date and Time:</label>
                    <input
                      type="datetime-local"
                      id="date"
                      value={date}
                      name="date"
                      onChange={(event) => {
                        setdate(event.target.value);
                      }}
                    />
                  </FormControl>
                </GridItem>
              )}
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel htmlFor="comments" style={{ marginLeft: "12px" }}>
                    Comments
                  </InputLabel>
                  <OutlinedInput
                    multiline
                    rows={12}
                    name="comments"
                    value={comments}
                    onChange={(event) => {
                      setcomments(event.target.value);
                      delete formError.comments;
                    }}
                    id="requirement"
                    color="primary"
                    label="Project Requirement"
                  />
                </FormControl>
                {Object.keys(formError).length > 0 && formError.comments && (
                  <p style={{ color: "#f44336", textAlign: "left" }}>
                    {formError.comments}
                  </p>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="input-margin">
                  <Button round color="rose" type="submit">
                    Submit
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

EditCreateTask.propTypes = {
  handlesLeadQualificaton: PropTypes.func,
  comment: PropTypes.object,
  date: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
};

export default EditCreateTask;
