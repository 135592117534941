import React, { useState } from "react";

import PropTypes from 'prop-types'

// Import CSS
import "assets/css/industryEditProfile.css"

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Business from "@material-ui/icons/Business";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// Submit Query step
import NewIndustryQuery from "./NewIndustryQuery";
import ExistingIndustryQuery from "./ExistingIndustryQuery";


// Radio Import Style
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// import Card from "components/Card/Card";
// import CardBody from "components/Card/CardBody";
const useStylesRadio = makeStyles(stylesRadio);


const SubmitYourQuery = (props) => {
    const { handleMainToggle } = props

    const classes = useStylesRadio();

    const [isToggleFormEdit, setIsToggleFormEdit] = useState(false)
    const [industry_type, setindustry_type] = React.useState("New Industry");

    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10} lg={10}>
                <Card>
                    <CardHeader color="warning" icon>
                        <CardIcon color="warning">
                            <Business />
                        </CardIcon>
                        <h4 className="card-title">
                            {industry_type}
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <div className="">
                            <GridContainer alignItems="baseline">
                                <GridItem xs={12} sm={4} >
                                    <p style={{ fontWeight: "bold" }}   >Select the type of Industry</p>
                                </GridItem>
                                <GridItem xs={6} sm={4} >
                                    <div
                                        className={
                                            classes.checkboxAndRadio +
                                            " " +
                                            classes.checkboxAndRadioHorizontal
                                        }
                                    >
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={industry_type === "New Industry"}
                                                    onChange={() => {
                                                        setindustry_type("New Industry")
                                                        setIsToggleFormEdit(false)
                                                    }}
                                                    name="radio button enabled"
                                                    aria-label="New Industry"
                                                    icon={
                                                        <FiberManualRecord
                                                            className={classes.radioUnchecked}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <FiberManualRecord
                                                            className={classes.radioChecked}
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.radio,
                                                        root: classes.radioRoot,
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label,
                                                root: classes.labelRoot,
                                            }}
                                            label="New Industry"
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={4}>
                                    <div
                                        className={
                                            classes.checkboxAndRadio +
                                            " " +
                                            classes.checkboxAndRadioHorizontal
                                        }
                                    >
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={industry_type === "Existing Industry"}
                                                    onChange={() => {
                                                        setindustry_type("Existing Industry")
                                                        setIsToggleFormEdit(true)
                                                    }}
                                                    name="radio button enabled"
                                                    aria-label="Existing Industry"
                                                    icon={
                                                        <FiberManualRecord
                                                            className={classes.radioUnchecked}
                                                        />
                                                    }
                                                    checkedIcon={
                                                        <FiberManualRecord
                                                            className={classes.radioChecked}
                                                        />
                                                    }
                                                    classes={{
                                                        checked: classes.radio,
                                                        root: classes.radioRoot,
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.label,
                                                root: classes.labelRoot,
                                            }}
                                            label="Existing Industry"
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    {
                                        !isToggleFormEdit ? (
                                            <>
                                                <NewIndustryQuery industry_type={industry_type} handleMainToggle={handleMainToggle} />
                                            </>
                                        ) : (
                                            <>
                                                <ExistingIndustryQuery industry_type={industry_type} handleMainToggle={handleMainToggle} />
                                            </>
                                        )
                                    }
                                </GridItem>
                            </GridContainer>
                        </div>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}


SubmitYourQuery.propTypes = {
    handleMainToggle: PropTypes.func
}

export default SubmitYourQuery 