import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { InputAdornment } from "@material-ui/core";

import "react-datetime/css/react-datetime.css";
import { Business } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(styles);

const ProposalStagesFormPD = (props) => {
  const { countValue } = props;
  const classes = useStyles();
  //   const params = useParams();
  //   const history = useHistory();

  //   getUser
  // const { http } = AuthUser();

  const [industry, setindustry] = React.useState("");
  const [industry_date, setindustry_date] = React.useState(new Date());

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // handle Form error function
  const handleFormError = () => {
    // problem_stage
    if (industry.toString().trim().length === 0) {
      formErr.industry = "Industry is required";
    }
    // industry_date
    if (!industry_date) {
      formErr.industry_date = "Date is required";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        industry,
        industry_date,
      };
      console.log(formData);
      resolve();
      //   handleFormAPI(formData);
    }
  };

  //   const handleFormAPI = (formData) => {
  //     http
  //       .post("/admin/lead_questionire", formData)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           resolve();
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(`Can't update - try later ${err.message}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 1500,
  //         });
  //       });
  //   };

  const resolve = () => {
    toast.success("Successfully updated", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    setindustry("");
    setindustry_date("");
    setFormError({});
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* industry */}
          <GridItem xs={12} sm={12} md={12}>
            <p>Stage:{countValue}</p>
            <CustomInput
              success={industry.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.industry
                  ? true
                  : false
              }
              labelText={<span>SB Proposal ₹*</span>}
              id="industry"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "industry",
                type: "number",
                value: industry,
                onChange: (event) => {
                  setindustry(event.target.value);
                  delete formError.industry;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.industry && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.industry}
              </p>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <input
              type="date"
              name="industry_date"
              value={industry_date}
              onChange={(e) => {
                setindustry_date(e.target.value);
              }}
              style={{ display: "block" }}
            />
          </GridItem>
          {/* save */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                save
              </Button>
            </div>
          </GridItem>
          {/* cancle */}
          {/* <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => setprojectProposalModal(false)}
              >
                cancle
              </Button>
            </div>
          </GridItem> */}
        </GridContainer>
      </form>
    </>
  );
};

ProposalStagesFormPD.propTypes = {
  countValue: PropTypes.any,
};

export default ProposalStagesFormPD;
