/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import AuthUser from "components/Sb/AuthUser";



// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomerTypeUpdate({ customer_typeModal, setcustomer_typeModal, customer ,handleUpdateCustomer}) {
    const classes = useStyles();
    const { http } = AuthUser();
    const [ctype, setctype] = React.useState("")

    React.useEffect(() => {
        if (customer && customer.customer_type == "SS") {
            setctype("SP")
        }
        if (customer && customer.customer_type == "SP") {
            setctype("SS")
        }
    }, [customer])

    const handleUpdateCType = () => {
        // console.log(customer, ctype)
        const dataRequest = {
            ctype,email:customer.email
        }
        http
            .post("/admin/update_ctype", dataRequest)
            .then((response) => {
                const data = response.data;
                if (response.status == 200) {
                    if (data && data.code && data.code == 200) {
                        toast.success(`Successfull changed the customer type to ${ctype}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 3000,
                        });
                        handleUpdateCustomer()
                        setcustomer_typeModal(false)
                    }
                }
                if ((!response.status == 200)) {
                    toast.error(`Something went wrong try again!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 3000,
                    });
                    setcustomer_typeModal(false)
                }
            })
            .catch((err) => {
                // console.log(err);
                toast.error(`Error! - ${err.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1500,
                });
            });
    }


    return (
        <div>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={customer_typeModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setcustomer_typeModal(false)}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                <DialogTitle
                    id="notice-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setcustomer_typeModal(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Update Customer Type</h4>
                </DialogTitle>
                <DialogContent
                    id="notice-modal-slide-description"
                    className={classes.modalBody}
                >
                    <p>
                        Click yes if you want to change the customer type {ctype == "SS" && "Consultant to Industry"} {ctype == "SP" && "Industry to Consultant"}
                    </p>
                </DialogContent>
                <DialogActions
                    className={
                        classes.modalFooter + " " + classes.modalFooterCenter
                    }
                >
                    <Button
                        onClick={() => {
                            handleUpdateCType()
                        }}
                        color="info"
                        round
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => setcustomer_typeModal(false)}
                        color="warning"
                        round
                    >
                        No
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

CustomerTypeUpdate.propTypes = {
    customer_typeModal: PropTypes.bool,
    setcustomer_typeModal: PropTypes.func,
    customer: PropTypes.any,
    handleUpdateCustomer: PropTypes.func,
};
