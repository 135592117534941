import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
// import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
// import Email from "@material-ui/icons/Email";
import Money from "@material-ui/icons/Money";
import Business from "@material-ui/icons/Business";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import NoteAdd from "@material-ui/icons/NoteAdd";
import PanTool from "@material-ui/icons/PanTool";
import LocationCity from "@material-ui/icons/LocationCity";
import LocationOn from "@material-ui/icons/LocationOn";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { OutlinedInput } from "@material-ui/core";
// import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const KYCDoneForm = (props) => {
  const { setkycDoneModal } = props;
  const classes = useStyles();
  //   const params = useParams();
  //   const history = useHistory();

  //   getUser
  const { http } = AuthUser();

  const [project_no, setproject_no] = React.useState("");
  const [project_title, setproject_title] = React.useState("");
  const [customer_name, setcustomer_name] = React.useState("");
  const [designation, setdesignation] = React.useState("");
  const [company_name, setcompany_name] = React.useState("");
  const [industry_category, setindustry_category] = React.useState("");
  const [location, setlocation] = React.useState("");
  const [language, setlanguage] = React.useState("");
  const [turnover, setturnover] = React.useState("");
  const [industry_vertical, setindustry_vertical] = React.useState("");
  const [products_manufactured, setproducts_manufactured] = React.useState("");
  const [to_manufacture, setto_manufacture] = React.useState("");
  const [requirement_type, setrequirement_type] = React.useState("");
  const [problem_type, setproblem_type] = React.useState("");
  const [budget, setbudget] = React.useState("");
  const [timeline, settimeline] = React.useState("");
  const [project_stage, setproject_stage] = React.useState("");
  const [experience, setexperience] = React.useState("");
  const [skills, setskills] = React.useState("");
  const [requirement, setrequirement] = React.useState("");
  const [type_of_consultant, settype_of_consultant] = React.useState("");
  const [comments, setcomments] = React.useState("");
  const [status, setstatus] = React.useState("");
  const [manager, setmanager] = React.useState("");

  const [industriesAll, setindustriesAll] = React.useState([]);

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // getting industry lists
  React.useEffect(() => {
    http
      .get("/industryList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setindustriesAll(data);
        }
      })
      .catch((err) => {
        toast.error(`Can't update - industry List ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  // handle Form error function
  const handleFormError = () => {
    // project_no
    if (project_no.toString().trim().length === 0) {
      formErr.project_no = "Project Number is required";
    }
    // project_title
    if (project_title.toString().trim().length === 0) {
      formErr.project_title = "Project title is required";
    }
    // customer_name
    if (customer_name.toString().trim().length === 0) {
      formErr.customer_name = "Customer Name is required";
    }
    // company_name
    if (company_name.toString().trim().length === 0) {
      formErr.company_name = "Company Name is required";
    }
    // industry_category
    if (industry_category.toString().trim().length === 0) {
      formErr.industry_category = "Industry category is required";
    }
    // industry_vertical
    if (industry_vertical.toString().trim().length === 0) {
      formErr.industry_vertical = "Industry is required";
    }
    // requirement_type
    if (requirement_type.toString().trim().length === 0) {
      formErr.requirement_type = "Requirement type is required";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        project_no,
        project_title,
        customer_name,
        designation,
        company_name,
        industry_category,
        location,
        language,
        turnover,
        industry_vertical,
        products_manufactured,
        to_manufacture,
        requirement_type,
        problem_type,
        budget,
        timeline,
        project_stage,
        experience,
        skills,
        requirement,
        type_of_consultant,
        comments,
        status,
        manager,
      };
      console.log(formData);
      resolve();
      //   handleFormAPI(formData);
    }
  };

  //   const handleFormAPI = (formData) => {
  //     http
  //       .post("/admin/lead_questionire", formData)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           resolve();
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(`Can't update - try later ${err.message}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 1500,
  //         });
  //       });
  //   };

  const resolve = () => {
    toast.success("Successfully updated", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    setkycDoneModal(false);
    setFormError({});
    setproject_no("");
    setproject_title("");
    setcustomer_name("");
    setdesignation("");
    setcompany_name("");
    setindustry_category("");
    setlocation("");
    setlanguage("");
    setturnover("");
    setindustry_vertical("");
    setproducts_manufactured("");
    setto_manufacture("");
    setrequirement_type("");
    setproblem_type("");
    setbudget("");
    setproject_stage("");
    setexperience("");
    settype_of_consultant("");
  };

  const industry_category_list = ["Micro", "Small", "Medium"];
  const requirementLists = ["New", "Existing", "Expansion"];
  const problem_type_list = ["Tooling", "Maching"];
  const timelineLists = [
    "Immediate",
    `1 month – 3 months`,
    `3 months – 6 months`,
    "6 months",
  ];
  const projectStageLists = ["Initiation", "Planning", "Execution"];
  const experienceLists = [
    "5-10 Years",
    "10-15 Years",
    "15-20 Years",
    "20+ Years",
  ];

  const statusLists = [
    "Project to be taken under Execution",
    "Project will be made live under Normal",
  ];
  const managerLists = [
    "Nithin",
    "Pruthvi Raj B",
    "Pruthviraj CNCN",
    "Devein",
    "Srinivasan",
  ];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* project_no */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={project_no.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.project_no
                  ? true
                  : false
              }
              labelText={<span>Project No*</span>}
              id="project_no"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "project_no",
                value: project_no,
                onChange: (event) => {
                  setproject_no(event.target.value);
                  delete formError.project_no;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.project_no && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.project_no}
              </p>
            )}
          </GridItem>
          {/* project_title */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={project_title.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.project_title
                  ? true
                  : false
              }
              labelText={<span>Project Title*</span>}
              id="project_title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "project_title",
                value: project_title,
                onChange: (event) => {
                  setproject_title(event.target.value);
                  delete formError.project_title;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.project_title && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.project_title}
              </p>
            )}
          </GridItem>
          {/* customer_name */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={customer_name.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.customer_name
                  ? true
                  : false
              }
              labelText={<span>Customer Name*</span>}
              id="customer_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "customer_name",
                value: customer_name,
                onChange: (event) => {
                  setcustomer_name(event.target.value);
                  delete formError.customer_name;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.customer_name && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.customer_name}
              </p>
            )}
          </GridItem>
          {/* designation */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={designation.length > 0 ? true : false}
              labelText={<span>Designation</span>}
              id="designation"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "designation",
                value: designation,
                onChange: (event) => {
                  setdesignation(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* company_name */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={company_name.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.company_name
                  ? true
                  : false
              }
              labelText={<span>Company Name*</span>}
              id="company_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "company_name",
                value: company_name,
                onChange: (event) => {
                  setcompany_name(event.target.value);
                  delete formError.company_name;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.company_name && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.company_name}
              </p>
            )}
          </GridItem>
          {/* industry_category */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="industry_category"
                className={classes.selectLabel}
              >
                <span id="industry_category">Select industry category*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={industry_category}
                onChange={(event) => {
                  setindustry_category(event.target.value);
                  delete formError.industry_category;
                }}
                inputProps={{
                  name: "industry_category",
                  id: "industry_category",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose industry
                </MenuItem>
                {industry_category_list.map((indCat, c) => {
                  return (
                    <MenuItem
                      key={c}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={indCat}
                    >
                      {indCat}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 &&
              formError.industry_category && (
                <p style={{ color: "#f44336", textAlign: "left" }}>
                  {formError.industry_category}
                </p>
              )}
          </GridItem>
          {/* location */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={location.length > 0 ? true : false}
              labelText={<span>Location</span>}
              id="location"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "location",
                value: location,
                onChange: (event) => {
                  setlocation(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <LocationCity className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* language */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={language.length > 0 ? true : false}
              labelText={<span>Language</span>}
              id="language"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "language",
                value: language,
                onChange: (event) => {
                  setlanguage(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <LocationOn className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* turnover */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={turnover.length > 0 ? true : false}
              labelText={<span>Turnover</span>}
              id="turnover"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "turnover",
                value: turnover,
                onChange: (event) => {
                  setturnover(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Money className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* industry_vertical */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="industry_vertical"
                className={classes.selectLabel}
              >
                <span id="industry_vertical">Select industry vertical*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={industry_vertical}
                onChange={(event) => {
                  setindustry_vertical(event.target.value);
                  delete formError.industry_vertical;
                }}
                inputProps={{
                  name: "industry_vertical",
                  id: "industry_vertical",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose industry
                </MenuItem>
                {industriesAll.map((industry) => {
                  return (
                    <MenuItem
                      key={industry.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={industry.id}
                    >
                      {industry.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 &&
              formError.industry_vertical && (
                <p style={{ color: "#f44336", textAlign: "left" }}>
                  {formError.industry_vertical}
                </p>
              )}
          </GridItem>
          {/* products_manufactured */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={products_manufactured.length > 0 ? true : false}
              labelText={<span>Product Manufactured</span>}
              id="products_manufactured"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "products_manufactured",
                value: products_manufactured,
                onChange: (event) => {
                  setproducts_manufactured(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <PanTool className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* to_manufacture */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={to_manufacture.length > 0 ? true : false}
              labelText={<span>To Manufacture</span>}
              id="to_manufacture"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "to_manufacture",
                value: to_manufacture,
                onChange: (event) => {
                  setto_manufacture(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <PanTool className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* requirement_type */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="requirement_type"
                className={classes.selectLabel}
              >
                <span id="requirement_type">Requirement Type*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={requirement_type}
                onChange={(event) => {
                  setrequirement_type(event.target.value);
                  delete formError.requirement_type;
                }}
                inputProps={{
                  name: "requirement_type",
                  id: "requirement_type",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose requirement type
                </MenuItem>
                {requirementLists.map((reqTyp, r) => {
                  return (
                    <MenuItem
                      key={r}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={reqTyp}
                    >
                      {reqTyp}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 &&
              formError.requirement_type && (
                <p style={{ color: "#f44336", textAlign: "left" }}>
                  {formError.requirement_type}
                </p>
              )}
          </GridItem>
          {/*  problem_type */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="problem_type"
                className={classes.selectLabel}
              >
                <span id="problem_type">Problem Type</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={problem_type}
                onChange={(event) => {
                  setproblem_type(event.target.value);
                  //   delete formError.problem_type;
                }}
                inputProps={{
                  name: "problem_type",
                  id: "problem_type",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Problem Type
                </MenuItem>
                {problem_type_list.map((prob, p) => {
                  return (
                    <MenuItem
                      key={p}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={prob}
                    >
                      {prob}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* {Object.keys(formError).length > 0 &&
              formError.problem_type && (
                <p style={{ color: "#f44336", textAlign: "left" }}>
                  {formError.problem_type}
                </p>
              )} */}
          </GridItem>
          {/* budget */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={turnover.length > 0 ? true : false}
              labelText={<span>Budget</span>}
              id="budget"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "budget",
                value: budget,
                onChange: (event) => {
                  setbudget(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Money className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* timeline */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="timeline" className={classes.selectLabel}>
                <span id="timeline_lable">Project Timeline</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={timeline}
                onChange={(event) => {
                  settimeline(event.target.value);
                  //   delete formError.timeline;
                }}
                inputProps={{
                  name: "timeline",
                  id: "timeline",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Timeline
                </MenuItem>
                {timelineLists.map((timelineList, b) => {
                  return (
                    <MenuItem
                      key={b}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={timelineList}
                    >
                      {timelineList}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.timeline && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.timeline}
              </p>
            )} */}
          </GridItem>
          {/* project_stage  */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="project_stage"
                className={classes.selectLabel}
              >
                <span id="project_stage">Stage of Project</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={project_stage}
                onChange={(event) => {
                  setproject_stage(event.target.value);
                  //   delete formError.project_stage;
                }}
                inputProps={{
                  name: "project_stage",
                  id: "project_stage",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Stage of Project
                </MenuItem>
                {projectStageLists.map((projectStageList, s) => {
                  return (
                    <MenuItem
                      key={s}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={projectStageList}
                    >
                      {projectStageList}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.timeline && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.timeline}
              </p>
            )} */}
          </GridItem>
          {/* experience  */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="experience" className={classes.selectLabel}>
                <span id="experience">Stage of Project</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={experience}
                onChange={(event) => {
                  setexperience(event.target.value);
                  //   delete formError.experience;
                }}
                inputProps={{
                  name: "experience",
                  id: "experience",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose experience
                </MenuItem>
                {experienceLists.map((experienceList, x) => {
                  return (
                    <MenuItem
                      key={x}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={experienceList}
                    >
                      {experienceList}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.timeline && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.timeline}
              </p>
            )} */}
          </GridItem>
          {/* skills */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={turnover.length > 0 ? true : false}
              labelText={<span>skills</span>}
              id="skills"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "skills",
                value: skills,
                onChange: (event) => {
                  setskills(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Money className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* requirement */}
          <GridItem xs={12} sm={12} md={12}>
            <p style={{ textAlign: "left" }}>
              Customer Requirement/Specific Challenge in Detail
            </p>
            <FormControl fullWidth sx={{ m: 1 }}>
              {/* <InputLabel htmlFor="type_of_consultant">
              &#173;&#173;Requirement&#173;
              </InputLabel> */}
              <OutlinedInput
                multiline
                rows={10}
                name="requirement"
                value={requirement}
                onChange={(event) => {
                  setrequirement(event.target.value);
                  //   delete formError.requirement;
                }}
                id="requirement"
                color="primary"
                label="Project Requirement"
              />
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.requirement && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.requirement}
              </p>
            )} */}
          </GridItem>
          {/* type_of_consultant */}
          <GridItem xs={12} sm={12} md={12}>
            <p style={{ textAlign: "left" }}>Type of Consultant Required</p>
            <FormControl fullWidth sx={{ m: 1 }}>
              {/* <InputLabel htmlFor="type_of_consultant">
                &#173;Type of Consultant Required&#173;
              </InputLabel> */}
              <OutlinedInput
                multiline
                rows={10}
                name="type_of_consultant"
                value={type_of_consultant}
                onChange={(event) => {
                  settype_of_consultant(event.target.value);
                  //   delete formError.type_of_consultant;
                }}
                id="type_of_consultant"
                color="primary"
                label="Type of Consultant Required"
              />
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.type_of_consultant && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.type_of_consultant}
              </p>
            )} */}
          </GridItem>
          {/* comments */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="comments" style={{ marginLeft: "12px" }}>
                Comments
              </InputLabel>
              <OutlinedInput
                multiline
                rows={10}
                name="comments"
                value={comments}
                onChange={(event) => {
                  setcomments(event.target.value);
                  //   delete formError.comments;
                }}
                id="comments"
                color="primary"
                label="comments"
              />
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.comments && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.comments}
              </p>
            )} */}
          </GridItem>
          {/* status */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="status" className={classes.selectLabel}>
                <span id="status">Project Status</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={status}
                onChange={(event) => {
                  setstatus(event.target.value);
                  //   delete formError.status;
                }}
                inputProps={{
                  name: "status",
                  id: "status",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose status
                </MenuItem>
                {statusLists.map((statusList, s) => {
                  return (
                    <MenuItem
                      key={s}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={statusList}
                    >
                      {statusList}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.statusList && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.statusList}
              </p>
            )} */}
          </GridItem>
          {/* manager */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="manager" className={classes.selectLabel}>
                <span id="manager">Project manager</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={manager}
                onChange={(event) => {
                  setmanager(event.target.value);
                  //   delete formError.manager;
                }}
                inputProps={{
                  name: "manager",
                  id: "manager",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose manager
                </MenuItem>
                {managerLists.map((managerList, m) => {
                  return (
                    <MenuItem
                      key={m}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={managerList}
                    >
                      {managerList}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.managerList && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.managerList}
              </p>
            )} */}
          </GridItem>

          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                save
              </Button>
            </div>
          </GridItem>
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => setkycDoneModal(false)}
              >
                cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

KYCDoneForm.propTypes = {
  setkycDoneModal: PropTypes.func,
};

export default KYCDoneForm;
