import React from "react";
import {useHistory} from "react-router-dom";

import AuthUser from "components/Sb/AuthUser";

import { toast } from 'react-toastify'
//import {useHistory} from 'react-router-dom'

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";
import Business from "@material-ui/icons/Business";
// import CalendarToday from "@material-ui/icons/CalendarToday";
import NoteAdd from "@material-ui/icons/NoteAdd";
// import Laptop from "@material-ui/icons/Laptop";
import LocationCity from "@material-ui/icons/LocationCity";
// import LocationOn from "@material-ui/icons/LocationOn";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";

// importing css
import "assets/css/industryEditProfile.css"
import "assets/css/common.css"
import defaultImage from "assets/img/default-avatar.png";



const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },
    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornment: {
        position: "relative",
    },
};

const useStyles = makeStyles(styles);

const NewIndustry = () => {
    const classes = useStyles()
     const history = useHistory()
    const {httpAxios,http} = AuthUser()
    const [firstname, setfirstname] = React.useState("");
    const [lastname, setlastname] = React.useState("");
    const [email, setemail] = React.useState("");
    const [phone, setPhone] = React.useState("")
    const [companyname, setcompanyname] = React.useState("")
    const [establishment, setestablishment] = React.useState("")
    const [brief_bio, setbrief_bio] = React.useState("")
    const [industries, setindustries] = React.useState("")
    const [city, setcity] = React.useState("")
    const [state, setstate] = React.useState("")
    const [stateAll, setStateAll] = React.useState([])
    const [industriesAll, setindustriesAll] = React.useState([])

    const [companylogo, Setcompanylogo] = React.useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);

    const [formError, setFormError] = React.useState({})
    let formErr = {}

    // getting state List
    React.useEffect(() => {
        http.get("/stateList") 
            .then((response) => {
                const data = response.data
                if (response.status === 200) {
                    setStateAll(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    // getting industry lists
    React.useEffect(() => {
        http.get("/industryList")
            .then((response) => {
                const data = response.data
                if (response.status === 200) {
                    setindustriesAll(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    // getting profile
    React.useEffect(() => {
        http.get("/profile")
            .then((response) => {
                const data = response.data
                if (response.status === 200) {
                    const date = data.establishment ? `${data.establishment.slice(0, 4)}-${data.establishment.slice(5, 7)}-${data.establishment.slice(8, 10)}` : ""
                    setfirstname(data.firstname ? data.firstname : "")
                    setlastname(data.lastname ? data.lastname : "")
                    setemail(data.email ? data.email : "")
                    setPhone(data.phone ? data.phone : "")
                    setcompanyname(data.companyname ? data.companyname : "")
                    setestablishment(date ? date : "")
                    setbrief_bio(data.brief_bio ? data.brief_bio : "")
                    setcity(data.city ? data.city : "")
                    setindustries(data.industries ? data.industries : "")
                    setstate(data.state ? data.state : "")
                    //change
                    //Setcompanylogo(data.companylogo?data.companylogo:"")
                    setImagePreviewUrl(data.companylogo ? 'https://api.solutionbuggy.com/customerProfile/' + data.companylogo : defaultImage)
                }
            })
            .catch((err) => {
                console.log(err)

            })
    }, [stateAll, industriesAll])

    // function that returns true if value is email, false otherwise
    // const verifyEmail = (value) => {
    //     var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     if (emailRex.test(value)) {
    //         return true;
    //     }
    //     return false;
    // };

    // function that verifies if a number has a given 
    const verifyNumber = (value) => {
        var numberRex = new RegExp("^[0-9]+$");
        if (numberRex.test(value)) {
            return true;
        }
        return false;
    };

    // handle Form error function
    const handleFormError = () => {
        let industryMsg = document.getElementById("industry")
        let stateMsg = document.getElementById("state")
        if (firstname.trim().length < 4) {
            formErr.firstname = "First name is required"
        }
        if (lastname.trim().length === 0) {
            formErr.lastname = "Last name is required"
        }
        if (phone.trim().length < 10 || !verifyNumber(phone)) {
            formErr.phone = "Phone is required"
        }
        if (companyname.trim().length === 0) {
            formErr.companyname = "Company name is required"
        }
        if (brief_bio.trim().length === 0) {
            formErr.brief_bio = "Brief is required more the 10 lenght"
        }
        if (industries.length === 0) {
            formErr.industries = "Industry need to select"
            industryMsg.classList.add("errorMsg")
        } else {
            if (industryMsg.classList.contains("errorMsg")) {
                industryMsg.classList.remove("errorMsg")
                industryMsg.classList.add("successMsg")
            }
        }
        if (city.trim().length === 0) {
            formErr.city = "City is required"
        }
        if (state.length === 0) {
            formErr.state = "State need to select"
            stateMsg.classList.add("errorMsg")
        } else {
            if (stateMsg.classList.contains("errorMsg")) {
                stateMsg.classList.remove("errorMsg")
                stateMsg.classList.add("successMsg")
            }
        }
    }

    //  Updating profile pic
    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let newFile = e.target.files[0];

        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };
        if (newFile) {
            reader.readAsDataURL(newFile);
        }
    };
    const handleImg = (e) => {
        Setcompanylogo(e.target.files[0])
    }

    // Form Submitting 
    const handleSubmit = (event) => {
        event.preventDefault()
        handleFormError()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)
        } else {
            // const formData = new FormData();
            // formData.append("firstname", firstname);
            // formData.append("lastname", lastname);
            // formData.append("email", email);
            // formData.append("phone", phone);
            // formData.append("companyname", companyname);
            // formData.append("establishment", establishment);
            // formData.append("brief_bio", brief_bio);
            // formData.append("industries", industries);
            // formData.append("city", city);
            // formData.append("state", state);
            // formData.append("companylogo", companylogo);

            const formData = {
                firstname, lastname, email, phone, companyname, establishment, brief_bio, industries, city, state, companylogo
            }
            console.log(formData)
            setFormError({})
            handleNewProfile(formData)
        }
    }

    // calling api form updating in new profile
    const handleNewProfile = (data) => {

            httpAxios.post("/customer/editNew",data)
                .then((response) => {
                    if (response.status === 200) {
                        toast.success('Your Profile Updated Successfull!', {
                            position: toast.POSITION.TOP_CENTER,
                            onClose: () => history.push('/customer/user-page'),
                            autoClose:1500
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                })

    }

    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={10} lg={10}>
                <form onSubmit={handleSubmit}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12}>
                                    <div className="picture-container">
                                        <div className="picture">
                                            <img src={imagePreviewUrl} className="picture-src" alt="..." />
                                            <input type="file" onChange={(e) => { handleImageChange(e), handleImg(e) }} name="companylogo" />
                                        </div>
                                        <h6 className="description">Choose Picture</h6>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <CustomInput
                                        success={firstname.length > 0 ? true : false}
                                        error={Object.keys(formError).length > 0 && formError.firstname ? true : false}
                                        labelText={
                                            <span>
                                                First Name <small>(required)</small>
                                            </span>
                                        }
                                        id="firstname"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: 'firstname',
                                            value: firstname,
                                            onChange: (event) => {
                                                setfirstname(event.target.value);
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" className={classes.inputAdornment}>
                                                    <Face className={classes.inputAdornmentIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <CustomInput
                                        success={lastname.length > 0 ? true : false}
                                        error={Object.keys(formError).length > 0 && formError.lastname ? true : false}
                                        labelText={
                                            <span>
                                                Last Name <small>(required)</small>
                                            </span>
                                        }
                                        id="lastname"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: 'lastname',
                                            value: lastname,
                                            onChange: (event) => {
                                                setlastname(event.target.value);
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" className={classes.inputAdornment}>
                                                    <RecordVoiceOver className={classes.inputAdornmentIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <CustomInput
                                        success={email.length > 0 ? true : false}
                                        labelText={
                                            <span>
                                                Email
                                            </span>
                                        }
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: 'email',
                                            value: email,
                                            onChange: (event) => {
                                                setemail(event.target.value);
                                            },
                                            disabled: true,
                                            endAdornment: (
                                                <InputAdornment position="end" className={classes.inputAdornment}>
                                                    <Email className={classes.inputAdornmentIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <CustomInput
                                        success={phone.length > 0 ? true : false}
                                        error={Object.keys(formError).length > 0 && formError.phone ? true : false}
                                        labelText={
                                            <span>
                                                Phone <small>(required)</small>
                                            </span>
                                        }
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: 'phone',
                                            value: phone,
                                            type: "number",
                                            onChange: (event) => {
                                                setPhone(event.target.value);
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" className={classes.inputAdornment}>
                                                    <Smartphone className={classes.inputAdornmentIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <CustomInput
                                        success={companyname.length > 0 ? true : false}
                                        error={Object.keys(formError).length > 0 && formError.companyname ? true : false}
                                        labelText={
                                            <span>
                                                Company Name <small>(required)</small>
                                            </span>
                                        }
                                        id="companyname"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: 'companyname',
                                            value: companyname,
                                            onChange: (event) => {
                                                setcompanyname(event.target.value);
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" className={classes.inputAdornment}>
                                                    <Business className={classes.inputAdornmentIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <CustomInput
                                        labelText={
                                            <span>
                                                Date of Establishment <small>(required)</small>
                                            </span>
                                        }
                                        id="establishment"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: 'establishment',
                                            value: establishment,
                                            type: "date",
                                            onChange: (event) => {
                                                setestablishment(event.target.value);
                                            },
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        success={brief_bio.length > 0 ? true : false}
                                        error={Object.keys(formError).length > 0 && formError.brief_bio ? true : false}
                                        labelText={
                                            <span>
                                                What Products you Manufacture <small>(required)</small>
                                            </span>
                                        }
                                        id="brief_bio"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: 'brief_bio',
                                            value: brief_bio,
                                            onChange: (event) => {
                                                setbrief_bio(event.target.value);
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" className={classes.inputAdornment}>
                                                    <NoteAdd className={classes.inputAdornmentIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                    >
                                        <InputLabel
                                            htmlFor="industries"
                                            className={classes.selectLabel}
                                        >
                                            <span id="industry">Choose industry <small>(required)</small></span>
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            value={industries}
                                            onChange={(event) => {
                                                setindustries(event.target.value)
                                            }}
                                            inputProps={{
                                                name: "industries",
                                                id: "industries",

                                            }}
                                        >
                                            <MenuItem
                                                disabled
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                }}
                                            >
                                                Choose industry
                                            </MenuItem>
                                            {
                                                industriesAll.map((industry) => {
                                                    return <MenuItem
                                                        key={industry.id}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected,
                                                        }}
                                                        value={industry.id}
                                                    >
                                                        {industry.name}
                                                    </MenuItem>
                                                })
                                            }

                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <CustomInput
                                        success={city.length > 0 ? true : false}
                                        error={Object.keys(formError).length > 0 && formError.city ? true : false}
                                        labelText={
                                            <span>
                                                City <small>(required)</small>
                                            </span>
                                        }
                                        id="city"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: 'city',
                                            value: city,
                                            onChange: (event) => {
                                                setcity(event.target.value);
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end" className={classes.inputAdornment}>
                                                    <LocationCity className={classes.inputAdornmentIcon} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6}>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                    >
                                        <InputLabel
                                            htmlFor="state_ex"
                                            className={classes.selectLabel}
                                        >

                                            <span id="state">Choose state <small>(required)</small></span>
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            value={state}
                                            onChange={(event) => {
                                                setstate(event.target.value)
                                            }}
                                            inputProps={{
                                                name: "state",
                                                id: "state_ex",

                                            }}
                                        >
                                            <MenuItem
                                                disabled
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                }}
                                            >
                                                Choose State
                                            </MenuItem>

                                            {
                                                stateAll.map((states) => {
                                                    return <MenuItem
                                                        key={states.state_id}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected,
                                                        }}
                                                        value={states.state_id}
                                                    >
                                                        {states.name}
                                                    </MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                
                            </GridContainer>
                            <div  style={{textAlign:"right"}}>
                                    <Button
                                    round
                                        color="success"
                                        className={classes.registerButton}
                                        type="submit"
                                    >
                                        save
                                    </Button>
                                </div>
                        </CardBody>
                    </Card>
                </form>
            </GridItem>
        </GridContainer>
    );
}


export default NewIndustry
