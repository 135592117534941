import axios from "axios";
import { useState } from "react";
//import Cookies from 'universal-cookie'
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function AuthUser() {
  //const navigate = useNavigate();
  const history = useHistory();
  //const cookies = new Cookies();

  const getUser = () => {
    const userString = sessionStorage.getItem("user");
    const user_detail = JSON.parse(userString);
    return user_detail;
  };

  const getRole = () => {
    const roleString = sessionStorage.getItem("role");
    const role_detail = JSON.parse(roleString);
    return role_detail;
  };

  const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const getEmail = () => {
    const tokenString = sessionStorage.getItem("otpEmail");
    //const otpEmail = JSON.parse(tokenString)
    return tokenString;
  };

  const [user, setUser] = useState(getUser());
  const [role, setRole] = useState(getRole());
  const [token, setToken] = useState(getToken());
  const [otpEmail, setotpEmail] = useState(getEmail());
  const [loading, setLoading] = useState(false);

  const getLoading = () => {
    return loading;
  };

  const saveEmail = (email) => {
    sessionStorage.setItem("otpEmail", email);
    setotpEmail(email);
  };
  const saveToken = (user, role, token) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("role", JSON.stringify(role));
    sessionStorage.setItem("token", JSON.stringify(token));

    setUser(user);
    setRole(role);
    setToken(token);

    if (role == "SS" || role == "SP") {
      history.push("/customer/dashboard");
    } else if (role == "A" || role == "S" || role == "P" || role == "U") {
      history.push("/admin/dashboard");
    }
  };

  const logout = () => {
    sessionStorage.clear();
    history.push("/auth/login-page");
  };

  const errorMsg = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
      //onClose: () => logout(),
    });
  };

  const warningMsg = (msg) => {
    toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
  };

  //Axios connection for 'Content-type': 'application/json'

  const http = axios.create({
    baseURL: 'https://api.solutionbuggy.com',
    headers: {
      "Content-type": "application/json",
    },
    withCredentials: true, // This should be at the top level, not in headers
});


http.interceptors.request.use(
  (config) => {
    setLoading(true);

    // Retrieve the token from sessionStorage and parse it (since it's stored as a JSON string)
    const token = JSON.parse(sessionStorage.getItem("token"));

    // If the token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.log("Interceptor Request Error: " + error);
    return Promise.reject(error);
  }
);


  //This allows you to intercept the response and check the status and error messages and if ncessary reject the promise.
  http.interceptors.response.use(
    (response) => {
      setLoading(false);
      return response;
    },
    (error) => {
      setLoading(false);

      if (error.response.status && error.response.status == 400) {
        //validation Errors
        warningMsg(error.response.data.message);
      } else if (
        error.response.status == 401 ||
        error.response.status == 403 ||
        error.response.status == 500
      ) {
        //Token is Invalid //Token is Expired //Authorization Token not found
        logout();
      } else if (error.response.status == 502) {
        //coding & functional error
        warningMsg(error.response.data.message);
      } else if (error.response.status == 413) {
        warningMsg(error.response.statusText);
      } else {
        //all other error
        errorMsg(error.response.data.message);
      }
      return Promise.reject(error);
    }
  );

  //Axios connection for 'Content-type': 'multipart/form-data'

  const httpAxios = axios.create({

    baseURL: 'https://api.solutionbuggy.com',
    // baseURL: "http://127.0.0.1:8000",
    headers: {
      "Content-type": "multipart/form-data",
      withCredentials: true,
      Authorization: `Bearer ${token}`,
    },
  });

  //This allows you to intercept the request before it is sent and alter headers or anyting else that is passed to the axios config.
  httpAxios.interceptors.request.use(
    (config) => {
      setLoading(true);
      return config;
    },
    (error) => {
      console.log("Interceptor Request Error" + error);
    }
  );

  //This allows you to intercept the response and check the status and error messages and if ncessary reject the promise.
  httpAxios.interceptors.response.use(
    (response) => {
      setLoading(false);
      return response;
    },
    (error) => {
      console.log(error.response);
      setLoading(false);

      if (error.response.status == 400) {
        //validation Errors
        warningMsg(error.response.data.message);
      } else if (error.response.status == 401) {
        //Token is Invalid
        logout();
      } else if (error.response.status == 403) {
        //Token is Expired
        logout();
      } else if (error.response.status == 500) {
        //Authorization Token not found
        logout();
      } else if (error.response.status == 502) {
        //coding & functional error
        warningMsg(error.response.data.message);
      } else if (error.response.status == 413) {
        warningMsg(error.response.statusText);
      } else {
        //all other error
        errorMsg(error.response.data.message);
      }
      return Promise.reject(error);
    }
  );

  return {
    setToken: saveToken,
    setotpEmail: saveEmail,
    user,
    role,
    token,
    otpEmail,
    getToken,
    getUser,
    getLoading,
    getRole,
    http,
    httpAxios,
    logout,
  };
}