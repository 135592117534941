import React from "react";
import PropTypes from 'prop-types'

// toast
import { toast } from 'react-toastify'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem"
// import Card from "components/Card/Card";
// import CardBody from "components/Card/CardBody";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";

import FileCopy from "@material-ui/icons/FileCopy";

// @material-ui/icons
//  import AttachMoney from "@material-ui/icons/AttachMoney";
// import Money from "@material-ui/icons/Money";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import AccountBalance from "@material-ui/icons/AccountBalance";
// import AccountBox from "@material-ui/icons/AccountBox";
// import Looks3 from "@material-ui/icons/Looks3";
//import FileCopy from "@material-ui/icons/FileCopy";

// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// Radio style
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);

import AuthUser from "components/Sb/AuthUser";

// style
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

const PaymentRequest = (props) => {
    const classes = useStyles();
    const classesRadio = useStylesRadio()
    const {http, httpAxios,getLoading } = AuthUser()
    const { setClassicModal,ProjectId,getPayment } = props

    // varibles
    const [amount, setAmount] = React.useState("")
    const [isgst, setIsgst] = React.useState("")
    const [gst, setGst] = React.useState("")
    const [doc_type, setDoc_type] = React.useState("")
    const [reference_no, setReference_no] = React.useState("")
    
    const [account_nm, setAccount_nm] = React.useState("")
    const [account_no, setAccount_no] = React.useState("")
    const [ifsc, setIfsc] = React.useState("")
    const [paymentDoc, setpaymentDoc] = React.useState(null)
    const [fileErrorMsg, setFileErrorMsg] = React.useState("")
    const [fileName, setfileName] = React.useState("")

    // Error
    const [formError, setFormError] = React.useState({})
    let formErr = {}

    
    

    // checking Form Error function
    const handleFormError = () => {
        let doc_typeLable = document.getElementById("doc_typeLable")
        // amount
        if (amount.trim().length === 0) {
            formErr.amount = "Error in Amount"
        }
        // gst
        if (isgst.trim().length === 0) {
            formErr.isgst = "Error in Gst"
        }
        if (isgst == "Yes") {
            if (gst.trim().length === 0) {
                formErr.gst = "Error in Gst"
            }
        }

        //upload file validation
        let element = document.getElementById("custom-file-upload");

        if (!paymentDoc) {
            formErr.paymentDoc = "Error in paymentDoc"
            element.classList.add("custom-error");
            setFileErrorMsg("Please! Select your file");
        }
        else {
            //file size validation
            // const MIN_FILE_SIZE = 1024 // 1MB
            const MAX_FILE_SIZE = 5120 // 5MB

            const fileSizeKiloBytes = paymentDoc.size / 1024

            // if (fileSizeKiloBytes < MIN_FILE_SIZE) {
            //     formErr.paymentDoc = "Error in paymentDoc"
            //     element.classList.add("custom-error");
            //     setFileErrorMsg("File size is less than minimum limit");
            // }

            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                formErr.paymentDoc = "Error in paymentDoc"
                element.classList.add("custom-error");
                setFileErrorMsg("File size is greater than maximum limit");
            }

        }

        //doc_type
        if (doc_type.trim().length === 0) {
            formErr.doc_type = "Error in doc_type"
            doc_typeLable.classList.add("custom-error");
        }

        //reference_no
        if (reference_no.trim().length === 0) {
            formErr.reference_no = "Error in reference_no"
        }

        // Account_nm
        if (account_nm.trim().length === 0) {
            formErr.account_nm = "Error in account_nm"
        }

        // account_no
        if (account_no.trim().length === 0) {
            formErr.account_no = "Error in account_no"
        }

        // ifsc
        if (ifsc.trim().length === 0) {
            formErr.ifsc = "Error in ifsc"
        }


    }

    // Reset Values 
    const resolve = () => {
        setFormError({})
        setAmount("")
        setIsgst("")
        setGst("")
        setDoc_type("")
        setReference_no("")
        
        setAccount_nm("")
        setAccount_no("")
        setIfsc("")
        setClassicModal(false)
    }

    // Form Submitting function
    const handleFormSubmit = (e) => {
        e.preventDefault()
        handleFormError()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)
            console.log(formErr)

            formErr = {}
        } else {
            const data = { amount, isgst, gst, doc_type, reference_no, paymentDoc, account_nm, account_no, ifsc ,ProjectId}
            console.log(data)
            //resolve()

            httpAxios.post("/customer/paymentRequest", data)
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    console.log(data)
                    toast.success('Payment Request has been Submitted Successfully.', {
                        position: toast.POSITION.TOP_CENTER,
                        onClose: () => resolve(),
                        autoClose : 1500
                    })
                    setClassicModal(false)
                    getPayment()
                    sentPaymentMail(ProjectId);
                    // getProvider();
                }
            }).catch((err) => {
                console.log(err)
            })

        }

    }

    const sentPaymentMail = (pid)=>{
        http.post("/customer/sentPaymentMail", {'pid':pid})
        .then((response) => {
          console.log(response)
    
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const fileHandle = (e) => {
        var file = e.target.files;
        setFileErrorMsg("");

        if (file.length > 0) {
            setfileName(file[0].name);
        }

        var element = document.getElementById("custom-file-upload");
        if (element.classList.contains("custom-error")) {
            element.classList.remove("custom-error");
            element.classList.add("custom-success");
        }
        else {
            element.classList.add("custom-success");
        }

    }

    const doc_handle = (event) => {
        if (event.target.value) {
            let doc_typeLable = document.getElementById("doc_typeLable")
            if (doc_typeLable.classList.contains("custom-error")) {
                doc_typeLable.classList.remove("custom-error");
                doc_typeLable.classList.add("custom-success");
            }
            else {
                doc_typeLable.classList.add("custom-success");
            }
        }

    }

    return (
        <>


            {/* <Card>
                <CardHeader color="primary" icon>
                    
                    <div style={{textAlign:"center"}}><h4 className='proposal-heading'>Payment Request</h4></div>

                </CardHeader>
                <CardBody> */}
            <form onSubmit={handleFormSubmit}>

                <GridContainer justify="center">

                    {/* Amount without GST */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <CustomInput
                            success={amount.length > 0 ? true : false}
                            error={Object.keys(formError).length > 0 && formError.amount ? true : false}
                            labelText={
                                <span>
                                    Amount without GST <small>(required)</small>
                                </span>
                            }
                            id="amount"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                name: 'amount',
                                value: amount,
                                type:'number',
                                onChange: (event) => {
                                    setAmount(event.target.value);
                                    delete formError.amount
                                },
                                endAdornment: (
                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                        {/* <AttachMoney className={classes.inputAdornmentIcon} /> */}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </GridItem>
                    {/* GST Applicable?  */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer justify="flex-start" alignItems="baseline">
                            <GridItem xs={4} sm={4} md={4} lg={4}>
                                <span>GST Applicable?</span>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} lg={4} >
                                <div
                                    className={
                                        classesRadio.checkboxAndRadio +
                                        " " +
                                        classesRadio.checkboxAndRadioHorizontal
                                    }
                                >
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={isgst === "1"}
                                                onChange={() => {
                                                    setIsgst("1")
                                                }}
                                                name="is_gst"
                                                aria-label="Yes"
                                                icon={
                                                    <FiberManualRecord
                                                        className={classesRadio.radioUnchecked}
                                                    />
                                                }
                                                checkedIcon={
                                                    <FiberManualRecord
                                                        className={classesRadio.radioChecked}
                                                    />
                                                }
                                                classes={{
                                                    checked: classesRadio.radio,
                                                    root: classesRadio.radioRoot,
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classesRadio.label,
                                            root: classesRadio.labelRoot,
                                        }}
                                        label="Yes"
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} lg={4} >
                                <div
                                    className={
                                        classesRadio.checkboxAndRadio +
                                        " " +
                                        classesRadio.checkboxAndRadioHorizontal
                                    }
                                >
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={isgst === "0"}
                                                onChange={() => {
                                                    setIsgst("0")
                                                }}
                                                name="is_gst"
                                                aria-label="No"
                                                icon={
                                                    <FiberManualRecord
                                                        className={classesRadio.radioUnchecked}
                                                    />
                                                }
                                                checkedIcon={
                                                    <FiberManualRecord
                                                        className={classesRadio.radioChecked}
                                                    />
                                                }
                                                classes={{
                                                    checked: classesRadio.radio,
                                                    root: classesRadio.radioRoot,
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classesRadio.label,
                                            root: classesRadio.labelRoot,
                                        }}
                                        label="No"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    {/* GST Number */}
                    {
                        isgst == "1" && (
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <CustomInput
                                    success={gst.length > 0 ? true : false}
                                    error={Object.keys(formError).length > 0 && formError.gst ? true : false}
                                    labelText={
                                        <span>
                                            GST Number <small>(required)</small>
                                        </span>
                                    }
                                    id="gst"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: 'gst',
                                        value: gst,
                                        onChange: (event) => {
                                            setGst(event.target.value);
                                            delete formError.gst
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                                {/* <Money className={classes.inputAdornmentIcon} /> */}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </GridItem>
                        )
                    }
                    {/* Document Type */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className="input-margin">
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="doc_type"
                                    className={classes.selectLabel}
                                >
                                    <span id="doc_typeLable">
                                        Document Type <small>(required)</small>
                                    </span>
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu,
                                    }}
                                    classes={{
                                        select: classes.select,
                                    }}
                                    value={doc_type}
                                    onChange={(event) => {
                                        setDoc_type(event.target.value);
                                        doc_handle(event);
                                    }}
                                    inputProps={{
                                        name: "doc_type",
                                        id: "doc_type",
                                    }}
                                >
                                    <MenuItem
                                        disabled
                                        classes={{
                                            root: classes.selectMenuItem,
                                        }}
                                    >
                                        Document Type
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected,
                                        }}
                                        value="invoice"
                                    >Invoice
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected,
                                        }}
                                        value="performa-invoice"
                                    >Performa Invoice
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected,
                                        }}
                                        value="advance-note"
                                    >Advance Note
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </GridItem>
                    {/* Reference No*/}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <CustomInput
                            success={reference_no.length > 0 ? true : false}
                            error={Object.keys(formError).length > 0 && formError.reference_no ? true : false}
                            labelText={
                                <span>
                                    Reference No <small>(required)</small>
                                </span>
                            }
                            id="reference_no"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                name: 'reference_no',
                                value: reference_no,
                                onChange: (event) => {
                                    setReference_no(event.target.value);
                                    delete formError.reference_no
                                },
                                endAdornment: (
                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                        {/* <LocalOffer className={classes.inputAdornmentIcon} /> */}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </GridItem>
                    {/* payment_doc*/}
                    {/* <GridItem xs={12} sm={12} md={12} lg={12}>    
                        <CustomInput className="custom-input"
                            success={payment_doc.length > 0 ? true : false}
                            error={Object.keys(formError).length > 0 && formError.payment_doc ? true : false}
                            labelText={
                                <span style={{ marginLeft: "200px" }} >
                                    Payment Document
                                </span>
                            }
                            id="payment_doc"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                name: 'payment_doc',
                                value: payment_doc,
                                type: "file",
                                onChange: (event) => {
                                    setPayment_doc(event.target.value);
                                },
                                endAdornment: (
                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                        <FileCopy className={classes.inputAdornmentIcon} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <small>(Ex: PDF) Max. 5MB</small>
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className='custom-div'>
                            <label className="custom-file-upload" id="custom-file-upload">
                                <input type="file" onChange={(e) => { setpaymentDoc(e.target.files[0]); fileHandle(e); }} name="payment_doc" accept=".pdf" />
                                <span className='attach'>Upload your Payment Doc. (Max File Size : 5mb)</span> <FileCopy className={classes.inputAdornmentIcon} />
                                <div className="file-preview">{fileName}</div>

                            </label>

                            <span className='custom-error' style={{ fontWeight: "500" }}>{(fileErrorMsg != "") ? fileErrorMsg : ""}</span>

                        </div>
                    </GridItem>

                    {/* bank */}
                    <h3 style={{ margin: "20px 0px 6px 0px", paddingTop: "15px", width: "100%" }}>Bank Details</h3>
                    {/* Name of Account Holder */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <CustomInput
                            success={account_nm.length > 0 ? true : false}
                            error={Object.keys(formError).length > 0 && formError.account_nm ? true : false}
                            labelText={
                                <span>
                                    Full Name <small>(required)</small>
                                </span>
                            }
                            id="account_nm"
                            
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                name: 'account_nm',
                                value: account_nm,
                                
                                onChange: (event) => {
                                    setAccount_nm(event.target.value);
                                    delete formError.account_nm
                                },
                                endAdornment: (
                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                        {/* <AccountBox className={classes.inputAdornmentIcon} /> */}
                                    </InputAdornment>
                                ),
                            }}
                           
                        />
                    </GridItem>
                    {/* Account Number */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <CustomInput
                            success={account_no.length > 0 ? true : false}
                            error={Object.keys(formError).length > 0 && formError.account_no ? true : false}
                            labelText={
                                <span>
                                    Account Number <small>(required)</small>
                                </span>
                            }
                            id="account_no"
                           
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                name: 'account_no',
                                value: account_no,
                                type:'number',
                                
                                onChange: (event) => {
                                    setAccount_no(event.target.value);
                                    delete formError.account_no
                                },
                                endAdornment: (
                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                        {/* <AccountBalance className={classes.inputAdornmentIcon} /> */}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </GridItem>
                    {/* IFSC Code */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <CustomInput
                            success={ifsc.length > 0 ? true : false}
                            error={Object.keys(formError).length > 0 && formError.ifsc ? true : false}
                            labelText={
                                <span>
                                    IFSC Code <small>(required)</small>
                                </span>
                            }
                            id="ifsc"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                name: 'ifsc',
                                value: ifsc,
                                onChange: (event) => {
                                    setIfsc(event.target.value);
                                    delete formError.ifsc
                                },
                                endAdornment: (
                                    <InputAdornment position="end" className={classes.inputAdornment}>
                                        {/* <Looks3 className={classes.inputAdornmentIcon} /> */}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </GridItem>
                    {/* submit and cancle */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div>
                            <Button type="submit" round color="info">{!getLoading() ? "Submit" : "Please Wait..."}</Button>
                            <Button round color="rose" onClick={() => {
                                setClassicModal(false)
                                resolve()
                            }}>Close</Button>
                        </div>
                    </GridItem>

                </GridContainer>
            </form>

            {/* </CardBody>
            </Card> */}



        </>
    )
}


PaymentRequest.propTypes = {
    setClassicModal: PropTypes.func,
    getPayment: PropTypes.func,
    ProjectId: PropTypes.string
}
export default PaymentRequest