import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import AuthUser from "components/Sb/AuthUser";

// toast
import { toast } from 'react-toastify'

// style
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

// Radio style
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);

import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";


// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import FileCopy from "@material-ui/icons/FileCopy";



export default function ConsultantProposal(props) {
    const classes = useStyles();
    const classesRadio = useStylesRadio()
    const { ProjectId, getProvider } = props
    const { httpAxios, http, getLoading } = AuthUser()

    const [fileName, setfileName] = React.useState("")
    const [gst, setGst] = React.useState("")
    const [isgst, setIsgst] = React.useState("")
    const [amount, setAmount] = React.useState("")
    const [pan, setPan] = React.useState("")
    const [proposalDoc, setproposalDoc] = React.useState(null)


    // Error
    const [fileErrorMsg, setFileErrorMsg] = React.useState("")
    const [formError, setFormError] = React.useState({})
    let formErr = {}


    // Reset Values 
    const resolve = () => {
        setFormError({})
        setAmount("")
        setIsgst("")
        setGst("")
        setPan("")
    }

    const fileHandle = (e) => {
        var file = e.target.files;
        setFileErrorMsg("");

        if (file.length > 0) {
            setfileName(file[0].name);
        }

        var element = document.getElementById("custom-file-upload");
        if (element.classList.contains("custom-error")) {
            element.classList.remove("custom-error");
            element.classList.add("custom-success");
        }
        else {
            element.classList.add("custom-success");
        }

    }

    const isGstValidate = () => {

        var elem = document.getElementById("gstis");
        if (elem.classList.contains("custom-error")) {
            elem.classList.remove("custom-error");
            elem.classList.add("custom-success");
        }
        else {
            elem.classList.add("custom-success");
        }

    }


    // checking Form Error function
    const handleFormError = () => {

        if (amount.trim().length === 0) {
            formErr.amount = "Error in Amount"
        }

        if (isgst === "") {
            formErr.isgst = "Error in isgst"
        }

        if (isgst == 1) {
            if (gst.trim().length === 0) {
                formErr.gst = "Error in Gst"
            }
        }

        if (isgst == 0) {
            if (pan.trim().length === 0) {
                formErr.pan = "Error in pan"
            }
        }

        //upload file validation
        let element = document.getElementById("custom-file-upload");

        if (!proposalDoc) {
            formErr.proposalDoc = "Error in proposalDoc"
            element.classList.add("custom-error");
            setFileErrorMsg("Please! Select your file");
        }
        else {
            //file size validation
            // const MIN_FILE_SIZE = 1024 // 1MB
            const MAX_FILE_SIZE = 5120 // 5MB

            const fileSizeKiloBytes = proposalDoc.size / 1024

            // if (fileSizeKiloBytes < MIN_FILE_SIZE) {
            //     formErr.proposalDoc = "Error in proposalDoc"
            //     element.classList.add("custom-error");
            //     setFileErrorMsg("File size is less than minimum limit");
            // }

            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                formErr.proposalDoc = "Error in proposalDoc"
                element.classList.add("custom-error");
                setFileErrorMsg("File size is greater than maximum limit");
            }

        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        handleFormError()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)

            if (formErr.isgst) {
                let ele = document.getElementById("gstis");
                ele.classList.add("custom-error");
            }
            formErr = {}
        } else {
            const data = { amount, isgst, gst, pan, proposalDoc, ProjectId }
            console.log(data)

            httpAxios.post("/customer/proposalInsert", data)
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data
                        console.log(data)
                        toast.success('Proposal has been Submitted Successfully.', {
                            position: toast.POSITION.TOP_CENTER,
                            onClose: () => resolve(),
                            autoClose:1500
                        })
                        sentProposalMail();
                        getProvider();
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }

    }

    const sentProposalMail = () => {
        const data = { 'pid': ProjectId }
        http.post("/customer/sentProposalMail", data)
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                }
            }).catch((err) => {
                console.log(err)
            })
    }




    return (
        <>
            <form onSubmit={handleSubmit} >


                <CustomInput
                    success={amount.length > 0 ? true : false}
                    error={Object.keys(formError).length > 0 && formError.amount ? true : false}
                    labelText={
                        <span>
                            Amount without GST <small>(required)</small>
                        </span>
                    }
                    id="amount"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        name: 'amount',
                        type: 'number',
                        value: amount,
                        onChange: (event) => {
                            setAmount(event.target.value);
                            delete formError.amount
                        },

                        endAdornment: (
                            <InputAdornment position="end" className={classes.inputAdornment}>
                                {/* <AttachMoney className={classes.inputAdornmentIcon} /> */}
                            </InputAdornment>
                        ),
                    }}
                />



                <GridContainer justify="flex-start" alignItems="baseline">
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                        <label style={{ fontSize: "18px" }} id="gstis">GST Applicable?</label>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} lg={4} >
                        <div
                            className={
                                classesRadio.checkboxAndRadio +
                                " " +
                                classesRadio.checkboxAndRadioHorizontal
                            }
                        >
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={isgst === 1}
                                        onChange={() => {
                                            setIsgst(1)
                                            isGstValidate()
                                        }}
                                        name="is_gst"
                                        aria-label="Yes"
                                        icon={
                                            <FiberManualRecord
                                                className={classesRadio.radioUnchecked}
                                            />
                                        }
                                        checkedIcon={
                                            <FiberManualRecord
                                                className={classesRadio.radioChecked}
                                            />
                                        }
                                        classes={{
                                            checked: classesRadio.radio,
                                            root: classesRadio.radioRoot,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classesRadio.label,
                                    root: classesRadio.labelRoot,
                                }}
                                label="Yes"

                            />
                        </div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} lg={4} >
                        <div
                            className={
                                classesRadio.checkboxAndRadio +
                                " " +
                                classesRadio.checkboxAndRadioHorizontal
                            }
                        >
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={isgst === 0}
                                        onChange={() => {
                                            setIsgst(0)
                                            isGstValidate()
                                        }}
                                        name="is_gst"
                                        aria-label="No"
                                        icon={
                                            <FiberManualRecord
                                                className={classesRadio.radioUnchecked}
                                            />
                                        }
                                        checkedIcon={
                                            <FiberManualRecord
                                                className={classesRadio.radioChecked}
                                            />
                                        }
                                        classes={{
                                            checked: classesRadio.radio,
                                            root: classesRadio.radioRoot,
                                        }}
                                    />
                                }
                                classes={{
                                    label: classesRadio.label,
                                    root: classesRadio.labelRoot,
                                }}
                                label="No"

                            />
                        </div>
                    </GridItem>
                </GridContainer>

                {(isgst == 1 &&
                    <CustomInput
                        success={gst.length > 0 ? true : false}
                        error={Object.keys(formError).length > 0 && formError.gst ? true : false}
                        labelText={
                            <span>
                                GST Number <small>(required)</small>
                            </span>
                        }
                        id="gst"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            name: 'gst',
                            value: gst,
                            onChange: (event) => {
                                setGst(event.target.value);
                                delete formError.gst
                            },


                            endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                    {/* <AttachMoney className={classes.inputAdornmentIcon} /> */}
                                </InputAdornment>
                            ),
                        }}
                    />
                )}


                {(isgst == 0 &&
                    <CustomInput
                        success={pan.length > 0 ? true : false}
                        error={Object.keys(formError).length > 0 && formError.pan ? true : false}
                        labelText={
                            <span>
                                PAN Number <small>(required)</small>
                            </span>
                        }
                        id="pan"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            name: 'pan',
                            value: pan,
                            onChange: (event) => {
                                setPan(event.target.value);
                                delete formError.pan
                            },

                            endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                    {/* <AttachMoney className={classes.inputAdornmentIcon} /> */}
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                <div className='custom-div'>
                    <label className="custom-file-upload" id="custom-file-upload">
                        <input type="file" onChange={(e) => { setproposalDoc(e.target.files[0]); fileHandle(e); }} name="proposal_doc" accept=".pdf" />
                        <span className='attach'>Upload your quotation (Max File Size : 5mb)</span> <FileCopy className={classes.inputAdornmentIcon} />
                        <div className="file-preview">{fileName}</div>

                    </label>

                    <span className='custom-error' style={{ fontWeight: "500" }}>{(fileErrorMsg != "") ? fileErrorMsg : ""}</span>

                </div>





                <GridContainer justify="center">

                    <GridItem xs={12} sm={4} md={4}>
                        <Button style={{ width: '100%' }} type="submit" round color="info">{!getLoading() ? "Submit" : "Please Wait..."}</Button>
                    </GridItem>

                </GridContainer>


            </form>
        </>
    )
}
ConsultantProposal.propTypes = {
    ProjectId: PropTypes.string,
    getProvider: PropTypes.func
}
