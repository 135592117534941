import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Store from "@material-ui/icons/Work";
import Business from "@material-ui/icons/Business";
import CardMembership from "@material-ui/icons/CardMembership";
import FileCopy from "@material-ui/icons/FileCopy";
import PermIdentity from "@material-ui/icons/PermIdentity";
import Today from "@material-ui/icons/Today";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardBody from "components/Card/CardBody";
import { Table, TableCell, TableHead, TableRow, TableBody, } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
const useStylesTable = makeStyles(stylesTable);

import DailyData from "./DailyDataTables/DailyData";

const AdminDashBoard = () => {
    const classes = useStyles();
    const classesTable = useStylesTable()
    // industry
    const [industriesRegistered, setIndustriesRegistered] = React.useState("")
    const [industriesPlan, setIndustriesPlan] = React.useState("")
    const [industriesSales, setIndustriesSales] = React.useState("")
    // Consultants
    const [consultantsRegistered, setconsultantsRegistered] = React.useState("")
    const [consultantsPlan, setconsultantsPlan] = React.useState("")
    const [consultantsSales, setconsultantsSales] = React.useState("")
    // Memebership
    const [membershipRegistered, setMembershipRegistered] = React.useState("")
    const [membershipPlan, setMembershipPlan] = React.useState("")
    const [membershipSales, setMembershipSales] = React.useState("")
    // Projects
    const [projectsPosted, setProjectsPosted] = React.useState("")
    const [projectsExecution, setProjectsExecution] = React.useState("")
    const [projectsSales, setProjectsSales] = React.useState("")
    // Today
    const [todayRegistered, setTodayRegistered] = React.useState("")
    const [todayPlan, setTodayPlan] = React.useState("")
    const [todayMembership, setTodayMembership] = React.useState("")
    const [todayProjects, setTodayProjects] = React.useState("")
    // Yesterday
    const [yesterdayRegistered, setYesterdayRegistered] = React.useState("")
    const [yesterdayPlan, setYesterdayPlan] = React.useState("")
    const [yesterdayMembership, setYesterdayMembership] = React.useState("")
    const [yesterdayProjects, setYesterdayProjects] = React.useState("")
    // This Month
    const [thisMonthRegistered, setThisMonthRegistered] = React.useState("")
    const [thisMonthPlan, setThisMonthPlan] = React.useState("")
    const [thisMonthMembership, setThisMonthMembership] = React.useState("")
    const [thisMonthProjects, setThisMonthProjects] = React.useState("")
    // Last Month
    const [lastMonthRegistered, setLastMonthRegistered] = React.useState("")
    const [lastMonthPlan, setLastMonthPlan] = React.useState("")
    const [lastMonthMembership, setLastMonthMembership] = React.useState("")
    const [lastMonthProjects, setLastMonthProjects] = React.useState("")
    // Cid , Pid
    const [cid, setCid] = React.useState("")
    const [pid, setPid] = React.useState("")
    // Project Table
    const [normalActive, setnormalActive] = React.useState(0)
    const [executionActive, setexecutionActive] = React.useState(0)
    const [normalAwarded, setnormalAwarded] = React.useState(0)
    // FormError
    const [formError, setFormError] = React.useState({})
    let formErr = {}

    const MembershipTable = [
        {
            name: "Vineet",
            NosinSep: 3,
            SepinRs: 30000,
            AuginRs: 20000,
            JulinRs: 34000
        },
        {
            name: "Vineet 2",
            NosinSep: 4,
            SepinRs: 20000,
            AuginRs: 10000,
            JulinRs: 54000
        },
        {
            name: "Vineet 3",
            NosinSep: 3,
            SepinRs: 33000,
            AuginRs: 34000,
            JulinRs: 34000
        },
    ]

    const projectTable = [
        {
            name: "Vineet",
            AwardedinSep: 3,
            SepinRs: 30000,
            AuginRs: 20000,
            JulinRs: 34000
        },
        {
            name: "Vineet 2",
            AwardedinSep: 4,
            SepinRs: 20000,
            AuginRs: 10000,
            JulinRs: 54000
        },
        {
            name: "Vineet 3",
            AwardedinSep: 3,
            SepinRs: 33000,
            AuginRs: 34000,
            JulinRs: 34000
        },
    ]

    const leadChannelTotal = [
        {
            channel: "Organic",
            registered: "33000",
            planTaken: "Basic",
            salesinRs: 34000
        },
        {
            channel: "Paid",
            registered: "33000",
            planTaken: "Basic",
            salesinRs: 34000
        }
    ]

    const NosinSepTotal = () => {
        let result = 0
        for (let i = 0; i < MembershipTable.length; i++) {
            result += MembershipTable[i].NosinSep
        }
        return result
    }
    const AuginRsTotal = () => {
        let result = 0
        for (let i = 0; i < MembershipTable.length; i++) {
            result += MembershipTable[i].AuginRs
        }
        return result
    }
    const SepinRsTotal = () => {
        let result = 0
        for (let i = 0; i < MembershipTable.length; i++) {
            result += MembershipTable[i].SepinRs
        }
        return result
    }
    const JulinRsTotal = () => {
        let result = 0
        for (let i = 0; i < MembershipTable.length; i++) {
            result += MembershipTable[i].JulinRs
        }
        return result
    }

    const projectAwardedinSepTotal = () => {
        let result = 0
        for (let i = 0; i < projectTable.length; i++) {
            result += projectTable[i].AwardedinSep
        }
        return result
    }
    const projectAuginRsTotal = () => {
        let result = 0
        for (let i = 0; i < projectTable.length; i++) {
            result += projectTable[i].AuginRs
        }
        return result
    }
    const projectSepinRsTotal = () => {
        let result = 0
        for (let i = 0; i < projectTable.length; i++) {
            result += projectTable[i].SepinRs
        }
        return result
    }
    const projectJulinRsTotal = () => {
        let result = 0
        for (let i = 0; i < projectTable.length; i++) {
            result += projectTable[i].JulinRs
        }
        return result
    }



    React.useEffect(() => {
        setIndustriesRegistered("39311")
        setIndustriesPlan("1272")
        setIndustriesSales("5.8Cr")

        setconsultantsRegistered("39314")
        setconsultantsPlan("12432")
        setconsultantsSales("4.8Cr")

        setMembershipRegistered("39311")
        setMembershipPlan("1272")
        setMembershipSales("5.8Cr")

        setProjectsPosted("39311")
        setProjectsExecution("39311")
        setProjectsSales("5.8Cr")

        setTodayRegistered("39311")
        setTodayPlan("39311")
        setTodayMembership("3.9Cr")
        setTodayProjects("3.0Cr")

        setYesterdayRegistered("39311")
        setYesterdayPlan("39311")
        setYesterdayMembership("9Cr")
        setYesterdayProjects("9Cr")

        setThisMonthRegistered("39311")
        setThisMonthPlan("39311")
        setThisMonthMembership("1.0Cr")
        setThisMonthProjects("1.0Cr")

        setLastMonthRegistered("39311")
        setLastMonthPlan("39311")
        setLastMonthMembership("1.0Cr")
        setLastMonthProjects("1.0Cr")

        setnormalActive(120)
        setnormalAwarded(70)
        setexecutionActive(43)
    }, [])

    const handleFormErrorPID = () => {
        if (pid.trim().length === 0) {
            formErr.pid = "ERROR"
        }
    }

    const handleFormPID = (e) => {
        e.preventDefault()
        handleFormErrorPID()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)
        } else {
            const data = {
                pid
            }
            console.log(data)
            setFormError({})
            formErr = {}
            setPid("")
        }
    }

    const handleFormErrorCID = () => {
        if (cid.trim().length === 0) {
            formErr.cid = "ERROR"
        }
    }

    const handleFormCID = (e) => {
        e.preventDefault()
        handleFormErrorCID()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)
        } else {
            const data = {
                cid
            }
            console.log(data)
            setFormError({})
            formErr = {}
            setCid("")
        }
    }

    return (
        <>
            {/* Industries , Consultant , Membership , Project */}
            <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardIcon color="warning">
                                    <Business />
                                </CardIcon>
                                <h3 className={classes.cardTitle} >Industries </h3>
                            </div>
                            {/* <p className={classes.cardCategory}>Used Space</p> */}
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h5>Registered : {industriesRegistered}</h5>
                                    <h5>Plan Taken : {industriesPlan}</h5>
                                    <h5>Sales : Rs. {industriesSales}</h5>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardIcon color="success">
                                    <Store />
                                </CardIcon>
                                <h3 className={classes.cardTitle}>Consultants</h3>
                            </div>
                            {/* <p className={classes.cardCategory}>Revenue</p> */}
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h5>Registered : {consultantsRegistered}</h5>
                                    <h5>Plan Taken : {consultantsPlan}</h5>
                                    <h5>Sales : Rs. {consultantsSales}</h5>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardIcon color="danger">
                                    <CardMembership />
                                </CardIcon>
                                <h3 className={classes.cardTitle}>Membership</h3>
                            </div>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h5>Registered : {membershipRegistered}</h5>
                                    <h5>Plan Taken : {membershipPlan}</h5>
                                    <h5>Sales : Rs. {membershipSales}</h5>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardIcon color="info">
                                    <FileCopy />
                                </CardIcon>
                                <h3 className={classes.cardTitle}>Projects</h3>
                            </div>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h5>Total Posted : {projectsPosted}</h5>
                                    <h5>Under Execution : {projectsExecution}</h5>
                                    <h5>Sales : Rs. {projectsSales}</h5>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>

            {/* Today , Yesterday , This Month , Last Month */}
            <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                        <CardHeader color="danger" stats icon>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardIcon color="danger">
                                    <Today />
                                </CardIcon>
                                <h3 className={classes.cardTitle} >Today </h3>
                            </div>
                            {/* <p className={classes.cardCategory}>Used Space</p> */}
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h5>Registered : {todayRegistered}</h5>
                                    <h5>Plan Taken : {todayPlan}</h5>
                                    <h5>Membership : Rs. {todayMembership}</h5>
                                    <h5>Projects : Rs. {todayProjects}</h5>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardIcon color="primary">
                                    <Today />
                                </CardIcon>
                                <h3 className={classes.cardTitle}>Yesterday</h3>
                            </div>
                            {/* <p className={classes.cardCategory}>Revenue</p> */}
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h5>Registered : {yesterdayRegistered}</h5>
                                    <h5>Plan Taken : {yesterdayPlan}</h5>
                                    <h5>Membership : Rs. {yesterdayMembership}</h5>
                                    <h5>Projects : Rs. {yesterdayProjects}</h5>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardIcon color="warning">
                                    <Today />
                                </CardIcon>
                                <h3 className={classes.cardTitle}>This Month</h3>
                            </div>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h5>Registered : {thisMonthRegistered}</h5>
                                    <h5>Plan Taken : {thisMonthPlan}</h5>
                                    <h5>Membership : Rs. {thisMonthMembership}</h5>
                                    <h5>Projects : Rs. {thisMonthProjects}</h5>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CardIcon color="success">
                                    <Today />
                                </CardIcon>
                                <h3 className={classes.cardTitle}>Last Month</h3>
                            </div>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <h5>Registered : {lastMonthRegistered}</h5>
                                    <h5>Plan Taken : {lastMonthPlan}</h5>
                                    <h5>Membership : Rs. {lastMonthMembership}</h5>
                                    <h5>Projects : Rs. {lastMonthProjects}</h5>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>

            {/* CID , PID */}
            <GridContainer >
                <GridItem xs={12} sm={6} md={6} lg={4}>
                    <div className="cid-container">
                        <form onSubmit={handleFormCID}>
                            <Card>
                                <CardHeader color="info" icon>
                                    <CardIcon color="info">
                                        <PermIdentity />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Customer ID
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <CustomInput
                                        success={cid.length > 0 ? true : false}
                                        error={Object.keys(formError).length > 0 && formError.cid ? true : false}
                                        labelText={
                                            <span id="cidLable">
                                                Customer ID <small>(required)</small>
                                            </span>
                                        }
                                        id="cid"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            type: "text",
                                            value: cid,
                                            name: "cid",
                                            onChange: (event) => {
                                                setCid(event.target.value)
                                                setFormError({})
                                            },
                                            autoComplete: "off",
                                        }}
                                    />
                                    <Button
                                        color="rose"
                                        className={classes.registerButton}
                                        type="submit" round>Show</Button>
                                </CardBody>
                            </Card>
                        </form>
                    </div>
                </GridItem>
                {/* PID */}
                <GridItem xs={12} sm={6} md={6} lg={4}>
                    <div className="cid-pid-container">
                        <form onSubmit={handleFormPID}>
                            <Card>
                                <CardHeader color="info" icon>
                                    <CardIcon color="info">
                                        <PermIdentity />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Project ID
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <CustomInput
                                        success={pid.length > 0 ? true : false}
                                        error={Object.keys(formError).length > 0 && formError.pid ? true : false}
                                        labelText={
                                            <span id="pidLable">
                                                Project ID <small>(required)</small>
                                            </span>
                                        }
                                        id="pid"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            type: "text",
                                            value: pid,
                                            name: "pid",
                                            onChange: (event) => {
                                                setPid(event.target.value)
                                                setFormError({})
                                            },
                                            autoComplete: "off",
                                        }}
                                    />
                                    <Button
                                        color="rose"
                                        className={classes.registerButton}
                                        type="submit" round>Show</Button>
                                </CardBody>
                            </Card>
                        </form>
                    </div>
                </GridItem>
                {/* btn */}
                <GridItem xs={12} sm={6} md={6} lg={4}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <PermIdentity />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Offline Plan Activation And Invoice Creation
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Button color="rose" round>
                                Offline Plan Activation
                            </Button>
                            <Button color="rose" round>
                                Offline Invoice Creation
                            </Button>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer >

            <hr style={{ borderTop: " 1px solid black" }} />
            {/* Quarterly Sales Data */}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4>Quarterly Sales Data</h4>
                </GridItem>
                {/* MemberShip */}
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <PermIdentity />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Membership
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <div className="membershipTable">
                                <div className={classesTable.tableResponsive}>
                                    <Table className="table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Name
                                                </TableCell>
                                                <TableCell>
                                                    Nos. in Sep S
                                                </TableCell>
                                                <TableCell>
                                                    Sep in Rs.
                                                </TableCell>
                                                <TableCell>
                                                    Aug in Rs.
                                                </TableCell>
                                                <TableCell>
                                                    Jul in Rs.
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                MembershipTable.map((ele, i) => {
                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell>{ele.name}</TableCell>
                                                            <TableCell>{ele.NosinSep}</TableCell>
                                                            <TableCell>{ele.SepinRs}</TableCell>
                                                            <TableCell>{ele.AuginRs}</TableCell>
                                                            <TableCell>{ele.JulinRs}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                            <TableRow>
                                                <TableCell>Total</TableCell>
                                                <TableCell>{NosinSepTotal()}</TableCell>
                                                <TableCell>{SepinRsTotal()}</TableCell>
                                                <TableCell>{AuginRsTotal()}</TableCell>
                                                <TableCell>{JulinRsTotal()}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                {/* Projects */}
                <GridItem xs={12} sm={12} md={6}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <PermIdentity />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Projects
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <div className={classesTable.tableResponsive}>
                                <Table className="table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Awarded in Sep
                                            </TableCell>
                                            <TableCell>
                                                Sep in Rs.
                                            </TableCell>
                                            <TableCell>
                                                Aug in Rs.
                                            </TableCell>
                                            <TableCell>
                                                Jul in Rs.
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            projectTable.map((ele, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell>{ele.name}</TableCell>
                                                        <TableCell>{ele.AwardedinSep}</TableCell>
                                                        <TableCell>{ele.SepinRs}</TableCell>
                                                        <TableCell>{ele.AuginRs}</TableCell>
                                                        <TableCell>{ele.JulinRs}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                        <TableRow>
                                            <TableCell>Total</TableCell>
                                            <TableCell>{projectAwardedinSepTotal()}</TableCell>
                                            <TableCell>{projectSepinRsTotal()}</TableCell>
                                            <TableCell>{projectAuginRsTotal()}</TableCell>
                                            <TableCell>{projectJulinRsTotal()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <hr style={{ borderTop: " 1px solid black" }} />
            {/* Monthly Data */}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4>Monthly Data</h4>
                </GridItem>
                {/* Lead Channel */}
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <PermIdentity />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Lead Channel
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <div className={classesTable.tableResponsive}>
                                <Table className="table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Channel
                                            </TableCell>
                                            <TableCell>
                                                Registered
                                            </TableCell>
                                            <TableCell>
                                                Plan Taken
                                            </TableCell>
                                            <TableCell>
                                                Sales in Rs
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            leadChannelTotal.map((ele, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell>{ele.channel}</TableCell>
                                                        <TableCell>{ele.registered}</TableCell>
                                                        <TableCell>{ele.planTaken}</TableCell>
                                                        <TableCell>{ele.salesinRs}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                {/* Lead Relevancy */}
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <PermIdentity />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Lead Relevancy
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <div className={classesTable.tableResponsive}>
                                <Table className="table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Channel
                                            </TableCell>
                                            <TableCell>
                                                Registered
                                            </TableCell>
                                            <TableCell>
                                                Plan Taken
                                            </TableCell>
                                            <TableCell>
                                                Sales in Rs
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            leadChannelTotal.map((ele, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell>{ele.channel}</TableCell>
                                                        <TableCell>{ele.registered}</TableCell>
                                                        <TableCell>{ele.planTaken}</TableCell>
                                                        <TableCell>{ele.salesinRs}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
                {/* Projects */}
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="info" icon>
                            <CardIcon color="info">
                                <PermIdentity />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Projects
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <div className={classesTable.tableResponsive}>
                                <Table className="table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Type
                                            </TableCell>
                                            <TableCell>
                                                Nos
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Normal Active</TableCell>
                                            <TableCell>{normalActive}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Normal Awarded</TableCell>
                                            <TableCell>{normalAwarded}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Execution Active</TableCell>
                                            <TableCell>{executionActive}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            {/* Daily Data */}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <DailyData />
                </GridItem>
            </GridContainer>
        </>
    );
}

export default AdminDashBoard