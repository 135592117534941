import React from "react";
import { useParams } from "react-router-dom"
import AuthUser from 'components/Sb/AuthUser'
import axios from 'axios'
import { toast } from 'react-toastify'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";

const useStyles = makeStyles(styles);

export default function PricingPage() {
  const classes = useStyles();
  const { setToken,logout } = AuthUser()
  let {token} = useParams();
  const [count, setCount] = React.useState(1)
  
  const errorMsg = (msg) => {
    toast.error(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
      //onClose: () => logout(),
    })
  }

  const warningMsg = (msg) => {
    toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    })
  }

  React.useEffect(() => {
    setTimeout(() => {
      const http = axios.create({
        baseURL: 'https://api.solutionbuggy.com',
        // baseURL: 'http://127.0.0.1:8000',
        headers: {
          'Content-type': 'application/json',
          withCredentials: true,
          Authorization: `Bearer ${token}`,
        },
      })

       //This allows you to intercept the response and check the status and error messages and if ncessary reject the promise.
  http.interceptors.response.use((response) => {
    // setLoading(false)
    return response
  }, (error) => {

    // setLoading(false)

    if (error.response.status == 400) {
      //validation Errors
      warningMsg(error.response.data.message)
    }
    else if (error.response.status == 401) {
      //Token is Invalid
      logout()
    }
    else if (error.response.status == 403) {
      //Token is Expired
      logout()
    }
    else if (error.response.status == 500) {
      //Authorization Token not found
      logout()
    }
    else if (error.response.status == 502) {
      //coding & functional error
      warningMsg(error.response.data.message)
    }
    else if(error.response.status == 413){
      warningMsg(error.response.statusText)
    }
    else {
      //all other error
      errorMsg(error.response.data.message)
    }
    return Promise.reject(error);
  })
    
      http.get("/getAuthUser")
          .then((response) => {
              if (response.status === 200) {
                let data = response.data
                setToken(data.user,data.role,token)
              }
          }).catch((err) => {
              console.log(err)
          })
    },2000)

  },[])

  React.useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        setCount(count - 1)
      }, 1000)
    }
  }, [count])


  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <h2 className={classes.title}>Thank you for Registering to our Platform</h2>
          {
            count == 0 ? "" : (
              <>
                <h5 className={classes.description}>
                  You will be Redirect to your SolutionBuggy Dashboard within {count}
                </h5>
              </>
            )
          }
        </GridItem>
      </GridContainer>
    </div>
  );
}