import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import {
  cardTitle,
  roseColor,
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#FFFFFF",
    background: "#4caf50",
    marginTop: "4px",
    textTransform: "none",
    padding: "10px",
  },
  card: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    marginTop: "15px",
    borderRadius: "6px",
    marginBottom: "10px",
    flexDirection: "column",
    // padding: "10px",
  },
  cardBody: {
    height: "245px",
    overflowY: "auto",
    padding: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "10px",
  },
  testimonialIcon: {
    marginTop: "10px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function ProjectsPostedCustomerSP({ companyALLDetails }) {
  const classes = useStyles();
  const [projectStatus, setProjectStatus] = React.useState([]);
  const [invoice, setinvoice] = React.useState({});
  const [projectReferals, setProjectReferals] = React.useState({});

  React.useEffect(() => {
    setProjectStatus([
      {
        title: "Forwarded Projects(Not applied yet) ",
        points: companyALLDetails.project_recent,
      },
      {
        title: "Applied Projects(Normal & Execution)",
        points: companyALLDetails.project_applied,
      },
      {
        title: "Awarded Projects (Normal & Execution)",
        points: companyALLDetails.project_awarded,
      },
      {
        title: "Execution Projects Applied",
        points: companyALLDetails.project_execution_applied,
      },
      {
        title: "Not Shortlisted Projects",
        points: companyALLDetails.project_not_shortlisted,
      },
      {
        title: "Shortlisted Projects",
        points: companyALLDetails.project_shortlisted,
      },
      {
        title: "Not Relevant Projects ",
        points: companyALLDetails.project_nr,
      },
    ]);
    setProjectReferals({
      title: "Project Referals",
      points: companyALLDetails.project_referal,
    });
    setinvoice({
      title: "Invoice",
      points: companyALLDetails.invoice,
    });
  }, [companyALLDetails]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} lg={12}>
          {projectStatus.map((project, p) => {
            return (
              <div className={classes.card} key={p}>
                <h6 className={classes.cardCategory}>
                  {project.title} -{" "}
                  <span>{project.points ? project.points.length : "0"}</span>
                </h6>
                <div className={classes.cardBody}>
                  {project.points.length > 0 &&
                    project.points.map((point, p) => {
                      return (
                        <div key={p}>
                          <h5 className={classes.cardTitle}>
                            {" "}
                            {point.id}
                            {point.title && <> : {point.title}</>}
                            {
                              (point.execution == 2 ? (
                                <span
                                  style={{
                                    padding: "2px 6px",
                                    background: "#000",
                                    color: "#fff",
                                    borderRadius: "50px",
                                    fontSize: "14px",
                                    margin: "0px 5px",
                                  }}
                                >
                                  E
                                </span>
                              ) : (
                                <span
                                  style={{
                                    margin: "0px 5px",
                                    fontSize: "14px",
                                    padding: "2px 6px",
                                    background: "#000",
                                    color: "#fff",
                                    borderRadius: "50px",
                                  }}
                                >
                                  N
                                </span>
                              ))}
                          </h5>
                          <hr style={{ margin: "10px 0px" }} />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
          {Object.keys(projectReferals).length > 0 && (
            <div className={classes.card}>
              <h6 className={classes.cardCategory}>
                {projectReferals.title} - {projectReferals.points.length}
              </h6>
              <div className={classes.cardBody}>
                {projectReferals.points.length > 0 &&
                  projectReferals.points.map((point, p) => {
                    return (
                      <div key={p}>
                        <h5 className={classes.cardTitle}>
                          Problem ID: {point.problem_id ? point.problem_id : ""}
                        </h5>
                        <p>Name:{point.name ? point.name : ""}</p>
                        <p>Phone:{point.phone ? point.phone : ""}</p>
                        <p>Date:{point.date ? point.date : ""}</p>
                        <p>customer_id:{point.customer_id ? point.customer_id : ""}</p>
                        <hr style={{margin:"5px 0px"}}/>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {/* projectReferals */}
          {/* invoice */}
          {Object.keys(invoice).length > 0 && (
            <div className={classes.card}>
              <h6 className={classes.cardCategory}>
                {invoice.title} - {invoice.points.length}
              </h6>
              <div className={classes.cardBody}>
                {invoice.points.length > 0 &&
                  invoice.points.map((point, p) => {
                    return (
                      <h5 key={p} className={classes.cardTitle}>
                        Invoice ID: {point.id}
                      </h5>
                    );
                  })}
              </div>
            </div>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
ProjectsPostedCustomerSP.propTypes = {
  companyALLDetails: PropTypes.object,
};
