import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";
import "react-datetime/css/react-datetime.css";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);
// icons
import { Business, FiberManualRecord } from "@material-ui/icons";

import { InputAdornment } from "@material-ui/core";

import CustomInput from "components/CustomInput/CustomInput";

// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);

const IndustryAdvanceFormPD = (props) => {
  const { setindustryAdvanceModal, industryAdvanceModal } = props;
  const classes = useStyles();
  const classesRadio = useStylesRadio();
  //   const params = useParams();
  //   const history = useHistory();

  //   getUser
  // const { http } = AuthUser();

  const [pid, setpid] = React.useState("");
  const [amount_wo_gst, setamount_wo_gst] = React.useState("");
  const [amount, setamount] = React.useState("");
  const [tds, settds] = React.useState("");
  const [reference_no, setreference_no] = React.useState("");
  const [indusryFile, setindusryFile] = React.useState("");

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  React.useEffect(() => {
    if (!industryAdvanceModal) {
      setpid("");
      setamount_wo_gst("");
      setamount("");
      settds("");
      setreference_no("");
      setindusryFile("");
      setFormError({});
    }
  }, [industryAdvanceModal]);

  // handle Form error function
  const handleFormError = () => {
    // problem_stage
    if (pid.toString().trim().length === 0) {
      formErr.pid = "Problem ID is required";
    }
    // amount_wo_gst
    if (amount_wo_gst.toString().trim().length === 0) {
      formErr.amount_wo_gst = "Amount Without GST is required";
    }
    // amount
    if (amount.toString().trim().length === 0) {
      formErr.amount = "Amount Received is required";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        pid,
        amount_wo_gst,
        amount,
        tds,
        reference_no,
        indusryFile,
      };
      console.log(formData);
      resolve();
      //   handleFormAPI(formData);
    }
  };

  //   const handleFormAPI = (formData) => {
  //     http
  //       .post("/admin/lead_questionire", formData)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           resolve();
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(`Can't update - try later ${err.message}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 1500,
  //         });
  //       });
  //   };

  const resolve = () => {
    toast.success("Successfully updated", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    setindustryAdvanceModal(false);
    setpid("");
    setamount_wo_gst("");
    setamount("");
    settds("");
    setreference_no("");
    setindusryFile("");
    setFormError({});
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* pid */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={pid.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.pid
                  ? true
                  : false
              }
              labelText={<span>Problem ID*</span>}
              id="pid"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "pid",
                type: "number",
                value: pid,
                onChange: (event) => {
                  setpid(event.target.value);
                  delete formError.pid;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.pid && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.pid}
              </p>
            )}
          </GridItem>
          {/* amount_wo_gst */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={amount_wo_gst.length > 0 ? true : false}
              labelText={<span>Amount Without GST*</span>}
              id="amount_wo_gst"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "amount_wo_gst",
                type: "number",
                value: amount_wo_gst,
                onChange: (event) => {
                  setamount_wo_gst(event.target.value);
                  delete formError.amount_wo_gst;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.amount_wo_gst && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.amount_wo_gst}
              </p>
            )}
          </GridItem>
          {/* amount */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={amount.length > 0 ? true : false}
              labelText={<span>Amount Received*</span>}
              id="amount"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "amount",
                type: "number",
                value: amount,
                onChange: (event) => {
                  setamount(event.target.value);
                  delete formError.amount;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.amount && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.amount}
              </p>
            )}
          </GridItem>
          {/* tds */}
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer alignItems="baseline">
              <GridItem xs={12} sm={4}>
                <p style={{ fontWeight: "bold" }}>TDS</p>
              </GridItem>
              <GridItem xs={6} sm={4}>
                <div
                  className={
                    classesRadio.checkboxAndRadio +
                    " " +
                    classesRadio.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={tds === true}
                        onChange={() => {
                          settds(true);
                        }}
                        name="tds"
                        aria-label="New Industry"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radio,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    label="Yes"
                  />
                </div>
              </GridItem>
              <GridItem xs={6} sm={4}>
                <div
                  className={
                    classesRadio.checkboxAndRadio +
                    " " +
                    classesRadio.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={tds === false}
                        onChange={() => {
                          settds(false);
                        }}
                        name="tds"
                        aria-label="tds"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radio,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    label="No"
                  />
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
          {/* reference_no */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={reference_no.length > 0 ? true : false}
              labelText={<span>Reference No*</span>}
              id="reference_no"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "reference_no",
                type: "number",
                value: reference_no,
                onChange: (event) => {
                  setreference_no(event.target.value);
                  delete formError.reference_no;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.reference_no && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.reference_no}
              </p>
            )}
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <input
              type="file"
              name="indusry-file"
              // value={indusryFile}
              onChange={(event) => {
                setindusryFile(event.target.files[0]);
              }}
              style={{ display: "block" }}
            />
          </GridItem>
          {/* save */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                save
              </Button>
            </div>
          </GridItem>
          {/* cancle */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => setindustryAdvanceModal(false)}
              >
                cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

IndustryAdvanceFormPD.propTypes = {
  industryAdvanceModal: PropTypes.bool,
  setindustryAdvanceModal: PropTypes.func,
};

export default IndustryAdvanceFormPD;
