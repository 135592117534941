import React from "react";
// react plugin for creating charts
// react plugin for creating vector maps
import { toast } from 'react-toastify'
import AuthUser from "components/Sb/AuthUser";
import { useHistory } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Tooltip from "@material-ui/core/Tooltip";
// import Icon from "@material-ui/core/Icon";


// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import Store from "@material-ui/icons/Store";
import CardMembership from "@material-ui/icons/CardMembership";
import CalendarToday from "@material-ui/icons/Today";
import FileCopy from "@material-ui/icons/FileCopy";
import  InsertChart  from "@material-ui/icons/InsertChart";
import AssignmentLate from "@material-ui/icons/AssignmentLate";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem"

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import BrowseProject from "./Common/BrowseProject";
import RecentActivities from "./Common/RecentActivities"; 

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const history = useHistory()
  const { http } = AuthUser()

  const [planeName, setplaneName] = React.useState("")
  const [planExpiry, setplanExpiry] = React.useState("")
  const [postCredit, setpostCredit] = React.useState("")
  const [completdcount, setCompletdcount] = React.useState("")

  const [industriesList, setindustriesList] = React.useState([])
  const [sub_catList, setsub_catList] = React.useState([])

  const [formError, setFormError] = React.useState({})

  const [industries, setindustries] = React.useState("")
  const [sub_cat, setsub_cat] = React.useState("")

  const [projects, setProjects] = React.useState([])
  const [browserProject, setBrowserProject] = React.useState(false)

  const [activities, setActivities] = React.useState([])
  const [checkActivity, setcheckActivity] = React.useState(false)
  const [planStatus, setPlanStatus] = React.useState(false)
   

  // let formErr = {}

  const customAnimation =
  {
    marginTop: "8vh",
    background: "rgba(0,0,0,.3)",
    // position: "relative",
    // zIndex: 1,

    opacity: !browserProject ? "0" : "1",
    transition: "all 0.5s ease",
    visibility: !browserProject ? "hidden" : "visible",

  }




  //getting box data
  React.useEffect(() => {
    http.get("/customer/getIndustryDashboard")
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          setplaneName(data.current_plan);
          setplanExpiry(data.expiry);
          setpostCredit(data.post_credit);
          setCompletdcount(data.completed_count);
        }
      })
      .catch((err) => {
        console.log(err)

      })
  }, [])

  React.useEffect(() => {
    http.get('/customer/ViewPlan')
      .then((res) => {
        if (res.status == 200) {
          if (Object.keys(res.data).length == 0) {
            history.push("/customer/register")
          }
          else if(Object.keys(res.data).length > 0){
              setPlanStatus(true)    
          }
          console.log(res.data, "plan")
        }
      }).catch((err) => {
        console.log(err)
      })
  }, []);


  // getting industry lists
  React.useEffect(() => {
    http.get("/industryList")
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          setindustriesList(data)
        }
      })
      .catch((err) => {
        console.log(err)

      })
  }, [])

  // getting category lists
  React.useEffect(() => {
    http.get("/categoryList")
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          setsub_catList(data)
        }
      })
      .catch((err) => {
        console.log(err)

      })
  }, [])

  // handle Form error function
  // const handleFormError = () => {
  //     let industryLable = document.getElementById("industryLable")

  //     let sub_catLable = document.getElementById("sub_catLable")

  //     if (industries.length === 0) {
  //         formErr.industries = "Industry need to select"
  //         industryLable.classList.add("errorMsg")
  //     } else {
  //         if (industryLable.classList.contains("errorMsg")) {
  //             industryLable.classList.remove("errorMsg")
  //             industryLable.classList.add("successMsg")
  //         }
  //     }

  //     if (sub_cat.length === 0) {
  //         formErr.sub_cat = "sub_cat need to select"
  //         sub_catLable.classList.add("errorMsg")
  //     } else {
  //         if (sub_catLable.classList.contains("errorMsg")) {
  //             sub_catLable.classList.remove("errorMsg")
  //             sub_catLable.classList.add("successMsg")
  //         }
  //     }

  // }

  // Form Submitting 
  const handleSubmit = (event) => {
    event.preventDefault()

    const resolve = () => {
      setindustries("")
      setsub_cat("")
    }

    if (industries.toString().trim().length > 0 && sub_cat.toString().trim().length > 0) {
      const data = {
        industries, sub_cat,
      }
      http.post("/customer/browseSs", data)
        .then((response) => {
          resolve()
          if (response.data.length > 0) {
            setProjects(response.data)
            setBrowserProject(true)
          } else {
            setProjects([])
            setBrowserProject(false)
            setFormError({ error404: 'Oops No Records found!' })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      if (industries.toString().trim().length > 0) {
        http.post("/customer/industryBrowseSs", { industries })
          .then((response) => {
            resolve()
            if (response.data.length > 0) {
              setProjects(response.data)
              setBrowserProject(true)
            } else {
              setProjects([])
              setBrowserProject(false)
              setFormError({ error404: 'Oops No Records found!' })
            }
          })
          .catch((err) => {
            toast.error(`ERROR in Serer ${err}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000
            })
          })

      }
      if (sub_cat.toString().trim().length > 0) {
        http.post("/customer/categoryBrowseSs", { sub_cat })
          .then((response) => {
            resolve()
            if (response.data.length > 0) {
              setProjects(response.data)
              setBrowserProject(true)
            } else {
              setProjects([])
              setBrowserProject(false)
              setFormError({ error404: 'Oops No Records found!' })
            }
          })
          .catch((err) => {
            toast.error(`ERROR in Serer ${err}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000
            })
          })
      }
    }

    if (industries.length === 0 && sub_cat.length === 0) {
      setFormError({ industries: "Please Select the Industry", sub_cat: "Please Select the Sub Cataegory" })
    }
  }

  //    //getting recent project activities SS

     React.useEffect(() => { 
      http.get("/customer/RecentActivitiesSs")
          .then((response) => {
              const data = response.data
              if (response.status === 200) {
                  setActivities(data)
                  setcheckActivity(true) 
              }
          })
          .catch((err) => {
              console.log(err)

          })
  }, [])


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                  <CardMembership />
              </CardIcon>
              <p className={classes.cardCategory}>Current Plan</p>
              <h3 className={classes.cardTitle}>
                {planeName}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
              <CalendarToday />
              </CardIcon>
              <p className={classes.cardCategory}>Valid Upto</p>
              <h3 className={classes.cardTitle}>{planExpiry}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
              <FileCopy />
              </CardIcon>
              <p className={classes.cardCategory}>Post Credit</p>
              <h3 className={classes.cardTitle}>{postCredit}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
              <AssignmentLate />
              </CardIcon>
              <p className={classes.cardCategory}>Completed Project</p>
              <h3 className={classes.cardTitle}>{completdcount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      {/* Browse Latest Projects */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} >
          <Card>
            <CardHeader color="info">
              <h3 ><InsertChart /> Browse Your Projects</h3>
              <small>Select Industry or Category below to view your list of projects</small>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  {/* Industry */}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div className="input-margin">
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="industries"
                          className={classes.selectLabel}
                        >
                          <span id="industryLable">Browse by Industry <small>(required)</small></span>
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={industries}
                          onChange={(event) => {
                            setindustries(event.target.value)
                            setFormError({})
                          }}
                          inputProps={{
                            name: "industries",
                            id: "industries",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            Choose industry
                          </MenuItem>
                          {
                            industriesList.map((industry) => {
                              return <MenuItem
                                key={industry.id}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={industry.id}
                              >
                                {industry.name}
                              </MenuItem>
                            })
                          }

                        </Select>
                      </FormControl>
                      {
                        ((Object.keys(formError).length > 0 && formError.industries) && <small style={{ color: "red" }}>{formError.industries}</small>)
                      }
                    </div>
                  </GridItem>
                  {/* Skills */}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div className="input-margin">
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="sub_cat"
                          className={classes.selectLabel}
                        >
                          <span id="sub_catLable">Browse by Category <small>(required)</small></span>
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={sub_cat}
                          onChange={(event) => {
                            setsub_cat(event.target.value)
                            setFormError({})
                          }}
                          inputProps={{
                            name: "sub_cat",
                            id: "sub_cat",

                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            Choose Sub Category
                          </MenuItem>
                          {
                            sub_catList.map((industry) => {
                              return <MenuItem
                                key={industry.id}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={industry.id}
                              >
                                {industry.name}
                              </MenuItem>
                            })
                          }
                        </Select>
                      </FormControl>
                      {
                        ((Object.keys(formError).length > 0 && formError.sub_cat) && <small style={{ color: "red" }}>{formError.sub_cat}</small>)


                      }
                    </div>
                  </GridItem>
                  {/* submit btn */}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Button type="submit" round color="info">Submit</Button>
                  </GridItem>
                </GridContainer>
              </form>
              {
                ((Object.keys(formError).length > 0 && formError.error404) &&
                  (<div style={{ textAlign: "center", fontSize: "13px" }}><small style={{ color: "red" }}>{formError.error404}</small></div>)

                )
              }
              <div style={customAnimation}>
                {
                  browserProject ? (<BrowseProject projects={projects} plan={planStatus} />) : (<></>)
                }
              </div>

            </CardBody>
          </Card>
        </GridItem>
        {
          checkActivity && 
          
            <GridItem xs={12} sm={12} md={6} >
              <Card>
                <CardHeader color="primary">
                
                  <h3 ><FileCopy /> Recent Project Status</h3>
                  {/* <small>Select Industry or Category below to view projects</small> */}
                </CardHeader>
                <CardBody>
                  <RecentActivities activities={activities} plan={planStatus}  />
                </CardBody>
              </Card>
            </GridItem>
          
        }
      </GridContainer> 
    </div>
  );
}
