import React from "react";
import PropTypes from "prop-types";

// import AuthUser from "components/Sb/AuthUser";
// Style
import "assets/css/common.css";

// @material-ui/icons
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import LocationCity from "@material-ui/icons/LocationCity";
import Business from "@material-ui/icons/Business";
import Work from "@material-ui/icons/Work";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ListTimeLinePersonalSS from "./ListTimeLinePersonalSS";
// import Loader from "views/Loader/Loader";

const PersonalTimeLineSS = ({ companyDetails,companyALLDetails }) => {
  // const { http } = AuthUser();
  const [personalInfo, setPersonalInfo] = React.useState({});
  const [stories, setstories] = React.useState([]);
  const [companyALLDetailsSS, setCompanyALLDetailsSS] = React.useState({});

  React.useEffect(() => {
    setPersonalInfo(companyDetails);
  }, [companyDetails]);

  React.useEffect(() => {
    setCompanyALLDetailsSS(companyALLDetails);
  }, [companyALLDetails]);

  React.useEffect(() => {
    if (Object.keys(personalInfo).length > 0) {
      const storiesOne = [
        {
          title: "Personal",
          data: {
            firstname: personalInfo.firstname?personalInfo.firstname:"NA",
            lastname: personalInfo.lastname?personalInfo.lastname:"NA",
            email: personalInfo.email?personalInfo.email:"NA",
            phone: personalInfo.phone?personalInfo.phone:"NA",
            type: (companyALLDetails.customer && companyALLDetails.customer.customer_type) ? companyALLDetails.customer.customer_type:"NA"
          },
        },
        {
          title: "Company",
          data: {
            companyname: personalInfo.companyname?personalInfo.companyname:"NA",
            mycurrentposition: personalInfo.mycurrentposition?personalInfo.mycurrentposition:"NA",
            industries: companyALLDetailsSS.ind_name ? companyALLDetailsSS.ind_name : [],
            category: companyALLDetailsSS.cat_name ? companyALLDetailsSS.cat_name : [],
          },
        },
        {
          title: "Career",
          data: {
            mytotalnoexp: personalInfo.mytotalnoexp ? personalInfo.mytotalnoexp : "NA",
            brief_bio: personalInfo.brief_bio ? personalInfo.brief_bio : "NA",
            myqualificaton: personalInfo.myqualificaton ? personalInfo.myqualificaton : "NA",
            linkedin: personalInfo.linkedin ? personalInfo.linkedin : "NA",
          },
        },
        {
          title: "Other",
          data: {
            address: personalInfo.address ? personalInfo.address : "NA",
            city: personalInfo.city ? personalInfo.city : "NA",
            state: personalInfo.state ? personalInfo.state : "NA",
          },
        },
      ];

      const handleColor = (title) => {
        if (title == "Personal") {
          return "primary";
        }
        if (title == "Company") {
          return "warning";
        }
        if (title == "Career") {
          return "danger";
        }
        if (title == "Other") {
          return "info";
        }
      };

      const handleIncons = (title) => {
        if (title == "Personal") {
          return VerifiedUser;
        }
        if (title == "Company") {
          return Business;
        }
        if (title == "Career") {
          return Work;
        }
        if (title == "Other") {
          return LocationCity;
        }
      };

      const handleColorText = (title) => {
        if (title == "Personal") {
          return "#9c27b0";
        }
        if (title == "Company") {
          return "#ff9800";
        }
        if (title == "Career") {
          return "#f44336";
        }
        if (title == "Other") {
          return "#00acc1";
        }
      };

      const storiess = storiesOne.map((log) => {
        return {
          badgeColor: handleColor(log.title),
          badgeIcon: handleIncons(log.title),
          titleColor: handleColorText(log.title),
          logged: log.title,
          data: log.data,
        };
      });
      setstories(storiess);
    }
  }, [personalInfo]);

  return (
    <>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <ListTimeLinePersonalSS simple stories={stories} />
        </GridItem>
      </GridContainer>
    </>
  );
};

PersonalTimeLineSS.propTypes = {
  companyDetails: PropTypes.object,
  companyALLDetails: PropTypes.object,
};

export default PersonalTimeLineSS;
