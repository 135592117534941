/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import AuthUser from "components/Sb/AuthUser";



// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";
import Code from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function CustomerEmailUpdate({ customer_emailModal, setcustomer_emailModal, customer,handleUpdateCustomer }) {
    const classes = useStyles();
    const { http } = AuthUser();
    const [email, setEmail] = React.useState(customer.email ? customer.email : "")

    const handleEmailUpdate = (e) => {
        e.preventDefault()
        if (email.length > 0) {
            const dataRequest = {
                old_email:customer.email,
                new_email:email
            }
            http
                .post("/admin/update_email", dataRequest)
                .then((response) => {
                    const data = response.data;
                    if (response.status == 200) {
                        if (data) {
                            setcustomer_emailModal(false)
                            handleUpdateCustomer()
                            toast.success(`Successfully updated`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 3000,
                            });
                        }
                    }
                    if (response.status == 202 || (!response.status == 200)) {
                        if (data.message) {
                            toast.error(`${data.message}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2500,
                            });
                        }
                        if (!data.message) {
                            toast.error(`Something went wrong try again!`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 3000,
                            });
                        }
                        setcustomer_emailModal(false)
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    toast.error(`Error! - ${err.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1500,
                    });
                });
        }
    }

    // console.log(customer, email)


    return (
        <div>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={customer_emailModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setcustomer_emailModal(false)}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                <DialogTitle
                    id="notice-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setcustomer_emailModal(false)}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>Update Customer Email Address</h4>
                </DialogTitle>
                <DialogContent
                    id="notice-modal-slide-description"
                    className={classes.modalBody}
                >
                    <form onSubmit={handleEmailUpdate}>
                        <div className="input-margin">
                            <CustomInput
                                labelText={
                                    <span>
                                        Email Address
                                    </span>
                                }
                                id="email"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    name: 'email',
                                    value: email,
                                    type: "text",
                                    onChange: (event) => {
                                        setEmail(event.target.value);
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.inputAdornment}>
                                            <Code className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <Button
                            type="submit"
                            color="success"
                            round
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={() => setcustomer_emailModal(false)}
                            color="warning"
                            round
                        >
                            Close
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

CustomerEmailUpdate.propTypes = {
    customer_emailModal: PropTypes.bool,
    setcustomer_emailModal: PropTypes.func,
    customer: PropTypes.any,
    handleUpdateCustomer: PropTypes.func,
};
