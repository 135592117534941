/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import OfflineLeadForm from "./OfflineLeadForm";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CreateOfflineLeads({
  createOfflineLeadsModel,
  setCreateOfflineLeadsModel,
}) {
  const classes = useStyles();

  React.useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  });

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={createOfflineLeadsModel}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setCreateOfflineLeadsModel(false)}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
    >
      <DialogTitle
        id="notice-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setCreateOfflineLeadsModel(false)}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>Notice Modal</h4>
      </DialogTitle>
      <DialogContent
        id="notice-modal-slide-description"
        className={classes.modalBody}
      >
       <OfflineLeadForm setCreateOfflineLeadsModel={setCreateOfflineLeadsModel} createOfflineLeadsModel={createOfflineLeadsModel}/>
      </DialogContent>
    </Dialog>
  );
}

CreateOfflineLeads.propTypes = {
  createOfflineLeadsModel: PropTypes.bool,
  setCreateOfflineLeadsModel: PropTypes.func,
};
