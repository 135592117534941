import React from "react";
// import { toast } from "react-toastify";
// import AuthUser from "components/Sb/AuthUser";
// import { useParams } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import Loader from "views/Loader/Loader";

// import { makeStyles } from "@material-ui/core/styles";
// import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
// const useStyles = makeStyles(styles);

// @material-ui/core components
// icons
import { Business, CardMembership, Store } from "@material-ui/icons";
// core
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardFooter from "components/Card/CardFooter";

import KYCDonePopup from "./kyc/KYCDonePopup";
import CardBody from "components/Card/CardBody";
import ChangeStageModelPD from "./ChangeStage/ChangeStageModelPD";
import ProjectProposalModelPD from "./ProjectProposal/ProjectProposalModelPD";
import IndustryAdvanceModelPD from "./IndustryAdvance/IndustryAdvanceModelPD";
import ExternalConsultantPopup from "./ExternalConsultant/ExternalConsultantPopup";
import EditProjectDetailsPopup from "./EditProjectDetailsPD/EditProjectDetailsPDPopup";
import PaymentBreakdownPDPopup from "./PaymentBreakdownPD/PaymentBreakdownPDPopup";
import ProposalStagesPDModel from "./ProposalStagesPD/ProposalStagesPDModel";

const ProblemDetailsCompany = () => {
  //   const { http } = AuthUser();
  //   const params = useParams();
  //   const history = useHistory();
  // const classes = useStyles();

  const [kycDoneModal, setkycDoneModal] = React.useState(false);
  const [changeStageModal, setchangeStageModal] = React.useState(false);
  const [projectProposalModal, setprojectProposalModal] = React.useState(false);
  const [industryAdvanceModal, setindustryAdvanceModal] = React.useState(false);
  const [externalConsultantModal, setexternalConsultantModal] = React.useState(
    false
  );
  const [editProjectDetailsModal, seteditProjectDetailsModal] = React.useState(
    false
  );
  const [paymentBreakdownModal, setpaymentBreakdownModal] = React.useState(
    false
  );

  const [proposalStagesModal, setproposalStagesModal] = React.useState(false);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={3} lg={2}>
              <div className="d-flex">
                <Button color="info" round>
                  Make Potential
                </Button>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={3} lg={2}>
              <div className="d-flex">
                <Button
                  color="info"
                  round
                  onClick={() => {
                    seteditProjectDetailsModal(true);
                  }}
                >
                  Edit Project
                </Button>
              </div>
              <EditProjectDetailsPopup
                editProjectDetailsModal={editProjectDetailsModal}
                seteditProjectDetailsModal={seteditProjectDetailsModal}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3} lg={2}>
              <div className="d-flex">
                <Button
                  color="info"
                  round
                  onClick={() => {
                    setkycDoneModal(true);
                  }}
                >
                  KYC Done
                </Button>
              </div>
              <KYCDonePopup
                kycDoneModal={kycDoneModal}
                setkycDoneModal={setkycDoneModal}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3} lg={2}>
              <div className="d-flex"></div>
              <Button
                color="info"
                round
                onClick={() => setexternalConsultantModal(true)}
              >
                External Consultant
              </Button>
              <ExternalConsultantPopup
                externalConsultantModal={externalConsultantModal}
                setexternalConsultantModal={setexternalConsultantModal}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={3} lg={2}>
              <div className="d-flex">
                <Button color="info" round>
                  Files
                </Button>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={3} lg={2}>
              <div className="d-flex">
                <Button color="info" round>
                  Schedule
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <div style={{ backgroundColor: "#FFE2BD" }}>
                  <CardHeader color="warning" stats icon>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CardIcon color="warning">
                        <Business />
                      </CardIcon>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5>Current Stage :</h5>
                      <h5>Expected stage : </h5>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6} lg={6}>
                        <Button
                          color="warning"
                          round
                          onClick={() => setchangeStageModal(true)}
                        >
                          {" "}
                          Change Stage
                        </Button>
                        <ChangeStageModelPD
                          changeStageModal={changeStageModal}
                          setchangeStageModal={setchangeStageModal}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                </div>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <div style={{ backgroundColor: "#B8FFBB" }}>
                  <CardHeader color="success" stats icon>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CardIcon color="success">
                        <Store />
                      </CardIcon>
                    </div>
                    {/* <p className={classes.cardCategory}>Revenue</p> */}
                  </CardHeader>
                  <CardBody>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5>SB Proposal : </h5>
                      <h5>Expert Charges : </h5>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6} lg={6}>
                        <Button
                          color="success"
                          round
                          onClick={() => setprojectProposalModal(true)}
                        >
                          {" "}
                          Submit Proposal
                        </Button>
                        <ProjectProposalModelPD
                          projectProposalModal={projectProposalModal}
                          setprojectProposalModal={setprojectProposalModal}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} lg={6}>
                        <Button color="success" round onClick={()=>{setproposalStagesModal(true)}}>
                          {" "}
                          Proposal Stages
                        </Button>
                        <ProposalStagesPDModel
                          proposalStagesModal={proposalStagesModal}
                          setproposalStagesModal={setproposalStagesModal}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                </div>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={4}>
              <Card>
                <div style={{ backgroundColor: "#FFC4C2" }}>
                  <CardHeader color="danger" stats icon>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CardIcon color="danger">
                        <CardMembership />
                      </CardIcon>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5>Invoice : </h5>
                      <h5>Industry (Recd.) : </h5>
                      <h5>Experts (Paid) :</h5>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={5} lg={5}>
                        <Button
                          color="danger"
                          round
                          onClick={() => setindustryAdvanceModal(true)}
                        >
                          {" "}
                          Add Advance
                        </Button>
                        <IndustryAdvanceModelPD
                          industryAdvanceModal={industryAdvanceModal}
                          setindustryAdvanceModal={setindustryAdvanceModal}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={7} lg={7}>
                        <Button
                          color="danger"
                          round
                          onClick={() => {
                            setpaymentBreakdownModal(true);
                          }}
                        >
                          {" "}
                          Payment Breakdown
                        </Button>
                        <PaymentBreakdownPDPopup
                          paymentBreakdownModal={paymentBreakdownModal}
                          setpaymentBreakdownModal={setpaymentBreakdownModal}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardFooter>
                </div>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default ProblemDetailsCompany;
