import React from "react"

import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import LoggedIn from "./LoggedIn";
import ProfileUpdated from "./ProfileUpdated";
import PaymentPageVisit from "./PaymentPageVisit";
import ProjectPosted from "./ProjectPosted";
import UpcomingProjectPayments from "./UpcomingProjectPayments";



const DailyData = () => {

    return (
        <>
            <Card>
                <CardBody>
                    <NavPills
                        color="info"
                        tabs={[
                            {
                                tabButton: "Logged in Today",
                                tabContent: (
                                    <span>
                                        <LoggedIn />
                                    </span>
                                ),
                            },
                            {
                                tabButton: "Profile Updated",
                                tabContent: (
                                    <span>
                                        <ProfileUpdated />
                                    </span>
                                ),
                            },
                            {
                                tabButton: "Payment Page Visit",
                                tabContent: (
                                    <span>
                                        <PaymentPageVisit />
                                    </span>
                                ),
                            },
                            {
                                tabButton: "Project Posted",
                                tabContent: (
                                    <span>
                                        <ProjectPosted />
                                    </span>
                                ),
                            },
                            {
                                tabButton: "Upcoming Project Payments",
                                tabContent: (
                                    <span>
                                        <UpcomingProjectPayments />
                                    </span>
                                ),
                            },
                        ]}
                    />
                </CardBody>
            </Card>
        </>
    )
}

export default DailyData