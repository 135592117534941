import React from "react"
import AuthUser from 'components/Sb/AuthUser';
// import costume style
import "assets/css/register.css"

// import icons
import VerifiedUser from "@material-ui/icons/People";
import DoneAll from "@material-ui/icons/CheckCircle";

// core components 
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import OTPVerify from "../Industry/RegisterProcessor/OTPVerify";
import SubmitYourQuery from "../Industry/RegisterProcessor/SubmitYourQuery/SubmitYourQuery";
import ExploreConsultant from "../Industry/RegisterProcessor/ExploreConsultant";
import BuyMemberShipIndustry from "views/BuyMemberShip/Industry/BuyMemberShipIndustry";
import Loader from "views/Loader/Loader";

// components

const IndustryDirectFlow = () => {
    const {http} = AuthUser();

    const [mainToggle, setMainToggle] = React.useState(false)
    const [toggleOne, setToggleOne] = React.useState(false)
    const [toggleTwo, setToggleTwo] = React.useState(false)
    const [step,setStep] = React.useState(0)


    React.useState(() => {
        http.get("/customer/getOnboardingstep")
            .then((response) => {
                if(response.status==200){
                const step = response.data
                setStep(step)
                if (step == 2) {
                    setToggleOne(true)
                }
                if (step == 3) {
                    setToggleOne(true)
                    setMainToggle(true)
                }
                if (step == 4) {
                    setToggleOne(true)
                    setMainToggle(true)
                    setToggleTwo(true)
                }
            }
        }) 
        .catch((err) => {
            console.log(err)
        })
    }, [])

    const handleMainToggle = () => {
        setMainToggle(true)
        setStep(3)
    }
    const handleToggleOne = () => {
        setToggleOne(true)
        setStep(2)
    }
    const handleToggleTwo = () => {
        setToggleTwo(true)
        setStep(4)
        window.location.reload();
    }

    return (
        <>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <CardHeader color="warning" icon>
                            <CardIcon color="warning">
                                <VerifiedUser />
                            </CardIcon>
                            <h4 style={{ color: "black", fontSize: "18px", fontWeight: "500", marginTop: "18px" }}>
                                Verfication of Plans
                            </h4>
                        </CardHeader>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={11} lg={11}>
                                {
                                    !mainToggle ? (
                                        <>
                                            <div className="editTile postProjectToggler consultanEditTile">
                                                <h5 className={toggleOne ? "" : "activeTitle"}>
                                                    Verfication
                                                    {
                                                        toggleOne && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5 className={toggleOne ? "activeTitle" : ""}>
                                                    Preferences
                                                    {
                                                        mainToggle && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5>Explore Consultants</h5>
                                                <h5> Complete Onboarding </h5>

                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="editTile postProjectToggler consultanEditTile">
                                                <h5>
                                                    Verfication
                                                    {
                                                        toggleOne && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5>
                                                    Preferences
                                                    {
                                                        mainToggle && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5 className={toggleTwo ? "" : "activeTitle"}>
                                                    Explore Experts
                                                    {
                                                        toggleTwo && <DoneAll style={{ color: "5BB25F" }} />
                                                    }
                                                </h5>
                                                <h5 className={toggleTwo ? "activeTitle" : ""}>
                                                    Complete Onboarding
                                                </h5>
                                            </div>
                                        </>
                                    )
                                }
                            </GridItem>
                        </GridContainer>
                        <CardBody>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    {step == 0 && <Loader />}
                                    {step == 1 && <OTPVerify handleToggleOne={handleToggleOne} />}
                                    {step == 2 && <SubmitYourQuery handleMainToggle={handleMainToggle}/>}
                                    {step == 3 && <ExploreConsultant handleToggleTwo={handleToggleTwo} />}
                                    {step == 4 && <BuyMemberShipIndustry />}
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default IndustryDirectFlow