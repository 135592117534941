import React from "react"

// import AuthUser from "components/Sb/AuthUser";
// import axios from "axios";
// import { toast } from 'react-toastify'
// import {useHistory} from 'react-router-dom'

//  @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment"
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons;
import Style from "@material-ui/icons/Style";
import Business from "@material-ui/icons/Business";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
const useStyles = makeStyles(styles);

const ProjectTags = () => {
    const classes = useStyles();

    const [tags, setTags] = React.useState("")

    const [formError, setFormError] = React.useState("")

    const handleFormSubmitted = (e) => {
        e.preventDefault()
        if (tags.trim().length === 0) {
            const err = {
                tags: "Error"
            }
            setFormError(err)
        } else {
            const data = {
                tags
            }
            setFormError({})
            setTags("")
            console.log(data)
        }
    }
    return (
        <Card>
            <CardHeader color="info" icon>
                <CardIcon color="info">
                    <Business />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Project Tags</h4>
            </CardHeader>
            <CardBody>
                <form onSubmit={handleFormSubmitted}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={9} lg={9}>
                            <CustomInput
                                success={tags.length > 0 ? true : false}
                                error={Object.keys(formError).length > 0 && formError.tags ? true : false}
                                labelText={
                                    <span>
                                        Project Tags <small>(required)</small>
                                    </span>
                                }
                                id="tags"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    name: 'tags',
                                    value: tags,
                                    onChange: (event) => {
                                        setTags(event.target.value);
                                        delete formError.tags
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.inputAdornment}>
                                            <Style className={classes.inputAdornmentIcon} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                            <Button
                                color="rose"
                                className={classes.registerButton}
                                type="submit"
                                round
                            >
                                Add Tag
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </CardBody>
        </Card>
    )
}


export default ProjectTags