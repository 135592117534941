import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import {
  cardTitle,
  roseColor,
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#FFFFFF",
    background: "#4caf50",
    marginTop: "4px",
    textTransform: "none",
    padding: "10px",
  },
  card: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    marginTop: "15px",
    borderRadius: "6px",
    marginBottom: "10px",
    flexDirection: "column",
    // padding: "10px",
  },
  cardBody: {
    height: "245px",
    overflowY: "auto",
    padding: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "10px",
  },
  testimonialIcon: {
    marginTop: "10px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function ProjectsPostedCustomerSS({ companyALLDetails }) {
  const classes = useStyles();
  const [projectStatus, setProjectStatus] = React.useState([]);
  const [invoice, setinvoice] = React.useState({});

  React.useEffect(() => {
    setProjectStatus([
      {
        title: "Projects Not Live",
        points: companyALLDetails.project_notlive,
      },
      {
        title: "Active Projects",
        points: companyALLDetails.project_active,
      },
      {
        title: "Awarded(Normal)/Proposal Sent(Execution)",
        points: companyALLDetails.project_archive,
      },
      {
        title: "Projects on Hold",
        points: companyALLDetails.project_hold,
      },
      {
        title: "Projects Dropped",
        points: companyALLDetails.project_dropped,
      },
      {
        title: "Projects Awarded Execution",
        points: companyALLDetails.project_awardexec,
      },
    ]);
    setinvoice({
      title: "Invoice",
      points: companyALLDetails.invoice,
    });
  }, [companyALLDetails]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} lg={12}>
          {projectStatus.map((project, p) => {
            return (
              <div className={classes.card} key={p}>
                <h6 className={classes.cardCategory}>
                  {project.title} -{" "}
                  <span>{project.points ? project.points.length : "0"}</span>
                </h6>
                <div className={classes.cardBody}>
                  {project.points.length > 0 &&
                    project.points.map((point, p) => {
                      return (
                        <div key={p}>
                          <h5 className={classes.cardTitle}>
                            {point.id}
                            {point.title && <> : {point.title}</>}
                          </h5>
                          <hr />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
          {/* invoice */}
          {Object.keys(invoice).length > 0 && (
            <div className={classes.card}>
              <h6 className={classes.cardCategory}>
                {invoice.title} - {invoice.points.length}
              </h6>
              <div className={classes.cardBody}>
                {invoice.points.length > 0 &&
                  invoice.points.map((point, p) => {
                    return (
                      <h5 key={p} className={classes.cardTitle}>
                        Invoice ID: {point.id}
                      </h5>
                    );
                  })}
              </div>
            </div>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
ProjectsPostedCustomerSS.propTypes = {
  companyALLDetails: PropTypes.object,
};
