import React from "react";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Grade";
import Note from "@material-ui/icons/Note";
import CardMembership from "@material-ui/icons/CardMembership";
import Call from "@material-ui/icons/Call";
// import PermIdentity from "@material-ui/icons/PermIdentity";
// import Today from "@material-ui/icons/Today";

// core components
import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import {
//   Table,
//   TableCell,
//   TableHead,
//   TableRow,
//   TableBody,
// } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import FreshLeadsSalesDB from "./SalesData/FreshLeadsSalesDB";
import PotentialLeadsDB from "./SalesData/PotentialLeadsDB";
import QualifiedLeadsDB from "./SalesData/QualifiedLeadsDB";
import HighPriorityDB from "./SalesData/HighPriorityDB";
import PendingTasksDB from "./SalesData/PendingTasksDB";
import Loader from "views/Loader/Loader";
import UpcomingTaskDB from "./SalesData/UpcomingTaskDB";
import ActiveUsersDB from "./SalesData/ActiveUsersDB";
const useStyles = makeStyles(styles);

// import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
// const useStylesTable = makeStyles(stylesTable);

const SalesDashBoard = () => {
  const { http, getUser } = AuthUser();
  const classes = useStyles();
  // const classesTable = useStylesTable();

  const [salesData, setSalesData] = React.useState({});

  const [currentMonth, setCurrentMonth] = React.useState("");

  React.useEffect(() => {
    const month = new Date().getMonth();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    setCurrentMonth(months.at(parseInt(month)));
  }, []);

  React.useEffect(() => {
    http
      .post("/admin/sales_indu_dashboard", { id: getUser().user_id })
      .then((response) => {
        const data = response.data;
        if (Object.keys(data).length > 0) {
          setSalesData(data);
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong! ${JSON.stringify(err.message)}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  return (
    <>
      {salesData && Object.keys(salesData).length > 0 ? (
        <div>
          {/* Industries , Consultant , Membership , Project */}
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader color="warning" stats icon>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CardIcon color="warning">
                      <Chat />
                    </CardIcon>
                    <h3 className={classes.cardTitle}>
                      Sales ({currentMonth})
                    </h3>
                  </div>
                  {/* <p className={classes.cardCategory}>Used Space</p> */}
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5>
                        Target Rs :{" "}
                        {salesData.salestarget ? salesData.salestarget : "NA"}
                      </h5>
                      <h5>
                        Achived Rs :{" "}
                        {salesData.monthsalesgraph
                          ? Number(salesData.monthsalesgraph).toFixed(2)
                          : "NA"}
                      </h5>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader color="success" stats icon>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CardIcon color="success">
                      <CardMembership />
                    </CardIcon>
                    <h3 className={classes.cardTitle}>Lead ({currentMonth})</h3>
                  </div>
                  {/* <p className={classes.cardCategory}>Used Space</p> */}
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5>Total : {salesData.monthlyregistration?salesData.monthlyregistration:"NA"} </h5>
                      <h5>Potential : {salesData.potential_count?salesData.potential_count:"NA"}</h5>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader color="info" stats icon>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CardIcon color="info">
                      <Call />
                    </CardIcon>
                    <h3 className={classes.cardTitle}>Calls </h3>
                  </div>
                  {/* <p className={classes.cardCategory}>Used Space</p> */}
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5>Connected Today : {salesData.todaycalls?salesData.todaycalls:"NA"}</h5>
                      <h5>Connected ({currentMonth}) : {salesData.todaycalls?salesData.todaycalls:"NA"}</h5>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CardIcon color="danger">
                      <Note />
                    </CardIcon>
                    <h3 className={classes.cardTitle}>Tasks </h3>
                  </div>
                  {/* <p className={classes.cardCategory}>Used Space</p> */}
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5>Tasks Today : {salesData.todaytasks?salesData.todaytasks:"NA"}</h5>
                      <h5>Tasks ({currentMonth}) : {salesData.monthlytasks?salesData.monthlytasks:"NA"}</h5>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>

          <hr style={{ borderTop: " 1px solid black" }} />
          {/* Quarterly Sales Data */}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <NavPills
                    color="info"
                    tabs={[
                      {
                        tabButton: "Fresh Leads / Today Tasks",
                        tabContent: (
                          <span>
                            <FreshLeadsSalesDB noncontacted={salesData.noncontacted} todayTask={salesData.todayTask}/>
                          </span>
                        ),
                      },
                      {
                        tabButton: "Potential Leads (Last 30 days)",
                        tabContent: (
                          <span>
                            <PotentialLeadsDB potential_customer={salesData.potential_customer}/>
                          </span>
                        ),
                      },
                      {
                        tabButton: "Qualified Leads (Last 30 days)",
                        tabContent: (
                          <span>
                            <QualifiedLeadsDB qualified_customer={salesData.qualified_customer}/>
                          </span>
                        ),
                      },
                      {
                        tabButton: "High Priority (not contacted > 2 days)",
                        tabContent: (
                          <span>
                            <HighPriorityDB noncontactedhigh={salesData.noncontactedhigh}/>
                          </span>
                        ),
                      },
                      {
                        tabButton: "Pending Tasks",
                        tabContent: (
                          <span>
                            <PendingTasksDB pendingTask={salesData.pendingTask}/>
                          </span>
                        ),
                      },
                      {
                        tabButton: "Upcoming Tasks",
                        tabContent: <span>
                          <UpcomingTaskDB upcomingTask={salesData.upcomingTask}/>
                        </span>,
                      },
                      {
                        tabButton: "Active Users",
                        tabContent: <span>
                          <ActiveUsersDB userList1={salesData.userList1}/>
                        </span>,
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>

          <hr style={{ borderTop: " 1px solid black" }} />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SalesDashBoard;
