import React from "react";
import { toast } from "react-toastify";
import AuthUser from "components/Sb/AuthUser";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "views/Loader/Loader";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// icons
import { Edit } from "@material-ui/icons";
import Business from "@material-ui/icons/Business";
// core
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

import CutomerActivitesAdmin from "./CutomerActivitesAdmin/CutomerActivitesAdmin";
import PersonalTimeLineSS from "./PersonalTimeLine/PersonalTimeLineSS";
import ProjectsPostedCustomerSS from "./ProjectsPostedCustomer/ProjectsPostedCustomerSS";
import PersonalTimeLineSP from "./PersonalTimeLine/PersonalTimeLineSP";
import ProjectsPostedCustomerSP from "./ProjectsPostedCustomer/ProjectsPostedCustomerSP";
import CustomerTypeUpdate from "./UpdatingCustomer/CustomerTypeUpdate";
import CustomerEmailUpdate from "./UpdatingCustomer/CustomerEmailUpdate";

const stylesHead = {
  cardHeadFirst: {
    display: "inline-block",
    fontSize: "12px !important",
    marginTop: "0",
    textTransform: "none",
    fontWeight: "medium",
    padding: "4px 6px",
    borderRadius: "50px",
    color: "#3C4858",
    background: "#90a4ae91",
  },
  cardHeadRegister: {
    display: "inline-block",
    fontSize: "12px !important",
    marginTop: "0",
    textTransform: "none",
    fontWeight: "medium",
    padding: "4px",
    borderRadius: "50px",
    color: "#3C4858",
    background: "#e1a3eb",
  },
};

const useStylesHead = makeStyles(stylesHead);

const customerAdmin = () => {
  const classes = useStyles();
  const classesHead = useStylesHead();
  const { http } = AuthUser();
  const params = useParams();
  const history = useHistory();
  const [companyDetails, setCompanyDetails] = React.useState({});
  const [companyALLDetails, setCompanyALLDetails] = React.useState({});
  const [google_analyticsData, setgoogle_analyticsData] = React.useState([]);
  const [customer_typeModal, setcustomer_typeModal] = React.useState(false);
  const [customer_emailModal, setcustomer_emailModal] = React.useState(false);

  const [updateCustomer,setUpdateCustomer] = React.useState(false)
  const handleUpdateCustomer = ()=>setUpdateCustomer(!updateCustomer)

  React.useEffect(() => {
    http
      .post("/admin/customer", { cid: parseInt(params.id) })
      .then((response) => {
        const data = response.data;
        if (response.status == 200) {
          if (data && Object.keys(data.customer).length > 0) {
            setCompanyDetails(data.customer);
          }
          if (data && Object.keys(data).length > 0) {
            setCompanyALLDetails(data);
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`Error! - ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, [params.id,updateCustomer]);

  React.useEffect(() => {
    if (
      companyALLDetails.google_analytics &&
      Object.keys(companyALLDetails.google_analytics).length > 0
    ) {
      setgoogle_analyticsData([
        {
          title: "Query: ",
          data: companyALLDetails.google_analytics.query,
        },
        {
          title: "Channel:",
          data: companyALLDetails.google_analytics.channel,
        },
        {
          title: "Campaign: ",
          data: companyALLDetails.google_analytics.campaign,
        },
        {
          title: "Source/Medium: ",
          data: companyALLDetails.google_analytics.sourcemedium,
        },
      ]);
    }
  }, [companyALLDetails]);

  // console.log(companyALLDetails);

  return (
    <>
      {companyALLDetails && Object.keys(companyALLDetails).length > 0 ? (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    color="info"
                    round
                    onClick={() => {
                      history.push(
                        `/admin/company/${companyALLDetails.companyid}`
                      );
                    }}
                  >
                    <Edit /> View Log
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    color="info"
                    round
                    onClick={() => {
                      setcustomer_typeModal(true);
                    }}
                  >
                    <Edit /> Edit customer type
                  </Button>
                  <CustomerTypeUpdate
                    customer={companyALLDetails.customer}
                    customer_typeModal={customer_typeModal}
                    setcustomer_typeModal={setcustomer_typeModal}
                    handleUpdateCustomer={handleUpdateCustomer}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    color="info"
                    round
                    onClick={() => {
                      setcustomer_emailModal(true);
                    }}
                  >
                    <Edit /> Edit customer Email
                  </Button>
                  <CustomerEmailUpdate
                    customer={companyALLDetails.customer}
                    customer_emailModal={customer_emailModal}
                    setcustomer_emailModal={setcustomer_emailModal}
                    handleUpdateCustomer={handleUpdateCustomer}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={4}>
              <Card>
                <CardHeader color="warning" stats icon>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CardIcon color="warning">
                      <Business />
                    </CardIcon>
                    <h4 className={classes.cardTitle}>Plan Details</h4>
                  </div>
                  {/* <p className={classes.cardCategory}>Used Space</p> */}
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5>
                        Current Plan :
                        {companyALLDetails.current_Plane.planeName ? (
                          companyALLDetails.current_Plane.planeName
                        ) : (
                          <span>NA</span>
                        )}
                      </h5>
                      <h5>
                        Validity upto :
                        {companyALLDetails.current_Plane.exp_plane ? (
                          companyALLDetails.current_Plane.exp_plane
                        ) : (
                          <span>NA</span>
                        )}
                      </h5>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={4} lg={4}>
              <Card>
                <CardHeader color="success" stats icon>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CardIcon color="success">
                      <Business />
                    </CardIcon>
                    <h4 className={classes.cardTitle}>Credits</h4>
                  </div>
                  {/* <p className={classes.cardCategory}>Used Space</p> */}
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    {companyALLDetails.customer.customer_type == "SS" && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h5>
                          Post credits left :
                          {companyALLDetails.current_Plane.problem
                            ? companyALLDetails.current_Plane.problem
                            : "NA"}{" "}
                        </h5>
                        <h5>
                          Industry Execution Invoice Raised :
                          {companyALLDetails.industry_amount
                            ? companyALLDetails.industry_amount
                            : "NA"}{" "}
                        </h5>
                      </div>
                    )}
                    {companyALLDetails.customer.customer_type == "SP" && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h5>
                          Apply Credits Left :
                          {companyALLDetails.current_Plane.apply
                            ? companyALLDetails.current_Plane.apply
                            : "NA"}{" "}
                        </h5>
                        <h5>
                          Execution Invoice Raised :
                          {companyALLDetails.customer_amount
                            ? companyALLDetails.customer_amount
                            : "NA"}{" "}
                        </h5>
                      </div>
                    )}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <Card>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "8px",
                  }}
                >
                  <h5 style={{ color: "#3C4858" }}>Google Analytics</h5>

                  {companyALLDetails.google_analytics &&
                  Object.keys(companyALLDetails.google_analytics).length > 0 ? (
                    <>
                      {google_analyticsData.map((googleEle, ge) => {
                        return (
                          <div
                            key={ge}
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <h5 className={classesHead.cardHeadFirst}>
                              {googleEle.title}
                            </h5>
                            <span style={{wordBreak:"break-all",fontWeight: "700"}}>
                              {" "}
                              {googleEle.data}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <h5>NA</h5>
                  )}
                </div>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={4}>
              {/* customer.customer_type */}
              {companyALLDetails &&
                companyALLDetails.customer.customer_type == "SS" && (
                  <PersonalTimeLineSS
                    companyDetails={companyDetails}
                    companyALLDetails={companyALLDetails}
                  />
                )}
              {companyALLDetails &&
                companyALLDetails.customer.customer_type == "SP" && (
                  <PersonalTimeLineSP
                    companyDetails={companyDetails}
                    companyALLDetails={companyALLDetails}
                  />
                )}
            </GridItem>
            <GridItem xs={12} sm={6} md={4} lg={4}>
              {companyALLDetails &&
                companyALLDetails.customer.customer_type == "SS" && (
                  <ProjectsPostedCustomerSS
                    companyALLDetails={companyALLDetails}
                  />
                )}
              {companyALLDetails &&
                companyALLDetails.customer.customer_type == "SP" && (
                  <ProjectsPostedCustomerSP
                    companyALLDetails={companyALLDetails}
                  />
                )}
            </GridItem>
            <GridItem xs={12} sm={6} md={4} lg={4}>
              <CutomerActivitesAdmin companyALLDetails={companyALLDetails} />
            </GridItem>
          </GridContainer>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default customerAdmin;
