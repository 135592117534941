import React, { useState } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import IndustryStep1 from "./ProfileEditIndustry/IndustryStep1.js";
import IndustryStep2 from "./ProfileEditIndustry/IndustryStep2.js";
import "../../../assets/css/industryEditProfile.css"
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

export default function ExistingIndustry() {
  const [isToggleFormEdit, setIsToggleFormEdit] = useState(false)
  const handleIsToggleFormEdit = () => {
    setIsToggleFormEdit(!isToggleFormEdit)
  }
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={10} lg={10}>
        <Card>
          <CardBody>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="editTile">
                  <h5 className={isToggleFormEdit ? "" : "activeTitle"} onClick={handleIsToggleFormEdit}>Personal Details</h5>
                  <h5 className={isToggleFormEdit ? "activeTitle" : ""} onClick={handleIsToggleFormEdit}>Company Details</h5>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                {
                  !isToggleFormEdit ? (
                    <IndustryStep1 handleIsToggleFormEdit={handleIsToggleFormEdit} />
                  ) : (
                    <>
                      <IndustryStep2 handleIsToggleFormEdit={handleIsToggleFormEdit} />
                    </>
                  )
                }
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
