import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import ProposalStagesIndustryPD from "./ProposalStagesIndustryPD";
import ProposalStagesConsultantPD from "./ProposalStagesConsultantPD";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ProposalStagesPDModel({
  proposalStagesModal,
  setproposalStagesModal,
}) {
  const classes = useStyles();
  // const { http } = AuthUser();

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={proposalStagesModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setproposalStagesModal(false)}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setproposalStagesModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Project Proposal</h4>
        </DialogTitle>
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <ProposalStagesIndustryPD
                proposalStagesModal={proposalStagesModal}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <ProposalStagesConsultantPD
                proposalStagesModal={proposalStagesModal}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}

ProposalStagesPDModel.propTypes = {
  proposalStagesModal: PropTypes.bool,
  setproposalStagesModal: PropTypes.func,
};
