import React from "react";
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";
// import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import InputLabel from "@material-ui/core/InputLabel";

// Core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// icons
import Business from "@material-ui/icons/Business";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";
import PermIdentity from "@material-ui/icons/PermIdentity";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Web from "@material-ui/icons/Public";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { toast } from "react-toastify";
const useStyles = makeStyles(styles);

const OfflineLeadForm = ({
  setCreateOfflineLeadsModel,
  createOfflineLeadsModel,
}) => {
  const { http } = AuthUser();
  const classes = useStyles();
  //   const params = useParams();

  // Varibles
  const [company, setcompany] = React.useState("");
  const [name, setName] = React.useState("");
  const [mobile, setmobile] = React.useState("");
  const [email, setemail] = React.useState("");
  const [comments, setComments] = React.useState("");
  const [keyword, setKeyword] = React.useState("");

  const [skills, setSkills] = React.useState([]);
  const [sub_catList, setsub_catList] = React.useState([]);

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  const keywordValues = [
    "Google ads",
    "Google ads Call",
    "WhatsApp",
    "Call enquiry",
    "Tawk chat",
    "Email Enquiry",
    "SMS enquiry",
    "LinkedIn Arjun group",
    "LinkedIn Vikas group",
    "LinkedIn Guru group",
    "Induvidual LinkedIn",
    "Facebook leads",
    "Normal Referral",
    "Project Referral",
    "Webinar",
    "Indiamart",
    "Blog",
    "Others",
  ];

  React.useEffect(() => {
    if (!createOfflineLeadsModel) {
      setFormError({});
      setcompany("");
      setName("");
      setmobile("");
      setemail("");
      setComments("");
      setSkills("");
      setKeyword("");
    }
  }, [createOfflineLeadsModel]);

  React.useEffect(() => {
    http
      .get("/skillList")
      .then((response) => {
        if (response.status == 200) {
          const data = response.data;
          setsub_catList(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // First form Error checking and toggle
  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  // Error
  const handleFormError = () => {
    if (company.toString().trim().length === 0) {
      formErr.company = "Company is required";
    }
    if (name.toString().trim().length === 0) {
      formErr.name = "Name is required";
    }
    if (mobile.toString().trim().length === 0 || !verifyNumber(mobile)) {
      formErr.mobile = "Mobile number is required";
    }
    if (email.toString().trim().length === 0 || !verifyEmail(email)) {
      formErr.email = "Email address is required";
    }
    if (keyword.toString().trim().length === 0) {
      formErr.keyword = "Keyword is required";
    }
    if (skills.toString().trim().length === 0) {
      formErr.skills = "Skills is required";
    }
    if (comments.toString().trim().length === 0) {
      formErr.comments = "Comments is required";
    }
  };

  const resolve = () => {
    setFormError({});
    setcompany("");
    setName("");
    setmobile("");
    setemail("");
    setComments("");
    setSkills("");
    setKeyword("");
    setCreateOfflineLeadsModel(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const date = new Date();
      const data = {
        company,
        name,
        mobile,
        email,
        comments,
        skills,
        keyword,
        created_by: 1,
        created_date: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
      };
      console.log(data);
      handleLogForm(data);
    }
  };

  const handleLogForm = (formData) => {
    http
      .post("/admin/add_offline_lead", formData)
      .then((response) => {
        // console.log(reposne);
        if (response.data && response.data.code == 200) {
          toast.success(`Successfull! company details updated`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          resolve();
        } else {
          toast.error(`Something went wrong try again`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`Error in updating company ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <GridContainer>
          {/* Comapny */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={company && company.length > 0 ? true : false}
              // error={(Object.keys(formError).length > 0 && formError.firstname) ? true : false}
              labelText={<span>Company*</span>}
              id="company"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: company,
                name: "company",
                onChange: (event) => {
                  setcompany(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.company && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.company}
              </p>
            )}
          </GridItem>
          {/* Name */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={name && name.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.name
                  ? true
                  : false
              }
              labelText={
                <span>
                  Name <span>*</span>
                </span>
              }
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: name,
                name: "name",
                onChange: (event) => {
                  setName(event.target.value);
                  delete formError.name;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <PermIdentity className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.name && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.name}
              </p>
            )}
          </GridItem>
          {/* mobile */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={mobile && mobile.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.mobile
                  ? true
                  : false
              }
              labelText={
                <span>
                  Mobile<span>*</span>
                </span>
              }
              id="mobile"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: mobile,
                name: "mobile",
                type: "number",
                onChange: (event) => {
                  setmobile(event.target.value);
                  delete formError.mobile;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Smartphone className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.mobile && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.mobile}
              </p>
            )}
          </GridItem>
          {/* email */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={email && email.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.email
                  ? true
                  : false
              }
              labelText={
                <span>
                  Email <span>*</span>
                </span>
              }
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: email,
                name: "email",
                onChange: (event) => {
                  setemail(event.target.value);
                  delete formError.email;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.email && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.email}
              </p>
            )}
          </GridItem>
          {/* Skills */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="skills" className={classes.selectLabel}>
                <span id="skillsLable">
                  Skills <span>*</span>
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={skills}
                onChange={(event) => {
                  setSkills(event.target.value);
                }}
                inputProps={{
                  name: "skills",
                  id: "skills",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose skills
                </MenuItem>
                {sub_catList.map((industry) => {
                  return (
                    <MenuItem
                      key={industry.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={industry.id}
                    >
                      {industry.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.skills && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.skills}
              </p>
            )}
          </GridItem>
          {/* Soucer keyword*/}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="keyword" className={classes.selectLabel}>
                <span id="keyword">
                  Select source<span>*</span>
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={keyword}
                onChange={(event) => {
                  setKeyword(event.target.value);
                  delete formError.keyword;
                }}
                inputProps={{
                  name: "keyword",
                  id: "keyword",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose source
                </MenuItem>
                {keywordValues.map((keywordValue, k) => {
                  return (
                    <MenuItem
                      key={k}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={keywordValue}
                    >
                      {keywordValue}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.keyword && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.keyword}
              </p>
            )}
          </GridItem>
          {/* comments */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="comments" style={{ marginLeft: "12px" }}>
                Comments<span>*</span>
              </InputLabel>
              <OutlinedInput
                multiline
                rows={10}
                name="comments"
                value={comments}
                onChange={(event) => {
                  setComments(event.target.value);
                  delete formError.comments;
                }}
                id="requirement"
                color="primary"
                label="Project Requirement"
              />
            </FormControl>
            {Object.keys(formError).length > 0 && formError.comments && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.comments}
              </p>
            )}
          </GridItem>
          {/* Submit */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className="edit-btn-industry">
              <Button round color="rose" type="submit">
                Submit
              </Button>
              <Button
                round
                onClick={() => {
                  setCreateOfflineLeadsModel(false);
                }}
              >
                Cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};
OfflineLeadForm.propTypes = {
  setCreateOfflineLeadsModel: PropTypes.func,
  createOfflineLeadsModel: PropTypes.bool,
};
export default OfflineLeadForm;
