
import React from "react";
import PropTypes from "prop-types";
// import AuthUser from "components/Sb/AuthUser";
// import { toast } from "react-toastify";

// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ProposalStagesFormPD from "./ProposalStagesFormPD";

const ProposalStagesIndustryPD = (props) => {
  const { proposalStagesModal } = props;
  const classes = useStyles();
  //   getUser
  // const { http } = AuthUser();

  const [countIndustry, setCountIndustry] = React.useState(0);
  const [countComponentIndustry, setCountComponentIndustry] = React.useState(
    []
  );

  React.useEffect(()=>{
    if(!proposalStagesModal){
      setCountIndustry(0)
      setCountComponentIndustry([])
    }
  },[proposalStagesModal])

  const handleIncrementIndustry = () => {
    setCountIndustry((prevousValue) => setCountIndustry(prevousValue + 1));
    setCountComponentIndustry([...countComponentIndustry, countIndustry + 1]);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <p>Industry Proposal Stages</p>
          <Button
            round
            color="danger"
            className={classes.registerButton}
            onClick={() => handleIncrementIndustry()}
          >
            Add +
          </Button>
          {countComponentIndustry.map((ele, e) => {
            return <ProposalStagesFormPD key={e} countValue={ele} />;
          })}
        </GridItem>
      </GridContainer>
    </>
  );
};

ProposalStagesIndustryPD.propTypes = {
  proposalStagesModal: PropTypes.bool,
};

export default ProposalStagesIndustryPD;
