import React from "react";
import AuthUser from "components/Sb/AuthUser";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function FilterRoute() {
  const { getUser, http } = AuthUser();

  const history = useHistory();
  const [plan, setPlan] = React.useState("");
  const [step, setStep] = React.useState("");

  const [user, setUser] = React.useState({});


  React.useEffect(() => {
    setUser(getUser());
  }, []);

  // admin
  React.useEffect(() => {
    if (user.user_type && (user.user_type == "A" || user.user_type == "U")) {
      const LeadNotifications = async () => {
        http
          .post("/admin/leadAssignNotify", { user_id: user.user_id })
          .then((response) => {
            // console.log(reposne);
            if (response.data) {
              if(response.data.length>0){
                const data = response.data
                for(let i=0;i<data.length;i++){
                  toast.success(`New Lead Assigned! CID:${data[i].customer_id}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    onClick:()=>{
                      history.push(`/admin/company/${data[i].customer_id}`)
                    },
                    autoClose: 40000,
                  });
                }
              }
            }
          })
      };

      LeadNotifications();
      // You can also set up a timer to refresh the notifications periodically if needed
      const taskNotify = async () => {
        http
          .post("/admin/taskNotify", { user_id: user.user_id })
          .then((response) => {
            // console.log(reposne);
            if (response.data) {
              if(response.data.length>0){
                const data = response.data
                for(let i=0;i<data.length;i++){
                  toast.warning(`Upcoming Task at - ${data[i].date.slice(11,16)}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false,
                    theme: "colored",
                    onClick:()=>{
                      history.push(`/admin/company/${data[i].cus_id}`)
                    },
                  });
                }
              }
            }
          })
      };

      taskNotify();

      const customerloginNotify = async () => {
        http
          .post("/admin/customerloginNotify", { user_id: user.user_id })
          .then((response) => {
            // console.log(reposne);
            if (response.data) {
              if(response.data.length>0){
                const data = response.data
                for(let i=0;i<data.length;i++){
                  toast.info(`Customer Login! CID:${data[i].customer_id}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    onClick:()=>{
                      history.push(`/admin/company/${data[i].customer_id}`)
                    },
                    autoClose: 40000,
                  });
                }
              }
            }
          })
      };

      customerloginNotify();

      const nonpaidprojectNotify = async () => {
        http
          .post("/admin/nonpaidprojectNotify", { user_id: user.user_id })
          .then((response) => {
            // console.log(reposne);
            if (response.data) {
              if(response.data.length>0){
                const data = response.data
                for(let i=0;i<data.length;i++){
                  toast.error(`Project Posted by : ${data[i].customer_id}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored",
                    onClick:()=>{
                      history.push(`/admin/company/${data[i].customer_id}`)
                    },
                    autoClose: 40000,
                  });
                }
              }
            }
          })
      };

      nonpaidprojectNotify();

      // Clean up function to cancel the ongoing fetch if the component unmounts
      return () => {
        // Cancel any ongoing fetch or timers here if necessary
      };
    }
  }, [user]);

  // get dynamic plan
  // console.log(getUser())
  React.useEffect(() => {
    if (
      (Object.keys(getUser()) .length>0 && getUser().customer_type) &&
      (getUser().customer_type == "SP" && parseInt(getUser().step) < 3) || (getUser().customer_type == "SS" && parseInt(getUser().step) < 4)
    ) {
      http
        .get("/customer/ViewPlan")
        .then((res) => {
          // console.log(res.data,)
          if (res.status == 200) {
            if (Object.keys(res.data).length == 0) {
              history.push(`/customer/register`);
            }
            setPlan(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  //get dynamic onboarding-step
  // console.log(getUser())
  React.useState(() => {
    if ((Object.keys(getUser()) .length>0 && getUser().customer_type) && (getUser().customer_type == "SS" || getUser().customer_type == "SP")
    ) {
      http
        .get("/customer/getOnboardingstep")
        .then((response) => {
          // console.log(response)
          if (response.status == 200) {
            const data = response.data;
            setStep(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  // console.log(getUser())

  const GetFilterRoute = (routes, role) => {
    let route_arr = [];
    for (let i = 0; i < routes.length; i++) {
      let route_obj = {};
      let collapse_obj = {};
      if (routes[i].collapse) {
        let view = routes[i].views;
        collapse_obj.collapse = true;
        collapse_obj.icon = routes[i].icon;
        collapse_obj.name = routes[i].name;
        collapse_obj.state = routes[i].state;
        let collapse_route = get_collapse_route(view, role);
        if (collapse_route.length > 0) {
          collapse_obj.views = collapse_route;
          route_arr.push(collapse_obj);
        }
      }
      if (routes[i].role == role) {
        // customer
        if (
          (getUser().customer_type && getUser().customer_type == "SP") ||
          getUser().customer_type == "SS"
        ) {
          if (Object.keys(plan).length === 0 || plan === null) {
            if (
              (getUser().customer_type == "SP" && step > 3) ||
              (getUser().customer_type == "SS" && step >= 3)
            ) {
              if (
                routes[i].path != "/register" &&
                routes[i].path != "/error-page" &&
                routes[i].path != "/project-details/:id" &&
                routes[i].path != "/checkout/:id" &&
                routes[i].path != "/payment-failed" &&
                routes[i].path != "/payment-success" &&
                routes[i].path != "/payment-success" &&
                routes[i].path != "/industry-register" &&
                routes[i].path != "/consultant-register" &&
                routes[i].path != "/viewInvoice/:id" &&
                routes[i].path != "/company/:id" &&
                routes[i].path != "/customer/:id"
              ) {
                // console.log(routes[i])
                route_obj.path = routes[i].path;
                route_obj.name = routes[i].name;
                route_obj.icon = routes[i].icon;
                route_obj.layout = routes[i].layout;
                route_arr.push(route_obj);
              }
            } else {
              if (routes[i].name === "/register") {
                // console.log(routes[i])
                route_obj.path = routes[i].path;
                route_obj.name = routes[i].name;
                route_obj.icon = routes[i].icon;
                route_obj.layout = routes[i].layout;
                route_arr.push(route_obj);
              }
            }
          } else {
            if (
              routes[i].path != "/register" &&
              routes[i].path != "/error-page" &&
              routes[i].path != "/project-details/:id" &&
              routes[i].path != "/checkout/:id" &&
              routes[i].path != "/payment-failed" &&
              routes[i].path != "/payment-success" &&
              routes[i].path != "/payment-success" &&
              routes[i].path != "/industry-register" &&
              routes[i].path != "/consultant-register" &&
              routes[i].path != "/viewInvoice/:id" &&
              routes[i].path != "/company/:id" &&
              routes[i].path != "/customer/:id"
            ) {
              // console.log(routes[i])
              route_obj.path = routes[i].path;
              route_obj.name = routes[i].name;
              route_obj.icon = routes[i].icon;
              route_obj.layout = routes[i].layout;
              route_arr.push(route_obj);
            }
          }
        }
        // admin
        if (getUser().user_type && (user.user_type == "A" || user.user_type == "U") ) {
          if (
            routes[i].path != "/register" &&
            routes[i].path != "/error-page" &&
            routes[i].path != "/project-details/:id" &&
            routes[i].path != "/checkout/:id" &&
            routes[i].path != "/payment-failed" &&
            routes[i].path != "/payment-success" &&
            routes[i].path != "/payment-success" &&
            routes[i].path != "/industry-register" &&
            routes[i].path != "/consultant-register" &&
            routes[i].path != "/viewInvoice/:id" &&
            routes[i].path != "/company/:id" &&
            routes[i].path != "/customer/:id"
          ) {
            // console.log(routes[i])
            route_obj.path = routes[i].path;
            route_obj.name = routes[i].name;
            route_obj.icon = routes[i].icon;
            route_obj.layout = routes[i].layout;
            route_arr.push(route_obj);
          }
        }
      }
    }
    return route_arr;
  };

  const get_collapse_route = (child_routes, role) => {
    let child_arr = [];
    for (let i = 0; i < child_routes.length; i++) {
      let child_obj = {};
      // customer
      if (
        (getUser().customer_type && getUser().customer_type == "SP") ||
        getUser().customer_type == "SS"
      ) {
        if (Object.keys(plan).length === 0 || plan === null) {
          if (
            (getUser().customer_type == "SP" && step > 3) ||
            (getUser().customer_type == "SS" && step >= 3)
          ) {
            if (child_routes[i].role == role) {
              child_obj.path = child_routes[i].path;
              child_obj.name = child_routes[i].name;
              child_obj.layout = child_routes[i].layout;
              child_arr.push(child_obj);
            }
          }
        } else {
          if (child_routes[i].role == role) {
            child_obj.path = child_routes[i].path;
            child_obj.name = child_routes[i].name;
            child_obj.layout = child_routes[i].layout;
            child_arr.push(child_obj);
          }
        }
      }
      // admin
      if (getUser().user_type && (user.user_type == "A" || user.user_type == "U") ) {
        if (child_routes[i].role == role) {
          child_obj.path = child_routes[i].path;
          child_obj.name = child_routes[i].name;
          child_obj.layout = child_routes[i].layout;
          child_arr.push(child_obj);
        }
      }
    }
    return child_arr;
  };

  return {
    GetFilterRoute,
  };
}
