import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";

import {
  cardTitle,
  roseColor,
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
    textTransform: "none",
  },
  cardHeadFirst: {
    display: "inline-block",
    fontSize: "12px !important",
    marginTop: "0",
    textTransform: "none",
    fontWeight: "medium",
    padding: "4px 6px",
    borderRadius: "50px",
    color: "#3C4858",
    background: "#90a4ae91",
  },
  cardHeadRegister: {
    display: "inline-block",
    fontSize: "12px !important",
    marginTop: "0",
    textTransform: "none",
    fontWeight: "medium",
    padding: "4px",
    borderRadius: "50px",
    color: "#3C4858",
    background: "#e1a3eb",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#9c27b0",
    marginTop: "4px",
    textTransform: "none",
  },
  card: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    marginTop: "15px",
    borderRadius: "6px",
    marginBottom: "10px",
    flexDirection: "column",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function CutomerActivitesAdmin({ companyALLDetails }) {
  const classes = useStyles();
  const [dataEntry,setdataEntry] = React.useState([])
  const [dataEntryRegistration,setdataEntryRegistration] = React.useState([])
  const [google_analyticsData,setgoogle_analyticsData] = React.useState([])

  React.useEffect(()=>{
    if(companyALLDetails.analytics){
      const dataEntrys = [
        {
          title: "First Entry Traffic Type",
          data: companyALLDetails.analytics.sb_first_typ,
        },
        {
          title: "First Entry Source",
          data: companyALLDetails.analytics.sb_first_src,
        },
        {
          title: "First Entry Medium",
          data: companyALLDetails.analytics.sb_first_mdm,
        },
        {
          title: "First Entry Campaign",
          data: companyALLDetails.analytics.sb_first_cmp,
        },
        {
          title: "First Entry Content",
          data: companyALLDetails.analytics.sb_first_cnt,
        },
        {
          title: "First Entry Keyword",
          data: companyALLDetails.analytics.sb_first_trm,
        },
        {
          title: "First Entry Date",
          data: companyALLDetails.analytics.sb_first_add_fd,
        },
        {
          title: "First Entry Point",
          data: companyALLDetails.analytics.sb_first_add_ep,
        },
        {
          title: "First Entry Referer URL",
          data: companyALLDetails.analytics.sb_first_add_rf,
        },
      ];
      const dataEntryRegistrations = [
        {
          title: "Registration Entry Traffic Type",
          data: companyALLDetails.analytics.sb_current_typ,
        },
        {
          title: "Registration Entry Source",
          data: companyALLDetails.analytics.sb_current_src,
        },
        {
          title: "Registration Entry Medium",
          data: companyALLDetails.analytics.sb_current_mdm,
        },
    
        {
          title: "Registration Entry Campaign",
          data: companyALLDetails.analytics.sb_current_cmp,
        },
        {
          title: "Registration Entry Content",
          data: companyALLDetails.analytics.sb_current_cnt,
        },
        {
          title: "Registration Entry Keyword",
          data: companyALLDetails.analytics.sb_current_trm,
        },
        {
          title: "Registration Entry Point",
          data: companyALLDetails.analytics.sb_current_add_ep,
        },
        {
          title: "Registration Entry Date",
          data: companyALLDetails.analytics.sb_current_add_fd,
        },
        {
          title: "Registration Entry Referer URL",
          data: companyALLDetails.analytics.sb_current_add_rf,
        },
        {
          title: "Pages seen till registration",
          data: companyALLDetails.analytics.sb_session_pgs,
        },
        {
          title: "Registration from page",
          data: companyALLDetails.analytics.sb_session_cpg,
        },
        {
          title: "How many times user visited site",
          data: companyALLDetails.analytics.sb_udata_vst,
        },
        //
      ];
      setdataEntry(dataEntrys)
      setdataEntryRegistration(dataEntryRegistrations)
    }
  },[companyALLDetails])
  React.useEffect(()=>{
    if(companyALLDetails.google_analytics){
      const google_analyticsDatas = [
        {
          title: "Query: ",
          data: companyALLDetails.google_analytics.query,
        },
        {
          title: "Channel:",
          data: companyALLDetails.google_analytics.channel,
        },
        {
          title: "Campaign: ",
          data: companyALLDetails.google_analytics.campaign,
        },
        {
          title: "Source/Medium: ",
          data: companyALLDetails.google_analytics.sourcemedium,
        },
        {
          title: "keyword: ",
          data: companyALLDetails.google_analytics.keyword,
        },
        {
          title: "Destination Url: ",
          data: companyALLDetails.google_analytics.destinationurl,
        },
        {
          title: "State: ",
          data: companyALLDetails.google_analytics.state,
        },
        {
          title: "City: ",
          data: companyALLDetails.google_analytics.city,
        },
        {
          title: "Landingpage: ",
          data: companyALLDetails.google_analytics.landingpage,
        },
      ];
      setgoogle_analyticsData(google_analyticsDatas)
    }
  },[companyALLDetails])
  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} lg={12}>
          <div className={classes.card}>
            <CardBody>
              <h6 className={classes.cardCategory}>Search Query</h6>
              <h5 className={classes.cardTitle}>
                {companyALLDetails.google_analytics &&
                companyALLDetails.google_analytics.query
                  ? companyALLDetails.google_analytics.query
                  : "NA"}
              </h5>
            </CardBody>
          </div>
          <div className={classes.card}>
            <CardBody>
              <h6 className={classes.cardCategory}>Date Joined</h6>
              <h5 className={classes.cardTitle}>
                {companyALLDetails.customer.date_added
                  ? companyALLDetails.customer.date_added
                  : "NA"}
              </h5>
            </CardBody>
          </div>
          <div className={classes.card}>
            <CardBody>
              <h6 className={classes.cardCategory}>Last Login</h6>
              <h5 className={classes.cardTitle}>
                {companyALLDetails.customer.last_login
                  ? companyALLDetails.customer.last_login
                  : "NA"}
              </h5>
            </CardBody>
          </div>
          <div className={classes.card}>
            <CardBody>
              <h6 className={classes.cardCategory}>Last Profile Updated</h6>
              <h5 className={classes.cardTitle}>
                {companyALLDetails.customer.date_updated
                  ? companyALLDetails.customer.date_updated
                  : "NA"}
              </h5>
            </CardBody>
          </div>
          <div className={classes.card}>
            <CardBody>
              <h6 className={classes.cardCategory}>IP address</h6>
              <h5 className={classes.cardTitle}>
                {companyALLDetails.customer.ip
                  ? companyALLDetails.customer.ip
                  : "NA"}
              </h5>
            </CardBody>
          </div>
          <div className={classes.card}>
            <CardBody>
              <h6 className={classes.cardCategory}>Google Analytics</h6>
              {companyALLDetails.google_analytics &&
              Object.keys(companyALLDetails.google_analytics).length > 0 ? (
                <>
                  {google_analyticsData.map((googleEle, ge) => {
                    return (
                      <div key={ge} style={{ display: "flex",alignItems:"baseline" ,flexWrap:"wrap"}}>
                        <h5 className={classes.cardHeadFirst} style={{maxWidth:"40%"}}>
                          {googleEle.title}
                        </h5>
                        <span style={{fontWeight:"700",maxWidth:"auto",wordBreak:"break-all"}}>{" "} {googleEle.data}</span>
                      </div>
                    );
                  })}
                </>
              ) : (
                <h5 className={classes.cardTitle}>NA</h5>
              )}
            </CardBody>
          </div>
          <div className={classes.card}>
            <CardBody>
              <h6 className={classes.cardCategory}>Entry Analytics</h6>
              {companyALLDetails.analytics &&
              Object.keys(companyALLDetails.analytics).length > 0 ? (
                <>
                  {dataEntry.map((eleDtaa, ed) => {
                    return (
                      <div key={ed} style={{display:"flex",flexWrap:"wrap",alignItems:"baseline"}}>
                        <h6 className={classes.cardHeadFirst}>
                          {eleDtaa.title}
                        </h6>
                        <span> {" "}: {" "} </span>
                        <h5 style={{wordBreak:"break-all"}}>{eleDtaa.data}</h5>
                      </div>
                    );
                  })}
                  {dataEntryRegistration.map((register, rg) => {
                    return (
                      <div key={rg} style={{display:"flex",flexWrap:"wrap",alignItems:"baseline"}}>
                        <h6 className={classes.cardHeadRegister}>
                          {register.title}
                        </h6>
                        <span> {" "}: {" "} </span>
                        <h5 style={{wordBreak:"break-all"}}>{register.data}</h5>
                      </div>
                    );
                  })}
                </>
              ) : (
                <h5 className={classes.cardTitle}>NA</h5>
              )}
            </CardBody>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
CutomerActivitesAdmin.propTypes = {
  companyALLDetails: PropTypes.object,
};
