import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
// import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
// import Email from "@material-ui/icons/Email";
// import Money from "@material-ui/icons/Money";
import Business from "@material-ui/icons/Business";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import NoteAdd from "@material-ui/icons/NoteAdd";
// import PanTool from "@material-ui/icons/PanTool";
// import LocationCity from "@material-ui/icons/LocationCity";
// import LocationOn from "@material-ui/icons/LocationOn";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { OutlinedInput } from "@material-ui/core";
// import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const ExternalConsultantForm = (props) => {
  const { setexternalConsultantModal, externalConsultantModal } = props;
  const classes = useStyles();
  //   const params = useParams();
  //   const history = useHistory();

  //   getUser
  // const { http } = AuthUser();

  const [name, setname] = React.useState("");
  const [email, setemail] = React.useState("");
  const [phone, setphone] = React.useState("");
  const [source, setsource] = React.useState("");
  const [comments, setcomments] = React.useState("");

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  React.useEffect(() => {
    if (!externalConsultantModal) {
      setFormError({});
      setname("");
      setemail("");
      setphone("");
      setsource("");
      setcomments("");
    }
  }, [externalConsultantModal]);

  // handle Form error function
  const handleFormError = () => {
    // name
    if (name.toString().trim().length === 0) {
      formErr.name = "name is required";
    }

    // email
    var Emailregex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!email.match(Emailregex) || email.trim().length == 0) {
      formErr.email = "Email is Invalid!";
    }
    // phone
    if (phone.toString().trim().length === 0) {
      formErr.phone = "phone is required";
    }
    // source
    if (source.toString().trim().length === 0) {
      formErr.source = "source is required";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        name,
        email,
        phone,
        source,
        comments,
      };
      console.log(formData);
      resolve();
      //   handleFormAPI(formData);
    }
  };

  //   const handleFormAPI = (formData) => {
  //     http
  //       .post("/admin/lead_questionire", formData)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           resolve();
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(`Can't update - try later ${err.message}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 1500,
  //         });
  //       });
  //   };

  const resolve = () => {
    toast.success("Successfully updated", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    setexternalConsultantModal(false);
    setFormError({});
    setname("");
    setemail("");
    setphone("");
    setsource("");
    setcomments("");
  };

  const sourceLists = [
    "Linkedin",
    "Existing Database",
    "Facebook",
    "Other Sources",
  ];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* name */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={name.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.name
                  ? true
                  : false
              }
              labelText={<span>Name*</span>}
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "name",
                value: name,
                onChange: (event) => {
                  setname(event.target.value);
                  delete formError.name;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.name && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.name}
              </p>
            )}
          </GridItem>
          {/* email */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={email.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.email
                  ? true
                  : false
              }
              labelText={<span>Email*</span>}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "email",
                value: email,
                onChange: (event) => {
                  setemail(event.target.value);
                  delete formError.email;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.email && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.email}
              </p>
            )}
          </GridItem>
          {/* phone */}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              success={phone.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.phone
                  ? true
                  : false
              }
              labelText={<span>Phone No*</span>}
              id="phone"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "phone",
                type:"number",
                value: phone,
                onChange: (event) => {
                  setphone(event.target.value);
                  delete formError.phone;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.phone && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.phone}
              </p>
            )}
          </GridItem>
          {/* source */}
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="source" className={classes.selectLabel}>
                <span id="source">Select industry category*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={source}
                onChange={(event) => {
                  setsource(event.target.value);
                  delete formError.source;
                }}
                inputProps={{
                  name: "source",
                  id: "source",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose source
                </MenuItem>
                {sourceLists.map((sourceList, s) => {
                  return (
                    <MenuItem
                      key={s}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={sourceList}
                    >
                      {sourceList}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.source && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.source}
              </p>
            )}
          </GridItem>
          {/* comments */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="comments" style={{ marginLeft: "12px" }}>
                Comments
              </InputLabel>
              <OutlinedInput
                multiline
                rows={10}
                name="comments"
                value={comments}
                onChange={(event) => {
                  setcomments(event.target.value);
                  //   delete formError.comments;
                }}
                id="comments"
                color="primary"
                label="comments"
              />
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.comments && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.comments}
              </p>
            )} */}
          </GridItem>
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                save
              </Button>
            </div>
          </GridItem>
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => setexternalConsultantModal(false)}
              >
                cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

ExternalConsultantForm.propTypes = {
  setexternalConsultantModal: PropTypes.func,
  externalConsultantModal: PropTypes.bool,
};

export default ExternalConsultantForm;
