import React from "react";

// Authuser
import AuthUser from "components/Sb/AuthUser";
import "assets/css/viewProfile.css"

// toast
// import { toast } from 'react-toastify'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Business from "@material-ui/icons/Business";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";
import Face from "@material-ui/icons/Face";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import defaultImage from "assets/img/default-avatar.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function ConsultantProfile() {
    const classes = useStyles();
    const { http } = AuthUser();
    const [companyname, setCompanyName] = React.useState("")
    const [firstname, setFirstName] = React.useState("")
    const [lastname, setlastname] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [dob, setdob] = React.useState("")
    const [city, setcity] = React.useState("")
    const [customer_id, setcustomer_id] = React.useState("")
    const [mycurrentposition, setmycurrentposition] = React.useState("")
    const [industries, setindustries] = React.useState([])
    const [industriesList, setindustriesList] = React.useState([])
    const [category, setcategory] = React.useState([])
    const [categoryList, setcategoryList] = React.useState([])
    const [mytotalnoexp, setmytotalnoexp] = React.useState("")
    const [archievements, setarchievements] = React.useState("")
    const [certification, setcertification] = React.useState("")
    const [linkedin, setlinkedin] = React.useState("")
    const [brief_bio, setbrief_bio] = React.useState("")
    const [mc_hour, setmc_hour] = React.useState("")
    const [mc_location, setmc_location] = React.useState("")
    const [mc_cost, setmc_cost] = React.useState("")
    const [companylogo, setcompanylogo] = React.useState("")

    React.useEffect(() => {
        http.get("/industryList")
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    setindustriesList(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    React.useEffect(() => {
        http.get("/categoryList")
            .then((response) => {
                const data = response.data
                if (response.status === 200) {
                    setcategoryList(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    React.useEffect(() => {
        http.get("/profile")
            .then((response) => {
                if (response.status === 200) {


                    http.get("/customer/customerIndustries")
                        .then((response) => {
                            if (response.status === 200) {
                                const datasIndustries = response.data
                                let result = []
                                datasIndustries.forEach((ele) => {
                                    result.push(ele.industries_id)
                                })
                                setindustries(result ? result : "0")
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })


                    http.get("/customer/customerCategory")
                        .then((response) => {
                            if (response.status === 200) {
                                const dataSkills = response.data
                                let result = []
                                dataSkills.forEach((ele) => {
                                    result.push(ele.category_id)
                                })
                                setcategory(result ? result : "0")
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                    const data = response.data
                    setCompanyName(data.companyname ? data.companyname : "NA")
                    setFirstName(data.firstname ? data.firstname : "NA")
                    setlastname(data.lastname ? data.lastname : "NA")
                    setEmail(data.email ? data.email : "NA")
                    setPhone(data.phone ? data.phone : "NA")
                    setdob(data.dob ? data.dob : "NA")
                    setcity(data.city ? data.city : "NA")
                    setcustomer_id(data.customer_id ? data.customer_id : "NA")
                    setmycurrentposition(data.mycurrentposition ? data.mycurrentposition : "NA")
                    setmytotalnoexp(data.mytotalnoexp ? data.mytotalnoexp : "NA")
                    setarchievements(data.archievements ? data.archievements : "NA")
                    setcertification(data.certification ? data.certification : "NA")
                    setlinkedin(data.linkedin ? data.linkedin : "NA")
                    setbrief_bio(data.brief_bio ? data.brief_bio : "NA")
                    setmc_hour(data.mc_hour ? data.mc_hour : "NA")
                    setmc_location(data.mc_location ? data.mc_location : "NA")
                    setmc_cost(data.mc_cost ? data.mc_cost : "NA")
                    setcompanylogo(data.companylogo ? 'https://api.solutionbuggy.com/customerProfile/' + data.companylogo : defaultImage)

                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const resultIndustries = industriesList.filter((industry) => {
        return industries.includes(industry.id)
    })
    const resultCategory = categoryList.filter((categorie) => {
        return category.includes(categorie.id)
    })
    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Card profile>
                        <CardAvatar profile>
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img src={companylogo} alt="..." />
                            </a>
                        </CardAvatar>
                        <CardBody > 
                           
                               
                                    <h3 className={classes.cardCategory}>customer id: {customer_id}</h3>
                              
                                        <h5 className={classes.lableHead}><Face />  Firstname: {firstname}</h5>
                                        
                                   

                               
                                        <h5 className={classes.lableHead}><Email />  Email: {email}</h5>
                                        
                                        <h5 className={classes.lableHead}><Smartphone /> Phone: {phone}</h5>
                                        

                                <div>
                                    <Link to="/customer/edit-profile" >
                                        <Button color="info" round>Edit</Button>
                                    </Link>
                                </div>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <PermIdentity />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Personal Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Fisrt Name: </h5>
                                                <p className={classes.lableValue}>{firstname}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Last Name: </h5>
                                                <p className={classes.lableValue}>{lastname}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Email : </h5>
                                                <p className={classes.lableValue}>{email}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Phone : </h5>
                                                <p className={classes.lableValue}>{phone}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Date of Birth : </h5>
                                                <p className={classes.lableValue}>{dob}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>City : </h5>
                                                <p className={classes.lableValue}>{city}</p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    {/* <Button color="rose" className={classes.updateProfileButton}>
                                        Update Profile
                                    </Button> */}
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <Business />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Company Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Company Name: </h5>
                                                <p className={classes.lableValue}>{companyname}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Current Position: </h5>
                                                <p className={classes.lableValue}>{mycurrentposition}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Industry : </h5>
                                                {
                                                    resultIndustries.map((industry) => {
                                                        return <span key={industry.id} className={classes.tags}>{industry.name}</span>
                                                    })
                                                }
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Skills : </h5>
                                                {
                                                    resultCategory.map((category) => {
                                                        return <span key={category.id} className={classes.tags}>{category.name}</span>
                                                    })
                                                }
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Experience : </h5>
                                                <p className={classes.lableValue}>{mytotalnoexp}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Acheivement : </h5>
                                                <p className={classes.lableValue}>{archievements}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Certification : </h5>
                                                <p className={classes.lableValue}>{certification}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Linkedin : </h5>
                                                <p className={classes.lableValue}>{linkedin}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Brief Bio : </h5>
                                                <p className={classes.lableValue}>{brief_bio}</p>
                                            </div>
                                        </GridItem>

                                    </GridContainer>
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <BusinessCenter />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>
                                        Micro Consultancy
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}> Number of hours that you are available : </h5>
                                                <p className={classes.lableValue}>{mc_hour}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Project Location : </h5>
                                                <p className={classes.lableValue}>{mc_location}</p>
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <div className={classes.detailsContainer}>
                                                <h5 className={classes.lableHead}>Cost per hour :</h5>
                                                <p className={classes.lableValue}>{mc_cost}</p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <Clearfix />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}
