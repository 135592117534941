import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
// import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
// import Email from "@material-ui/icons/Email";
// import Money from "@material-ui/icons/Money";
import Business from "@material-ui/icons/Business";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import NoteAdd from "@material-ui/icons/NoteAdd";
// import PanTool from "@material-ui/icons/PanTool";
// import LocationCity from "@material-ui/icons/LocationCity";
// import LocationOn from "@material-ui/icons/LocationOn";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { OutlinedInput } from "@material-ui/core";
// import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const EditProjectDetailsPDForm = (props) => {
  const { seteditProjectDetailsModal, editProjectDetailsModal } = props;
  const classes = useStyles();
  //   const params = useParams();
  //   const history = useHistory();

  //   getUser
  const { http } = AuthUser();

  const [title, settitle] = React.useState("");
  const [describe, setdescribe] = React.useState("");
  const [budget, setbudget] = React.useState("");
  const [location, setlocation] = React.useState("");
  const [industries, setindustries] = React.useState("");
  const [sub_cat, setsub_cat] = React.useState("");
  const [typeofproject, settypeofproject] = React.useState("");
  const [time_limit, settime_limit] = React.useState("");
  const [action, setaction] = React.useState("");
  const [assigned_to, setassigned_to] = React.useState("");
  const [assigned_to_1, setassigned_to_1] = React.useState("");
  const [execution, setexecution] = React.useState("");

  const [industriesAll, setindustriesAll] = React.useState([]);
  const [categoryList, setcategoryList] = React.useState([]);

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // getting industry lists
  React.useEffect(() => {
    http
      .get("/industryList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setindustriesAll(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    http
      .get("/categoryList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setcategoryList(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    if (!editProjectDetailsModal) {
      setFormError({});
      settitle("");
      setdescribe("");
      setbudget("");
      setlocation("");
      setindustries("");
      setsub_cat("")
      settypeofproject("")
      settime_limit("")
      setaction("")
      setassigned_to("")
      setassigned_to_1("")
      setexecution("")
    }
  }, [editProjectDetailsModal]);

  // handle Form error function
  const handleFormError = () => {
    // title
    if (title.toString().trim().length === 0) {
      formErr.title = "title is required";
    }
    // location
    if (location.toString().trim().length === 0) {
      formErr.location = "location is required";
    }
    // time_limit
    if (time_limit.toString().trim().length === 0) {
      formErr.time_limit = "Time limit is required";
    }
    // action
    if (action.toString().trim().length === 0) {
      formErr.action = "Project Status is required";
    }
    // assigned_to
    if (assigned_to.toString().trim().length === 0) {
      formErr.assigned_to = "Assigned to is required";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        title,
        describe,
        budget,
        location,
        industries,
        sub_cat,
        typeofproject,
        time_limit,
        action,
        assigned_to,
        assigned_to_1,
        execution,
      };
      console.log(formData);
      resolve();
      //   handleFormAPI(formData);
    }
  };

  //   const handleFormAPI = (formData) => {
  //     http
  //       .post("/admin/lead_questionire", formData)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           resolve();
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(`Can't update - try later ${err.message}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 1500,
  //         });
  //       });
  //   };

  const resolve = () => {
    toast.success("Successfully updated", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    seteditProjectDetailsModal(false);
    setFormError({});
    settitle("");
    setdescribe("");
    setbudget("");
    setlocation("");
    setindustries("");
    setsub_cat("")
    settypeofproject("")
    settime_limit("")
    setaction("")
    setassigned_to("")
    setassigned_to_1("")
    setexecution("")
  };

  const typeofprojectList = ["Onsite", "Work from home"];
  const time_limit_list = [
    "< 1 month",
    "1 month – 3 months",
    "3 months – 6 months",
    "> 6 months",
  ];

  const action_list = [
    "Not Live",
    "Active",
    "Proposal Submitted",
    "On Hold",
    "Awarded",
    "Dropped",
    "Project Completed",
  ];

  const assignedToList = [
    {
      no: "1",
      name: "Admin",
    },
    {
      no: "2",
      name: "Sales-Admin",
    },
    {
      no: "3",
      name: "Arjun",
    },
    {
      no: "4",
      name: "Nisha",
    },
    {
      no: "36",
      name: "Pallavi",
    },
    {
      no: "60",
      name: "dsmehra",
    },
    {
      no: "71",
      name: "Nithin",
    },
    {
      no: "77",
      name: "Chithra",
    },
    {
      no: "84",
      name: "Pruthvi",
    },
    {
      no: "92",
      name: "Shivani",
    },
    {
      no: "99",
      name: "Manjunath",
    },
    {
      no: "109",
      name: "Pruthvi Raj",
    },
    {
      no: "111",
      name: "Pruthvi Raj",
    },
    {
      no: "114",
      name: "Pruthviraj CN",
    },
    {
      no: "119",
      name: "Srimaya",
    },
    {
      no: "122",
      name: "Arumai",
    },
    {
      no: "124",
      name: "Mayure",
    },
    {
      no: "126",
      name: "Sravan",
    },
    {
      no: "127",
      name: "Vaishnavi",
    },
    {
      no: "128",
      name: "Devein",
    },
    {
      no: "129",
      name: "Raghavendra",
    },
    {
      no: "130",
      name: "Srinivasan",
    },
    {
      no: "131",
      name: "Srinivasan",
    },
  ];

  const executionList = ["Normal", "Execution"];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* title */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomInput
              success={title.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.title
                  ? true
                  : false
              }
              labelText={<span>Title*</span>}
              id="title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "title",
                value: title,
                onChange: (event) => {
                  settitle(event.target.value);
                  delete formError.title;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.title && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.title}
              </p>
            )}
          </GridItem>
          {/* describe */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="describe" style={{ marginLeft: "12px" }}>
                Description
              </InputLabel>
              <OutlinedInput
                multiline
                rows={10}
                name="describe"
                value={describe}
                onChange={(event) => {
                  setdescribe(event.target.value);
                  //   delete formError.describe;
                }}
                id="describe"
                color="primary"
                label="Description"
              />
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.describe && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.describe}
              </p>
            )} */}
          </GridItem>
          {/* budget */}
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <CustomInput
              success={budget.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.budget
                  ? true
                  : false
              }
              labelText={<span>Budget*</span>}
              id="budget"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "budget",
                value: budget,
                onChange: (event) => {
                  setbudget(event.target.value);
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {/* {Object.keys(formError).length > 0 && formError.budget && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.budget}
              </p>
            )} */}
          </GridItem>
          {/* location */}
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <CustomInput
              success={location.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.location
                  ? true
                  : false
              }
              labelText={<span>Location*</span>}
              id="location"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "location",
                type: "text",
                value: location,
                onChange: (event) => {
                  setlocation(event.target.value);
                  delete formError.location;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {Object.keys(formError).length > 0 && formError.location && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.location}
              </p>
            )}
          </GridItem>
          {/* industries */}
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="industries" className={classes.selectLabel}>
                <span id="industry">Choose industry</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={industries}
                onChange={(event) => {
                  setindustries(event.target.value);
                }}
                inputProps={{
                  name: "industries",
                  id: "industries",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose industry
                </MenuItem>
                {industriesAll.map((industry) => {
                  return (
                    <MenuItem
                      key={industry.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={industry.id}
                    >
                      {industry.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          {/* sub_cat */}
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="category" className={classes.selectLabel}>
                <span id="categoryLable">
                  Choose Skills <small>(required)</small>
                </span>
              </InputLabel>
              <Select
                
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={sub_cat}
                onChange={(value) => {
                  setsub_cat(value.target.value);
                  setFormError({});
                }}
                inputProps={{
                  name: "category",
                  id: "category",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Skills
                </MenuItem>
                {categoryList.map((category) => {
                  return (
                    <MenuItem
                      key={category.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={category.id}
                    >
                      {category.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          {/* typeofproject */}
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="typeofproject"
                className={classes.selectLabel}
              >
                <span id="typeofproject">Choose Project Type</span>
              </InputLabel>
              <Select
                // multiple
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={typeofproject}
                onChange={(value) => {
                  settypeofproject(value.target.value);
                  setFormError({});
                }}
                inputProps={{
                  name: "typeofproject",
                  id: "typeofproject",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose type of project
                </MenuItem>
                {typeofprojectList.map((typeProject, t) => {
                  return (
                    <MenuItem
                      key={t}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelectedMultiple,
                      }}
                      value={t + 1}
                    >
                      {typeProject}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          {/* time_limit */}
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="time_limit" className={classes.selectLabel}>
                <span id="time_limit">Select Time Limit*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={time_limit}
                onChange={(event) => {
                  settime_limit(event.target.value);
                  delete formError.time_limit;
                }}
                inputProps={{
                  name: "time_limit",
                  id: "time_limit",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose time limit
                </MenuItem>
                {time_limit_list.map((timeList, l) => {
                  return (
                    <MenuItem
                      key={l}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={l + 1}
                    >
                      {timeList}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.time_limit && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.time_limit}
              </p>
            )}
          </GridItem>
          {/* action */}
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="action" className={classes.selectLabel}>
                <span id="action">Project Status</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={action}
                onChange={(event) => {
                  setaction(event.target.value);
                  delete formError.action;
                }}
                inputProps={{
                  name: "action",
                  id: "action",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Project Status
                </MenuItem>
                {action_list.map((actionValue, a) => {
                  return (
                    <MenuItem
                      key={a}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={a}
                    >
                      {actionValue}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.action && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.action}
              </p>
            )}
          </GridItem>
          {/* assigned_to */}
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="assigned_to" className={classes.selectLabel}>
                <span id="assigned_to">Assigned To 1</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={assigned_to}
                onChange={(event) => {
                  setassigned_to(event.target.value);
                  delete formError.assigned_to;
                }}
                inputProps={{
                  name: "assigned_to",
                  id: "assigned_to",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Assigned
                </MenuItem>
                {assignedToList.map((assignedTovalue, t) => {
                  return (
                    <MenuItem
                      key={t}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={assignedTovalue.no}
                    >
                      {assignedTovalue.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.assigned_to && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.assigned_to}
              </p>
            )}
          </GridItem>
          {/* assigned_to_1 */}
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="assigned_to_1"
                className={classes.selectLabel}
              >
                <span id="assigned_to_1">Assigned To 2</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={assigned_to_1}
                onChange={(event) => {
                  setassigned_to_1(event.target.value);
                  // delete formError.assigned_to_1;
                }}
                inputProps={{
                  name: "assigned_to_1",
                  id: "assigned_to_1",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Assigned
                </MenuItem>
                {assignedToList.map((assignedTovalue, t) => {
                  return (
                    <MenuItem
                      key={t}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={assignedTovalue.no}
                    >
                      {assignedTovalue.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.assigned_to_1 && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.assigned_to_1}
              </p>
            )} */}
          </GridItem>
          {/* execution */}
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="execution" className={classes.selectLabel}>
                <span id="execution">Project Type(Execution)</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={execution}
                onChange={(event) => {
                  setexecution(event.target.value);
                  // delete formError.execution;
                }}
                inputProps={{
                  name: "execution",
                  id: "execution",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Project Type(Execution)
                </MenuItem>
                {executionList.map((executionvalue, e) => {
                  return (
                    <MenuItem
                      key={e}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={e + 1}
                    >
                      {executionvalue}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* {Object.keys(formError).length > 0 && formError.execution && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.execution}
              </p>
            )} */}
          </GridItem>

          <GridItem xs={4} sm={6} md={6} lg={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                Update
              </Button>
            </div>
          </GridItem>
          <GridItem xs={4} sm={6} md={6} lg={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => seteditProjectDetailsModal(false)}
              >
                cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

EditProjectDetailsPDForm.propTypes = {
  seteditProjectDetailsModal: PropTypes.func,
  editProjectDetailsModal: PropTypes.bool,
};

export default EditProjectDetailsPDForm;
