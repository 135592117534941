import React from "react"

// import styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

// @material-ui/core"
import { TableBody, TableRow, Table, TableCell } from "@material-ui/core"

// Components
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import DoneAll from "@material-ui/icons/DoneAll";

// Images
import payment_suc from "assets/img/payment_suc.png"

const useStyles = makeStyles(styles);

const PaymentSuccess = () => {
    const classes = useStyles();
    const [status, setStatus] = React.useState("")
    const [plan, setPlan] = React.useState("")
    const [amount, setAmount] = React.useState("")
    const [transactionId, setTransactionId] = React.useState("")

    React.useEffect(() => {
        setStatus("Payment Success")
        setPlan("Basic")
        setAmount("9440")
        setTransactionId("155566")
    }, [])
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <CardBody>
                        <GridContainer alignItems="center">
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <div className="paymentStatus">
                                    <h4 className="paymentSuccess">Payment Success <DoneAll  /> </h4>
                                    <h5>Your payment has been processed</h5>
                                    <h5>Here are the details of your tanscation for your reference</h5>
                                </div>
                                <div className="paymentBox">
                                    <div className={classes.tableResponsive}>
                                        <Table className="table">
                                            <TableBody>
                                                <TableRow className={"tableBackground"}>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>{status}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Plan</TableCell>
                                                    <TableCell>{plan}</TableCell>
                                                </TableRow>
                                                <TableRow className={"tableBackground"}>
                                                    <TableCell>Amount</TableCell>
                                                    <TableCell>{amount}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Transaction ID</TableCell>
                                                    <TableCell>{transactionId}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div className="paymentStatusBtn">
                                        <Button round color="rose">Done</Button>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <div className="paymentImg">
                                    <img src={payment_suc} alt="payment_suc" />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default PaymentSuccess