import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import AuthUser from "components/Sb/AuthUser";
import { useParams } from "react-router-dom";

// Style
import "assets/css/common.css";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// Style
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
const useStyles = makeStyles(styles);

// Input
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// start model
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import stylesModel from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
const useStylesModel = makeStyles(stylesModel);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
// end model

import { OutlinedInput } from "@material-ui/core";

const CallbackNoResponse = ({
  callbackModal,
  setCallbackModal,
  handlesLeadQualificaton,
}) => {
  const classes = useStyles();
  const { http, getUser } = AuthUser();
  const params = useParams();
  // Model
  const classesModel = useStylesModel();

  const [grade, setgrade] = React.useState("");
  const [date, setdate] = React.useState("");
  const [comments, setcomments] = React.useState("");

  React.useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");

    setdate(`${year}-${month}-${day}T${hours}:${minutes}`);
  }, [grade, comments]);

  // FormError
  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // Form Error
  const handleFormError = () => {
    if (comments.trim().length === 0) {
      formErr.comments = "Please Enter Comments";
    }

    if (grade.trim().length === 0) {
      formErr.grade = "Please Select Grade";
    }
  };

  //reset values
  const resolve = () => {
    setFormError({});
    setcomments("");
    setCallbackModal(false);
  };

  // Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const data = {
        date,
        comments,
        grade,
        id: parseInt(params.id),
        user_id: getUser().user_id,
      };
      console.log(data);
      handleLogForm(data);
    }
  };

  const handleLogForm = (formData) => {
    http
      .post("/admin/grade", formData)
      .then((reposne) => {
        console.log(reposne);
        toast.success(`Your grade Updated Successfull!`, {
          position: toast.POSITION.TOP_CENTER,
          onClose: () => resolve(),
          autoClose: 1500,
        });
        handlesLeadQualificaton();
      })
      .catch((err) => {
        toast.error(`Error ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  return (
    <>
      <Dialog
        classes={{
          root: classesModel.center + " " + classesModel.modalRoot,
          paper: classesModel.modal,
        }}
        open={callbackModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setCallbackModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classesModel.modalHeader}
        >
          <Button
            justIcon
            className={classesModel.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setCallbackModal(false);
            }}
          >
            <Close className={classesModel.modalClose} />
          </Button>
          <h4 className={classesModel.modalTitle}>Call Back / No Response</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classesModel.modalBody}
        >
          <form onSubmit={handleFormSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel htmlFor="grade" className={classes.selectLabel}>
                      <span id="gradeLable">Select your grade*</span>
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={grade}
                      onChange={(event) => {
                        setgrade(event.target.value);
                        delete formError.grade;
                      }}
                      inputProps={{
                        name: "grade",
                        id: "grade",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Choose grade
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="NR1-No Response"
                      >
                        NR1-No Response
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="CB-Callback"
                      >
                        CB-Callback
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {Object.keys(formError).length > 0 && formError.grade && (
                    <p style={{ color: "#f44336", textAlign: "left" }}>
                      {formError.grade}
                    </p>
                  )}
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                  <label htmlFor="date">Date and Time:</label>
                  <input
                    type="datetime-local"
                    id="date"
                    value={date}
                    name="date"
                    onChange={(event) => {
                      setdate(event.target.value);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel htmlFor="comments" style={{ marginLeft: "12px" }}>
                    Comments
                  </InputLabel>
                  <OutlinedInput
                    multiline
                    rows={10}
                    name="comments"
                    value={comments}
                    onChange={(event) => {
                      setcomments(event.target.value);
                      delete formError.comments;
                    }}
                    id="requirement"
                    color="primary"
                    label="Project Requirement"
                  />
                </FormControl>
                {Object.keys(formError).length > 0 && formError.comments && (
                  <p style={{ color: "#f44336", textAlign: "left" }}>
                    {formError.comments}
                  </p>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <div style={{ margin: "0px 6px" }}>
                    <Button round color="rose" type="submit">
                      Submit
                    </Button>
                  </div>
                  <div style={{ margin: "0px 6px" }}>
                    <Button
                      round
                      onClick={() => {
                        setCallbackModal(false);
                        resolve();
                      }}
                    >
                      Cancle
                    </Button>
                  </div>
                </div>
                <div></div>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

CallbackNoResponse.propTypes = {
  setCallbackModal: PropTypes.func,
  callbackModal: PropTypes.bool,
  setLeadQualification0Modal: PropTypes.func,
  handlesLeadQualificaton: PropTypes.func,
};

export default CallbackNoResponse;
