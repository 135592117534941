import React from "react"
import { useParams } from "react-router-dom"
import { toast } from 'react-toastify'
import AuthUser from "components/Sb/AuthUser"

import "assets/css/common.css"
// import PropTypes from 'prop-types'
import invoiceLogo from "assets/img/invoiceLogo.png"
// @material-ui/icons
import VerifiedUser from "@material-ui/icons/VerifiedUser";

// Core components
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";




const InvoicePage = () => {
    let { id } = useParams();
    const { http } = AuthUser()

    const [compay, setCompay] = React.useState("")
    const [address, SetAddress] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [gst, setGst] = React.useState("")
    const [date, setdate] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [sacCode, setSacCode] = React.useState("")
    const [rate, setRate] = React.useState("")
    const [state, setState] = React.useState("")
    const [SCgst, setSCgst] = React.useState("")
    const [igst, setIgst] = React.useState("")
    const [totalcost, setTotalcost] = React.useState("")

    React.useEffect(() => {
        setSacCode("3355356")
        http.post("/customer/ViewInvoice", { inv_id: id })
            .then((response) => {
                http.get("/profile")
                    .then((responseProfile) => {
                        if (response.status == 200 || responseProfile.status == 200) {
                            const data = response.data
                            console.log(data, "invoice")
                            // invoice
                            SetAddress(data.address ? data.address : "NA")
                            setGst(data.gst ? data.gst : "NA")
                            setdate(data.date ? data.date : "NA")
                            setDescription(data.project_id > 0 ? (
                                data.description ? data.description : "NA"
                            ) : (
                                data.title ? data.title : "NA"
                            ))
                            setRate(data.totalcost ? (data.totalcost / 1.18) : "NA")
                            setState(data.state ? data.state : "NA")
                            if (data.state == 29) {
                                setSCgst(data.totalcost ? (((data.totalcost / 1.18) * 9) / 100) : "NA")
                            } else {
                                setIgst(data.totalcost ? (((data.totalcost / 1.18) * 18) / 100) : "NA")
                            }
                            setTotalcost(data.totalcost ? data.totalcost : "NA")

                            // profile
                            const dataProfile = responseProfile.data
                            setCompay(data.company ? data.company : dataProfile.firstName)
                            setEmail(dataProfile.email ? dataProfile.email : "NA")

                        }
                        else {
                            //warning msg
                            toast.warn('Oops Something went wrong!', {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose:1500
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)

                    })

            })
            .catch((err) => {
                console.log(err)

            })
    }, [id])

    const printData = () => {
        var tab = document.getElementById('printTable');
        var win = window.open('', '', 'height=800,width=1200');
        win.document.write(tab.outerHTML);
        win.document.close();
        win.print(tab);
    }

    return (
        <>

            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <CardHeader color="warning" icon>
                            <CardIcon color="warning">
                                <VerifiedUser />
                            </CardIcon>
                            <h4 className="card-title">
                                Invoice
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={10} lg={10}>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button round color="info" onClick={() => {
                                            printData()
                                        }}>Print </Button>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10} lg={10}>
                                    <div>
                                        <table
                                            style={{
                                                borderCollapse: "collapse",
                                                borderSpacing: "0",
                                                width: "100%",
                                                margin: "30px 0px"
                                            }}
                                            id="printTable"
                                        >
                                            <thead>
                                                <tr>
                                                    <th colSpan="5"
                                                        style={{
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontWeight: "normal",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal",
                                                            backgroundColor: "#343434",
                                                            borderColor: "inherit",
                                                            color: "#ffffff",
                                                            fontSize: "15px",
                                                            textAlign: "center",
                                                            verticalAlign: 'top'
                                                        }}>
                                                        TAX INVOICE (Original)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            borderColor: "inherit",
                                                            textAlign: "center",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                        colSpan="5">
                                                        <h2 style={{ margin: "2px", fontWeight: "bold" }}>
                                                            SOLBUGGY CONNECT PVT LTD
                                                        </h2>
                                                        <p>
                                                            #249, 2nd floor, 14th Main Rd, Sector 7, H.S.R Layout , Bangalore - 560102
                                                        </p>
                                                        <p>
                                                            GSTIN No: 29AAXCS1211K1ZX
                                                        </p>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{
                                                        backgroundColor: "#efefef",
                                                        borderColor: "inherit",
                                                        textAlign: "left",
                                                        verticalAlign: "top",
                                                        borderStyle: "solid",
                                                        borderWidth: "1px",
                                                        fontFamily: "Arial, sans-serif",
                                                        fontSize: "14px",
                                                        overflow: "hidden",
                                                        padding: "10px 5px",
                                                        wordBreak: "normal"
                                                    }}
                                                        colSpan="3" rowSpan="2">
                                                        <span style={{ fontWeight: "bold" }}>Bill to</span><br /><br />
                                                        <h4 style={{ fontWeight: "bold" }} >{compay},</h4>
                                                        <p>Email: {email} </p>
                                                        <p>Address: {address} </p>
                                                        <p>GSTIN No: {gst}</p>
                                                    </td>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#efefef",
                                                            borderColor: "inherit",
                                                            fontWeight: "bold",
                                                            textAlign: "center",
                                                            verticalAlign: "middle",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                    >Invoice No.</td>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#efefef",
                                                            borderColor: "inherit",
                                                            fontWeight: "bold",
                                                            textAlign: "center",
                                                            verticalAlign: "middle",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                    >Dated</td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#efefef",
                                                            borderColor: "inherit",
                                                            textAlign: "center",
                                                            verticalAlign: "middle",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                    >{id}</td>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#efefef",
                                                            borderColor: "inherit",
                                                            textAlign: "center",
                                                            verticalAlign: "middle",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                    >{date}</td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#d1cece",
                                                            borderColor: "inherit",
                                                            fontWeight: "bold",
                                                            textAlign: "left",
                                                            verticalAlign: "middle",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                    >Description of Goods</td>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#d1cece",
                                                            borderColor: "inherit",
                                                            fontWeight: "bold",
                                                            textAlign: "left",
                                                            verticalAlign: "middle",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                    >SAC Code</td>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#d1cece",
                                                            borderColor: "inherit",
                                                            fontWeight: "bold",
                                                            textAlign: "left",
                                                            verticalAlign: "middle",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}>Rate</td>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#d1cece",
                                                            borderColor: "inherit",
                                                            fontWeight: "bold",
                                                            textAlign: "left",
                                                            verticalAlign: "middle",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}>Qty.</td>
                                                    <td style={{
                                                        backgroundColor: "#d1cece",
                                                        borderColor: "inherit",
                                                        fontWeight: "bold",
                                                        textAlign: "left",
                                                        verticalAlign: "middle",
                                                        borderStyle: "solid",
                                                        borderWidth: "1px",
                                                        fontFamily: "Arial, sans-serif",
                                                        fontSize: "14px",
                                                        overflow: "hidden",
                                                        padding: "10px 5px",
                                                        wordBreak: "normal"
                                                    }}>Amount</td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            borderColor: "inherit",
                                                            textAlign: "left",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }} >
                                                        {description}
                                                    </td>
                                                    <td
                                                        style={{
                                                            borderColor: "inherit",
                                                            textAlign: "center",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }} >{sacCode}</td>
                                                    <td
                                                        style={{
                                                            borderColor: "inherit",
                                                            textAlign: "center",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}>{rate}</td>
                                                    <td
                                                        style={{
                                                            borderColor: "inherit",
                                                            textAlign: "center",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }} >1</td>
                                                    <td
                                                        style={{
                                                            borderColor: "inherit",
                                                            textAlign: "center",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}>{rate}</td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#d1cece",
                                                            borderColor: "inherit",
                                                            fontWeight: "bold",
                                                            textAlign: "right",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }} colSpan="4">Taxable Value</td>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#d1cece",
                                                            borderColor: "inherit",
                                                            fontWeight: "bold",
                                                            textAlign: "center",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                    >{rate}</td>
                                                </tr>
                                                {/* state == 29 -> SGST and CGST (or)  IGST */}
                                                {
                                                    state == "29" ? (
                                                        <>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        borderColor: "inherit",
                                                                        textAlign: "right",
                                                                        verticalAlign: "top",
                                                                        borderStyle: "solid",
                                                                        borderWidth: "1px",
                                                                        fontFamily: "Arial, sans-serif",
                                                                        fontSize: "14px",
                                                                        overflow: "hidden",
                                                                        padding: "10px 5px",
                                                                        wordBreak: "normal"
                                                                    }}
                                                                    colSpan="4">Add SGST 9%</td>
                                                                <td
                                                                    style={{
                                                                        borderColor: "inherit",
                                                                        textAlign: "center",
                                                                        verticalAlign: "top",
                                                                        borderStyle: "solid",
                                                                        borderWidth: "1px",
                                                                        fontFamily: "Arial, sans-serif",
                                                                        fontSize: "14px",
                                                                        overflow: "hidden",
                                                                        padding: "10px 5px",
                                                                        wordBreak: "normal"
                                                                    }}
                                                                >{SCgst}</td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        borderColor: "inherit",
                                                                        textAlign: "right",
                                                                        verticalAlign: "top",
                                                                        borderStyle: "solid",
                                                                        borderWidth: "1px",
                                                                        fontFamily: "Arial, sans-serif",
                                                                        fontSize: "14px",
                                                                        overflow: "hidden",
                                                                        padding: "10px 5px",
                                                                        wordBreak: "normal"
                                                                    }}
                                                                    colSpan="4">Add CGST 9%</td>
                                                                <td
                                                                    style={{
                                                                        borderColor: "inherit",
                                                                        textAlign: "center",
                                                                        verticalAlign: "top",
                                                                        borderStyle: "solid",
                                                                        borderWidth: "1px",
                                                                        fontFamily: "Arial, sans-serif",
                                                                        fontSize: "14px",
                                                                        overflow: "hidden",
                                                                        padding: "10px 5px",
                                                                        wordBreak: "normal"
                                                                    }}
                                                                >{SCgst}</td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        borderColor: "inherit",
                                                                        textAlign: "right",
                                                                        verticalAlign: "top",
                                                                        borderStyle: "solid",
                                                                        borderWidth: "1px",
                                                                        fontFamily: "Arial, sans-serif",
                                                                        fontSize: "14px",
                                                                        overflow: "hidden",
                                                                        padding: "10px 5px",
                                                                        wordBreak: "normal"
                                                                    }}
                                                                    colSpan="4">ADD IGST 18%</td>
                                                                <td
                                                                    style={{
                                                                        borderColor: "inherit",
                                                                        textAlign: "center",
                                                                        verticalAlign: "top",
                                                                        borderStyle: "solid",
                                                                        borderWidth: "1px",
                                                                        fontFamily: "Arial, sans-serif",
                                                                        fontSize: "14px",
                                                                        overflow: "hidden",
                                                                        padding: "10px 5px",
                                                                        wordBreak: "normal"
                                                                    }}
                                                                >{igst}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                                <tr>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#d1cece",
                                                            borderColor: "inherit",
                                                            textAlign: "right",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                        colSpan="4">
                                                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                                                            Total
                                                        </span>
                                                    </td>
                                                    <td
                                                        style={{
                                                            backgroundColor: "#d1cece",
                                                            borderColor: "inherit",
                                                            textAlign: "center",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }}
                                                    >
                                                        <span style={{ fontWeight: "bold" }}>
                                                            {totalcost}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{
                                                            borderColor: "inherit",
                                                            textAlign: "left",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }} colSpan="2" rowSpan="2">
                                                        Company&apos;s PAN: AAXCS1211K
                                                        <br /><br />
                                                        Note - Please make cheques in favor of  &quot;SOLBUGGY CONNECT PVT LTD&quot;
                                                        <br /></td>
                                                    <td
                                                        style={{
                                                            borderColor: "inherit",
                                                            textAlign: "left",
                                                            verticalAlign: "top",
                                                            borderStyle: "solid",
                                                            borderWidth: "1px",
                                                            fontFamily: "Arial, sans-serif",
                                                            fontSize: "14px",
                                                            overflow: "hidden",
                                                            padding: "10px 5px",
                                                            wordBreak: "normal"
                                                        }} colSpan="3" rowSpan="2">
                                                        For SolBuggy Connect Pvt Ltd
                                                        <br />
                                                        <img src={invoiceLogo} alt="invoice log" width={"100px"} height="auto" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <iframe name="print_frame" width="0" height="0" src="about:blank"></iframe> */}
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default InvoicePage