import React from "react";
// import AuthUser from "components/Sb/AuthUser";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PermIdentity from "@material-ui/icons/PermIdentity";
import "assets/css/common.css"

// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon.js";
import ListTimeLine from "./ListTimeLine";
//import DescriptionIcon from '@material-ui/icons/Description';
// import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

function AppliedConsultanList(props) {
    const classes = useStyles();
    // const { http } = AuthUser()
    const { handleTigger,appliedList,project } = props

    const stories = appliedList.map((list) => {
        return {
            badgeColor: "success",
            badgeIcon: PermIdentity,
            titleColor: "success",
            problem_id:list.problem_id,
            action:list.action,
            customer_id: list.customer_id,
            SP_name: list.SP_name,
            date_added: list.date_added,
            offer: (
                <p>
                    {list.offer}
                </p>
            )

        }
    })

    // console.log(appliedList,"appliedList")
    // console.log(project,"project")
    return (
        <GridContainer >
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <PermIdentity />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle} style={{ color: "#3c4858", marginTop: "14px", fontWeight: "500" }}>
                            List of Applicants
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div>
                                    <h5 style={{fontWeight:"400"}}>Total offer:{appliedList.length}</h5>
                                </div>
                                <ListTimeLine simple stories={stories} project={project} handleTigger={handleTigger}/>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer >
    );
}
AppliedConsultanList.propTypes = {
    id: PropTypes.string,
    handleTigger : PropTypes.func,
    project: PropTypes.object,
    appliedList : PropTypes.array

}
export default AppliedConsultanList