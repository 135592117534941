import React from "react"

// @material-ui/icons
import Business from "@material-ui/icons/Business";

// Style
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

// import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
// const useStylesTable = makeStyles(stylesTable);

// Core Component
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableStyle from "components/Table/TableStyle";

// import { Table, TableCell, TableRow, TableBody, } from "@material-ui/core";
// import TableStyle from "components/Table/TableStyle";



const ProjectDetailsShow = () => {
    const classes = useStyles();

    // states
    const [projectTitle, setProjectTitle] = React.useState("")
    const [createdDate, setcreatedDate] = React.useState("")
    const [postedBy, setPostedBy] = React.useState("")
    const [loaction, setLocation] = React.useState("")
    const [industry, setindustry] = React.useState("")
    const [subCategory, setsubCategory] = React.useState("")
    const [typeofProject, setTypeofProject] = React.useState("")
    const [durationofProject, setDurationofProject] = React.useState("")
    const [budgetforProject, setBudgetforProject] = React.useState("")
    const [description, setDescription] = React.useState("")


    React.useEffect(() => {
        setProjectTitle("Food")
        setcreatedDate("30 January 2023, 11:39 am")
        setPostedBy("surendra")
        setLocation("Hyderabad,Telagana")

        setindustry("Food")
        setsubCategory("Food")
        setTypeofProject("New")
        setDurationofProject("< 1 month")
        setBudgetforProject("1.5cr - 2cr")
        setDescription("food industry")
    }, [])

    const data = [
        {
            heading: "Project Title",
            para:`${projectTitle}`
        },
        {
            heading: "Created Date :",
            para: `${createdDate}`
        },
        {
            heading: "Posted By :",
            para: `${postedBy}`
        },
        {
            heading: "Loaction :",
            para:  `${loaction}`
        },
        {
            heading: "Industry :",
            para:  `${industry}`
        },
        {
            heading: "Sub Category/Skills Required :",
            para:  `${subCategory}`
        },
        {
            heading: "Type of Project :",
            para:  `${typeofProject}`
        },
        {
            heading: "Duration of Project :",
            para: `${durationofProject}`
        },
        {
            heading: "Budget for Project :",
            para: `${budgetforProject}`
        },
        {
            heading: "Description :",
            para: `${description}`
        },
    ]

    return (
        <>
            <Card>
                <CardHeader color="info" icon>
                    <CardIcon color="info">
                        <Business />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Company Details</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} lg={12}>
                            <TableStyle data={data} />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </>
    )
}

export default ProjectDetailsShow