import React from "react";
import PropTypes from "prop-types";
const MessageChats = ({ message, handleblockInput }) => {
  //   console.log(message); eventType const [date, time] = message.eventType=="ticket" message.eventDescription
  const [date, setDate] = React.useState("");
  const [time, setTime] = React.useState("");

  React.useEffect(() => {
    const [dateCreate, timeCreate] = message.created.split("T");
    setDate(dateCreate);
    setTime(timeCreate);
  }, []);
  // eventType message.eventType == "ticket"
  return (
    <>
      {((message.operatorName && message.text) ||
        (message.eventType == "broadcastMessage" && message.finalText)) && (
        <div className="sending-message-body">
          {message.operatorName && (
            <p className="sending-message">{message.text}</p>
          )}
          {message.eventType == "broadcastMessage" && (
            <p className="sending-message">{message.finalText}</p>
          )}
          <small className="time-smg time-sending">
            {date} @ {time.slice(0, 5)}
          </small>
        </div>
      )}
      {message.eventType == "ticket" &&
        message.eventDescription ==
          "The chat has been expired (after 24 hours of last received message)" &&
        handleblockInput()}
      {message.eventType == "ticket" && message.eventDescription && (
        <div className="sending-message-body">
          {message.eventDescription && (
            <p className="sending-message">{message.eventDescription}</p>
          )}
          <small className="time-smg time-sending">
            {date} @ {time.slice(0, 5)}
          </small>
        </div>
      )}
      {!message.operatorName && message.text && (
        <div className="reviced-message-body">
          <p className="reviced-message">{message.text}</p>
          <small className="time-smg time-reviced">
            {date} @ {time.slice(0, 5)}
          </small>
        </div>
      )}
    </>
  );
};

MessageChats.propTypes = {
  message: PropTypes.object,
  handleblockInput: PropTypes.func,
};

export default MessageChats;
