import React from 'react'
import "../../assets/css/loader.css"

const Loader = () => {
  return (
    <div>
        <div className="cssload-container">
            <div>
                <div className="cssload-loading"><i></i><i></i><i></i><i></i></div>
                <p>Loading...</p>
            </div>
        </div>
    </div>
  )
}

export default Loader