import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// @material-ui/icons
// import Face from "@material-ui/icons/Face";
// import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
// import Email from "@material-ui/icons/Email";
// import Smartphone from "@material-ui/icons/Smartphone";
import Business from "@material-ui/icons/Business";
// import CalendarToday from "@material-ui/icons/CalendarToday";
// import NoteAdd from "@material-ui/icons/NoteAdd";
// import Laptop from "@material-ui/icons/Laptop";
import LocationCity from "@material-ui/icons/LocationCity";
// import LocationOn from "@material-ui/icons/LocationOn";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { OutlinedInput } from "@material-ui/core";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const ExistingFormLQC1 = (props) => {
  const { handleResetIndPop, industries, } = props;
  const classes = useStyles();
  const params = useParams();
  //   const history = useHistory();
  const { http, getUser } = AuthUser();

  const [industriesAll, setindustriesAll] = React.useState([]);
  const [industry, setIndustry] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [contact_type, setContact_type] = React.useState("");
  const [requirement, setRequirement] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [timeline, setTimeline] = React.useState("");
  const [cat_sel,setCat_sel] = React.useState("")

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // getting industry lists
  React.useEffect(() => {
    http
      .get("/industryList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setindustriesAll(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // handle Form error function
  const handleFormError = () => {
    // 1 industry
    if (industry.length === 0) {
      formErr.industry = "Industry need to select";
    }
    // 2 company
    if (company.trim().length === 0) {
      formErr.company = "Company name is required";
    }
    // 3 category
    if (category.trim().length === 0) {
      formErr.category = "category need to select";
    }
    // 4 contact_type
    if (contact_type.trim().length === 0) {
      formErr.contact_type = "contact type need to select";
    }
    // 5 requirement
    if (requirement.trim().length === 0) {
      formErr.requirement = "Requirement is required";
    }
    // 6 location
    if (location.trim().length === 0) {
      formErr.location = "Location is required";
    }
    // 7 timeline
    if (timeline.trim().length === 0) {
      formErr.timeline = "timeline need to select";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        ind_type: industries,
        industry,
        company,
        category,
        contact_type,
        requirement,
        location,
        timeline,
        cus_id: parseInt(params.id),
        cat_sel,
        user_id: getUser().user_id,
      };
      console.log(formData);
      handleExistingForm(formData);
    }
  };

  const handleExistingForm = (formData) => {
    http
      .post("/admin/lead_questionire", formData)
      .then((reposne) => {
        console.log(reposne);
        resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resolve = () => {
    handleResetIndPop();
    setFormError({});
    toast.success("Successfully updated", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    setIndustry("");
    setCompany("");
    setCategory("");
    setContact_type("");
    setRequirement("");
    setLocation("");
    setTimeline("");
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* industy */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="industry" className={classes.selectLabel}>
                <span id="industry_lable">Select industry vertical*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={industry}
                onChange={(event) => {
                  setIndustry(event.target.value);
                  delete formError.industry;
                }}
                inputProps={{
                  name: "industry",
                  id: "industry",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose industry
                </MenuItem>
                {industriesAll.map((industry) => {
                  return (
                    <MenuItem
                      key={industry.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={industry.id}
                    >
                      {industry.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.industry && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.industry}
              </p>
            )}
          </GridItem>
          {/* company */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={company.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.company
                  ? true
                  : false
              }
              labelText={<span>Company Name*</span>}
              id="company"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "company",
                value: company,
                onChange: (event) => {
                  setCompany(event.target.value);
                  delete formError.company;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Business className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* category */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="category">Project Requirement Category</InputLabel>
              <Select
                native
                value={category}
                onChange={(event) => {
                  setCategory(event.target.value);
                  setCat_sel(event.target.options[event.target.selectedIndex].parentNode.label)
                  delete formError.category;
                }}
                defaultValue=""
                id="category"
                label="Project Requirement Category"
              >
                <option aria-label="None" value="" />
                <optgroup label="Category 1">
                  <option value={"Product Development"}>Product Development</option>
                  <option value={"Product design/R&D"}>Product design/R&D</option>
                  <option value={"Product Formulation/ Shelf life"}>Product Formulation/ Shelf life</option>
                  <option value={"Process Improvement"}>Process Improvement</option>
                  <option value={"Automation"}>Automation</option>
                  <option value={"Diversification Expand/New Industry Setup"}>Diversification Expand/New Industry Setup</option>
                  <option value={"Merger, Aquistion, Joint Venture, Technology transfer"}>Merger, Aquistion, Joint Venture, Technology transfer</option>
                </optgroup>
                <optgroup label="Category 2">
                  <option value={"Product Packaging"}>Product Packaging</option>
                  <option value={"Certification, licenses"}>Certification, licenses</option>
                  <option value={"Organisational Development"}>Organisational Development</option>
                  <option value={"Cost Reduction"}>Cost Reduction</option>
                  <option value={"Lean, Kaizen, Six sigma"}>Lean, Kaizen, Six sigma</option>
                  <option value={"TPM,TQM, PPC"}>TPM,TQM, PPC</option>
                  <option value={"Funding, loans"}>Funding, loans</option>
                  <option value={"Contract Manufacturing"}>Contract Manufacturing</option>
                  <option value={"Machining , tooling, Fabrication"}>Machining , tooling, Fabrication</option>
                  <option value={"Product Identification"}>Product Identification</option>
                  <option value={"Reverse Engineering"}>Reverse Engineering</option>
                  <option value={"Energy management"}>Energy management</option>
                  <option value={"Industry 4.0, Robotics"}>Industry 4.0, Robotics</option>
                </optgroup>
                <optgroup label="Category 3">
                  <option value={"Sales & Marketing"}>Sales & Marketing</option>
                  <option value={"Branding"}>Branding</option>
                  <option value={"Business Developmant"}>Business Developmant</option>
                  <option value={"Supply chain management"}>Supply chain management</option>
                  <option value={"Vendor development"}>Vendor development</option>
                  <option value={"Training"}>Training</option>
                  <option value={"CAD, CAM"}>CAD, CAM</option>
                  <option value={"Strategy Consulting"}>Strategy Consulting</option>
                  <option value={"3D printing"}>3D printing</option>
                  <option value={"Others"}>Others</option>
                </optgroup>
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.category && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.category}
              </p>
            )}
          </GridItem>
          {/* contact_type */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="contact_type"
                className={classes.selectLabel}
              >
                <span id="contact_type_lable">Contact Person Level*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={contact_type}
                onChange={(event) => {
                  setContact_type(event.target.value);
                  delete formError.contact_type;
                }}
                inputProps={{
                  name: "contact_type",
                  id: "contact_type",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose contact type
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"Junior Level"}
                >
                  Junior Level
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"Decision Maker"}
                >
                  Decision Maker
                </MenuItem>
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.contact_type && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.contact_type}
              </p>
            )}
          </GridItem>
          {/* requirement */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel htmlFor="requirement">
                &#173;Complete Customer Requirement&#173;
              </InputLabel>
              <OutlinedInput
                multiline
                rows={10}
                name="requirement"
                value={requirement}
                onChange={(event) => {
                  setRequirement(event.target.value);
                  delete formError.requirement;
                }}
                id="requirement"
                color="primary"
                label="Project Requirement"
              />
            </FormControl>
            {Object.keys(formError).length > 0 && formError.requirement && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.requirement}
              </p>
            )}
          </GridItem>
          {/* location */}
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={location.length > 0 ? true : false}
              error={
                Object.keys(formError).length > 0 && formError.location
                  ? true
                  : false
              }
              labelText={<span>Project Location*</span>}
              id="location"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "location",
                value: location,
                onChange: (event) => {
                  setLocation(event.target.value);
                  delete formError.location;
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <LocationCity className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </GridItem>
          {/* timeline */}
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="timeline" className={classes.selectLabel}>
                <span id="timeline_lable">Project Timeline*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={timeline}
                onChange={(event) => {
                  setTimeline(event.target.value);
                  delete formError.timeline;
                }}
                inputProps={{
                  name: "timeline",
                  id: "timeline",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose Timeline
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"Immediate"}
                >
                  Immediate
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"1 month – 3 months"}
                >
                  1 month – 3 months
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"3 months – 6 months"}
                >
                  3 months – 6 months
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={"6 months"}
                >
                  6 months
                </MenuItem>
              </Select>
            </FormControl>
            {Object.keys(formError).length > 0 && formError.timeline && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.timeline}
              </p>
            )}
          </GridItem>

          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                save
              </Button>
            </div>
          </GridItem>
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => handleResetIndPop()}
              >
                cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

ExistingFormLQC1.propTypes = {
  industries: PropTypes.string,
  handleResetIndPop: PropTypes.func,
};

export default ExistingFormLQC1;
