/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
//import App from './App';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";


import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import CustomerLayout from "layouts/Customer.js";

import configStore from "redux/configStore/configStore";
import { Provider } from "react-redux";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

const store = configStore()
console.log(store.getState())
store.subscribe(() => {
  console.log("changes", store.getState())
})


ReactDOM.render(
  // forceRefresh={true} - using for refresh
  <Provider store={store}>
    <BrowserRouter >
      <Switch>
        <Route path="/rtl" component={RtlLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Route path="/customer" component={CustomerLayout} />
        <Redirect from="/" to="/auth/login-page" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
