
import React from "react";
import PropTypes from "prop-types";
// import AuthUser from "components/Sb/AuthUser";
// import { toast } from "react-toastify";

// @material-ui/styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ProposalStagesFormPD from "./ProposalStagesFormPD";


const ProposalStagesConsultantPD = (props) => {
  const classes = useStyles();
  const { proposalStagesModal } = props;
  // const { http } = AuthUser();

  const [countConsultant, setCountConsultant] = React.useState(0);
  const [countComponentConsult, setCountComponentConsult] = React.useState([]);

  React.useEffect(() => {
    if (!proposalStagesModal) {
      setCountConsultant(0);
      setCountComponentConsult([]);
    }
  }, [proposalStagesModal]);

  const handleIncrementConsultant = () => {
    setCountConsultant((prevousValue) => setCountConsultant(prevousValue + 1));
    setCountComponentConsult([...countComponentConsult, countConsultant + 1]);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <p>Consultant Proposal Stages</p>
          <Button
            round
            color="danger"
            className={classes.registerButton}
            onClick={() => handleIncrementConsultant()}
          >
            Add +
          </Button>
          {countComponentConsult.map((ele, e) => {
            return <ProposalStagesFormPD key={e} countValue={ele} />;
          })}
        </GridItem>
      </GridContainer>
    </>
  );
};

ProposalStagesConsultantPD.propTypes = {
  proposalStagesModal: PropTypes.bool,
};

export default ProposalStagesConsultantPD;
