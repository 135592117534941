import React from "react";

// import Datetime from "react-datetime";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import AuthUser from "components/Sb/AuthUser";
import { useParams } from "react-router-dom";

// Style
import "assets/css/common.css";

// @material-ui/icons
// import Face from "@material-ui/icons/Message";

// core components
// import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// Input
// import InputAdornment from "@material-ui/core/InputAdornment";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// Radio Import Style
// import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
// const useStyles = makeStyles(styles);

const NoteCompany = (props) => {
  const { handlesLeadQualificaton } = props;
  const { http, getUser } = AuthUser();
  const params = useParams();

  const [comments, setcomments] = React.useState("");

  // FormError
  const [formError, setFormError] = React.useState({});
  let formErr = {};

  // Form Error
  const handleFormError = () => {
    if (comments.trim().length === 0) {
      formErr.comments = "Error";
    }
  };

  //reset values
  const resolve = () => {
    setFormError({});
    setcomments("");
  };

  // Form Submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const data = {
        date: `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
        type: 0,
        comments,
        cus_id: parseInt(params.id),
        call_outcome: 0,
        activity: 2,
        user_id: getUser().user_id,
      };
      handleLogForm(data);
      // console.log(data);
    }
  };

  const handleLogForm = (formData) => {
    http
      .post("/admin/log", formData)
      .then((response) => {
        // console.log(reposne);
        if(response.data){
          toast.success(`Your Log Acivity Updated Successfull!`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          handlesLeadQualificaton();
          resolve();
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(`Error in log ${err.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="comments" style={{marginLeft:"12px"}}>Comments</InputLabel>
            <OutlinedInput
              multiline
              rows={10}
              name="comments"
              value={comments}
              onChange={(event) => {
                setcomments(event.target.value);
                delete formError.comments;
              }}
              id="requirement"
              color="primary"
              label="Project Requirement"
            />
          </FormControl>
          {Object.keys(formError).length > 0 && formError.comments && (
            <p style={{ color: "#f44336", textAlign: "left" }}>
              {formError.comments}
            </p>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="input-margin">
            <Button round color="rose" type="submit">
              Submit
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

NoteCompany.propTypes = {
  handlesLeadQualificaton: PropTypes.func,
};

export default NoteCompany;
