import React, { useState } from "react";
import AuthUser from "components/Sb/AuthUser";

import { useHistory } from "react-router-dom";
// toast
import { toast } from 'react-toastify'
import FileCopy from "@material-ui/icons/FileCopy";

import "assets/css/proposalCon.css"

// Import CSS
import "assets/css/common.css"
import "assets/css/industryEditProfile.css"
import defaultImage from "assets/img/default-avatar.png";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";
import LocationCity from "@material-ui/icons/LocationCity";
import Business from "@material-ui/icons/Business";
import People from "@material-ui/icons/People";
import Work from "@material-ui/icons/Work";
import Link from "@material-ui/icons/Link";
//import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Message from "@material-ui/icons/Message";
import Money from "@material-ui/icons/Money";



// @material-ui/core/styles
import { makeStyles } from "@material-ui/core/styles";

import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.js";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

const useStyles = makeStyles(styles);


export default function EditProfileConsultan() {
  const { http,httpAxios} = AuthUser()
  const classes = useStyles();
  const history = useHistory()

  const [bodyToggle, setBodyToggle] = useState(false)
  const [firstFormToggle, setFirstFormToggle] = useState(false)
  const [secondFormToggle, setSecondFormToggle] = useState(false)

  // First step form variables 
  const [firstname, setfirstname] = React.useState("")
  const [lastname, setlastname] = React.useState("")
  const [email, setemail] = React.useState("")
  const [phone, setphone] = React.useState("")
  const [dob, setdob] = React.useState("")
  const [companylogo, Setcompanylogo] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultImage);

  // Second step form variables
  const [city, setCity] = React.useState("")
  const [state, setState] = React.useState("")
  const [address, setAddress] = React.useState("")
  const [stateList, setStateList] = React.useState([])

  // Third step form variables
  const [companyname, setcompanyname] = React.useState("")
  const [industries, setindustries] = React.useState([])
  const [mycurrentposition, setmycurrentposition] = React.useState("")
  const [mytotalnoexp, setMytotalnoexp] = React.useState("")
  const [category, setcategory] = React.useState([])
  // const [skills, setSkills] = React.useState([])
  const [linkedin, setLinkedin] = React.useState("")
  const [resume, setResume] = React.useState("")
  const [brief_bio, setbrief_bio] = React.useState("")
  const [industriesList, setindustriesList] = React.useState([])
  const [categoryList, setcategoryList] = React.useState([])
  // const [skillList, setskillList] = React.useState([])

  // Fourt step form variable
  const [mc_hour, setmc_hour] = React.useState("")
  const [mc_location, setmc_location] = React.useState("")
  const [mc_cost, setmc_cost] = React.useState("")

  const [fileName, setfileName] = React.useState("")

  // Error
  const [fileErrorMsg, setFileErrorMsg] = React.useState("")
  const [formError, setFormError] = React.useState({})



  let formErr = {}

  const handleBodyToggle = () => {
    setBodyToggle(!bodyToggle)
  }
  const handleFirstFormToggle = () => {
    setFirstFormToggle(!firstFormToggle)
  }
  const handleSecondFormToggle = () => {
    setSecondFormToggle(!secondFormToggle)
  }

  React.useEffect(() => {
    http.get("/stateList")
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          setStateList(data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  React.useEffect(() => {
    http.get("/industryList")
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          setindustriesList(data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  React.useEffect(() => {
    http.get("/categoryList")
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          setcategoryList(data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  // customer/categoryDepDropdown
  // const categoryApi = (id) => {
  //   const $indId = { industries: id }
  //   http.post("customer/categoryDepDropdown", $indId)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         const data = response.data
  //         setcategoryList(data)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }

  // customer/killDepDropdown
  // const skillApi = (id) => {
  //   const $indId = { industries: id }
  //   http.post("customer/skillDepDropdown", $indId)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         const data = response.data
  //         setskillList(data)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }

  // Form input from Api
  React.useEffect(() => {
    profileUpdate()
  }, [])

  const profileUpdate = () => {
    http.get("/profile")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          // const dob =  data.dob ? `${data.dob.slice(0, 4)} - ${data.dob.slice(5, 7)} - ${data.dob.slice(8, 10)}` : ""
          http.get("/customer/customerIndustries")
            .then((response) => {
              if (response.status === 200) {
                const datasIndustries = response.data
                let result = []
                datasIndustries.forEach((ele) => {
                  result.push(ele.industries_id)
                })
                setindustries(result)
              }
            })
            .catch((err) => {

              console.log(err)

            })

          http.get("/customer/customerCategory")
            .then((response) => {
              if (response.status === 200) {
                const dataSkills = response.data
                let result = []
                dataSkills.forEach((ele) => {
                  result.push(ele.category_id)
                })
                setcategory(result)
              }
            })
            .catch((err) => {

              console.log(err)

            })
          setemail(data.email ? data.email : "")
          setfirstname(data.firstname ? data.firstname : "")
          setlastname(data.lastname ? data.lastname : "")
          setphone(data.phone ? data.phone : "")
          setdob(data.dob ? data.dob : "")
          setCity(data.city ? data.city : "")
          setState(data.state ? data.state : "")
          setAddress(data.address ? data.address : "")
          setcompanyname(data.companyname ? data.companyname : "")
          setmycurrentposition(data.mycurrentposition ? data.mycurrentposition : "")
          setMytotalnoexp(data.mytotalnoexp ? data.mytotalnoexp : "")
          setLinkedin(data.linkedin ? data.linkedin : "")
          setbrief_bio(data.brief_bio ? data.brief_bio : "")
          setmc_cost(data.mc_cost ? data.mc_cost : "")
          setmc_hour("0-4")
          setmc_location("Pan India")
          setImagePreviewUrl(data.companylogo ? 'https://api.solutionbuggy.com/customerProfile/' + data.companylogo : defaultImage)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //  Updating profile pic
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    if (newFile) {
      reader.readAsDataURL(newFile);
    }
  };
  const handleImg = (e) => {
    Setcompanylogo(e.target.files[0])
  }

  // Adding class Success and error msg
  // const lableColor = (value, idName) => {
  //   let lableName = document.getElementById(idName)
  //   if (value.length === 0) {
  //     lableName.classList.add("errorMsg")
  //   } else {
  //     if (lableName.classList.contains("errorMsg")) {
  //       lableName.classList.remove("errorMsg")
  //       lableName.classList.add("successMsg")
  //     } else {
  //       lableName.classList.add("successMsg")
  //     }
  //   }
  // }

  // First form Error checking and toggle
  // const verifyNumber = (value) => {
  //   var numberRex = new RegExp("^[0-9]+$");
  //   if (numberRex.test(value)) {
  //     return true;
  //   }
  //   return false;
  // };

  const firstStepError = () => {
    if (firstname.trim().length === 0) {
      formErr.firstname = "Error"
    }
    if (lastname.trim().length === 0) {
      formErr.lastname = "Error"
    }

    if (phone.trim().length < 10) {
      formErr.phone = "Error"
    }
  }

  const handleFirstStepForm = () => {
    firstStepError()
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr)
    } else {
      const data = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone,
        dob: dob,
        companylogo: companylogo
      }

      httpAxios.post("/customer/editSPAccount", data)
        .then((response) => {
          if (response.status === 200) {
            setFormError({})
            formErr = {}
            handleFirstFormToggle()
          }
        }).catch((err) => {
          console.log(err)
        })

    }
  }







  // Second form Error checking and toggle
  const secondStepError = () => {
    if (city.trim().length === 0) {
      formErr.city = "Error"
    }
    if (state.length === 0) {
      formErr.state = "State need to select"
    }

    if (address.trim().length === 0) {
      formErr.address = "Error"
    }
  }
  const handleSecondStepForm = () => {
    secondStepError()
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr)
    } else {
      const data = {
        city: city,
        state: state,
        address: address
      }
      http.post("/customer/editSPPersonal", data)
        .then((response) => {
          if (response.status === 200) {
            setFormError({})
            formErr = {}
            handleBodyToggle()
          }
          else {
            //warning msg
            toast.error('Oops something went wrong!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose:1500
              // onClose: () => history.push('/customer/user-page'),
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  // Third form Error checking and toggle
  const thirdStepError = () => {
    if (companyname.trim().length === 0) {
      formErr.companyname = "Error"
    }

    if (industries.length === 0) {
      formErr.industries = "Error"
    }

    if (mycurrentposition.trim().length === 0) {
      formErr.mycurrentposition = "Error"
    }
    if (mytotalnoexp.length === 0) {
      formErr.mytotalnoexp = "Error"
    }
    if (category.length === 0) {
      formErr.category = "Error"
    }
    // if (skills.length === 0) {
    //   formErr.skills = "Error"
    // }
    if (brief_bio.trim().length === 0) {
      formErr.brief_bio = "Error"
    }

    //upload file validation
    let element = document.getElementById("custom-file-upload");

    // if (!resume) {
    //     formErr.resume = "Error in resume"
    //     element.classList.add("custom-error");
    //     setFileErrorMsg("Please! Select your file");
    // }
   if(resume) {
        //file size validation
        // const MIN_FILE_SIZE = 1024 // 1MB
        const MAX_FILE_SIZE = 5120 // 5MB

        const fileSizeKiloBytes = resume.size / 1024

        // if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        //     formErr.paymentDoc = "Error in paymentDoc"
        //     element.classList.add("custom-error");
        //     setFileErrorMsg("File size is less than minimum limit");
        // }

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            formErr.resume = "Error in resume"
            element.classList.add("custom-error");
            setFileErrorMsg("File size is greater than maximum limit");
        }

    }



  }

  const handleThirdStepForm = () => {
    thirdStepError()
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr)
    } else {
      const data = {
        companyname: companyname,
        mycurrentposition: mycurrentposition,
        mytotalnoexp: mytotalnoexp,
        linkedin: linkedin,
        brief_bio: brief_bio,
        resume: resume,
        industries:industries,
        category:category
        

      }
      httpAxios.post("/customer/editSPCareer", data)
        .then((response) => {
          if (response.status === 200) {
            setFormError({})
            formErr = {}
            handleSecondFormToggle()
          }
          else {
            //warning msg
            toast.error('Oops something went wrong!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose:1500
              // onClose: () => history.push('/customer/user-page'), 
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  // Fourht form Error checking and toggle
  const fourthStepError = () => {
    if (mc_hour.trim().length === 0) {
      formErr.mc_hour = "Error"
    }
    if (mc_location.trim().length === 0) {
      formErr.mc_location = "Error"
    }
    if (mc_cost.trim().length === 0) {
      formErr.mc_cost = "Error"
    }
  }

  // Main Form Submit 
  const handleFormSubmit = (e) => {
    e.preventDefault()
    fourthStepError()

    if (Object.keys(formErr).length > 0) {
      setFormError(formErr)
    } else {
      const data = {
        mc_location: mc_location,
        mc_hour: mc_hour,
        mc_cost: mc_cost
      }
      http.post("/customer/microEdit", data)
        .then((response) => {
          if (response.status === 200) {
            setFormError({})
            formErr = {}
            toast.success('Your Profile Updated Successfull!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose:1500,
              onClose: () => history.push('/customer/user-page'),
            })
          }
          else {
            //warning msg
            toast.error('Oops something went wrong!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose:1500
              // onClose: () => history.push('/customer/user-page'),
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const fileHandle = (e) => {
    var file = e.target.files;
    setFileErrorMsg("");

    if (file.length > 0) {
      setfileName(file[0].name);
    }

    var element = document.getElementById("custom-file-upload");
    if (element.classList.contains("custom-error")) {
      element.classList.remove("custom-error");
      element.classList.add("custom-success");
    }
    else {
      element.classList.add("custom-success");
    }

  }


  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={11}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <PermIdentity />
            </CardIcon>
            <h4 className="cardHeader">Edit Profile</h4>
            <GridItem xs={12} sm={12} md={12}>
              {
                !bodyToggle ? (
                  <>
                    <div className="editTile postProjectToggler consultanEditTile">
                      <h5 className={firstFormToggle ? "" : "activeTitle"} >Edit Account Details</h5>
                      <h5 className={firstFormToggle ? "activeTitle" : ""} >Edit Personal Details</h5>
                      <h5 >Edit Career Details</h5>
                      <h5 >Micro-Consulting Details</h5>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="editTile postProjectToggler consultanEditTile">
                      <h5 >Edit Account Details</h5>
                      <h5 >Edit Personal Details</h5>
                      <h5 className={secondFormToggle ? "" : "activeTitle"} >Edit Career Details</h5>
                      <h5 className={secondFormToggle ? "activeTitle" : ""} >Micro-Consulting Details</h5>
                    </div>
                  </>
                )
              }
            </GridItem>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleFormSubmit}>
              {
                !bodyToggle ? (
                  <>
                    {
                      !firstFormToggle ? (
                        <>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Card>
                                <CardHeader>
                                  <h4 style={{ color: "#3c4858", fontWeight: "500", textAlign: "center" }}>
                                    Edit Account Details
                                  </h4>
                                </CardHeader>
                                <CardBody>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12}>
                                      <div className="picture-container">
                                        <div className="picture">
                                          <img src={imagePreviewUrl} className="picture-src" alt="..." />
                                          <input type="file" onChange={(e) => { handleImageChange(e), handleImg(e) }} name="companylogo" />
                                        </div>
                                        <h6 className="description">Choose Picture</h6>
                                      </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={firstname.length ? true : false}
                                        error={(Object.keys(formError).length > 0 && formError.firstname) ? true : false}
                                        labelText={
                                          <span >
                                            First name <small>(required)</small>
                                          </span>
                                        }
                                        id="firstname"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}

                                        inputProps={{
                                          value: firstname,
                                          name: "firstname",
                                          onChange: (event) => {
                                            setfirstname(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <Face className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={lastname.length ? true : false}
                                        error={(Object.keys(formError).length > 0 && formError.lastname) ? true : false}
                                        labelText={
                                          <span >
                                            Last name <small>(required)</small>
                                          </span>
                                        }
                                        id="lastname"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}

                                        inputProps={{
                                          value: lastname,
                                          name: "lastname",
                                          onChange: (event) => {
                                            setlastname(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <RecordVoiceOver className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={email.length ? true : false}
                                        labelText={
                                          <span >
                                            Email
                                          </span>
                                        }
                                        id="email"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}

                                        inputProps={{
                                          value: email,
                                          name: "email",
                                          disabled: true,
                                          onChange: (event) => {
                                            setemail(event.target.value)
                                            setFormError({})
                                          },

                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <Email className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={phone.length > 0 ? true : false}
                                        error={(Object.keys(formError).length > 0 && formError.phone) ? true : false}
                                        labelText={
                                          <span >
                                            Phone Number <small>(required)</small>
                                          </span>
                                        }
                                        id="phone"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        multiline
                                        inputProps={{
                                          value: phone,
                                          name: "phone",

                                          onChange: (event) => {
                                            setphone(event.target.value)
                                            setFormError({})
                                          },
                                          type: "text",
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <Smartphone className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        labelText={
                                          <span >
                                            Date of Brith
                                          </span>
                                        }
                                        id="dob"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        multiline
                                        inputProps={{
                                          value: dob,
                                          name: "dob",

                                          onChange: (event) => {
                                            setdob(event.target.value)
                                            setFormError({})
                                          },
                                          type: "date",
                                          // endAdornment: (
                                          //   <InputAdornment position="end" className={classes.inputAdornment}>
                                          //     <Smartphone className={classes.inputAdornmentIcon} />
                                          //   </InputAdornment>
                                          // ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                      <div className="edit-btn-industry" >
                                        <Button round color="rose" onClick={handleFirstStepForm}>Next</Button>
                                      </div>
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </>
                      ) : (
                        <>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Card>
                                <CardHeader>
                                  <h4 style={{ color: "#3c4858", fontWeight: "500", textAlign: "center" }}>
                                    Edit Personal Details
                                  </h4>
                                </CardHeader>
                                <CardBody>
                                  <GridContainer>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={city.length ? true : false}
                                        error={(Object.keys(formError).length > 0 && formError.city) ? true : false}
                                        labelText={
                                          <span >
                                            City <small>(required)</small>
                                          </span>
                                        }
                                        id="city"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        multiline
                                        inputProps={{
                                          value: city,
                                          name: "city",
                                          onChange: (event) => {
                                            setCity(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <LocationCity className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <FormControl fullWidth className={classes.selectFormControl}>
                                        <InputLabel
                                          htmlFor="state"
                                          className={classes.selectLabel}
                                        >
                                          <span id="stateLable">Choose state <small>(required)</small></span>
                                        </InputLabel>
                                        <Select
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          value={state}
                                          onChange={(value) => {
                                            setState(value.target.value);
                                            setFormError({})
                                          }}
                                          inputProps={{
                                            name: "state",
                                            id: "state",
                                          }}
                                        >
                                          <MenuItem
                                            disabled
                                            classes={{
                                              root: classes.selectMenuItem,
                                            }}
                                          >
                                            Choose State
                                          </MenuItem>
                                          {
                                            stateList.map((states) => {
                                              return <MenuItem
                                                key={states.state_id}
                                                classes={{
                                                  root: classes.selectMenuItem,
                                                  selected: classes.selectMenuItemSelected,
                                                }}
                                                value={states.name}
                                              >
                                                {states.name}
                                              </MenuItem>
                                            })
                                          }
                                        </Select>
                                      </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={address.length ? true : false}
                                        error={(Object.keys(formError).length > 0 && formError.address) ? true : false}
                                        labelText={
                                          <span >
                                            Address <small>(required)</small>
                                          </span>
                                        }
                                        id="address"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        multiline
                                        inputProps={{
                                          value: address,
                                          name: "address",
                                          onChange: (event) => {
                                            setAddress(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <Business className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                      <div className="edit-btn-industry" >
                                        <Button round color="info" onClick={handleFirstFormToggle}>Previous</Button>
                                        <Button round color="rose" onClick={handleSecondStepForm}>Next</Button>
                                      </div>
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </>
                      )
                    }
                  </>
                ) : (
                  <>
                    {
                      !secondFormToggle ? (
                        <>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Card>
                                <CardHeader>
                                  <h4 style={{ color: "#3c4858", fontWeight: "500", textAlign: "center" }}>
                                    Edit Career Details
                                  </h4>
                                </CardHeader>
                                <CardBody>
                                  <GridContainer>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={companyname.length ? true : false}
                                        error={(Object.keys(formError).length > 0 && formError.companyname) ? true : false}
                                        labelText={
                                          <span >
                                            Company Name <small>(required)</small>
                                          </span>
                                        }
                                        id="companyname"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        multiline
                                        inputProps={{
                                          value: companyname,
                                          name: "companyname",
                                          onChange: (event) => {
                                            setcompanyname(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <LocationCity className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <FormControl fullWidth className={classes.selectFormControl}>
                                        <InputLabel
                                          htmlFor="industries"
                                          className={classes.selectLabel}
                                        >
                                          <span id="industryLable">Choose industry <small>(required)</small></span>
                                        </InputLabel>
                                        <Select
                                          multiple
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}

                                          value={industries}
                                          onChange={(value) => {
                                            setindustries(value.target.value)
                                            setFormError({})
                                          }}

                                          inputProps={{
                                            name: "industries",
                                            id: "industries",
                                          }}
                                        >
                                          <MenuItem
                                            disabled
                                            classes={{
                                              root: classes.selectMenuItem,
                                            }}
                                          >
                                            Choose industry
                                          </MenuItem>
                                          {
                                            industriesList.map((industry) => {
                                              return <MenuItem
                                                key={industry.id}
                                                classes={{
                                                  root: classes.selectMenuItem,
                                                  selected: classes.selectMenuItemSelectedMultiple,
                                                }}
                                                value={industry.id}
                                              >
                                                {industry.name}
                                              </MenuItem>
                                            })
                                          }
                                        </Select>
                                      </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={mycurrentposition.length ? true : false}
                                        error={(Object.keys(formError).length > 0 && formError.mycurrentposition) ? true : false}
                                        labelText={
                                          <span >
                                            Position <small>(required)</small>
                                          </span>
                                        }
                                        id="mycurrentposition"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        multiline
                                        inputProps={{
                                          value: mycurrentposition,
                                          name: "mycurrentposition",
                                          onChange: (event) => {
                                            setmycurrentposition(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <People className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={mytotalnoexp.length ? true : false}
                                        error={(Object.keys(formError).length > 0 && formError.mytotalnoexp) ? true : false}
                                        labelText={
                                          <span >
                                            Experience <small>(required)</small>
                                          </span>
                                        }
                                        id="mytotalnoexp"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        multiline
                                        inputProps={{
                                          value: mytotalnoexp,
                                          name: "mytotalnoexp",
                                          onChange: (event) => {
                                            setMytotalnoexp(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <Work className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <FormControl fullWidth className={classes.selectFormControl}>
                                        <InputLabel
                                          htmlFor="category"
                                          className={classes.selectLabel}
                                        >

                                          <span id="categoryLable">Choose Skills <small>(required)</small></span>
                                        </InputLabel>
                                        <Select
                                          multiple
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          value={category}
                                          onChange={(value) => {
                                            setcategory(value.target.value);
                                            setFormError({})
                                          }}
                                          inputProps={{
                                            name: "category",
                                            id: "category",
                                          }}
                                        >
                                          <MenuItem
                                            disabled
                                            classes={{
                                              root: classes.selectMenuItem,
                                            }}
                                          >
                                            Choose Skills
                                          </MenuItem>
                                          {
                                            categoryList.map((category) => {
                                              return <MenuItem
                                                key={category.id}
                                                classes={{
                                                  root: classes.selectMenuItem,
                                                  selected: classes.selectMenuItemSelectedMultiple,
                                                }}
                                                value={category.id}
                                              >
                                                {category.name}
                                              </MenuItem>
                                            })
                                          }
                                        </Select>
                                      </FormControl>
                                    </GridItem>
                                    {/* <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <FormControl fullWidth className={classes.selectFormControl}>
                                        <InputLabel
                                          htmlFor="skills"
                                          className={classes.selectLabel}
                                        >

                                          <span id="skills">Choose Sub Skills <small>(required)</small></span>
                                        </InputLabel>
                                        <Select
                                          multiple
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          value={skills}
                                          onChange={(value) => {
                                            setSkills(value.target.value);
                                          }}
                                          inputProps={{
                                            name: "skills",
                                            id: "skills",
                                          }}
                                        >
                                          <MenuItem
                                            disabled
                                            classes={{
                                              root: classes.selectMenuItem,
                                            }}
                                          >
                                            Choose Sub Skills
                                          </MenuItem>
                                          {
                                            skillList.map((skill) => {
                                              return <MenuItem
                                                key={skill.id}
                                                classes={{
                                                  root: classes.selectMenuItem,
                                                  selected: classes.selectMenuItemSelectedMultiple,
                                                }}
                                                value={skill.id}
                                              >
                                                {skill.name}
                                              </MenuItem>
                                            })
                                          }
                                        </Select>
                                      </FormControl>
                                    </GridItem> */}
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={linkedin.length ? true : false}
                                        labelText={
                                          <span >
                                            Linkedin URL <small>(required)</small>
                                          </span>
                                        }
                                        id="linkedin"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: linkedin,
                                          name: "linkedin",
                                          onChange: (event) => {
                                            setLinkedin(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <Link className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    {/* <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        id="resume"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        labelText={
                                          <span >
                                            Resume <small>(required)</small>
                                          </span>
                                        }
                                        inputProps={{
                                          name: "resume",
                                          type: "file",
                                          value: resume,
                                          onChange: (e) => {
                                            setResume(e.target.files[0]);
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <LibraryBooks className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem> */}
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <div className='custom-div'>
                                        <label className="custom-file-upload" id="custom-file-upload">
                                          <input type="file" onChange={(e) => { setResume(e.target.files[0]); fileHandle(e); }} name="resume"  />
                                          <span className='attach'>Upload your Resume (Max File Size : 5mb)</span> <FileCopy className={classes.inputAdornmentIcon} />
                                          <div className="file-preview">{fileName}</div>

                                        </label>

                                        <span className='custom-error' style={{ fontWeight: "500" }}>{(fileErrorMsg != "") ? fileErrorMsg : ""}</span>

                                      </div>
                                    </GridItem>


                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                      <CustomInput
                                        success={brief_bio.length ? true : false}
                                        error={(Object.keys(formError).length > 0 && formError.brief_bio) ? true : false}
                                        labelText={
                                          <span >
                                            Description <small>(required)</small>
                                          </span>
                                        }
                                        id="brief_bio"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          value: brief_bio,
                                          name: "brief_bio",
                                          onChange: (event) => {
                                            setbrief_bio(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <Message className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                      <div className="edit-btn-industry" >
                                        <Button round color="info" onClick={handleBodyToggle}>Previous</Button>
                                        <Button round color="rose" onClick={handleThirdStepForm}>Next</Button>
                                      </div>
                                    </GridItem>

                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </>
                      ) : (
                        <>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Card>
                                <CardHeader>
                                  <h4 style={{ color: "#3c4858", fontWeight: "500", textAlign: "center" }}>
                                    Edit Micro-Consulting Details
                                  </h4>
                                </CardHeader>
                                <CardBody>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <FormControl fullWidth className={classes.selectFormControl}>
                                        <InputLabel
                                          htmlFor="mc_hour"
                                          className={classes.selectLabel}
                                        >
                                          <span id="mc_hourLable">Number of hours that you are available <small>(required)</small></span>
                                        </InputLabel>
                                        <Select

                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          value={mc_hour}
                                          onChange={(value) => {
                                            setmc_hour(value.target.value);
                                            setFormError({})
                                          }}

                                          inputProps={{
                                            name: "mc_hour",
                                            id: "mc_hour",
                                          }}
                                        >
                                          <MenuItem
                                            disabled
                                            classes={{
                                              root: classes.selectMenuItem,
                                            }}
                                          >
                                            Choose Number of hours that you are available
                                          </MenuItem>
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected,
                                            }}
                                            value="0-4"
                                          >
                                            0-4
                                          </MenuItem>
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected,
                                            }}
                                            value="4-10"
                                          >
                                            4-10
                                          </MenuItem>
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected,
                                            }}
                                            value="10-20"
                                          >
                                            10-20
                                          </MenuItem>
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected,
                                            }}
                                            value="20-40"
                                          >
                                            20-40
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <FormControl fullWidth className={classes.selectFormControl}>
                                        <InputLabel
                                          htmlFor="mc_location"
                                          className={classes.selectLabel}
                                        >
                                          <span id="mc_locationLabel">Choose Location <small>(required)</small></span>
                                        </InputLabel>
                                        <Select
                                          MenuProps={{
                                            className: classes.selectMenu,
                                          }}
                                          classes={{
                                            select: classes.select,
                                          }}
                                          value={mc_location}
                                          onChange={(value) => {
                                            setmc_location(value.target.value);
                                            setFormError({})
                                          }}

                                          inputProps={{
                                            name: "mc_location",
                                            id: "mc_location",
                                          }}
                                        >
                                          <MenuItem
                                            disabled
                                            classes={{
                                              root: classes.selectMenuItem,
                                            }}
                                          >
                                            Choose Location
                                          </MenuItem>
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected,
                                            }}
                                            value="North India"
                                          >
                                            North India
                                          </MenuItem>
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected,
                                            }}
                                            value="South India"
                                          >
                                            South India
                                          </MenuItem>
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected,
                                            }}
                                            value="East India"
                                          >
                                            East India
                                          </MenuItem>
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected,
                                            }}
                                            value="West India"
                                          >
                                            West India
                                          </MenuItem>
                                          <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected,
                                            }}
                                            value="Pan India"
                                          >
                                            Pan India
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <CustomInput
                                        success={(Object.keys(formError).length > 0 && formError.mc_cost) ? false : true}
                                        error={(Object.keys(formError).length > 0 && formError.mc_cost) ? true : false}
                                        labelText={
                                          <span >
                                            Cost per hour <small>(required)</small>
                                          </span>
                                        }
                                        id="mc_cost"
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        multiline
                                        inputProps={{
                                          value: mc_cost,
                                          name: "mc_cost",
                                          onChange: (event) => {
                                            setmc_cost(event.target.value)
                                            setFormError({})
                                          },
                                          endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                              <Money className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                      <div className="edit-btn-industry" >
                                        <Button round color="info" onClick={handleSecondFormToggle}>Previous</Button>
                                        <Button round color="rose" type="submit">Submit</Button>
                                      </div>
                                    </GridItem>
                                  </GridContainer>
                                </CardBody>
                              </Card>
                            </GridItem>
                          </GridContainer>
                        </>
                      )
                    }
                  </>
                )
              }
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
