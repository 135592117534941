import React from "react";
import PropTypes from "prop-types";

// core components
// import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import IndustryTypeLQC from "./IndustryTypeLQC";



const LeadQualificationPopup = (props) => {
  const {
    industries,
    setindustries,
    setClassicModal,
    handlesLeadQualificaton,
    setCallbackModal,
    setMarkNotRelevantModal
  } = props;


  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Button color="warning" round onClick={()=>{
              setCallbackModal(true)
              setClassicModal(false)
            }}>
              Callback/No-Response
            </Button>
            <Button color="danger" round onClick={()=>{
              setMarkNotRelevantModal(true)
              setClassicModal(false)
            }}>
              Mark Not Relevant
            </Button>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <p>
            All the details required in this form is mandatory and sales person
            should update it with accurate data without fail as this will be
            used for lead analysis and optimization of campaigns
          </p>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <IndustryTypeLQC
            industries={industries}
            setindustries={setindustries}
            setClassicModal={setClassicModal}
            handlesLeadQualificaton={handlesLeadQualificaton}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};
LeadQualificationPopup.propTypes = {
  industries: PropTypes.string,
  setindustries: PropTypes.func,
  setClassicModal: PropTypes.func,
  handlesLeadQualificaton: PropTypes.func,
  setCallbackModal: PropTypes.func,
  setMarkNotRelevantModal: PropTypes.func,
};
export default LeadQualificationPopup;
