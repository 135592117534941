import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components


// @material-ui/icons

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import { useHistory } from "react-router-dom";

const UpcomingTaskDB = (props) => {
  const {upcomingTask} = props
  // const classesTable = useStylesTable();
  const history = useHistory()
  const [data, setdata] = React.useState([]);

  React.useEffect(() => {
    const result = upcomingTask.map((ele) => {
      ele.action = (
        <Button color="rose" round onClick={()=>{
          history.push(`/admin/company/${ele.id}`)
        }}>
          View
        </Button>
      );
      return ele;
    });
    setdata(result);
  }, [upcomingTask]);
  return (
    <>
      <Card>
        <h4>Upcoming Task (Total : {upcomingTask&&upcomingTask.length})</h4>
        <CardBody>
        <ReactTable
            columns={[
              {
                Header: "ID",
                accessor: "id",
              },
              {
                Header: "Name",
                accessor: "name",
              },
              {
                Header: "Priority",
                accessor: "sales_priority",
              },
              {
                Header: "Task Comments",
                accessor: "comments",
              },
              {
                Header: "Pending from",
                accessor: "date",
              },
              {
                Header: "Grading",
                accessor: "grade",
              },
              {
                Header: "Action",
                accessor: "action",
              },
            ]}
            data={data}
          />
        </CardBody>
      </Card>
    </>
  );
};
UpcomingTaskDB.propTypes = {
  upcomingTask: PropTypes.array,
};
export default UpcomingTaskDB;
