import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";

// components
import GenerateMeetingLink from "./GenerateMeetingLink";
import FilesProjectDetails from "./FilesProjectDetails";
import LogActivity from "views/Admin/Contact/ContactForms/LogActivity";
import NoteCompany from "views/Admin/Contact/ContactForms/NoteCompany";
import CreateTask from "views/Admin/Contact/ContactForms/CreateTask";

// @material-ui/icons
import Business from "@material-ui/icons/Business";

// Style
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);


const ProblemFormNav = () => {
    const classes = useStyles();

    return (
        <>
            <Card>
                <CardHeader color="info" icon>
                    <CardIcon color="info">
                        <Business />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Project Details</h4>
                </CardHeader>
                <CardBody>
                    <NavPills
                        color="info"
                        tabs={[
                            {
                                tabButton: "Log activity",
                                tabContent: (
                                    <LogActivity />
                                ),
                            },
                            {
                                tabButton: "Note",
                                tabContent: (
                                    <NoteCompany />
                                ),
                            },
                            {
                                tabButton: "Create Task",
                                tabContent: (
                                    <CreateTask />
                                ),
                            },
                            {
                                tabButton: "Send Mail",
                                tabContent: (
                                    <span>
                                        <p>Send Mail</p>
                                    </span>
                                ),
                            },
                            {
                                tabButton: "Generate Meeting Link",
                                tabContent: (
                                    <span>
                                        <GenerateMeetingLink />
                                    </span>
                                ),
                            },
                            {
                                tabButton: "Files",
                                tabContent: (
                                    <span>
                                        <FilesProjectDetails />
                                    </span>
                                ),
                            },
                        ]}
                    />
                </CardBody>
            </Card>
        </>
    );
}

export default ProblemFormNav