import React from "react";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";
// import PropTypes from 'prop-types'

// CSS importing
import "assets/css/LoginPage.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// style
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
const useStyles = makeStyles(styles);

function LoginOTPSubmit() {
  const classes = useStyles();
  const textErrorStyles = {
    color: "#fb483a",
    fontWeight: 600,
  };
  const [otp, setOtp] = React.useState("");
  //const [email, setEmail] = React.useState("")
  const { http, setToken, getLoading } = AuthUser();
  const [formErr, setFormErr] = React.useState({});

  const [count, setCount] = React.useState(30);
  React.useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
  }, [count]);

  const successMessage = (user, role, access_token) => {
    toast.success("Otp Verification Successfull!", {
      position: toast.POSITION.TOP_CENTER,
      onClose: () => setToken(user, role, access_token),
      autoClose: 1500,
    });
  };

  let handleResend = () => {
    const data = { email: sessionStorage.otpEmail };
    http
      .post("/loginWithOtp", data)
      .then((res) => {
        if (res.status == 200) {
          console.log(res);
        } else {
          //warning msg
          toast.warn("Oops Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChar = (e) => {
    let len = e.target.value.length;
    if (len > 3) {
      document.getElementById("otp").disabled = true;
      const data = { password: otp, email: sessionStorage.otpEmail };
      http
        .post("/checkOtp", data)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            //console.log(res.data)
            let token = res.data.access_token;
            if (typeof token !== "undefined")
              successMessage(
                res.data.user,
                res.data.role,
                res.data.access_token
              );
          }
        })
        .catch((err) => {
          document.getElementById("otp").disabled = false;
          toast.error(`Server Down Try Later! ${err}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        });
    }
  };

  return (
    <>
      <p>Verify OTP.</p>
      <form>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={otp.length > 0 ? true : false}
              error={
                Object.keys(formErr).length > 0 && formErr.otp ? true : false
              }
              labelText={
                <span>
                  OTP <small>*</small>
                </span>
              }
              id="otp"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                name: "password",
                type: "number",
                id: "otp",
                value: otp,
                onChange: (event) => {
                  setOtp(event.target.value);
                  setFormErr({});
                },
                onKeyUp: handleChar,
                autoComplete: "off",
              }}
            />
            {/* <input type="hidden" name="email" value={otpEmail}/> */}
            <span style={textErrorStyles}>
              {formErr.otp ? formErr.otp : ""}
            </span>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" alignItems="baseline">
          <div>
            {count == 0 ? (
              <>
                <Button round color="rose" onClick={handleResend}>
                  {!getLoading() ? "Resend OTP" : "Please Wait..."}
                </Button>
              </>
            ) : (
              <>
                <span style={{ fontWeight: "800", color: "#fb483a" }}>
                  {count} second to Resend OTP{" "}
                </span>
              </>
            )}
          </div>
        </GridContainer>
      </form>
    </>
  );
}

export default LoginOTPSubmit;
