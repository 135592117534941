/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// import { toast } from "react-toastify";
// import AuthUser from "components/Sb/AuthUser";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import ChangeStageFormPD from "./ChangeStageFormPD";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ChangeStageModelPD({ changeStageModal, setchangeStageModal }) {
  const classes = useStyles();
  // const { http } = AuthUser();

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={changeStageModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setchangeStageModal(false)}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setchangeStageModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Change Stage</h4>
        </DialogTitle>
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
          <ChangeStageFormPD setchangeStageModal={setchangeStageModal} changeStageModal={changeStageModal} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

ChangeStageModelPD.propTypes = {
  changeStageModal: PropTypes.bool,
  setchangeStageModal: PropTypes.func,
};
