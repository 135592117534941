import React from "react"

// Core Component
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"

// Component
import ProjectDetailsShow from "./ProblemDetails/ProjectDetailsShow"
import ProjectTags from "./ProblemDetails/ProjectTags"
import OfferReceived from "./ProblemDetails/OfferReceived"
import ProblemFormNav from "./ProblemDetails/ProblemFormNav"


const ProblemDetails = () => {

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
                <ProjectDetailsShow />
                <ProjectTags />
                <OfferReceived />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
                <ProblemFormNav />
            </GridItem>
        </GridContainer>
    )
}

export default ProblemDetails