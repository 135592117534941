import React from "react";
import { useParams } from "react-router-dom";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

//@material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import PermIdentity from "@material-ui/icons/PermIdentity";
import ArrowBack from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import "assets/css/common.css";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
//import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import AppliedConsultanList from "../ConsultanList/AppliedConsultanList";
import Loader from "views/Loader/Loader";

const useStyles = makeStyles(styles);

export default function IndusrtyViewProjectTable() {
  const classes = useStyles();
  const { http } = AuthUser();
  let { id } = useParams();

  const [projectID, setProjectID] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [type, setType] = React.useState("");
  const [date, setDate] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [industries, setIndustries] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [budget, setBudget] = React.useState("");

  const [colorStatus, setColorStatus] = React.useState("");
  const [project, setProject] = React.useState({});
  const [appliedList, setAppliedList] = React.useState([]);
  const [tigger, setTigger] = React.useState(false);
  const [plan, setPlan] = React.useState({});

  const [manager, setManager] = React.useState("");

  //plan check api
  React.useEffect(() => {
    http
      .get("/customer/ViewPlan")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setPlan(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    const data = { pid: id };

    http
      .post("/customer/getProjectManager", data)
      .then((response) => {
        if (response.status === 200) {
          const res = response.data;
          console.log(res);
          setManager(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //  Project list api
  React.useEffect(() => {
    const formData = new FormData();
    formData.append("p_id", id);
    http
      .post("/customer/ViewProject", formData)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setProject(data);
          // console.log(data)

          if (data.execution == 0) {
            setType("Not defined");
          } else if (data.execution == 1) {
            setType("Normal");
          } else {
            setType("Execution");
          }

          setProjectID(data.id);
          setDate(data.date_added && data.date_added.slice(0, 10));
          setLocation(data.location);
          setDescription(data.describe);
          setIndustries(data.industry);
          setSubCategory(data.cat);
          setBudget(data.budget);
        } else {
          //warning msg
          toast.warn("Oops Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [tigger]);

  React.useEffect(() => {
    if (project.action == 0) {
      if (Object.keys(plan).length > 0) {
        setColorStatus("info");
        setStatus("Under Review");
      } else {
        setColorStatus("rose");
        setStatus("Nots Live");
      }
    } else if (project.action == 1) {
      setColorStatus("primary");
      setStatus("Live");
    } else if (
      project.action == 2 ||
      project.action == 4 ||
      project.action == 5
    ) {
      setColorStatus("success");
      setStatus("Completed");
    } else if (project.action == 3) {
      setColorStatus("warning");
      setStatus("On hold");
    }
  }, [project]);

  //  Project list api
  React.useEffect(() => {
    const formData = new FormData();
    formData.append("p_id", id);

    http
      .post("/customer/SP_applied", formData)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setAppliedList(data);
        } else {
          //warning msg
          toast.warn("Oops Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [tigger]);

  const handleTigger = () => {
    setTigger(!tigger);
  };

  const goBack = () => {
    window.history.back();
  };
  // (project.action == 0 && "rose" ) || (project.action == 1 && "primary" ) || (project.action == 2 && "success" ) || (project.action == 3 && "warning" )

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button
          onClick={goBack}
          className="go-back"
          color="success"
          round
          style={{ fontWeight: 600 }}
        >
          <ArrowBack /> Back
        </Button>
      </GridItem>

      {project && Object.keys(project).length > 0 ? (
        <>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <DescriptionIcon />
                </CardIcon>
                <h4
                  className={classes.cardIconTitle}
                  style={{
                    color: "#3c4858",
                    marginTop: "14px",
                    fontWeight: "500",
                  }}
                >
                  Project ID : {projectID}
                </h4>
              </CardHeader>
              <CardBody>
                <div className={classes.tableResponsive}>
                  <Table className="table">
                    <TableBody>
                      <TableRow className={"tableBackground"}>
                        <TableCell>Status</TableCell>
                        <TableCell>
                          <Button
                            color={colorStatus}
                            round
                            style={{ fontWeight: 600 }}
                          >
                            {status}
                          </Button>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>{type}</TableCell>
                      </TableRow>

                      <TableRow className={"tableBackground"}>
                        <TableCell>Date</TableCell>
                        <TableCell>{date}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell>{location}</TableCell>
                      </TableRow>

                      <TableRow className={"tableBackground"}>
                        <TableCell>Description</TableCell>
                        <TableCell>{description}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Industries</TableCell>
                        <TableCell>{industries}</TableCell>
                      </TableRow>

                      <TableRow className={"tableBackground"}>
                        <TableCell>Sub Category </TableCell>
                        <TableCell>{subCategory}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Budget</TableCell>
                        <TableCell>{budget}</TableCell>
                      </TableRow>
                    </TableBody>
                    {project.action > 0 && project.execution == 2 && (
                      <TableRow>
                        <TableCell>Project Manager</TableCell>
                        <TableCell>{manager === "" ? "NA" : manager}</TableCell>
                      </TableRow>
                    )}
                  </Table>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            {project.execution === 1 &&
              (project.action === 1 ||
                project.action === 2 ||
                project.action === 3) &&
              appliedList.length > 0 && (
                <AppliedConsultanList
                  id={id}
                  handleTigger={handleTigger}
                  appliedList={appliedList}
                  project={project}
                />
              )}
          </GridItem>
        </>
      ) : (
        <GridItem xs={12} sm={12} md={12}>
          <Loader />
        </GridItem>
      )}
    </GridContainer>
  );
}
