import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
//import { useNavigate } from 'react-router-dom';
//import axios from 'axios'
import sbjs from 'sourcebuster';
//import { toast } from 'react-toastify'
import AuthUser from 'components/Sb/AuthUser'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Icon from '@material-ui/core/Icon'

// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline'
import Code from '@material-ui/icons/Code'
import Group from '@material-ui/icons/Group'
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
import Call from '@material-ui/icons/Call'
import Check from '@material-ui/icons/Check'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import InfoArea from 'components/InfoArea/InfoArea.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'

// common style
import styles from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle'
const useStyles = makeStyles(styles)

// Radio
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// Radio style
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);



export default function RegisterPage() {
  const history = useHistory()
  const classesRadio = useStylesRadio()
  //const navigate = useNavigate();
  const { http, getLoading } = AuthUser()
  

  const [checked, setChecked] = React.useState([])

  // const [inputField, setInputField] = useState({
  //   firstname: '',
  //   email: '',
  //   phone: '',
  //   role: "SS"
  // })

  // Error
  const [formError, setFormError] = React.useState({})
  let formErr = {}


  const [firstname, setFirstName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [role, setRole] = React.useState("")

  const [status, setStatus] = React.useState("2")
  const [reg_url, setRegUrl] = React.useState(window.location.href)
  const [howsb, setHowsb] = React.useState("SBRegister")
  const [password, setPassword] = React.useState("SBRegister")








  const [sbfirsttype, setSbFirstType] = React.useState("")
  const [sbfirstsrc, setSbFirstSrc] = React.useState("")
  const [sbfirstmdm, setSbFirstMdm] = React.useState("")

  const [sbfirstcmp, setSbFirstCmp] = React.useState("")
  const [sbfirstcnt, setSbFirstCnt] = React.useState("")
  const [sbfirsttrm, setSbFirstTrm] = React.useState("")



  const [sbcurrenttype, setSbCurrentType] = React.useState("")
  const [sbcurrentsrc, setSbCurrentSrc] = React.useState("")
  const [sbcurrentmdm, setSbCurrentMdm] = React.useState("")

  const [sbcurrentcmp, setSbCurrentCmp] = React.useState("")
  const [sbcurrentCnt, setSbCurrentCnt] = React.useState("")
  const [sbcurrenttrm, setSbCurrentTrm] = React.useState("")

  const [sbfirstaddfd, setSbfirstAddFd] = React.useState("")
  const [sbfirstaddep, setSbfirstAddEp] = React.useState("")
  const [sbfirstaddrf, setSbfirstAddRf] = React.useState("")

  const [sbcurrentaddfd, setSbcurrentAddFd] = React.useState("")
  const [sbcurrentaddep, setSbcurrentAddEp] = React.useState("")
  const [sbcurrentaddrf, setSbcurrentAddRf] = React.useState("")

  const [sbsessionpgs, setSbSessionPgs] = React.useState("")
  const [sbsessioncpg, setSbSessionCpg] = React.useState("")


  const [sbsudatavst, setSbUdataVst] = React.useState("")
  const [sbsudatauip, setSbUdataUip] = React.useState("")




  // checking Form Error function
  const handleFormError = () => {

    // firstname
    if (firstname.trim().length === 0) {
      formErr.firstname = "Firstname is Required!"
    }
    // email
    if (email.trim().length === 0) {
      formErr.email = "Email is Required!"
    }

    // phone
    if (phone.trim().length === 0) {
      formErr.phone = "Phone is Required!"
    }

    // role
    if (role.trim().length === 0) {
      formErr.role = "Please Select one option!"
    }
  }



  // Reset Values 
  const resolve = () => {
    setFormError({})
    setFirstName("")
    setEmail("")
    setPhone("")
    setRole("")

  }






  //   document.getElementsByClassName('sb_first_cnt')[i].value = sb.first.cnt;
  //   document.getElementsByClassName('sb_first_trm'

  // const [inputError, setInputError] = useState({})
  // const [formSubmit, setFormSubmit] = useState(false)

  // const inputsHandler = (e) => {
  //   setInputField((inputField) => ({
  //     ...inputField,
  //     [e.target.name]: e.target.value,
  //   }))
  // }

  const successMessage = (user, role, access_token) => {

    history.push(`/auth/welcome/${access_token}`)
    //setToken(user, role, access_token)
  }

  // const existMessage = () => {
  //   toast.warn('Email is already exist!', {
  //     position: toast.POSITION.TOP_CENTER,
  //     autoClose: 1500
  //   })
  // }

  // const errorMessage = () => {
  //   toast.warn('Oops Invalid data entered!', {
  //     position: toast.POSITION.TOP_CENTER,
  //     autoClose: 1500
  //   })
  // }

  // const error_message = (msg) => {
  //   toast.warn(msg, {
  //     position: toast.POSITION.TOP_CENTER,
  //     autoClose: 1500
  //   })
  // }



  // const validated = (values) => {
  //   const error = {}
  //   var Emailregex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  //   let em = values.email
  //   if (!values.firstname) {
  //     error.firstname = 'First Name is required!'
  //   }
  //   if (!em) {
  //     error.email = 'Email is required!'
  //   } else if (!em.match(Emailregex)) {
  //     error.email = 'Email is Invalid!'
  //   }

  //   if (!values.phone) {
  //     error.phone = 'Phone No is required!'
  //   } else if (isNaN(values.phone)) {
  //     error.phone = 'Only Numbers are allowed!'
  //   } else if (!(values.phone.length >= 10) && !(values.phone.length <= 12)) {
  //     error.phone = 'Phone No is Invalid!'
  //   }

  //   // if (!values.password) {
  //   //   error.password = 'Password is required!'
  //   // }

  //   if (!values.role) {
  //     error.role = 'Role is required!'
  //   }

  //   return error
  // }

  let handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(inputField)
    // setInputError(validated(inputField))
    // setFormSubmit(true)

    handleFormError()
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr)
      console.log(formErr)

      formErr = {}
    }
    else {
      let data = {
        firstname: firstname,
        email: email,
        phone: phone,
        role: role,
        status: status,
        reg_url: reg_url,
        howsb: howsb,
        password: password,
        sb_first_typ: sbfirsttype,
        sb_first_src: sbfirstsrc,
        sb_first_mdm: sbfirstmdm,
        sb_first_cmp: sbfirstcmp,
        sb_first_cnt: sbfirstcnt,
        sb_first_trm: sbfirsttrm,
        sb_current_typ: sbcurrenttype,
        sb_current_src: sbcurrentsrc,
        sb_current_mdm: sbcurrentmdm,
        sb_current_cmp: sbcurrentcmp,
        sb_current_cnt: sbcurrentCnt,
        sb_current_trm: sbcurrenttrm,
        sb_first_add_fd: sbfirstaddfd,
        sb_first_add_ep: sbfirstaddep,
        sb_first_add_rf: sbfirstaddrf,
        sb_current_add_fd: sbcurrentaddfd,
        sb_current_add_ep: sbcurrentaddep,
        sb_current_add_rf: sbcurrentaddrf,
        sb_session_pgs: sbsessionpgs,
        sb_session_cpg: sbsessioncpg,
        sb_udata_vst: sbsudatavst,
        sb_udata_uip: sbsudatauip

      }
      console.log(data)
      http.post("/register", data)
            .then((response) => {
              resolve()
                if (response.status === 200) {
                   console.log(response.data) 
                   
                    let token = response.data.access_token
                    if (typeof token !== 'undefined')
                      successMessage(response.data.user, response.data.role, response.data.access_token,)
                }
            }).catch((err) => {
                console.log(err)
            })
    }



  }

  // useEffect(() => {
  //   if (formSubmit && Object.keys(inputError).length === 0) {
  //     try {
  //       let form_data = document.getElementById('reg_form')
  //       let data = new FormData(form_data)
  //       console.log(data, "datas")

  //       http.post('/register', data).then((res) => {

  //         console.log(res);
  //         if (res.status == 200) {
  //           let token = res.data.tracking
  //           if (typeof token !== 'undefined')
  //             successMessage(res.data.user, res.data.role, res.data.access_token,)

  //         }
  //         if (res.status == 210) existMessage()
  //         if (res.status == 220) errorMessage()

  //       })
  //     } catch (err) {
  //       error_message(err.response.data.message)
  //     }
  //   }
  // }, [inputError])


  useEffect(() => {
    sbjs.init({
      lifetime: 3,
      callback: placeData
    });
  }, [])

  const placeData = (sb) => {
    console.log(sb)

    setSbFirstType(sb.first.typ)
    setSbFirstSrc(sb.first.src)
    setSbFirstMdm(sb.first.mdm)
    setSbFirstCmp(sb.first.cmp)
    setSbFirstCnt(sb.first.cnt)
    setSbFirstTrm(sb.first.trm)

    setSbCurrentType(sb.current.typ)
    setSbCurrentSrc(sb.current.src)
    setSbCurrentMdm(sb.current.mdm)
    setSbCurrentCmp(sb.current.cmp)
    setSbCurrentCnt(sb.current.cnt)
    setSbCurrentTrm(sb.current.trm)

    setSbfirstAddFd(sb.first_add.fd)
    setSbfirstAddEp(sb.first_add.ep)
    setSbfirstAddRf(sb.first_add.rf)

    setSbcurrentAddFd(sb.current_add.fd)
    setSbcurrentAddEp(sb.current_add.ep)
    setSbcurrentAddRf(sb.current_add.rf)

    setSbSessionPgs(sb.session.pgs)
    setSbSessionCpg(sb.session.cpg)

    setSbUdataVst(sb.udata.vst)
    setSbUdataUip(sb.udata.uip)






    // var x = document.getElementsByClassName("sb_first_typ");
    // for (var i = x.length - 1; i >= 0; --i) {
    //   document.getElementsByClassName('sb_first_typ')[i].value = sb.first.typ;
    //   document.getElementsByClassName('sb_first_src')[i].value = sb.first.src;
    //   document.getElementsByClassName('sb_first_mdm')[i].value = sb.first.mdm;
    //   document.getElementsByClassName('sb_first_cmp')[i].value = sb.first.cmp;
    //   document.getElementsByClassName('sb_first_cnt')[i].value = sb.first.cnt;
    //   document.getElementsByClassName('sb_first_trm')[i].value = sb.first.trm;
    //   document.getElementsByClassName('sb_current_typ')[i].value = sb.current.typ;
    //   document.getElementsByClassName('sb_current_src')[i].value = sb.current.src;
    //   document.getElementsByClassName('sb_current_mdm')[i].value = sb.current.mdm;
    //   document.getElementsByClassName('sb_current_cmp')[i].value = sb.current.cmp;
    //   document.getElementsByClassName('sb_current_cnt')[i].value = sb.current.cnt;
    //   document.getElementsByClassName('sb_current_trm')[i].value = sb.current.trm;
    //   document.getElementsByClassName('sb_first_add_fd')[i].value = sb.first_add.fd;
    //   document.getElementsByClassName('sb_first_add_ep')[i].value = sb.first_add.ep;
    //   document.getElementsByClassName('sb_first_add_rf')[i].value = sb.first_add.rf;
    //   document.getElementsByClassName('sb_current_add_fd')[i].value = sb.current_add.fd;
    //   document.getElementsByClassName('sb_current_add_ep')[i].value = sb.current_add.ep;
    //   document.getElementsByClassName('sb_current_add_rf')[i].value = sb.current_add.rf;
    //   document.getElementsByClassName('sb_session_pgs')[i].value = sb.session.pgs;
    //   document.getElementsByClassName('sb_session_cpg')[i].value = sb.session.cpg;
    //   document.getElementsByClassName('sb_udata_vst')[i].value = sb.udata.vst;
    //   document.getElementsByClassName('sb_udata_uip')[i].value = sb.udata.uip;
    // }


  }

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const removeFn = (val) => {
    if (val) {
      let errfn = document.getElementById('errfn');
      errfn.style.display = 'none'
    }
  }

  const removePh = (val) => {
    if (val) {
      let errph = document.getElementById('errph');
      errph.style.display = 'none'
    }
  }
  const removeEm = (val) => {
    if (val) {
      let errem = document.getElementById('errem');
      errem.style.display = 'none'
    }
  }

  const removeRl = (val) => {
    if (val) {
      let errrl = document.getElementById('errrl');
      errrl.style.display = 'none'
    }
  }

  const classes = useStyles()
  const textErrorStyles = {
    color: '#fb483a',
    fontWeight: 600,
    marginLeft: '1vw',
  }
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title="Connect"
                    description="Register and create your profile."
                    icon={Timeline}
                    iconColor="rose"
                  />
                  <InfoArea
                    title="Interface"
                    description="Discuss and interact on your project requirements."
                    icon={Code}
                    iconColor="primary"
                  />
                  <InfoArea
                    title="Teamup"
                    description="Negotiate and successfully complete the project on time."
                    icon={Group}
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  <form
                    className={classes.form}
                    onSubmit={handleSubmit}

                  >
                    {/* account type */}
                    <div>
                      <div style={{ paddingLeft: "18px", display: "flex", alignItems: "center" }}>
                        <div>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={role === "SS"}
                                onChange={(event) => { setRole(event.target.value); removeRl(event.target.value) }}
                                value="SS"
                                name="role"
                                aria-label="SS"
                                icon={
                                  <FiberManualRecord
                                    className={classesRadio.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classesRadio.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classesRadio.radio,
                                  root: classesRadio.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classesRadio.label,
                              root: classesRadio.labelRoot,
                            }}
                            label="Industry"
                          />
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={role === "SP"}
                                onChange={(event) => { setRole(event.target.value); removeRl(event.target.value) }}
                                value="SP"
                                name="role"
                                aria-label="SP"
                                icon={
                                  <FiberManualRecord
                                    className={classesRadio.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classesRadio.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classesRadio.radio,
                                  root: classesRadio.radioRoot,
                                }}
                              />
                            }
                            classes={{
                              label: classesRadio.label,
                              root: classesRadio.labelRoot,
                            }}
                            label="Consultant"
                          />
                        </div>
                      </div>
                      <span style={textErrorStyles} id="errrl">
                        {formError.role ? formError.role : ''}
                      </span>
                    </div>
                    {/* firstname */}
                    <CustomInput
                      success={firstname.length > 0 ? true : false}
                      error={Object.keys(formError).length > 0 && formError.firstname ? true : false}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face
                              className={classes.inputAdornmentIcon}
                              type="hidden"
                            />
                          </InputAdornment>
                        ),
                        placeholder: 'First Name...',
                        name: 'firstname',
                        onChange: (event) => { setFirstName(event.target.value); removeFn(event.target.value) },
                      }}
                      value={firstname}
                    />
                    <span style={textErrorStyles} id="errfn">
                      {formError.firstname ? formError.firstname : ''}
                    </span>


                    {/* email */}
                    <CustomInput
                      // success={email.length > 0 ? true : false}
                      // error={Object.keys(formError).length > 0 && formError.email ? true : false}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'Email...',
                        name: 'email',
                        onChange: (event) => { setEmail(event.target.value); removeEm(event.target.value) },
                      }}
                      value={email}
                    />
                    <span style={textErrorStyles} id="errem">
                      {formError.email ? formError.email : ''}
                    </span>


                    {/* phone */}
                    <CustomInput
                      // success={phone.length > 0 ? true : false}
                      // error={Object.keys(formError).length > 0 && formError.phone ? true : false}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Call className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'Phone...',
                        name: 'phone',
                        onChange: (event) => { setPhone(event.target.value); removePh(event.target.value) },
                      }}
                      value={phone}
                    />
                    <span style={textErrorStyles} id="errph">
                      {formError.phone ? formError.phone : ''}
                    </span>


                    <input type="hidden" name="status" value={status} onInput={setStatus} />
                    <input type="hidden" name="howsb" value={howsb} onInput={setHowsb} />
                    <input type="hidden" name="reg_url" value={reg_url} onInput={setRegUrl} />
                    <input type="hidden" name="password" value={password} onInput={setPassword} />


                    <input type="hidden" name="sb_first_typ" className="sb_first_typ" value={sbfirsttype} />
                    <input type="hidden" name="sb_first_src" className="sb_first_src" value={sbfirstsrc} />
                    <input type="hidden" name="sb_first_mdm" className="sb_first_mdm" value={sbfirstmdm} />
                    <input type="hidden" name="sb_first_cmp" className="sb_first_cmp" value={sbfirstcmp} />
                    <input type="hidden" name="sb_first_cnt" className="sb_first_cnt" value={sbfirstcnt} />
                    <input type="hidden" name="sb_first_trm" className="sb_first_trm" value={sbfirsttrm} />
                    <input type="hidden" name="sb_current_typ" className="sb_current_typ" value={sbcurrenttype} />
                    <input type="hidden" name="sb_current_src" className="sb_current_src" value={sbcurrentsrc} />
                    <input type="hidden" name="sb_current_mdm" className="sb_current_mdm" value={sbcurrentmdm} />
                    <input type="hidden" name="sb_current_cmp" className="sb_current_cmp" value={sbcurrentcmp} />
                    <input type="hidden" name="sb_current_cnt" className="sb_current_cnt" value={sbcurrentCnt} />
                    <input type="hidden" name="sb_current_trm" className="sb_current_trm" value={sbcurrenttrm} />
                    <input type="hidden" name="sb_first_add_fd" className="sb_first_add_fd" value={sbfirstaddfd} />
                    <input type="hidden" name="sb_first_add_ep" className="sb_first_add_ep" value={sbfirstaddep} />
                    <input type="hidden" name="sb_first_add_rf" className="sb_first_add_rf" value={sbfirstaddrf} />
                    <input type="hidden" name="sb_current_add_fd" className="sb_current_add_fd" value={sbcurrentaddfd} />
                    <input type="hidden" name="sb_current_add_ep" className="sb_current_add_ep" value={sbcurrentaddep} />
                    <input type="hidden" name="sb_current_add_rf" className="sb_current_add_rf" value={sbcurrentaddrf} />
                    <input type="hidden" name="sb_session_pgs" className="sb_session_pgs" value={sbsessionpgs} />
                    <input type="hidden" name="sb_session_cpg" className="sb_session_cpg" value={sbsessioncpg} />
                    <input type="hidden" name="sb_udata_vst" className="sb_udata_vst" value={sbsudatavst} />
                    <input type="hidden" name="sb_udata_uip" className="sb_udata_uip" value={sbsudatauip} />


                    <div style={{ marginTop: "1vh" }}>
                      <FormControlLabel
                        classes={{
                          root: classes.checkboxLabelControl,
                          label: classes.checkboxLabel,
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleToggle(1)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={
                          <span>
                            I agree to the{' '}
                            <a href="#pablo">terms and conditions</a>.
                          </span>
                        }
                      />
                    </div>


                    <div className={classes.center}>
                      <Button round color="primary" type="submit">
                        {!getLoading() ? "Get Started" : "Please Wait..."}
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}