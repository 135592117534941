import React from "react"
import { useParams } from "react-router-dom"
import AuthUser from "components/Sb/AuthUser"
import { toast } from 'react-toastify'
// Importing css
import "assets/css/ByMemberShip.css"
import "assets/css/common.css"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CardMembership from "@material-ui/icons/CardMembership";
import Payment from "@material-ui/icons/Payment";
// Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

const CheckoutMemberShip = () => {
    const classes = useStyles();
    let { id } = useParams();
    const { http } = AuthUser()
    console.log(id)
    const [planTitle, setTitle] = React.useState("")
    const [amount, setAmount] = React.useState("")
    const [subTotal, setSubTotal] = React.useState("")
    const [gst, setGST] = React.useState("")
    const [total, setTotal] = React.useState("")
    const [couponCode, setCouponCode] = React.useState("")
    //const [discount, setDiscount] = React.useState("")
    const [merchant, setMerchant] = React.useState("")
    const [hash, setHash] = React.useState("")
    const [paction, setPaction] = React.useState("")
    const [txnid, setTxnid] = React.useState("")
    const [fname, setFname] = React.useState("")
    const [lname, setLname] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [city, setCity] = React.useState("")
    const [state, setState] = React.useState("")
    const [cid, setCid] = React.useState("")
    const [coupon, setCoupon] = React.useState("")
    const [wallet, setWallet] = React.useState("")
    const [formError, setFormError] = React.useState({})

    React.useEffect(() => {

        http.post("/customer/getSubcriberPlane", { id: id })
            .then((response) => {
                if (response.status == 200) {
                    console.log(response.data)

                    setTitle(response.data.planeInfo.title)
                    setAmount(response.data.planeInfo.cost)
                    setSubTotal(response.data.amount)
                    setGST(response.data.gst)
                    //setDiscount("0.00")
                    setTotal(response.data.cost)
                    setMerchant(response.data.merchant_key)
                    setHash(response.data.hash)
                    setPaction(response.data.action)
                    setTxnid(response.data.txnid)
                    setFname(response.data.customerInfo.firstname)
                    setEmail(response.data.customerInfo.email)
                    setPhone(response.data.customerInfo.phone)
                    setCid(response.data.customerInfo.customer_id)


                    if (!response.data.lastname) {
                        setLname("")
                    }
                    else {
                        setLname(response.data.lastname)
                    }

                    if (!response.data.address) {
                        setAddress("")
                    }
                    else {
                        setAddress(response.data.address)
                    }

                    if (!response.data.city) {
                        setCity("")
                    }
                    else {

                        setCity(response.data.city)
                    }

                    if (!response.data.state) {
                        setState("")
                    }
                    else {
                        setState(response.data.state)
                    }

                    if (!response.data.coupon) {
                        setCoupon("")
                    }
                    else {
                        setCoupon(response.data.coupon)
                    }

                    if (!response.data.wallet) {
                        setWallet("0")
                    }
                    else {
                        setWallet(response.data.wallet)
                    }

                }
                else {
                    //warning msg
                    toast.warn('Oops Something went wrong!', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1500
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])








    const hadleFormSubmit = (e) => {
        e.preventDefault()
        if (couponCode.trim().length === 0) {
            setFormError({ couponCode: "Error" })
        } else {
            console.log(couponCode)
            setFormError({})

            http.post("/customer/ApplyCoupon", { id: id, code: couponCode })
                .then((response) => {
                    if (response.status == 200) {
                        let res = response.data
                        if (Object.keys(res).length > 0) { 
                            toast.success('Coupon Code Applied Successfully.', {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 1500
                            })
                        }
                        
                        setTitle(response.data.planeInfo.title)
                        setAmount(response.data.planeInfo.cost)
                        setSubTotal(response.data.amount)
                        setGST(response.data.gst)
                        //setDiscount("0.00")
                        setTotal(response.data.cost)
                        setMerchant(response.data.merchant_key)
                        setHash(response.data.hash)
                        setPaction(response.data.action)
                        setTxnid(response.data.txnid)
                        setFname(response.data.customerInfo.firstname)
                        setEmail(response.data.customerInfo.email)
                        setPhone(response.data.customerInfo.phone)
                        setCid(response.data.customerInfo.customer_id)


                        if (!response.data.lastname) {
                            setLname("")
                        }
                        else {
                            setLname(response.data.lastname)
                        }

                        if (!response.data.address) {
                            setAddress("")
                        }
                        else {
                            setAddress(response.data.address)
                        }

                        if (!response.data.city) {
                            setCity("")
                        }
                        else {

                            setCity(response.data.city)
                        }

                        if (!response.data.state) {
                            setState("")
                        }
                        else {
                            setState(response.data.state)
                        }

                        if (!response.data.coupon) {
                            setCoupon("")
                        }
                        else {
                            setCoupon(response.data.coupon)
                        }

                        if (!response.data.wallet) {
                            setWallet("0")
                        }
                        else {
                            setWallet(response.data.wallet)
                        }
                        setCouponCode("")

                    }
                    else {
                        //warning msg
                        toast.warn('Oops Something went wrong!', {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 1500
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })


        }
    }
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="ticker-wrap">
                    <div className="ticker">
                        <h4 className="ticker_item">
                            Do not close or press back button while processing the payment
                        </h4>
                    </div>
                </div>

            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <CardMembership />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle} style={{ color: "#3c4858", marginTop: "14px", fontWeight: "500" }}>
                            Checkout
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={hadleFormSubmit}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div className={classes.tableResponsive}>
                                        <Table className="table">
                                            <TableHead>
                                                <TableRow className={"tableBackground"}>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>{planTitle}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Amount</TableCell>
                                                    <TableCell>Rs.{parseInt(amount)}</TableCell>
                                                </TableRow>
                                                <TableRow className={"tableBackground"}>
                                                    <TableCell>Subtotal</TableCell>
                                                    <TableCell>Rs.{parseInt(subTotal)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Discount</TableCell>
                                                    <TableCell>Rs.{parseInt(amount) - parseInt(subTotal)}</TableCell>
                                                </TableRow>
                                                <TableRow className={"tableBackground"}>
                                                    <TableCell>GST @ 18%</TableCell>
                                                    <TableCell>{parseInt(gst)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Total</TableCell>
                                                    <TableCell>Rs.{parseInt(total)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div >
                                        <CustomInput
                                            success={couponCode.trim().length > 0}
                                            error={(Object.keys(formError).length > 0 && formError.couponCode) ? true : false}
                                            labelText={
                                                <span className="coupnLabel">
                                                    Coupon Code
                                                </span>
                                            }
                                            id="couponCode"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            multiline
                                            inputProps={{
                                                value: couponCode,
                                                name: "code",
                                                onChange: (event) => {
                                                    setCouponCode(event.target.value)
                                                    // setFormError({})
                                                },
                                            }}
                                        />
                                        <div className="coupnbtndiv">
                                            <Button round color="rose" type="submit" >Submit</Button>
                                        </div>

                                    </div>
                                </GridItem>
                            </GridContainer>
                        </form>

                        <div className="paydiv">
                            <form method="post" name="payuForm" action={paction}>

                                <input type="hidden" name="key" value={merchant} />
                                <input type="hidden" name="hash" value={hash} />
                                <input type="hidden" name="txnid" value={txnid} />

                                <input type="hidden" name="amount" value={total} />
                                <input type="hidden" name="firstname" id="firstname" value={fname} />
                                <input type="hidden" name="email" id="email" value={email} />
                                <input type="hidden" name="phone" value={phone} />
                                <input type="hidden" name="productinfo" value={planTitle} />
                                <input type="hidden" name="surl"
                                    size="64" value="https://api.solutionbuggy.com/paymentSuccess" />
                                <input type="hidden" name="furl"
                                    size="64" value="https://api.solutionbuggy.com/paymentFailed" />
                                <input type="hidden" name="service_provider" value="payu_paisa" size="64" />
                                <input type="hidden" name="lastname" id="lastname" value={lname} />
                                <input type="hidden" name="curl" value="" />
                                <input type="hidden" name="address1" value={address} />
                                <input type="hidden" name="city" value={city} />
                                <input type="hidden" name="state" value={state} />
                                <input type="hidden" name="country" value="India" />
                                <input type="hidden" name="zipcode" value="" />
                                <input type="hidden" name="udf1" value={cid} />
                                <input type="hidden" name="udf2" value={id} />
                                <input type="hidden" name="udf3" value={coupon} />
                                <input type="hidden" name="udf4" value={wallet} />
                                <input type="hidden" name="udf5" value="" />
                                <input type="hidden" name="pg" value="" />
                                <Button round color="success" type="submit">Pay Now</Button>
                            </form>
                        </div>

                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Payment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle} style={{ color: "#3c4858", marginTop: "14px", fontWeight: "500" }}>
                            Bank Details
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div className="paymentDetails">
                                    <div className="paymentHeader">
                                        <h5>Note : For Payment through Wire Transfer/RTGS/NEFT/UPI</h5>
                                    </div>
                                    <div className="paymentBody">
                                        <div className={classes.tableResponsive}>
                                            <Table className="table">
                                                <TableBody>
                                                    <TableRow className={"tableBackground"}>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>SOLBUGGY CONNECT PRIVATE LIMITED</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>Account No</TableCell>
                                                        <TableCell>59209035553216</TableCell>
                                                    </TableRow>
                                                    <TableRow className={"tableBackground"}>
                                                        <TableCell>Bank</TableCell>
                                                        <TableCell>HDFC Bank</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>Branch</TableCell>
                                                        <TableCell>HSR Layout</TableCell>
                                                    </TableRow>
                                                    <TableRow className={"tableBackground"}>
                                                        <TableCell>IFSC Code</TableCell>
                                                        <TableCell>HDFC0001993</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>UPI</TableCell>
                                                        <TableCell>solutionbuggy@upi</TableCell>
                                                    </TableRow>
                                                    <TableRow className={"tableBackground"}>
                                                        <TableCell>Google Pay / Paytm</TableCell>
                                                        <TableCell>9035553216</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <div className="paymentHeader">
                                                <h5>For offline payment : Send Payment Acknowledgement to info@solutionbuggy.com to Activate the Plan</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default CheckoutMemberShip