import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import AuthUser from "components/Sb/AuthUser";
import "assets/css/viewProject.css";

import { toast } from 'react-toastify'

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/style/sweetalert
import { makeStyles } from "@material-ui/core/styles";
const useStylesAlert = makeStyles(Alert);

// @material-ui/core components
import Alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";


// core components
import Badge from "components/Badge/Badge.js";

// Model
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

import Close from "@material-ui/icons/Close";
// import Instruction from "components/Instruction/Instruction.js";
// import noticeModal1 from "assets/img/card-1.jpeg";
// import noticeModal2 from "assets/img/card-2.jpeg";

import styles from "assets/jss/material-dashboard-pro-react/components/timelineStyle.js";
import Button from "components/CustomButtons/Button.js";
import ModleViewProfile from "./ModleViewProfile";

const useStyles = makeStyles(styles);

export default function ListTimeLine(props) {
  const classes = useStyles();
  const { stories, simple, project, handleTigger } = props;
  const { http} = AuthUser()
  const [noticeModal, setNoticeModal] = React.useState(false);
  const [consultant, setConsultant] = React.useState({})

  const classesAlert = useStylesAlert();
  const [alert, setAlert] = React.useState(null);

  const handleAwarded = (customer_id, problem_id) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => confirm(customer_id, problem_id)}
        onCancel={() => cancelDetele()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
        cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
        confirmBtnText="Award"
        cancelBtnText="Cancel"
        showCancel
      >
        You want to award this project to this Consultant!
      </SweetAlert>
    );
  };
  const confirm = (customer_id, problem_id) => {
    http.post("/customer/AwardNormalProject", { problem_id: problem_id, provider_id: customer_id })
      .then((response) => {
        if (response.status === 200) {
          handleTigger()
          toast.success('Awarded Successfull!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose:1500
            // onClose: () => {},
          })
          hideAlert()
        }
        else {
          //warning msg
          toast.warn('Oops Something went wrong!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose:1500
          })
        }
      }).catch((err) => {
        console.log(err)

      })

    //  setAlert(
    //   <SweetAlert
    //     success
    //     style={{ display: "block", marginTop: "-100px" }}
    //     title="Success!"
    //     onConfirm={() => hideAlert()}
    //     onCancel={() => hideAlert()}
    //     confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
    //   >
    //     Awarded successfully.
    //   </SweetAlert>
    // );
  };
  const cancelDetele = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      >
        Project is not awarded :)
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const timelineClass =
    classes.timeline +
    " " +
    cx({
      [classes.timelineSimple]: simple,
    });

  const handleViewProfile = (id) => {
    http.post("/customer/ViewProfile", { "c_id": id })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data
          setConsultant(data)
        } else {
          //warning msg
          toast.warn('Oops Something went wrong!', {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      })
      .catch((err) => {
        console.log(err)

      })
    setNoticeModal(true)
  }

  return (
    <ul className={timelineClass}>
      {stories.map((prop, key) => {
        const panelClasses =
          classes.timelinePanel +
          " " +
          cx({
            [classes.timelinePanelInverted]: prop.inverted || simple,
            [classes.timelineSimplePanel]: simple,
          });
        const timelineBadgeClasses =
          classes.timelineBadge +
          " " +
          classes[prop.badgeColor] +
          " " +
          cx({
            [classes.timelineSimpleBadge]: simple,
          });
        return (
          <li className={classes.item} key={key}>
            {prop.badgeIcon ? (
              <div className={timelineBadgeClasses}>
                <prop.badgeIcon className={classes.badgeIcon} />
              </div>
            ) : null}
            <div className={panelClasses}>
              {prop.SP_name ? (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className={classes.timelineHeading}>
                    <Badge color={prop.titleColor}><span style={{ fontSize: "18px" }}>{prop.SP_name}</span></Badge>
                  </div>
                  <div className={classes.timelineHeading}>
                    <Badge color={(prop.action == 0 && "rose") || (prop.action == 1 && "primary") || (prop.action == 2 && "success") || (prop.action == 3 && "warning")}>
                      <span style={{ fontSize: "12px" }}>
                        {(prop.action == 0 && "Forwarded") || (prop.action == 1 && "Applied") || (prop.action == 2 && "Awarded") || (prop.action == 4 && "Not relevant") || (prop.action == 5 && "Show in rest")}
                      </span>
                    </Badge>
                  </div>
                </div>
              ) : null}
              <div className={classes.timelineBody}><span style={{ fontWeight: "400", color: "#3c4858" }}>Date of Applied :</span> {prop.date_added}</div>
              <label style={{ fontWeight: "400", color: "#3c4858" }}>Description : </label>
              <div className={classes.timelineBody}>{prop.offer}</div>

              <div className="btnDiv">
                <Button color="info" round
                  className={classes.marginRight}
                  onClick={() => handleViewProfile(prop.customer_id)} value={prop.customer_id} id="viewProfileBtn">View Profile</Button>
                {alert}
                {
                  (prop.problem_id === project.id && (prop.action !== 2)) && (
                    <Button
                      color="rose" round style={{ marginLeft: "6px" }} id="awardBtn"
                      onClick={() => {
                        handleAwarded(prop.customer_id, prop.problem_id)
                      }}>
                      Award
                    </Button>
                  )
                }
              </div>

              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal,
                }}
                open={noticeModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setNoticeModal(false)}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
              >
                <DialogTitle
                  id="notice-modal-slide-title"
                  disableTypography
                  className={classes.modalHeader}
                >
                  <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => setNoticeModal(false)}
                  >
                    <Close className={classes.modalClose} />
                  </Button>
                  <h4 className={classes.modalTitle} style={{ color: "#3c4858", fontWeight: "500" }}>Applicant Details</h4>
                </DialogTitle>
                <DialogContent
                  id="notice-modal-slide-description"
                  className={classes.modalBody}
                >
                  {/* <Instruction
                    title="1. Register"
                    text={
                      <span>
                        The first step is to create an account at{" "}
                        <a href="https://www.creative-tim.com/?ref=mdpr-notifications-page">
                          Creative Tim
                        </a>
                        . You can choose a social network or go for the
                        classic version, whatever works best for you.
                      </span>
                    }
                    image={noticeModal1}
                    className={classes.instructionNoticeModal}
                    imageClassName={classes.imageNoticeModal}
                  /> */}
                  <ModleViewProfile consultant={consultant} />
                </DialogContent>
                <DialogActions
                  className={
                    classes.modalFooter + " " + classes.modalFooterCenter
                  }
                >
                  <Button
                    onClick={() => setNoticeModal(false)}
                    color="rose"
                    round
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              {/* {prop.footerTitle ? (
                <h6 className={classes.footerTitle}>{prop.footerTitle}</h6>
              ) : null}
              {prop.footer ? <hr className={classes.footerLine} /> : null}
              {prop.footer ? (
                <div className={classes.timelineFooter}>{prop.footer}</div>
              ) : null} */}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

ListTimeLine.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
  simple: PropTypes.bool,
  project: PropTypes.object,
  handleTigger: PropTypes.func
};
