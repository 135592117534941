import React, { useState } from "react";
import AuthUser from "components/Sb/AuthUser";
// toast
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// Import CSS
import "assets/css/common.css";
import "../../assets/css/industryEditProfile.css";

// @material-ui/icons
import NoteAdd from "@material-ui/icons/NoteAdd";
import Domain from "@material-ui/icons/Domain";
import FileCopy from "@material-ui/icons/FileCopy";

// @material-ui/core/styles
import { makeStyles } from "@material-ui/core/styles";

import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.js";
import Loader from "views/Loader/Loader";

// import PostProjectStep1 from "./PostProjectSteps/PostProjectStep1";
// import PostProjectStep2 from "./PostProjectSteps/PostProjectStep2";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
  selectFormControl: {
    paddingTop: "24px",
  },
};

const useStyles = makeStyles(styles);

export default function IndustryPostProject() {
  const { http, getLoading } = AuthUser();
  const classes = useStyles();
  const history = useHistory();

  const [isToggleFormEdit, setIsToggleFormEdit] = useState(false);

  const [describe, setdescribe] = React.useState("");
  const [industries, setIndustries] = React.useState("");
  const [sub_cat, setsub_cat] = React.useState("");
  const [skills, setSkills] = React.useState(0);
  const [budget, setBudget] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setcity] = React.useState("");

  const [industriesList, setindustriesList] = React.useState([]);
  const [subCategoryList, setSubCategoryLsit] = React.useState([]);
  const [skillList, setSkillList] = React.useState([]);
  const [stateList, setstateList] = React.useState([]);

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  const handleIsToggleFormEdit = () => {
    setIsToggleFormEdit(!isToggleFormEdit);
  };

  // State list api
  React.useEffect(() => {
    http
      .get("/stateList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setstateList(data);
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  // industry List api
  React.useEffect(() => {
    http
      .get("/industryList")
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setindustriesList(data);
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  // customer/categoryDepDropdown
  const categoryApi = (id) => {
    const $indId = { industries: id };
    http
      .post("customer/categoryDepDropdown", $indId)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setSubCategoryLsit(data);
        } else {
          //warning msg
          toast.warn("Oops Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  // customer/killDepDropdown
  const skillApi = (id) => {
    const $indId = { industries: id };
    http
      .post("customer/skillDepDropdown", $indId)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setSkillList(data);
        } else {
          //warning msg
          toast.warn("Oops Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  const handleFormOneError = () => {
    if (describe.trim().length === 0) {
      formErr.describe = "Please give some describe";
    }

    if (industries.toString().length === 0) {
      formErr.industries = "Select industry";
    }

    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    }

    if (describe.length > 0 && industries.toString().length > 0) {
      handleIsToggleFormEdit();
      setFormError({});
    }
  };

  const handleFormError = () => {
    if (describe.trim().length === 0) {
      formErr.describe = "Please give some describe";
    }
    if (industries.length === 0) {
      formErr.industries = "Select industry";
    }

    if (city.trim().length === 0) {
      formErr.city = "Select city";
    }

    if (sub_cat.length === 0) {
      formErr.sub_cat = "Select Sub category";
    }

    // skillsLable
    if (industries == 9 || industries == 18) {
      if (skills.length === 0) {
        formErr.skills = "Select skill";
      }
    }

    if (budget.trim().length === 0) {
      formErr.budget = "Select budget";
    }

    if (state.length === 0) {
      formErr.state = "Select state";
    }
  };

  const RedirectProject = (id) => {
    history.push(`/customer/project-details/${id}`);
  };

  //reset values
  const resolve = () => {
    setdescribe("");
    setIndustries("");
    setsub_cat("");
    setSkills("");
    setBudget("");
    setState("");
    setcity("");
    handleIsToggleFormEdit("");
    setFormError({});
    formErr = {};
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleFormError();

    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = new FormData();
      formData.append("describe", describe);
      formData.append("industries", parseInt(industries));
      formData.append("sub_cat", parseInt(sub_cat));
      formData.append("skills", skills);
      formData.append("budget", budget);
      formData.append("state", state);
      formData.append("city", city);
      http
        .post("/customer/postProject", formData)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            if (response.data.status == 200) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                onClose: () => RedirectProject(response.data.pid),
              });
            } else if (response.data.status == 210) {
              toast.warn(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                onClose: () => RedirectProject(response.data.pid),
              });
            } else if (response.data.status == 220) {
              toast.warn(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                onClose: () => RedirectProject(response.data.pid),
              });
            } else if (response.data.status == 230) {
              toast.warn(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1500,
                onClose: () => RedirectProject(response.data.pid),
              });
            }
            resolve();
            //sent post project related mail api
            postProjectMailSent(response.data.pid);
          }
        })
        .catch((err) => {
          toast.error(err.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1500,
          });
        });
    }
  };

  const postProjectMailSent = (pid) => {
    http
      .post("/customer/postProjectMail", { pid: pid })
      .then((response) => {
        // console.log(response);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12} lg={8}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <FileCopy />
            </CardIcon>
            <h4 className="cardHeader">Post Project</h4>
            <GridItem xs={12} sm={12} md={12}>
              <div className="editTile postProjectToggler">
                <h5
                  className={isToggleFormEdit ? "" : "activeTitle"}
                  onClick={handleIsToggleFormEdit}
                >
                  Brief Description
                </h5>
                <h5
                  className={isToggleFormEdit ? "activeTitle" : ""}
                  onClick={handleFormOneError}
                >
                  Project Details
                </h5>
              </div>
            </GridItem>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleFormSubmit}>
              {!isToggleFormEdit ? (
                <>
                  <GridContainer justify="center">
                    {industriesList &&
                    Object.keys(industriesList).length > 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                          <CardHeader>
                            <h4
                              style={{
                                color: "#3c4858",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              Brief Description
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <GridContainer justify="center">
                              <GridItem xs={12} sm={12} md={10} lg={10}>
                                <CustomInput
                                  success={describe.trim().length > 0}
                                  labelText={
                                    <span>
                                      Brief Requirement{" "}
                                      <small>(required)</small>
                                    </span>
                                  }
                                  id="describe"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  multiline
                                  inputProps={{
                                    value: describe,
                                    name: "describe",
                                    onChange: (event) => {
                                      setdescribe(event.target.value);
                                      delete formError.describe;
                                    },
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        className={classes.inputAdornment}
                                      >
                                        <NoteAdd
                                          className={classes.inputAdornmentIcon}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {Object.keys(formError).length > 0 &&
                                  formError.describe && (
                                    <p style={{ color: "red" }}>
                                      {formError.describe}
                                    </p>
                                  )}
                              </GridItem>
                              <GridItem xs={12} sm={12} md={10} lg={10}>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="industries"
                                    className={classes.selectLabel}
                                  >
                                    <span id="industryLable">
                                      Choose industry <small>(required)</small>
                                    </span>
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{
                                      select: classes.select,
                                    }}
                                    value={industries}
                                    onChange={(value) => {
                                      setIndustries(value.target.value);
                                      categoryApi(value.target.value);
                                      skillApi(value.target.value);
                                      delete formError.industries;
                                    }}
                                    inputProps={{
                                      name: "industries",
                                      id: "industries",
                                    }}
                                  >
                                    <MenuItem
                                      disabled
                                      classes={{
                                        root: classes.selectMenuItem,
                                      }}
                                    >
                                      Choose industry
                                    </MenuItem>
                                    {industriesList.map((industry) => {
                                      return (
                                        <MenuItem
                                          key={industry.id}
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelectedMultiple,
                                          }}
                                          value={industry.id}
                                        >
                                          {industry.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                {Object.keys(formError).length > 0 &&
                                  formError.industries && (
                                    <p style={{ color: "red" }}>
                                      {formError.industries}
                                    </p>
                                  )}
                              </GridItem>
                              <GridItem xs={12} sm={12} md={10} lg={10}>
                                <div className="edit-btn-industry">
                                  <Button
                                    round
                                    color="rose"
                                    onClick={handleFormOneError}
                                  >
                                    Next
                                  </Button>
                                </div>
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                    ) : (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Loader />
                      </GridItem>
                    )}
                  </GridContainer>
                </>
              ) : (
                <>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Card>
                        <CardHeader>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12}>
                              <h4
                                style={{
                                  color: "#3c4858",
                                  fontWeight: "500",
                                  textAlign: "center",
                                }}
                              >
                                Project Details
                              </h4>
                            </GridItem>
                          </GridContainer>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  htmlFor="sub_cat"
                                  className={classes.selectLabel}
                                >
                                  <span id="subCategoryLable">
                                    Choose Sub category{" "}
                                    <small>(required)</small>
                                  </span>
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu,
                                  }}
                                  classes={{
                                    select: classes.select,
                                  }}
                                  value={sub_cat}
                                  onChange={(value) => {
                                    setsub_cat(value.target.value);
                                    delete formError.sub_cat;
                                  }}
                                  inputProps={{
                                    name: "sub_cat",
                                    id: "sub_cat",
                                  }}
                                >
                                  <MenuItem
                                    disabled
                                    classes={{
                                      root: classes.selectMenuItem,
                                    }}
                                  >
                                    Choose sub category
                                  </MenuItem>
                                  {subCategoryList.map((category) => {
                                    return (
                                      <MenuItem
                                        key={category.id}
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelectedMultiple,
                                        }}
                                        value={category.id}
                                      >
                                        {category.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              {Object.keys(formError).length > 0 &&
                                formError.sub_cat && (
                                  <p style={{ color: "red" }}>
                                    {formError.sub_cat}
                                  </p>
                                )}
                            </GridItem>

                            {(industries == 9 || industries == 18) && (
                              <GridItem xs={12} sm={6}>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="skills"
                                    className={classes.selectLabel}
                                  >
                                    <span id="skillsLable">
                                      Choose Skills <small>(required)</small>
                                    </span>
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu,
                                    }}
                                    classes={{
                                      select: classes.select,
                                    }}
                                    value={skills}
                                    onChange={(value) => {
                                      setSkills(value.target.value);
                                      delete formError.skills;
                                    }}
                                    inputProps={{
                                      name: "skills",
                                      id: "skills",
                                    }}
                                  >
                                    <MenuItem
                                      disabled
                                      classes={{
                                        root: classes.selectMenuItem,
                                      }}
                                    >
                                      Choose skills
                                    </MenuItem>
                                    {skillList.map((skill) => {
                                      return (
                                        <MenuItem
                                          key={skill.id}
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelectedMultiple,
                                          }}
                                          value={skill.id}
                                        >
                                          {skill.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                {Object.keys(formError).length > 0 &&
                                  formError.skills && (
                                    <p style={{ color: "red" }}>
                                      {formError.skills}
                                    </p>
                                  )}
                              </GridItem>
                            )}

                            <GridItem xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  htmlFor="budget"
                                  className={classes.selectLabel}
                                >
                                  <span id="budgetLable">
                                    Choose budget <small>(required)</small>
                                  </span>
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu,
                                  }}
                                  classes={{
                                    select: classes.select,
                                  }}
                                  value={budget}
                                  onChange={(value) => {
                                    setBudget(value.target.value);
                                    delete formError.budget;
                                  }}
                                  inputProps={{
                                    name: "budget",
                                    id: "budget",
                                  }}
                                >
                                  <MenuItem
                                    disabled
                                    classes={{
                                      root: classes.selectMenuItem,
                                    }}
                                  >
                                    Country
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value="Less than 10Lac"
                                  >
                                    Less than 10Lac
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value="10Lac - 30Lac"
                                  >
                                    10Lac - 30Lac
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value="30Lac - 60Lac"
                                  >
                                    30Lac - 60Lac
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value="60Lac - 1Cr"
                                  >
                                    60Lac - 1Cr
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value="1Cr - 1.5Cr"
                                  >
                                    1Cr - 1.5Cr
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value="1.5Cr - 2Cr"
                                  >
                                    1.5Cr - 2Cr
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value="1.5Cr - 2Cr"
                                  >
                                    More than 2Cr
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              {Object.keys(formError).length > 0 &&
                                formError.budget && (
                                  <p style={{ color: "red" }}>
                                    {formError.budget}
                                  </p>
                                )}
                            </GridItem>

                            <GridItem xs={12} sm={6}>
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  htmlFor="state"
                                  className={classes.selectLabel}
                                >
                                  <span id="stateLable">
                                    Choose state <small>(required)</small>
                                  </span>
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu,
                                  }}
                                  classes={{
                                    select: classes.select,
                                  }}
                                  value={state}
                                  onChange={(value) => {
                                    setState(value.target.value);
                                    delete formError.state;
                                  }}
                                  inputProps={{
                                    name: "state",
                                    id: "state",
                                  }}
                                >
                                  <MenuItem
                                    disabled
                                    classes={{
                                      root: classes.selectMenuItem,
                                    }}
                                  >
                                    Choose State
                                  </MenuItem>
                                  {stateList.map((states) => {
                                    return (
                                      <MenuItem
                                        key={states.state_id}
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected,
                                        }}
                                        value={states.name}
                                      >
                                        {states.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              {Object.keys(formError).length > 0 &&
                                formError.state && (
                                  <p style={{ color: "red" }}>
                                    {formError.state}
                                  </p>
                                )}
                            </GridItem>

                            <GridItem xs={12} sm={6}>
                              <CustomInput
                                success={city.trim().length > 0}
                                error={
                                  Object.keys(formError).length > 0 &&
                                  formError.city
                                    ? true
                                    : false
                                }
                                labelText={
                                  <span>
                                    City <small>(required)</small>
                                  </span>
                                }
                                id="city"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  value: city,
                                  name: "city",
                                  onChange: (event) => {
                                    setcity(event.target.value);
                                    delete formError.city;
                                  },
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      className={classes.inputAdornment}
                                    >
                                      <Domain
                                        className={classes.inputAdornmentIcon}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {Object.keys(formError).length > 0 &&
                                formError.city && (
                                  <p style={{ color: "red" }}>
                                    {formError.city}
                                  </p>
                                )}
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                              <div className="edit-btn-industry">
                                <Button
                                  type="button"
                                  color="info"
                                  round
                                  onClick={handleIsToggleFormEdit}
                                >
                                  Previous
                                </Button>
                                <Button
                                  type="submit"
                                  round
                                  color="rose"
                                  onClick={handleFormSubmit}
                                >
                                  {!getLoading() ? "Submit" : "Please Wait..."}
                                </Button>
                              </div>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </>
              )}
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
