import React from "react"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import NavPills from "components/NavPills/NavPills.js";

// import { makeStyles } from "@material-ui/core/styles";

// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";

// // core components
// import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import ExistingIndustry from "./ExistingIndustry";
import NewIndustry from "./NewIndustry";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import PermIdentity from "@material-ui/icons/PermIdentity";
import CardBody from "components/Card/CardBody";

  
// import { useDispatch, useSelector } from "react-redux";
// import { startUserList } from "redux/actions/userAction";
// const useStyles = makeStyles(styles);

export default function EditProfileIndustry() {
    // const classes = useStyles();
    // const dispatch = useDispatch();

    // const user = useSelector((state)=>{
    //     return state.user
    // })
    // console.log(9,user)

    // React.useEffect(()=>{
    //     const unsubscribe = dispatch(startUserList())
    //     return unsubscribe
    // },[dispatch])

    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <PermIdentity />
                        </CardIcon>
                        <h4 className="card-title">
                            Edit Profile -  Complete your profile
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <NavPills
                            color="rose"
                            tabs={[
                                {
                                    tabButton: "Edit New Industry",
                                    tabContent: (
                                        <NewIndustry />
                                    ),
                                },
                                {
                                    tabButton: "Edit Existing Industry",
                                    tabContent: (
                                        <ExistingIndustry />
                                    ),
                                },
                            ]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}