import React from "react";

// @material-ui/icons
// import DateRange from '@material-ui/icons/DateRange'
// import Place from '@material-ui/icons/Place'
// import Timeline from '@material-ui/icons/Timeline'
// import WidgetsIcon from '@material-ui/icons/Widgets'
// import PagesIcon from '@material-ui/icons/Pages'
//import MoneyOff from '@material-ui/icons/AttachMoney'
//import Lock from '@material-ui/icons/Lock'
import Apps from "@material-ui/icons/Apps";
import ErrorIcon from "@material-ui/icons/Error";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PermIdentity from "@material-ui/icons/PermIdentity";
import Description from "@material-ui/icons/Description";
import ViewList from "@material-ui/icons/ViewList";
import Image from "@material-ui/icons/Image";
import CardMembership from "@material-ui/icons/CardMembership";
import Receipt from "@material-ui/icons/Receipt";
import Business from "@material-ui/icons/Business";

// Components
// import Buttons from 'views/Components/Buttons.js'
// import Calendar from 'views/Calendar/Calendar.js'
// import Charts from 'views/Charts/Charts.js'
// import ExtendedForms from 'views/Forms/ExtendedForms.js'
// import ExtendedTables from 'views/Tables/ExtendedTables.js'
// import FullScreenMap from 'views/Maps/FullScreenMap.js'
// import GoogleMaps from 'views/Maps/GoogleMaps.js'
// import GridSystem from 'views/Components/GridSystem.js'
// import Icons from 'views/Components/Icons.js'
// import LockScreenPage from 'views/Pages/LockScreenPage.js'
// import Notifications from 'views/Components/Notifications.js'
// import Panels from 'views/Components/Panels.js'
// import RegularForms from 'views/Forms/RegularForms.js'
// import RegularTables from 'views/Tables/RegularTables.js'
// import SweetAlert from 'views/Components/SweetAlert.js'
// import Typography from 'views/Components/Typography.js'
// import ValidationForms from 'views/Forms/ValidationForms.js'
// import VectorMap from 'views/Maps/VectorMap.js'
// import Widgets from 'views/Widgets/Widgets.js'
// import Wizard from 'views/Forms/Wizard.js'
// import TimelinePage from 'views/Pages/Timeline.js'

// Login and register pages
import LoginPage from "views/Pages/LoginPage.js";
import AdminLogin from "views/Pages/AdminLogin";
import RegisterPage from "views/Pages/RegisterPage.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";

// industry and consultant
import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import NotLive from "views/Tables/NotLive.js";
import Live from "views/Tables/Live.js";
import Completed from "views/Tables/Completed.js";
import Onhold from "views/Tables/Onhold.js";
import Latest from "views/Tables/Latest";
import Applied from "views/Tables/Applied";
import Awarded from "views/Tables/Awarded";
import NotAwarded from "views/Tables/NotAwarded";
import LatestExecution from "views/Tables/LatestExecution";
import AppliedExecution from "views/Tables/AppliedExecution";
import AwardedExecution from "views/Tables/AwardedExecution";
import NotAwardedExecution from "views/Tables/NotAwardedExecution";
import ShortlistedExecution from "views/Tables/ShortlistedExecution";
import Invoice from "views/Tables/Invoice";
import UserProfile from "views/Pages/UserProfile.js";
import IndustryPostProject from "views/IndustryPostProject/IndustryPostProject";
import EditProfileIndustry from "views/EditProfile/IndustryProfile/EditProfileIndustry";
import EditProfileConsultan from "views/EditProfile/ConsultanProfile/EditProfileConsultan";
import ConsultantProfile from "views/ViewProfile/ConsultantProfile";
import IndustryProfile from "views/ViewProfile/IndustryProfile";
import IndustryViewProject from "views/ViewPorject/IndustryViewProject/IndustryViewProject";
import ConsultanViewProject from "views/ViewPorject/ConsultanViewProject/ConsultanViewProject";
import BuyMemberShipConsultan from "views/BuyMemberShip/Consultan/BuyMemberShipConsultan";
import BuyMemberShipIndustry from "views/BuyMemberShip/Industry/BuyMemberShipIndustry";
import CheckoutMemberShip from "views/BuyMemberShip/CheckoutMemberShip";
import PaymentFailed from "views/BuyMemberShip/PaymentFailed";
import PaymentSuccess from "views/BuyMemberShip/PaymentSuccess";
import ConsultantRegister from "views/Register/Consultant/ConsultantRegister";
// import ChangePassword from 'views/ChangePassword/ChangePassword'
// import ReportBug from 'views/ReportBug/ReportBug'
// import ReferAndEarn from 'views/ReferAndEarn/ReferAndEarn'
// import IndustryRegister from 'views/Register/Industry/IndustryRegister'

// Admin
import AdminDashBoard from "views/Admin/AdminDashBoard/AdminDashBoard";
import ContactView from "views/Admin/Contact/ContactView";
import ProblemDetails from "views/Admin/ManageProjects/ProblemDetails";
import ConsultantDB from "views/Dashboard/ConsultantDB";
import InvoicePage from "views/Invoice/InvoicePage";
import IndustryDirectFlow from "views/Register/IndustryDirectFlow/IndustryDirectFlow";
import AuthUser from "components/Sb/AuthUser";
import customerAdmin from "views/Admin/customerPage/customerAdmin";
import SalesDashBoard from "views/Sales/SalesDashBoard/SalesDashBoard";
import companyListAdminSales from "views/Admin/CompanyList/CompanyList";
import ProblemDetailsCompany from "views/Admin/problem/details/ProblemDetailsCompany";
import ProblemTableAdmin from "views/Admin/problem/problemTable/ProblemTableAdmin";

//import ChatBotSBIndusty from 'views/ChatBotSBIndusty/ChatBotSBIndusty';

export default function Routes() {
  const { http, getUser } = AuthUser();
  const [plan, setPlan] = React.useState("Not Live");

  //plan check api
  React.useEffect(() => {
    let user = getUser();
    if (user) {
      let data = { customer_id: user.customer_id };
      http
        .post("/ViewPlanNonAuthenticate", data)
        .then((response) => {
          if (response.status === 200) {
            setPlan("Under Review");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  var routes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: AdminDashBoard,
      layout: "/admin",
      role: "A",
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: SalesDashBoard,
      layout: "/admin",
      role: "U",
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: ConsultantDB,
      layout: "/customer",
      role: "SP",
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      layout: "/customer",
      role: "SS",
    },
    // Problem
    {
      path: "/problem",
      name: "Problem",
      icon: DashboardIcon,
      component: ProblemTableAdmin,
      layout: "/admin",
      role: "U",
    },
    // ProblemDetails
    {
      path: "/ProblemDetails",
      name: "Problem Details",
      icon: DashboardIcon,
      component: ProblemDetailsCompany,
      layout: "/admin",
      role: "U",
    },
    

    // {
    //   path: '/ChatBotIndusty',
    //   name: 'ChatBot Industy',
    //   icon: DashboardIcon,
    //   component: ChatBotSBIndusty,
    //   layout: '/customer',
    //   role: 'SS'
    // },

    // login and register
    {
      collapse: true,
      name: "Pages",
      icon: Image,
      state: "pageCollapse",
      views: [
        {
          path: "/welcome/:token",
          name: "Pricing Page",
          component: PricingPage,
          layout: "/auth",
          role: "NA",
        },
        {
          path: "/rtl-support-page",
          name: "RTL Support",
          component: RTLSupport,
          layout: "/rtl",
          role: "NA",
        },
        {
          path: "/login-page",
          name: "Login",
          component: LoginPage,
          layout: "/auth",
          role: "NA",
        },
        {
          path: "/admin-login",
          name: "Login Page",
          component: AdminLogin,
          layout: "/auth",
          role: "NA",
        },

        {
          path: "/register-page",
          name: "Register",
          component: RegisterPage,
          layout: "/auth",
          role: "NA",
        },
        // {
        //   path: '/timeline-page',
        //   name: 'Timeline Page',
        //   component: TimelinePage,
        //   layout: '/admin',
        //   role: 'A'
        // },
        // {
        //   path: '/lock-screen-page',
        //   name: 'Lock Screen Page',
        //   component: LockScreenPage,
        //   layout: '/auth',
        //   role: 'NA'
        // },
      ],
    },

    // Admin Pages
    {
      path: "/proble-details",
      name: "Problem Details",
      icon: DashboardIcon,
      component: ProblemDetails,
      layout: "/admin",
      role: "A",
    },
    {
      path: "/company/:id",
      name: "Company",
      icon: DashboardIcon,
      component: ContactView,
      layout: "/admin",
      role: "A",
    },
    {
      path: "/customer/:id",
      name: "Customer",
      icon: DashboardIcon,
      component: customerAdmin,
      layout: "/admin",
      role: "A",
    },
    // Sales Team
    {
      path: "/companyList/",
      name: "Company List",
      icon: Business,
      component: companyListAdminSales,
      layout: "/admin",
      role: "U",
    },
    // {
    //   path: '/proble-details',
    //   name: 'Problem Details',
    //   icon: DashboardIcon,
    //   component: ProblemDetails,
    //   layout: '/admin',
    //   role: 'U'
    // },
    {
      path: "/company/:id",
      name: "Company",
      icon: DashboardIcon,
      component: ContactView,
      layout: "/admin",
      role: "U",
    },
    {
      path: "/customer/:id",
      name: "Customer",
      icon: DashboardIcon,
      component: customerAdmin,
      layout: "/admin",
      role: "U",
    },
    // Error Pages
    {
      path: "/error-page",
      name: "Page Not Found",
      icon: ErrorIcon,
      component: ErrorPage,
      layout: "/auth",
      role: "NA",
    },
    {
      path: "/error-page",
      name: "Page Not Found",
      icon: ErrorIcon,
      component: ErrorPage,
      layout: "/admin",
      role: "A",
    },
    {
      path: "/error-page",
      name: "Page Not Found",
      icon: ErrorIcon,
      component: ErrorPage,
      layout: "/admin",
      role: "S",
    },
    {
      path: "/error-page",
      name: "Page Not Found",
      icon: ErrorIcon,
      component: ErrorPage,
      layout: "/admin",
      role: "P",
    },
    {
      path: "/error-page",
      name: "Page Not Found",
      icon: ErrorIcon,
      component: ErrorPage,
      layout: "/admin",
      role: "U",
    },
    {
      path: "/error-page",
      name: "Page Not Found",
      icon: ErrorIcon,
      component: ErrorPage,
      layout: "/customer",
      role: "SP",
    },
    {
      path: "/error-page",
      name: "Page Not Found",
      icon: ErrorIcon,
      component: ErrorPage,
      layout: "/customer",
      role: "SS",
    },
    {
      path: "/register",
      name: "Onboarding",
      icon: DashboardIcon,
      component: ConsultantRegister,
      layout: "/customer",
      role: "SP",
    },
    {
      path: "/register",
      name: "Onboarding",
      icon: DashboardIcon,
      component: IndustryDirectFlow,
      layout: "/customer",
      role: "SS",
    },
    // Profile
    {
      collapse: true,
      name: "My Profile",
      icon: PermIdentity,
      state: "profileCollapse",
      views: [
        {
          path: "/edit-profile",
          name: "Edit New Profile",
          component: EditProfileIndustry,
          layout: "/customer",
          role: "SS",
        },
        {
          path: "/edit-profile",
          name: "Edit Profile",
          component: EditProfileConsultan,
          layout: "/customer",
          role: "SP",
        },
        {
          path: "/user-page",
          name: "View Profile",
          component: UserProfile,
          layout: "/admin",
          role: "A",
        },
        {
          path: "/user-page",
          name: "View Profile",
          component: ConsultantProfile,
          layout: "/customer",
          role: "SP",
        },
        {
          path: "/user-page",
          name: "View Profile",
          component: IndustryProfile,
          layout: "/customer",
          role: "SS",
        },
      ],
    },

    // Industry Post Project
    {
      path: "/post-project",
      name: "Post Project",
      icon: ViewList,
      component: IndustryPostProject,
      layout: "/customer",
      role: "SS",
    },

    // Project SS
    {
      collapse: true,
      name: "My Project",
      icon: Description,
      state: "projectCollapse",
      views: [
        {
          path: "/notlive-project",
          name: plan,
          component: NotLive,
          layout: "/customer",
          role: "SS",
        },
        {
          path: "/live-project",
          name: "Live",
          component: Live,
          layout: "/customer",
          role: "SS",
        },
        {
          path: "/completed-project",
          name: "Completed",
          component: Completed,
          layout: "/customer",
          role: "SS",
        },
        {
          path: "/onhold-project",
          name: "Onhold",
          component: Onhold,
          layout: "/customer",
          role: "SS",
        },
        // {
        //   path: '/latest-project',
        //   name: 'Latest Project',
        //   component: Latest,
        //   layout: '/customer',
        //   role: 'SP'
        // },
        // {
        //   path: '/applied-project',
        //   name: 'Applied Project',
        //   component: Applied,
        //   layout: '/customer',
        //   role: 'SP'
        // },
        // {
        //   path: '/awarded-project',
        //   name: 'Awarded Project',
        //   component: Awarded,
        //   layout: '/customer',
        //   role: 'SP'
        // },
        // {
        //   path: '/notawarded-project',
        //   name: 'Not Awarded Project',
        //   component: NotAwarded,
        //   layout: '/customer',
        //   role: 'SP'
        // },
      ],
    },

    //Normal Project SP
    {
      collapse: true,
      name: "Normal Project",
      icon: Description,
      state: "normalprojectCollapse",
      views: [
        {
          path: "/latest-project",
          name: "Latest Project",
          component: Latest,
          layout: "/customer",
          role: "SP",
        },
        {
          path: "/applied-project",
          name: "Applied Project",
          component: Applied,
          layout: "/customer",
          role: "SP",
        },
        {
          path: "/awarded-project",
          name: "Awarded Project",
          component: Awarded,
          layout: "/customer",
          role: "SP",
        },
        {
          path: "/notawarded-project",
          name: "Not Awarded Project",
          component: NotAwarded,
          layout: "/customer",
          role: "SP",
        },
      ],
    },

    // Execution Project SP
    {
      collapse: true,
      name: "Execution Project",
      icon: Description,
      state: "executionprojectCollapse",
      views: [
        {
          path: "/latestExecution-project",
          name: "Latest Project",
          component: LatestExecution,
          layout: "/customer",
          role: "SP",
        },
        {
          path: "/appliedExecution-project",
          name: "Applied Project",
          component: AppliedExecution,
          layout: "/customer",
          role: "SP",
        },
        {
          path: "/awardedExecution-project",
          name: "Awarded Project",
          component: AwardedExecution,
          layout: "/customer",
          role: "SP",
        },
        {
          path: "/notawardedExecution-project",
          name: "Not Awarded Project",
          component: NotAwardedExecution,
          layout: "/customer",
          role: "SP",
        },
        {
          path: "/shortlistedExecution-project",
          name: "Shortlisted Project",
          component: ShortlistedExecution,
          layout: "/customer",
          role: "SP",
        },
      ],
    },

    // Buy Membership
    {
      path: "/buy-memberships",
      name: "Buy Memberships",
      icon: CardMembership,
      component: BuyMemberShipConsultan,
      layout: "/customer",
      role: "SP",
    },
    {
      path: "/buy-memberships",
      name: "Buy Memberships",
      icon: CardMembership,
      component: BuyMemberShipIndustry,
      layout: "/customer",
      role: "SS",
    },

    // Refer and Earn
    // {
    //   path: '/refer-and-earn',
    //   name: 'Refer and Earn',
    //   icon: MoneyOff,
    //   component: ReferAndEarn,
    //   layout: '/customer',
    //   role: 'SS'
    // },
    // {
    //   path: '/refer-and-earn',
    //   name: 'Refer and Earn',
    //   icon: MoneyOff,
    //   component: ReferAndEarn,
    //   layout: '/customer',
    //   role: 'SP'
    // },

    // InVoice
    {
      path: "/invoice",
      name: "Invoice",
      icon: Receipt,
      component: Invoice,
      layout: "/customer",
      role: "SS",
    },
    {
      path: "/invoice",
      name: "Invoice",
      icon: Receipt,
      component: Invoice,
      layout: "/customer",
      role: "SP",
    },
    // View Invoice
    {
      path: "/viewInvoice/:id",
      name: "viewInvoice",
      icon: Receipt,
      component: InvoicePage,
      layout: "/customer",
      role: "SP",
    },
    {
      path: "/viewInvoice/:id",
      name: "viewInvoice",
      icon: Receipt,
      component: InvoicePage,
      layout: "/customer",
      role: "SS",
    },

    // Change password
    // {
    //   path: '/change-password',
    //   name: 'Change Password',
    //   icon: Lock,
    //   component: ChangePassword,
    //   layout: '/customer',
    //   role: 'SS'
    // },
    // {
    //   path: '/change-password',
    //   name: 'Change Password',
    //   icon: Lock,
    //   component: ChangePassword,
    //   layout: '/customer',
    //   role: 'SP'
    // },

    // Report bug
    // {
    //   path: '/report-bug',
    //   name: 'Report Bug',
    //   icon: ErrorIcon,
    //   component: ReportBug,
    //   layout: '/customer',
    //   role: 'SS'
    // },
    // {
    //   path: '/report-bug',
    //   name: 'Report Bug',
    //   icon: ErrorIcon,
    //   component: ReportBug,
    //   layout: '/customer',
    //   role: 'SP'
    // },

    // Other Pages

    {
      path: `/project-details/:id`,
      name: "Project Details",
      icon: Apps,
      component: IndustryViewProject,
      layout: "/customer",
      role: "SS",
    },
    {
      path: "/project-details/:id",
      name: "Project Details",
      icon: Apps,
      component: ConsultanViewProject,
      layout: "/customer",
      role: "SP",
    },

    {
      path: "/checkout/:id",
      name: "checkout",
      icon: Apps,
      component: CheckoutMemberShip,
      layout: "/customer",
      role: "SP",
    },
    {
      path: "/checkout/:id",
      name: "checkout",
      icon: Apps,
      component: CheckoutMemberShip,
      layout: "/customer",
      role: "SS",
    },
    {
      path: "/payment-failed",
      name: "Payment Failed",
      icon: Apps,
      component: PaymentFailed,
      layout: "/customer",
      role: "SP",
    },
    {
      path: "/payment-success",
      name: "Payment Success",
      icon: Apps,
      component: PaymentSuccess,
      layout: "/customer",
      role: "SP",
    },
    {
      path: "/payment-failed",
      name: "Payment Failed",
      icon: Apps,
      component: PaymentFailed,
      layout: "/customer",
      role: "SP",
    },
    {
      path: "/payment-success",
      name: "Payment Success",
      icon: Apps,
      component: PaymentSuccess,
      layout: "/customer",
      role: "SP",
    },

    // Martial Ui components
    // {
    //   collapse: true,
    //   name: 'Components',
    //   icon: Apps,
    //   state: 'componentsCollapse',
    //   views: [
    //     // {
    //     //   collapse: true,
    //     //   name: 'Multi Level Collapse',
    //     //   state: 'multiCollapse',
    //     //   views: [
    //     //     {
    //     //       path: '#sample-path',
    //     //       name: 'Example',
    //     //       component: () => {},
    //     //       layout: '#sample-layout',
    //     //     },
    //     //   ],
    //     // },
    //     {
    //       path: '/buttons',
    //       name: 'Buttons',
    //       component: Buttons,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/grid-system',
    //       name: 'Grid System',
    //       component: GridSystem,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/panels',
    //       name: 'Panels',
    //       component: Panels,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/sweet-alert',
    //       name: 'Sweet Alert',
    //       component: SweetAlert,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/notifications',
    //       name: 'Notifications',
    //       component: Notifications,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/icons',
    //       name: 'Icons',
    //       component: Icons,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/typography',
    //       name: 'Typography',
    //       component: Typography,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //   ],
    // },
    // {
    //   collapse: true,
    //   name: 'Forms',
    //   icon: PagesIcon,
    //   state: 'formsCollapse',
    //   views: [
    //     {
    //       path: '/regular-forms',
    //       name: 'Regular Forms',
    //       component: RegularForms,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/extended-forms',
    //       name: 'Extended Forms',
    //       component: ExtendedForms,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/validation-forms',
    //       name: 'Validation Forms',
    //       component: ValidationForms,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/wizard',
    //       name: 'Wizard',
    //       component: Wizard,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //   ],
    // },
    // {
    //   collapse: true,
    //   name: 'Maps',
    //   icon: Place,
    //   state: 'mapsCollapse',
    //   views: [
    //     {
    //       path: '/google-maps',
    //       name: 'Google Maps',
    //       component: GoogleMaps,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/full-screen-maps',
    //       name: 'Full Screen Map',
    //       component: FullScreenMap,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //     {
    //       path: '/vector-maps',
    //       name: 'Vector Map',
    //       component: VectorMap,
    //       layout: '/admin',
    //       role: 'A'
    //     },
    //   ],
    // },
    // {
    //   path: '/widgets',
    //   name: 'Widgets',
    //   icon: WidgetsIcon,
    //   component: Widgets,
    //   layout: '/admin',
    //   role: 'A'
    // },
    // {
    //   path: '/charts',
    //   name: 'Charts',
    //   icon: Timeline,
    //   component: Charts,
    //   layout: '/admin',
    //   role: 'A'
    // },
    // {
    //   path: '/calendar',
    //   name: 'Calendar',
    //   icon: DateRange,
    //   component: Calendar,
    //   layout: '/admin',
    //   role: 'A'
    // },
  ];

  return {
    routes,
  };
}

//export default dashRoutes
