import React from "react"
import AuthUser from "components/Sb/AuthUser";
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


// @material-ui/icons
import VerifiedUser from "@material-ui/icons/VerifiedUser";

// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// Radio style
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);

// Core components
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);


const Step2RegCon = (props) => {
    const { handleMainToggle } = props
    const classes = useStyles();
    const { http } = AuthUser();
    const classesRadio = useStylesRadio()

    const [freelance, setFreelance] = React.useState("")
    const [location, setlocation] = React.useState("")
    const [state, setstate] = React.useState([])
    const [duration, setduration] = React.useState("")
    const [organizations, setorganizations] = React.useState([])
    const [relocate, setrelocate] = React.useState("")
    const [relocateLocation, setrelocateLocation] = React.useState("")
    const [startProject, setStartProject] = React.useState("")

    const [stateList, setStateList] = React.useState([])

    const [formError, setFormError] = React.useState({})
    let formErr = {}

    // getting state List
    React.useEffect(() => {
        http.get("/stateList")
            .then((response) => {
                if (response.status === 200) {
                    
                    const data = response.data
                    setStateList(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const handleFormError = () => {
        locationLable
        let freelanceLable = document.getElementById("freelanceLable")
        let locationLable = document.getElementById("locationLable")
        let stateLable = document.getElementById("stateLable")
        let durationLable = document.getElementById("durationLable")
        let organizationsLable = document.getElementById("organizationsLable")
        let relocateLable = document.getElementById("relocateLable")
        let startProjectLable = document.getElementById("startProjectLable")

        // freelance
        if (freelance.length === 0) {
            formErr.freelance = "Error in freelance"
            freelanceLable.classList.add("errorMsg")
        } else {
            if (freelanceLable.classList.contains("errorMsg")) {
                freelanceLable.classList.remove("errorMsg")
                freelanceLable.classList.add("successMsg")
            }
        }
        // LoactionLable
        if (location.length === 0) {
            formErr.location = "Error in loaction"
            locationLable.classList.add("errorMsg")
        } else {
            if (locationLable.classList.contains("errorMsg")) {
                locationLable.classList.remove("errorMsg")
                locationLable.classList.add("successMsg")
            }
        }
        // stateLable
        if (state.length === 0) {
            formErr.state = "Error in loaction"
            stateLable.classList.add("errorMsg")
        } else {
            if (stateLable.classList.contains("errorMsg")) {
                stateLable.classList.remove("errorMsg")
                stateLable.classList.add("successMsg")
            }
        }
        // durationLable
        if (duration.length === 0) {
            formErr.duration = "Error in duration"
            durationLable.classList.add("errorMsg")
        } else {
            if (durationLable.classList.contains("errorMsg")) {
                durationLable.classList.remove("errorMsg")
                durationLable.classList.add("successMsg")
            }
        }
        // organizationsLable
        if (organizations.length === 0) {
            formErr.organizations = "Error in organizations"
            organizationsLable.classList.add("errorMsg")
        } else {
            if (organizationsLable.classList.contains("errorMsg")) {
                organizationsLable.classList.remove("errorMsg")
                organizationsLable.classList.add("successMsg")
            }
        }
        // relocateLable
        if (relocate.length === 0) {
            formErr.relocate = "Error in relocate"
            relocateLable.classList.add("errorMsg")
        } else {
            if (relocateLable.classList.contains("errorMsg")) {
                relocateLable.classList.remove("errorMsg")
                relocateLable.classList.add("successMsg")
            }
        }
        // startProjectLable
        if (startProject.length === 0) {
            formErr.startProject = "Error in start Project"
            startProjectLable.classList.add("errorMsg")
        } else {
            if (startProjectLable.classList.contains("errorMsg")) {
                startProjectLable.classList.remove("errorMsg")
                startProjectLable.classList.add("successMsg")
            }
        }
    }

    const resolve = () => {
        setFormError({})
        setFreelance("")
        setlocation("")
        setstate([])
        setduration("")
        setorganizations([])
        setrelocate("")
        setrelocateLocation("")
        setStartProject("")
        handleMainToggle()
    }


    const handleFormSubmit = (e) => {
        e.preventDefault()
        handleFormError()
        if (Object.keys(formErr).length > 0) {
            setFormError(formErr)
            console.log(formError)
        } else {

            const data = { freelance, location, state, duration, organizations, relocate, relocateLocation, startProject }

            //api connectivity

            http.post('/customer/OnboardingPreference', data).then((res) => {
                if (res.status == 200) {
                    console.log(res)
                    toast.success("Your preference has been added", {
                        position: toast.POSITION.TOP_CENTER,
                        onClose: () => resolve(),
                        autoClose:1500
                    })
                }
            }).catch((err) => {
                console.log(err)
            });
        }
    }

    return (
        <>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10} lg={10}>
                    <Card>
                        <CardHeader color="warning" icon>
                            <CardIcon color="warning">
                                <VerifiedUser />
                            </CardIcon>
                            <h4 className="card-title">
                                Opportunity Preferences
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleFormSubmit}>
                                <GridContainer>
                                    {/* 1) Freelance consulting opportunities */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="freelance"
                                                className={classes.selectLabel}
                                            >
                                                <span id="freelanceLable">Freelance consulting opportunities <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                value={freelance}
                                                onChange={(event) => {
                                                    setFreelance(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "freelance",
                                                    id: "freelance",
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose Freelance consulting opportunities
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Actively looking for projects"
                                                >
                                                    Actively looking for projects
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Not actively looking but open to be contacted"
                                                >
                                                    Not actively looking but open to be contacted
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Not interested in freelancing but looking for job opportunity"
                                                >
                                                    Not interested in freelancing but looking for job opportunity
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    {/* 2) What are your location preferences */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="location"
                                                className={classes.selectLabel}
                                            >
                                                <span id="locationLable">What are your location preferences <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                value={location}
                                                onChange={(event) => {
                                                    setlocation(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "location",
                                                    id: "location",
                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose What are your location preferences
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Remote"
                                                >
                                                    Remote
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Onsite"
                                                >
                                                    Onsite
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    {/* 3) Locations you are comfortable to work for Onsite */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="state"
                                                className={classes.selectLabel}
                                            >

                                                <span id="stateLable">Locations you are comfortable to work for Onsite <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                multiple
                                                value={state}
                                                onChange={(event) => {
                                                    setstate(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "state",
                                                    id: "state",

                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose Locations you are comfortable to work for Onsite
                                                </MenuItem>

                                                {
                                                    stateList.map((state) => {
                                                        return <MenuItem
                                                            key={state.state_id}
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected,
                                                            }}
                                                            value={state.state_id}
                                                        >
                                                            {state.name}
                                                        </MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    {/* 4) What project duration you are open to */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="duration"
                                                className={classes.selectLabel}
                                            >

                                                <span id="durationLable">What project duration you are open to <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}

                                                value={duration}
                                                onChange={(event) => {
                                                    setduration(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "duration",
                                                    id: "duration",

                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose What project duration you are open to
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Upto 3 Months"
                                                >
                                                    Upto 3 Months
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="3-6 months"
                                                >
                                                    3-6 months
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="6-9 months"
                                                >
                                                    6-9 months
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="no time bound"
                                                >
                                                    no time bound
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    {/* 5) What type of organizations are you open to do projects with */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="organizations"
                                                className={classes.selectLabel}
                                            >

                                                <span id="organizationsLable">What type of organizations are you open to do projects with <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}
                                                multiple
                                                value={organizations}
                                                onChange={(event) => {
                                                    setorganizations(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "organizations",
                                                    id: "organizations",

                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose What type of organizations are you open to do projects with
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Private SMEs"
                                                >
                                                    Private SMEs
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Private Corporate"
                                                >
                                                    Private Corporate
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Consulting Firms"
                                                >
                                                    Consulting Firms
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Private Principal Investors"
                                                >
                                                    Private Principal Investors
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Social Sector"
                                                >
                                                    Social Sector
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Public Sector/Government"
                                                >
                                                    Public Sector/Government
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    {/* 6)Are you willing to relocate if a project requires you to do */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="relocate"
                                                className={classes.selectLabel}
                                            >

                                                <span id="relocateLable">Are you willing to travel if a project requires you to do <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}

                                                value={relocate}
                                                onChange={(event) => {
                                                    setrelocate(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "relocate",
                                                    id: "relocate",

                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose Are you willing to travel if a project requires you to do
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Yes"
                                                >
                                                    Yes
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="No"
                                                >
                                                    No
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        {
                                            relocate === "Yes" && (
                                                <>
                                                    <GridContainer>
                                                        <GridItem xs={6} sm={6} >
                                                            <div
                                                                className={
                                                                    classesRadio.checkboxAndRadio +
                                                                    " " +
                                                                    classesRadio.checkboxAndRadioHorizontal
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Radio
                                                                            checked={relocateLocation === "National"}
                                                                            onChange={() => {
                                                                                setrelocateLocation("National")
                                                                            }}
                                                                            name="relocate_location"
                                                                            value="National"
                                                                            aria-label="National"
                                                                            icon={
                                                                                <FiberManualRecord
                                                                                    className={classesRadio.radioUnchecked}
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <FiberManualRecord
                                                                                    className={classesRadio.radioChecked}
                                                                                />
                                                                            }
                                                                            classes={{
                                                                                checked: classesRadio.radio,
                                                                                root: classesRadio.radioRoot,
                                                                            }}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classesRadio.label,
                                                                        root: classesRadio.labelRoot,
                                                                    }}
                                                                    label="National"
                                                                />
                                                            </div>
                                                        </GridItem>
                                                        <GridItem xs={6} sm={6}>
                                                            <div
                                                                className={
                                                                    classesRadio.checkboxAndRadio +
                                                                    " " +
                                                                    classesRadio.checkboxAndRadioHorizontal
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Radio
                                                                            checked={relocateLocation === "International"}
                                                                            onChange={() => {
                                                                                setrelocateLocation("International")
                                                                            }}
                                                                            name="relocate_location"
                                                                            value="International"
                                                                            aria-label="International"
                                                                            icon={
                                                                                <FiberManualRecord
                                                                                    className={classesRadio.radioUnchecked}
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <FiberManualRecord
                                                                                    className={classesRadio.radioChecked}
                                                                                />
                                                                            }
                                                                            classes={{
                                                                                checked: classesRadio.radio,
                                                                                root: classesRadio.radioRoot,
                                                                            }}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classesRadio.label,
                                                                        root: classesRadio.labelRoot,
                                                                    }}
                                                                    label="International"
                                                                />
                                                            </div>
                                                        </GridItem>
                                                    </GridContainer>
                                                </>
                                            )
                                        }
                                    </GridItem>
                                    {/* 7) How soon can you get started on a new project */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="startProject"
                                                className={classes.selectLabel}
                                            >

                                                <span id="startProjectLable">How soon can you get started on a new project <small>(required)</small></span>
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu,
                                                }}
                                                classes={{
                                                    select: classes.select,
                                                }}

                                                value={startProject}
                                                onChange={(event) => {
                                                    setStartProject(event.target.value)
                                                }}
                                                inputProps={{
                                                    name: "startProject",
                                                    id: "startProject",

                                                }}
                                            >
                                                <MenuItem
                                                    disabled
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                    }}
                                                >
                                                    Choose How soon can you get started on a new project
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="Below 10 Days"
                                                >
                                                    Below 10 Days
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="1 Month"
                                                >
                                                    1 Month
                                                </MenuItem>
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value="2 Month"
                                                >
                                                    2 Month
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    {/* Submit  */}
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <Button type="submit" color="info" round>Submit</Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}
Step2RegCon.propTypes = {
    handleMainToggle: PropTypes.func
}
export default Step2RegCon