import React from "react";
// import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";
import "react-datetime/css/react-datetime.css";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);
// icons
import { FiberManualRecord } from "@material-ui/icons";

// Radio
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import stylesRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStylesRadio = makeStyles(stylesRadio);

const GenerateInvoiceFormPD = (props) => {
  const { setindustryAdvanceModal, industryAdvanceModal } = props;
  const classes = useStyles();
  const classesRadio = useStylesRadio();
  //   const params = useParams();
  //   const history = useHistory();

  //   getUser
  // const { http } = AuthUser();

  const [totalcost, settotalcost] = React.useState("");

  const [formError, setFormError] = React.useState({});
  let formErr = {};

  React.useEffect(() => {
    if (!industryAdvanceModal) {
      settotalcost("");
      setFormError({});
    }
  }, [industryAdvanceModal]);

  // handle Form error function
  const handleFormError = () => {
    // totalcost
    if (totalcost.toString().trim().length === 0) {
      formErr.totalcost = "total cost is required";
    }
  };

  // Form Submitting
  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormError();
    if (Object.keys(formErr).length > 0) {
      setFormError(formErr);
    } else {
      const formData = {
        totalcost,
      };
      console.log(formData);
      resolve();
      //   handleFormAPI(formData);
    }
  };

  //   const handleFormAPI = (formData) => {
  //     http
  //       .post("/admin/lead_questionire", formData)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           resolve();
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(`Can't update - try later ${err.message}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 1500,
  //         });
  //       });
  //   };

  const resolve = () => {
    toast.success("Successfully updated", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1500,
    });
    setindustryAdvanceModal(false);
    settotalcost("");
    setFormError({});
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <GridContainer>
          {/* tds */}
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer alignItems="baseline">
              <GridItem xs={12} sm={4}>
                <p style={{ fontWeight: "bold" }}>TDS</p>
              </GridItem>
              <GridItem xs={6} sm={4}>
                <div
                  className={
                    classesRadio.checkboxAndRadio +
                    " " +
                    classesRadio.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={totalcost == 59000}
                        onChange={() => {
                          settotalcost(59000);
                        }}
                        name="totalcost"
                        aria-label="59000"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radio,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    label="59000"
                  />
                </div>
              </GridItem>
              <GridItem xs={6} sm={4}>
                <div
                  className={
                    classesRadio.checkboxAndRadio +
                    " " +
                    classesRadio.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={totalcost === 0}
                        onChange={() => {
                          settotalcost(0);
                        }}
                        name="totalcost"
                        aria-label="0"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radio,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    label="0"
                  />
                </div>
              </GridItem>
            </GridContainer>
            {Object.keys(formError).length > 0 && formError.totalcost && (
              <p style={{ color: "#f44336", textAlign: "left" }}>
                {formError.totalcost}
              </p>
            )}
          </GridItem>
          {/* save */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right", width: "100%" }}>
              <Button
                round
                color="success"
                className={classes.registerButton}
                type="submit"
              >
                save
              </Button>
            </div>
          </GridItem>
          {/* cancle */}
          <GridItem xs={4} sm={6} md={6}>
            <div style={{ textAlign: "right" }}>
              <Button
                round
                color="danger"
                className={classes.registerButton}
                onClick={() => setindustryAdvanceModal(false)}
              >
                cancle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

GenerateInvoiceFormPD.propTypes = {
  industryAdvanceModal: PropTypes.bool,
  setindustryAdvanceModal: PropTypes.func,
};

export default GenerateInvoiceFormPD;
