import React, { useEffect } from "react";
import AuthUser from 'components/Sb/AuthUser'
import {useHistory} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
//import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
// import Close from "@material-ui/icons/Close";
// // core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";

// import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};



const useStyles = makeStyles(styles);

export default function NotLive() {
  const { http} = AuthUser()
  const [data, setData] = React.useState([]);
  const history = useHistory()
  const [plan, setPlan] = React.useState({}) 

     //plan check api
     React.useEffect(() => {
      http.get("/customer/ViewPlan")
          .then((response) => {
              if (response.status === 200) {
                  const data = response.data
                  setPlan(data)
              }
          }).catch((err) => {
              console.log(err)
          })
  }, [])



  useEffect(() => {
    http.get('/customer/notlive-project').then((res) => {
      if(res.status==200){
      let result = res.data;
      console.log(result);

      for (var i = 0; i < result.length; i++) {
        result[i].actions = 'Na'
        if (result[i].execution == 0)
          result[i].type = 'Not Defined'
        else if (result[i].execution == 1)
          result[i].type = 'Normal'
        else if (result[i].execution == 2)
          result[i].type = 'Execution'
      }

      const reDirectToViewProject = (id) => {
        history.push(`/customer/project-details/${id}`)
      }

      let final = result.map((prop, key) => {
        return {
          id: key,
          projectId:  (<><span style={{fontWeight:"bold"}}>{prop.projectId}</span></>),
          describe: prop.describe,
          industry: prop.industry,
          category: prop.category,
          type: prop.type,
          date_added: prop.date_added,
          actions: (
            <div className="actions-right">
              <Button type="submit" round color="rose" style={{ fontWeight: 600 }}
                onClick={() => {
                  reDirectToViewProject(prop.projectId)
                }} >View</Button>
            </div>
          ),
        };
      })
      setData(final);
    }
    }).catch((err) => {
      console.log(err)
                
    });
  }, []);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}><b>{(Object.keys(plan).length > 0) ? "Projects Under Review" : "Not-Live Project List"} </b></h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[

                {
                  Header:  (<><span style={{fontWeight:"bold"}}>Project ID</span></>),
                  accessor: "projectId",
                },
                {
                  Header:  (<><span style={{fontWeight:"bold"}}>Description</span></>),
                  accessor: "describe",
                },
                {
                  Header:  (<><span style={{fontWeight:"bold"}}>Industry</span></>),
                  accessor: "industry",
                },
                {
                  Header:  (<><span style={{fontWeight:"bold"}}>Category</span></>),
                  accessor: "category",
                },
                {
                  Header:  (<><span style={{fontWeight:"bold"}}>Project Type</span></>),
                  accessor: "type",
                },
                {
                  Header:  (<><span style={{fontWeight:"bold"}}>Posted On</span></>),
                  accessor: "date_added",
                },
                {
                  Header:  (<><span style={{fontWeight:"bold"}}>Action</span></>),
                  accessor: "actions",
                },

              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
