import React from "react";

// import Datetime from "react-datetime";
import PropTypes from 'prop-types'
// import { toast } from 'react-toastify'


// Style
import "assets/css/common.css"

// @material-ui/icons
// import Search from "@material-ui/icons/Search";
import Call from "@material-ui/icons/Call";
import Note from "@material-ui/icons/Note";
import Tab from "@material-ui/icons/Tab";
import Grade from "@material-ui/icons/Grade";
import Diamond from "@material-ui/icons/Flag";
import Business from "@material-ui/icons/Business";
import Work from "@material-ui/icons/Work";
import Link from "@material-ui/icons/Link";
import ViewDay from "@material-ui/icons/ViewDay";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Email from "@material-ui/icons/Email";
import QueryBuilder from "@material-ui/icons/QueryBuilder";

// core components
// import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";

// Input 
// import InputAdornment from "@material-ui/core/InputAdornment";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";

// Radio Import Style
// import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// const useStyles = makeStyles(styles);

import ListTimeLineCompany from "./ListTimeLineCompany";

const   TimeLineCompany = ({logs,handlesLeadQualificaton,assignedEdit}) => {
    // const classes = useStyles();
    // const [search, setSearch] = React.useState("")

   

    // const searchedTimeLine = timeLogo.filter((log) => {
    //     return log.type.toLowerCase().includes(search.toLowerCase())
    // })

    const handleIncons = (activity,type)=>{
        if(activity=="1"&&type=="1"){
            return Call
        }
        if(activity=="1"&&type=="2"){
            return Email
        }
        if(activity=="1"&&type=="3"){
            return ViewDay
        }
        if(activity=="2"&&type=="0"){
            return Note
        }
        if(activity=="2"&&type=="1"){
            return Diamond
        }
        if(activity=="3"){
            return Tab
        }
        if(activity=="4"){
            return Link
        }
        if(activity=="5"){
            return Grade
        }
        if(activity=="6"){
            return Business
        }
        if(activity=="7"){
            return Work
        }
        if(activity=="8"){
            return QueryBuilder
        }
        if(activity=="9"){
            return WhatsApp
        }
        // Call ,Note, Create Task , Customer Interest,Industry , Skill ,Send Whatsapp
    }
    const handleColor = (activity,type)=>{
        if(activity=="1"&&type=="1"){
            return "warning"
        }
        if(activity=="1"&&type=="2"){
            return "primary"
        }
        if(activity=="1"&&type=="3"){
            return "info"
        }
        if(activity=="2"&&type=="0"){
            return "danger"
        }
        if(activity=="2"&&type=="1"){
            return "success"
        }
        if(activity=="3"){
            return "primary"
        }
        if(activity=="4"){
            return "primary"
        }
        if(activity=="5"){
            return "success"
        }
        if(activity=="6"){
            return "rose"
        }
        if(activity=="7"){
            return "warning"
        }
        if(activity=="8"){
            return "danger"
        }
        if(activity=="9"){
            return "success"
        }
    } 
    const handleColorText = (activity,type)=>{
        if(activity=="1"&&type=="1"){
            return "#ff9800"
        }
        if(activity=="1"&&type=="2"){
            return "#9c27b0"
        }
        if(activity=="1"&&type=="3"){
            return "#00acc1"
        }
        if(activity=="2"&&type=="0"){
            return "#f44336"
        }
        if(activity=="2"&&type=="1"){
            return "#4caf50"
        }
        if(activity=="3"){
            return "#9c27b0"
        }
        if(activity=="4"){
            return "#9c27b0"
        }
        if(activity=="5"){
            return "#4caf50"
        }
        if(activity=="6"){
            return "#e91e63"
        }
        if(activity=="7"){
            return "#ff9800"
        }
        if(activity=="8"){
            return "#f44336"
        }
        if(activity=="9"){
            return "#4caf50"
        }
    } 

    const handleLogType = (activity,type)=>{
        if(activity=="1"&&type=="1"){
            return "Call"
        }
        if(activity=="1"&&type=="2"){
            return "Email"
        }
        if(activity=="1"&&type=="3"){
            return "Meeting"
        }
        if(activity=="2"&&type=="0"){
            return "Note"
        }
        if(activity=="2"&&type=="1"){
            return "Sales Qualification Details"
        }
        if(activity=="3"){
            return "Create Task"
        }
        if(activity=="4"){
            return "Customer Interest"
        }
        if(activity=="5"){
            return "Grade"
        }
        if(activity=="6"){
            return "Industry"
        }
        if(activity=="7"){
            return "Skills"
        }
        if(activity=="8"){
            return "Lead Queries"
        }
        if(activity=="9"){
            return "Send Whatsapp"
        }
    }

    const handleCallOut = (activity,type,call_outcome)=>{
        if(activity=="1"&&type=="1"){
            if(call_outcome=="1"){
                return "Connected"
            }
            if(call_outcome=="2"){
                return "Busy"
            }
            if(call_outcome=="3"){
                return "No Answer"
            }
            if(call_outcome=="4"){
                return "Wrong Number"
            }
        }
    }

    const handleComments = (comments)=>{
        return <span dangerouslySetInnerHTML={{ __html: comments }}></span>
    }

    const stories = logs.map((log) => {
        return {
            id:log.id,
            badgeColor: handleColor(log.activity,log.type),
            badgeIcon: handleIncons(log.activity,log.type),
            titleColor: handleColorText(log.activity,log.type),
            call_outCome:handleCallOut(log.activity,log.type,log.call_outcome),
            logged: log.user_name,
            type: handleLogType(log.activity,log.type),
            date: log.date,
            assignedEdit:assignedEdit,
            comments: handleComments(log.comments),
            handlesLeadQualificaton:handlesLeadQualificaton
        }
    })

    // {
    //     "id": 543164,
    //     "user_id": 1,
    //     "cus_id": 7326,
    //     "activity": 9,
    //     "type": 0,
    //     "call_outcome": 0,
    //     "comments": "Whatsapp Message sent to Abhaya N R for changing Grade as \"No Response\"",
    //     "date": "2022-05-02 03:35:44",
    //     "user_name": "Admin"
    //   },


    return (
        <form>
            <GridContainer justify="center">
                {/* <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CustomInput
                        labelText={
                            <span>
                                Search Timeline <small>(required)</small>
                            </span>
                        }
                        id="search"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            name: 'search',
                            value: search,
                            onChange: (event) => {
                                setSearch(event.target.value);
                            },
                            endAdornment: (
                                <InputAdornment position="end" className={classes.inputAdornment}>
                                    <Search className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <h3>Log Acivity</h3>
                    <ListTimeLineCompany simple stories={stories} />
                </GridItem>
            </GridContainer>
        </form >
    );
}

TimeLineCompany.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.object).isRequired,
    handlesLeadQualificaton:PropTypes.func,
    assignedEdit:PropTypes.bool,
};

export default TimeLineCompany 