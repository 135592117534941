import React from "react";
import PropTypes from "prop-types";
// import {useHistory} from "react-router-dom";
// import AuthUser from "components/Sb/AuthUser";
// import { toast } from 'react-toastify'
//import {useHistory} from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";

// importing css
import "assets/css/industryEditProfile.css";
import "assets/css/common.css";

import ExistingFormLQc1 from "./1ExistingFormLQC";

// Style
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import NewEntrepreneur2 from "./2NewEntrepreneur";
const useStyles = makeStyles(styles);

const IndustryTypeLQC = (props) => {
  const classes = useStyles();
  // const {http} = AuthUser()
  const { industries, setindustries, setClassicModal,handlesLeadQualificaton } = props;
  const [industriesAll, setindustriesAll] = React.useState([]);

  React.useEffect(() => {
    setindustriesAll([
      {
        id: "1",
        name: "1-Existing",
      },
      {
        id: "2",
        name: "2-New Entrepreneur with Business Background",
      },
      {
        id: "3",
        name: "3-New Entrepreneur without Business Background",
      },
    ]);
  }, []);

  const handleResetIndPop=()=>{
    setClassicModal(false)
    setindustries("")
    handlesLeadQualificaton()
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="industries" className={classes.selectLabel}>
            <span id="industry">
              Choose industry*
            </span>
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={industries}
            onChange={(event) => {
              setindustries(event.target.value);
            }}
            inputProps={{
              name: "industries",
              id: "industries",
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              Choose industry
            </MenuItem>
            {industriesAll.map((industry) => {
              return (
                <MenuItem
                  key={industry.id}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={industry.name}
                >
                  {industry.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem>
        {industries=="1-Existing" && <ExistingFormLQc1  handleResetIndPop={handleResetIndPop} industries={industries}/>}
        {(industries=="2-New Entrepreneur with Business Background" || industries=="3-New Entrepreneur without Business Background") && <NewEntrepreneur2 industries={industries} handleResetIndPop={handleResetIndPop}/>}
      </GridItem>
    </GridContainer>
  );
};

IndustryTypeLQC.propTypes = {
  industries: PropTypes.string,
  setindustries: PropTypes.func,
  setClassicModal: PropTypes.func,
  handlesLeadQualificaton: PropTypes.func,
};

export default IndustryTypeLQC;
