import {
  cardTitle,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const userProfileStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400",
    },
  },
  cardCategory: {
    marginTop: "10px",
    color: grayColor[0] + " !important",
    textAlign: "center",
    fontSize:"24px",
    fontWeight:"400"
  },
  description: {
    color: grayColor[0],
  },
  updateProfileButton: {
    float: "right",
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    flexWrap: "wrap"
  },
  tags: {
    backgroundColor: "#4caf50",
    fontWeight: "400",
    color: "#ffffff",
    padding: "4px 8px",
    margin: "4px",
    borderRadius: "6px",
  },
  lableHead:{
    fontWeight: "500",
    fontSize: "16px",
    marginRight: "6px"
  },
  lableValue:{
    fontWeight: "400",
    fontSize: "16px"
  },
  basicCategory : {
    textAlign:"justify",
    marginLeft:"25%"
  }
};
export default userProfileStyles;
