import React from "react"
import { useHistory } from "react-router-dom"
// Importing css
import "assets/css/ByMemberShip.css"
import "assets/css/common.css"

import AuthUser from 'components/Sb/AuthUser';


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CardMembership from "@material-ui/icons/CardMembership";
import Payment from "@material-ui/icons/Payment";

// Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader";

// Image Importing
import ConsultantBasic from "assets/img/memberShip/ConsultantBasic.png"
import ConsultantAssist from "assets/img/memberShip/ConsultantAssist.png"
import ConsultantPremium from "assets/img/memberShip/ConsultantPremium.png"
import PaymentQR from "assets/img/paymentQR/QR-SB.jpeg"

// icons
// import CloudDownload from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles(styles);

const BuyMemberShipConsultan = () => {
    const classes = useStyles();
    const history = useHistory()
    const { http} = AuthUser();

    const handleCheckout = (id) => {
        history.push(`/customer/checkout/${id}`)
    }

    React.useEffect(() => {

        http.get("/customer/membershipVisitorNotify")
            .then((response) => {
                if (response.status === 200) {
                    console.log(response)
                }
            })
            .catch((err) => {
              console.log(err)
        })
    }, [])

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <CardMembership />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle} style={{ color: "#3c4858", marginTop: "14px", fontWeight: "500" }}>
                            Buy Memberships
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <div className="planImage">
                                    <img src={ConsultantBasic} alt="ConsultantBasic" onClick={() => {
                                        handleCheckout(25)
                                    }} />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <div className="planImage">
                                    <img src={ConsultantPremium} alt="ConsultantPremium" onClick={() => {
                                        handleCheckout(26)
                                    }} />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <div className="planImage">
                                    <img src={ConsultantAssist} alt="ConsultantAssist" onClick={() => {
                                        handleCheckout(27)
                                    }} />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Payment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle} style={{ color: "#3c4858", marginTop: "14px", fontWeight: "500" }}>
                            Payment QR-Code
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={5} lg={5}>
                                <div className="paymentQR">
                                    <img src={PaymentQR} alt="payment QR code" />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <div className="paymentDetails">
                                    <div className="paymentHeader">
                                        <h3>For Payment through Wire Transfer/RTGS/NEFT/UPI</h3>
                                        <p>Send Payment Acknowledgement to info@solutionbuggy.com to Activate your Plan</p>
                                    </div>
                                    <div className="paymentBody">
                                        <div className={classes.tableResponsive}>
                                            <Table className="table">
                                                <TableBody>
                                                    <TableRow className={"tableBackground"}>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>SOLBUGGY CONNECT PRIVATE LIMITED</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>Account No</TableCell>
                                                        <TableCell>59209035553216</TableCell>
                                                    </TableRow>
                                                    <TableRow className={"tableBackground"}>
                                                        <TableCell>Bank</TableCell>
                                                        <TableCell>HDFC Bank</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>Branch</TableCell>
                                                        <TableCell>HSR Layout</TableCell>
                                                    </TableRow>
                                                    <TableRow className={"tableBackground"}>
                                                        <TableCell>IFSC Code</TableCell>
                                                        <TableCell>HDFC0001993</TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>UPI</TableCell>
                                                        <TableCell>solutionbuggy@upi</TableCell>
                                                    </TableRow>
                                                    <TableRow className={"tableBackground"}>
                                                        <TableCell>Google Pay / Paytm</TableCell>
                                                        <TableCell>9035553216</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default BuyMemberShipConsultan