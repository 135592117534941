import React, { useEffect } from "react";
import AuthUser from 'components/Sb/AuthUser'
import { useHistory } from "react-router-dom"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
//import Dvr from "@material-ui/icons/Dvr";
// import Favorite from "@material-ui/icons/Favorite";
// import Close from "@material-ui/icons/Close";
// // core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";

// import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};



const useStyles = makeStyles(styles);

export default function Invoice() {
  const {http} = AuthUser()
  const [data, setData] = React.useState([]);
  const history = useHistory()

  useEffect(() => {
    http.get('/customer/invoiceList').then((res) => {
      if(res.status==200){
      let result = res.data;
      console.log(result);

      let final = result.map((prop, key) => {
        return {
          id: key,
          invoiceId: (<><span style={{fontWeight:"bold"}}>{prop.invoiceId}</span></>),
          date: prop.date,
          title: prop.title,
          totalcost: prop.totalcost,
          actions: (

            <div className="actions-right">
              <Button
                round
                color="rose"
                style={{ fontWeight: 600 }}
                value={prop.projectId}
                onClick={() => {
                  history.push(`/customer/viewInvoice/${prop.invoiceId}`)
                }}
              >
                View
              </Button>
            </div>
          ),
        };
      })
      setData(final);
    }
    }).catch((err) => {
      console.log(err)
    })
  }, []);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}><b>Invoice List</b></h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={[

                {
                  Header: (<><span style={{fontWeight:"bold"}}>Invoice ID</span></>),
                  accessor: "invoiceId",
                },
                {
                  Header: (<><span style={{fontWeight:"bold"}}>Date</span></>),
                  accessor: "date",
                },
                {
                  Header: (<><span style={{fontWeight:"bold"}}>Title</span></>),
                  accessor: "title",
                },
                {
                  Header: (<><span style={{fontWeight:"bold"}}>Total cost</span></>),
                  accessor: "totalcost",
                },
                {
                  Header: (<><span style={{fontWeight:"bold"}}>Action</span></>),
                  accessor: "actions",
                },

              ]}
              data={data}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
