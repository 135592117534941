import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { useHistory } from "react-router-dom";
const useStylesTable = makeStyles(stylesTable);

const HighPriorityDB = (props) => {
  const {noncontactedhigh} = props
  const classesTable = useStylesTable();
  const history = useHistory()

  const [data, setdata] = React.useState([]);

  React.useEffect(() => {
    setdata(noncontactedhigh);
  }, [noncontactedhigh]);

  return (
    <>
      <Card>
        <h4>High Priority (not contacted &gt; 2 days)</h4>
        <h5>Total : {data.length}</h5>
        <CardBody>
          <div className={classesTable.tableResponsive}>
            <Table className="commonTable">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Non Contacted from(in days)</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((eleData, ed) => {
                  return (
                    <TableRow key={ed} className={classesTable.tableRowHover}>
                      <TableCell>{eleData.id}</TableCell>
                      <TableCell>{eleData.name}</TableCell>
                      <TableCell>{eleData.create_date}</TableCell>
                      <TableCell>{eleData.ddif}</TableCell>
                      <TableCell>
                        <Button color="rose" round onClick={()=>{
                            history.push(`/admin/company/${eleData.id}`)
                          }}>
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
HighPriorityDB.propTypes = {
  noncontactedhigh: PropTypes.array,
};
export default HighPriorityDB;