import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// Core Compoenents
// import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { useHistory } from "react-router-dom";
const useStylesTable = makeStyles(stylesTable);

const FreshLeadsSalesDB = ({ noncontacted, todayTask }) => {
  const classesTable = useStylesTable();
  const history = useHistory();
  const [data, setdata] = React.useState([]);
  const [taskdata, setTaskData] = React.useState([]);

  React.useEffect(() => {
    setdata(noncontacted);
  }, []);
  React.useEffect(() => {
    setTaskData(todayTask);
  }, []);
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} lg={6}>
          <div style={{ marginBottom: "60px" }}>
            <h5>
              Not Contacted Fresh Leads last 7 days (Total : {data.length})
            </h5>
            <div className={classesTable.tableResponsive}>
              <table className="freshLeadTable commonTable">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Lead Mapping</th>
                    <th>Lead Type</th>
                    <th>Date Assigned</th>
                    <th>Registered before</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((eleData, ed) => {
                    return (
                      <tr key={ed} className={classesTable.tableRowHover}>
                        <td
                          style={{
                            color: "blue",
                            textDecorationLine: "underline",
                            cursor:"pointer"
                          }}
                          onClick={() => {
                            history.push(`/admin/company/${eleData.id}`);
                          }}
                        >
                          {eleData.id}
                        </td>
                        <td>{eleData.sourcemedium}</td>
                        <td style={{ width: "200px" }}>{eleData.lead_type}</td>
                        <td>{eleData.assigned_date}</td>
                        <td>{eleData.tfr}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} lg={6}>
          <div style={{ marginBottom: "60px" }}>
            <h5>Today Tasks (Total : {taskdata.length})</h5>
            <div className={classesTable.tableResponsive}>
              <table className="freshLeadTable commonTable">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Priority</th>
                    <th>Task Comments</th>
                    <th>Task Time</th>
                    <th>Grading</th>
                  </tr>
                </thead>
                <tbody>
                  {taskdata.map((eleData, e) => {
                    return (
                      <tr key={e} className={classesTable.tableRowHover}>
                        <td
                          style={{
                            color: "blue",
                            textDecorationLine: "underline",
                            cursor:"pointer"
                          }}
                          onClick={() => {
                            history.push(`/admin/company/${eleData.id}`);
                          }}
                        >
                          {eleData.id}
                        </td>
                        <td>{eleData.name}</td>
                        <td>{eleData.sales_priority}</td>
                        <td>{eleData.comments}</td>
                        <td>{eleData.date}</td>
                        <td>{eleData.grade}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
};
FreshLeadsSalesDB.propTypes = {
  noncontacted: PropTypes.array,
  todayTask: PropTypes.array,
};
export default FreshLeadsSalesDB;
