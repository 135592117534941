import React from "react";
import AuthUser from "components/Sb/AuthUser";
import { toast } from "react-toastify";

// import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
// import ReactTable from "components/ReactTable/ReactTable.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

import stylesTable from "assets/jss/material-dashboard-pro-react/components/tableStyle";
import { useHistory } from "react-router-dom";
import Loader from "views/Loader/Loader";
const useStylesTable = makeStyles(stylesTable);

const ProblemTableAdmin = () => {
  const { http, getUser } = AuthUser();
  const classesTable = useStylesTable();
  const history = useHistory();

  const [companyList, setCompanyList] = React.useState([]);

  const [projectID, setprojectID] = React.useState("");
  const [lastUpdated, setlastUpdated] = React.useState("");
  const [noApplications, setnoApplications] = React.useState("");
  const [title, settitle] = React.useState("");
  const [industry, setindustry] = React.useState("");
  const [category, setcategory] = React.useState("");
  const [location, setlocation] = React.useState("");
  const [
    assignedTo1,
    setassignedTo1,
  ] = React.useState("");
  const [
    assignedTo2,
    setassignedTo2,
  ] = React.useState("");

  const [problemType, setproblemType] = React.useState("");
  const [Status, setStatus] = React.useState("");

  const [currentPage, setCurrentPage] = React.useState(1);

  const [filterData, setFilterData] = React.useState([]);
  const dataPerPage = 10;

  React.useEffect(() => {
    http
      .post("/admin/companylist", { id: getUser().user_id })
      .then((response) => {
        const data = response.data;
        if (response) {
          setCompanyList(data.reverse());
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong! ${JSON.stringify(err.message)}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1500,
        });
      });
  }, []);

  React.useEffect(() => {
    setFilterData(companyList);
  }, [companyList]);

  const handleSearch = (key, value) => {
    const result = companyList.filter((ele) => {
      if (ele[key]) {
        return ele[key]
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      }
    });
    setFilterData(result);
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalData = filterData.length;
    const maxPages = Math.ceil(totalData / dataPerPage);

    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Calculate the index range for the expenses to display on the current page
  const startIndex = (currentPage - 1) * dataPerPage;
  const endIndex = startIndex + dataPerPage;

  // Filter and map expenses based on the current page
  const dataToDisplay = filterData.slice(startIndex, endIndex);

  const totalDatas = filterData.length;
  const totalPages = Math.ceil(totalDatas / dataPerPage);

  //   console.log()

  return (
    <div>
      {companyList && companyList.length > 0 ? (
        <>
          <Card>
            <h4 style={{ marginLeft: "18px", marginTop: "18px" }}>
              Paid Projects (Total : {companyList.length})
            </h4>
            <h6
              style={{
                marginLeft: "18px",
                marginTop: "18px",
                textTransform: "none",
              }}
            >
              Sort Company list (Total : {filterData.length})
            </h6>
            <CardBody>
              <div className="pageinationSearch">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <p>
                      Page {currentPage} of {totalPages}
                    </p>
                  </div>
                  <div>
                    <Button round onClick={handlePreviousPage}>
                      Pervious
                    </Button>
                    <Button round onClick={handleNextPage}>
                      Next
                    </Button>
                  </div>
                </div>
              </div>
              <div className={classesTable.tableResponsive}>
                <Table className="commonTable compantListTable">
                  <TableHead>
                    <TableRow>
                      <TableCell>Project ID</TableCell>
                      <TableCell>Last Updated</TableCell>
                      <TableCell>No. of Applications</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Industry</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Assigned To 1</TableCell>
                      <TableCell>Assigned To 2</TableCell>
                      <TableCell>Problem Type (Execution)</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by Project ID"
                            value={projectID}
                            onChange={(e) => {
                              e.preventDefault();
                              setprojectID(e.target.value);
                              handleSearch("projectID", e.target.value);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <input
                          type="text"
                          placeholder="Search by Project ID"
                          value={lastUpdated}
                          onChange={(e) => {
                            e.preventDefault();
                            setlastUpdated(e.target.value);
                            handleSearch("lastUpdated", e.target.value);
                          }}
                          className="searchInput"
                        />
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by no Applications"
                            value={noApplications}
                            onChange={(e) => {
                              e.preventDefault();
                              setnoApplications(e.target.value);
                              handleSearch("noApplications", e.target.value);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by title"
                            value={title}
                            onChange={(e) => {
                              e.preventDefault();
                              settitle(e.target.value);
                              handleSearch("title", e.target.value);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by industry"
                            value={industry}
                            onChange={(e) => {
                              e.preventDefault();
                              setindustry(e.target.value);
                              handleSearch("industry", e.target.value);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by category"
                            value={category}
                            onChange={(e) => {
                              e.preventDefault();
                              setcategory(e.target.value);
                              handleSearch("category", e.target.value);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <input
                            type="text"
                            placeholder="Search by location"
                            value={location}
                            onChange={(e) => {
                              e.preventDefault();
                              setlocation(e.target.value);
                              handleSearch("location", e.target.value);
                            }}
                            className="searchInput"
                          />
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <select
                            name="customer_type"
                            className="searchInput"
                            value={assignedTo1}
                            onChange={(e) => {
                              e.preventDefault();
                              setassignedTo1(e.target.value);
                              handleSearch("assignedTo1", e.target.value);
                            }}
                          >
                            <option value="">select customer type</option>
                            <option value="0">Not Assigned</option>
                            <option value="2">Guru</option>
                            <option value="33">Vikas</option>
                            <option value="60">Mehra</option>

                            <option value="36">Pallavi</option>
                            <option value="71">Nithin</option>
                            <option value="74">Phaneendra</option>
                            <option value="75">Suraj</option>
                            <option value="73">Veenith</option>
                            <option value="111">Pruthviraj</option>
                            <option value="89">Laxmi</option>
                            <option value="92">Shivani</option>
                            <option value="95">Sanjeevani</option>
                            <option value="110">Rajat</option>
                          </select>
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <select
                            name="customer_type"
                            className="searchInput"
                            value={assignedTo2}
                            onChange={(e) => {
                              e.preventDefault();
                              setassignedTo2(e.target.value);
                              handleSearch('assignedTo2', e.target.value);
                            }}
                          >
                            <option value="">select customer type</option>
                            <option value="0">Not Assigned</option>
                            <option value="2">Guru</option>
                            <option value="33">Vikas</option>
                            <option value="60">Mehra</option>

                            <option value="36">Pallavi</option>
                            <option value="70">Dhanlaxmi</option>
                            <option value="71">Nithin</option>
                            <option value="111">Pruthvi Raj B</option>
                            <option value="89">Laxmi</option>
                            <option value="92">Shivani</option>
                            <option value="95">Sanjeevani</option>
                            <option value="110">Rajat</option>
                            <option value="114">Pruthviraj CN</option>
                            <option value="96">Shyam</option>
                            <option value="103">Mannan</option>
                            <option value="107">Vipin</option>
                            <option value="108">Abhisai</option>
                            <option value="113">Gowtham</option>
                          </select>
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <select
                            name="customer_type"
                            className="searchInput"
                            value={problemType}
                            onChange={(e) => {
                              e.preventDefault();
                              setproblemType(e.target.value);
                              handleSearch("problemType", e.target.value);
                            }}
                          >
                            <option>Select</option>
                            <option value="x">Not Defined</option>
                            <option value="1">Normal</option>
                            <option value="2">Execution</option>
                          </select>
                        </>
                      </TableCell>
                      <TableCell>
                        <>
                          <select
                            name="customer_type"
                            className="searchInput"
                            value={Status}
                            onChange={(e) => {
                              e.preventDefault();
                              setStatus(e.target.value);
                              handleSearch("Status", e.target.value);
                            }}
                          >
                            <option>Select</option>
                            <option value="x">Not Live</option>
                            <option value="1">Active</option>
                            <option value="2">Awarded(Normal)/Proposal Sent(Execution)</option>
                            <option value="3">On Hold</option>
                            <option value="4">Awarded(Execution)</option>
                            <option value="5">Dropped(Execution)</option>
                            <option value="6">Completed(Execution)</option>
                          </select>
                        </>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataToDisplay.map((company, c) => {
                      return (
                        <TableRow
                          key={c}
                          className={classesTable.tableRowHover}
                        >
                          <TableCell>
                            <Button
                              color="rose"
                              round
                              onClick={() => {
                                history.push(`/admin/company/${company.id}`);
                              }}
                            >
                              View
                            </Button>
                          </TableCell>
                          <TableCell>
                            {/* {company.priority} */}
                            {company.priority &&
                              company.priority.toLowerCase() == "high" && (
                                <div style={{ textAlign: "center" }}>
                                  <Button color="success">
                                    {company.priority}
                                  </Button>
                                </div>
                              )}
                            {company.priority &&
                              company.priority.toLowerCase() == "medium" && (
                                <div style={{ textAlign: "center" }}>
                                  <Button color="warning">
                                    {company.priority}
                                  </Button>
                                </div>
                              )}
                          </TableCell>
                          <TableCell>{company.grade}</TableCell>
                          <TableCell>{company.customer_id}</TableCell>
                          <TableCell>{company.name}</TableCell>
                          <TableCell>{company.email}</TableCell>
                          <TableCell>{company.mobile}</TableCell>

                          <TableCell>{company.customer_type}</TableCell>
                          <TableCell>{company.previously_assigned}</TableCell>

                          <TableCell>{company.last_contacted}</TableCell>
                          <TableCell>{company.plan_taken}</TableCell>

                          <TableCell>{company.list_name}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <div className="pageinationSearch">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <p>
                      Page {currentPage} of {totalPages}
                    </p>
                  </div>
                  <div>
                    <Button round onClick={handlePreviousPage}>
                      Pervious
                    </Button>
                    <Button round onClick={handleNextPage}>
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </div>
  );
};

export default ProblemTableAdmin;
